import * as S from "./GnbMyPage.style";
import { useAtom } from "jotai";
import { NextImageWrapper } from "../../../atoms/NextImageWrapper";
import { useUserInfo } from "../../../../businesslogics/_common/auth";
import { GnbRowMobile } from "../atoms/GnbRow.mobile";
import { isMyPageOpenAtom } from "../../../../stores/_layout/gnb";
import { useIsLoggedInV2 } from "../../../../businesslogics/_common/login";
import { useEffect, useRef, useState } from "react";
import { useOnOutsideClick } from "../../../../businesslogics/events/useOnOutsideClick";
import { showLineBannerAtom } from "../../../../stores/showLineBannerAtom";
import { CouponIcon, PointIcon } from "./GnbMyPage.svg";
import { logoutAnchor, mobileMyPageAnchors } from "../models/gnbV3.models";
import {
  useClickAnchor,
  useUserMetaData,
} from "../../../../businesslogics/_layout/gnb/v2";
import {
  couponAnchor,
  pointAnchor,
} from "../../../../models/_layout/gnbV2.models";
import { isLoginModalShowAtom } from "../../../../stores/_common/modal";
import { ArrowChevronRightSmall } from "@teamsparta/design-system";
import { ZeroWonIcon } from "src/components/atoms/Icons/ZeroWon/ZeroWon.svg";
import Link from "next/link";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

export const GnbMyPage = () => {
  const userMetaData = useUserMetaData();
  const onClickPointAnchor = useClickAnchor(pointAnchor);
  const onClickCouponAnchor = useClickAnchor(couponAnchor);
  const [show, setIsMyPageOpenAtom] = useAtom(isMyPageOpenAtom);
  const isLoggedIn = useIsLoggedInV2();
  const userName = useUserInfo("name");
  const userEmail = useUserInfo("email");
  const myPageRef = useRef(null);
  useOnOutsideClick(myPageRef, () => setIsMyPageOpenAtom(false));
  const [showLineBanner] = useAtom(showLineBannerAtom);
  const [, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  //userMetaData를 바로 받아오지 못해 재시도 로직 추가
  const [retryCount, setRetryCount] = useState(0); // 재시도 횟수 관리
  const MAX_RETRIES = 3; // 최대 재시도 횟수
  const RETRY_INTERVAL = 100;

  useEffect(() => {
    if (!isLoggedIn && show) {
      setIsLoginModalShow(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!userMetaData && retryCount < MAX_RETRIES) {
      const timer = setTimeout(() => {
        setRetryCount((prevCount) => prevCount + 1);
      }, RETRY_INTERVAL);

      return () => clearTimeout(timer); // 컴포넌트 unmount 시 타이머 클리어
    }
  }, [userMetaData, retryCount]);
  const imgLink = `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/profile/developer.png`;

  return (
    <S.ToolTipBox
      show={show}
      isShort={!isLoggedIn}
      ref={myPageRef}
      showLineBanner={showLineBanner}
    >
      <S.MyPageContainer>
        <S.GreetingContainer>
          {isLoggedIn && (
            <>
              <S.UserInfoWrapper>
                <NextImageWrapper
                  src={imgLink}
                  mobileWidth={40}
                  mobileHeight={40}
                  borderRadius={"100%"}
                />
                <S.ProfileContainer>
                  <S.UserName>{userName}</S.UserName>
                  <S.EmailContainer>{userEmail}</S.EmailContainer>
                </S.ProfileContainer>
              </S.UserInfoWrapper>
              <S.PointCouponContainer>
                <S.PointCouponWrapper onClick={onClickPointAnchor}>
                  <S.TitleWrapper>
                    <PointIcon />
                    <S.PointCouponTitle>포인트</S.PointCouponTitle>
                  </S.TitleWrapper>
                  <S.PointCouponContent>
                    {userMetaData?.point || "로딩중"}
                  </S.PointCouponContent>
                </S.PointCouponWrapper>
                <S.ReversDivider />
                <S.PointCouponWrapper onClick={onClickCouponAnchor}>
                  <S.TitleWrapper>
                    <CouponIcon />
                    <S.PointCouponTitle>쿠폰</S.PointCouponTitle>
                  </S.TitleWrapper>
                  <S.PointCouponContent>
                    {userMetaData?.coupon
                      ? userMetaData.coupon.split("장")[0] > 0
                        ? userMetaData?.coupon
                        : "없음"
                      : "로딩중"}
                  </S.PointCouponContent>
                </S.PointCouponWrapper>
              </S.PointCouponContainer>
            </>
          )}
        </S.GreetingContainer>

        <S.RowsContainer>
          <S.Divider />
          <S.Group>
            {mobileMyPageAnchors.map((anchor) => (
              <div key={`gnb-mypage-mobile-${anchor.key}`}>
                {isLoggedIn && (
                  <div key={`gnb-mypage-mobile-${anchor.key}`}>
                    <GnbRowMobile anchor={anchor} />
                  </div>
                )}
              </div>
            ))}
            {/* <Link
              href={`${process.env.BASE_URL}/nb/guide`}
              onClick={() => {
                sendCPLog("scc_myPage_click", {
                  button_text: "국비지원 신청 가이드",
                });
              }}
            >
              <S.MyPageBanner>
                <S.BanenrContainer>
                  <S.BannerIconWrapper>
                    <ZeroWonIcon />
                  </S.BannerIconWrapper>
                  <S.BannerTextContainer>
                    <S.BannerSubText>50만원 강의를 0원으로?</S.BannerSubText>
                    <S.BannerText>국비지원 신청 가이드 보기</S.BannerText>
                  </S.BannerTextContainer>
                </S.BanenrContainer>
                <ArrowChevronRightSmall size={16} color={"#9da7ae"} />
              </S.MyPageBanner>
            </Link> */}
          </S.Group>
          <S.Divider />

          <S.Group>
            <GnbRowMobile
              key={`gnb-mypage-mobile-${logoutAnchor.key}`}
              anchor={logoutAnchor}
            />
          </S.Group>
        </S.RowsContainer>
      </S.MyPageContainer>
    </S.ToolTipBox>
  );
};

export const courseIds = {
  //tmt
  web: "5f0ae408765dae0006002816",
  sql: "5f51cba7d76b11f9244d1111",
  app: "5f0ae408765dae0006002817",
  game: "6083eaca6305e019d3e0c3b4",
  data_v2: "6361d2ce4239a844b30d4163",
  dataforbusiness: "6083eb1f6305e019d3e0c483",
  nocode: "63d8ec592ad417e6257ffef8",
  firststep: "63d8cb5e2ad417e6257ffd44",

  //  general
  ml_basic: "602a022f5b5001847b616df9",
  spring: "5f8315459328d9a2909acb52",
  algo: "5f8bc3e83cff77cc2abeb181",
  lang_python: "5feffb89f85b7ed4b4bdc7bd",
  lang_java: "606bfb39c11b70df6da82d51",
  lang_cpp: "606bfc0fc11b70df6da82da5",
  lang_js: "606bfa74c11b70df6da82cfd",
  zepeto: "6295890f8240a11ae2511cab",
  finance_automation: "62973372bf6e0e4f799fc76e",
  webplus: "5faa208089ddcab5ed1a47cd",

  // b2b
  dockerforbusiness: "6083ebb16305e019d3e0c552",
  app_plusforbusiness: "602a02345104e5fd0517dd95",
  dlforbusiness: "5f51cba0e62d08d140b3e5bd",
  reactforbusiness: "5f83153e410f5ac76429585d",
  nodeforbusiness: "6049dc8de987c144932211b3",
  node_plusforbusiness: "6049dc3ee987c1449322114a",
  spring_plusforbusiness: "6049dc03e987c144932210e1",
  react_plusforbusiness: "6049dbbbe987c14493221078",
  drill_gitforbusiness: "606bee36c11b70df6da82afe",
  androidforbusiness: "606bf913c11b70df6da82bc9",
  djangoforbusiness: "606bf98bc11b70df6da82c32",
  iosforbusiness: "606bf88ec11b70df6da82b60",
  drill_awsforbusiness: "606bccf0c11b70df6da82a9d",
  web_pubforbusiness: "60fa73bb3f9cb1a2ca68aaf3",
  ml_aiforbusiness: "60fa74443f9cb1a2ca68abc7",
  pytorch_dlforbusiness: "60fa748c3f9cb1a2ca68ac9a",
  aws_plusforbusiness: "60fa74cc3f9cb1a2ca68ad6d",

  package_sql: "643e10c8ae797ba22b4a8c22",

  // master
  web_basic: "649938ae1e7a5e8c1b9dfb37",
  ai_news: "6504029282a304dd31edfb7f",
  moonwish: "64efe93e3c4f28c927faf0bf",
  androidvsios: "652bea6b08647a82bb62eb54",
};

export const courseTitles = {
  // tmt
  web: "웹개발 종합반",
  sql: "엑셀보다 쉬운 SQL",
  app: "앱개발 종합반",
  game: "게임개발 종합반",
  data_v2: "데이터 분석 종합반",
  nocode: "노코드로 빠르게 웹 서비스 만들기",
  firststep: "컴퓨터를 몰라도 할 수 있는 코딩 첫걸음",

  // general
  ml_basic: "가장 쉽게 배우는 머신러닝",
  spring: "웹개발의 봄 Spring",
  algo: "알고보면 알기쉬운 알고리즘",
  lang_python: "파이썬 문법 뽀개기",
  lang_java: "Java 문법 뽀개기",
  lang_cpp: "C++ 문법 뽀개기",
  lang_js: "JavaScript 문법 뽀개기",
  zepeto: "[스파르타xNAVER Z] 제페토 메타버스 뉴월드 게임개발 종합반",
  finance_automation: "금융인을 위한 파이썬 업무자동화",
  webplus: "웹개발 플러스",

  // b2b
  dockerforbusiness: "핵심 쏙쏙 도커",
  app_plusforbusiness: "앱개발 플러스",
  dlforbusiness: "이미지처리로 시작하는 딥러닝",
  reactforbusiness: "프론트엔드의 꽃, 리액트",
  nodeforbusiness: "Node.js 기초반",
  node_plusforbusiness: "Node.js 심화반",
  spring_plusforbusiness: "Spring 심화반",
  react_plusforbusiness: "리액트 심화반",
  drill_gitforbusiness: "핵심 쏙쏙 Git",
  androidforbusiness: "안드로이드 앱개발 기초반",
  djangoforbusiness: "Django 기초반",
  iosforbusiness: "iOS 앱개발 기초반",
  drill_awsforbusiness: "핵심 쏙쏙 AWS",
  web_pubforbusiness: "웹 퍼블리싱 정복반",
  ml_aiforbusiness: "실무에 바로쓰는 AI 예측/추천",
  pytorch_dlforbusiness: "PyTorch로 시작하는 딥러닝",
  aws_plusforbusiness: "심화 쏙쏙 AWS",

  //  special
  myprofile: "나만의 프로필 링크 만들기",
  momentum: "나만의 동기부여 홈화면 만들기",
  bucketlist: "2023 나만의 버킷리스트 만들기",
  photos: "인생사진 쏙쏙 코딩네컷",
  chang: "IT 창업, 실패 확률 줄이는 방법",
  hr: "교육 담당자를 위한 DT교육 설계 로드맵",
  teenager: "중고등학생 코딩교육 준비 전략",
  chatgpt: "ChatGPT로 10분 만에 웹사이트 만들기",
  nbfree: "내일배움카드 발급부터 활용의 모든 것",
  wf_free: "코드 없이 AI로 웹사이트 하나가 뚝딱",
  "ai-virtual": "AI로 나만의 버추얼 캐릭터 만들기",
  codingtest: "1시간 만에 정복하는 코딩 테스트 합격법",

  // master
  web_basic: "[왕초보] 웹개발 종합반",
  androidvsios: "앱 개발의 모든 것, 안드로이드 vs iOS",
};

import Image, { ImageProps } from "next/legacy/image";
import { ReactElement } from "react";
import { useFallbackImageSrc } from "src/businesslogics/_common/fallbackImage/useFallbackImageSrc";

export type NextImageWithFallbackProps = ImageProps;

export const NextImageWithFallback: React.FC<NextImageWithFallbackProps> = ({
  src,
  ...rest
}): ReactElement => {
  const { imgSrc, primarySrc, onErrorImage } = useFallbackImageSrc(src);

  return process.env.IS_PROD ? (
    <Image {...rest} src={primarySrc} />
  ) : (
    <Image {...rest} src={imgSrc} onError={onErrorImage} />
  );
};

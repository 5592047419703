import React from "react";
import { Gap } from "../../../styles/util";
import * as S from "../../../components/organisms/online/special/Benefit/Benefit.style";
import {
  Banner,
  Curriculum,
  EventBenefit,
  Future,
  Hero,
  Meta,
  NewBenefit,
  Payment,
  Recommend,
  SpecialFAQ,
  Target,
  Tutor,
} from "./special";
import { isNewYear } from "../../../businesslogics/free_pass";
import {
  isChannelTalkAnswer,
  isFastQna,
  isIn24HoursAnswer,
} from "src/businesslogics/online/special";
import { courseTitles } from "../courses";
import {
  AiVirtual,
  Bucketlist,
  ChatGPT,
  CodingTest,
  DataTransfomation,
  ItStartUp,
  Momentum,
  MyProfile,
  NBFree,
  Photos,
  WfFree,
  Youth,
} from "src/models/catalog/courses.model";

export const MONTHLY_SPECIAL = (() => {
  switch (`${new Date().getFullYear()}/${new Date().getMonth() + 1}`) {
    case "2022/10":
      return "myprofile";
    case "2022/11":
      return "momentum";
    case "2022/12":
      return "bucketlist";
    case "2023/1":
      return "bucketlist";
    case "2023/2":
      return "photos";
    case "2023/3":
      return "photos";
    default:
      return "photos";
  }
})();

// export const MONTHLY_SPECIAL = "momentum";

export const MonthlyCourseUrls = [
  "myprofile",
  "momentum",
  "bucketlist",
  "photos",
];

const lnbItems = (url: string) => {
  switch (url) {
  }
};

export const getSpecialMetaData = (url: string): Meta => {
  switch (url) {
    case "myprofile":
      return {
        title: "나만의 프로필 링크 페이지 만들기 | 스파르타코딩클럽 무료 특강",
        description:
          "1시간 만에 할 수 있는 무료 코딩 강의. 코딩을 처음 해 봐도 괜찮아요. 스파르타코딩클럽에서 시작하세요!",
        ogTitle: "스파르타코딩클럽 무료특강 | 프로필 링크 페이지 만들기",
        ogDescription:
          "1시간 만에 할 수 있는 무료 코딩 강의. 코딩을 처음 해 봐도 괜찮아요. 스파르타코딩클럽에서 시작하세요!",
        ogSiteName:
          "스파르타코딩클럽 무료특강 | 나만의 프로필 링크 페이지 만들기",
        keywords:
          "스파르타무료특강, 코딩 무료 강의, 웹개발, 파이썬, python, 무료코딩, 무료코딩강의, 무료교육, 무료강의, 코딩강의, 코딩공부, 코딩, 프로그래밍, 코딩 스터디, 개발자, 개발자 취업, 웹서버, 자바스크립트, 리액트, React, 스프링, Spring, React Native, 마케터코딩, PM코딩, 퍼스널브랜딩, 프로필링크, 프로필 링크 페이지, 인스타프로필, 인스타그램 프로필, 인스타그램 프로필 링크, 무료 링크 페이지, 셀프브랜딩, 브랜딩, 자영업, 인플루언서, 아이돌팬페이지, 인스타마켓",
        ogUrl: `${process.env.BASE_URL}/online/special/myprofile`,
        ogType: "website",
      };
    case "momentum":
      return {
        title: "나만의 동기부여 홈화면 만들기 | 스파르타코딩클럽 무료 특강",
        description:
          "1시간 만에 코딩을 무료로 배워 동기부여가 되는 문장들을 나만의 인터넷 홈화면에 넣어보세요.",
        ogTitle: "스파르타코딩클럽 무료특강 | 나만의 동기부여 홈화면 만들기",
        ogDescription:
          "1시간 만에 무료로 코딩을 배워 동기부여가 되는 문장들을 나만의 인터넷 홈화면에 넣어보세요.",
        ogSiteName: "스파르타코딩클럽 무료특강 | 나만의 동기부여 홈화면 만들기",
        keywords:
          "스파르타무료특강, 코딩 무료 강의, 무료코딩강의, 무료교육, 무료강의, 코딩강의, 코딩공부, 코딩, 프로그래밍, 코딩 스터디, 스타트업, 생산성, 동기부여, 사이드프로젝트, 직장인 자기계발, 자기계발, 자기개발, 직장인 자기개발, 모멘텀, 크롬 익스텐션, 동기부여, 명언, 코딩 실습 강의, 왕초보 코딩강의, 웹개발, html, css, 왕초보, 다이어리",
        ogUrl: `${process.env.BASE_URL}/online/special/momentum`,
        ogType: "website",
      };
    case "bucketlist":
      return {
        title: "2023 나만의 버킷리스트 만들기 | 스파르타코딩클럽 무료 특강",
        description:
          "1시간 만에 코딩 무료 강의로 나만의 2023 새해 버킷리스트를 만들어 보세요.",
        ogTitle: "스파르타코딩클럽 무료특강 | 2023 나만의 버킷리스트 만들기",
        ogDescription:
          "1시간 만에 코딩 무료 강의로 나만의 2023 새해 버킷리스트를 만들어 보세요.",
        ogSiteName: "스파르타코딩클럽 무료특강 | 2023 나만의 버킷리스트 만들기",
        keywords:
          "스파르타코딩클럽, 스파르타무료특강, 코딩 무료 강의, 무료코딩강의, 무료교육, 무료강의, 월간코딩, 코딩강의, 코딩공부, 코딩, 프로그래밍, 스타트업, 작심삼일, 2023 버킷리스트, 신년 목표, 새해 목표, 새해 계획, 새해 위시리스트, 새해 버킷리스트, 2023 새해 목표, 2023 목표, 갓생, 목표 관리, 목표 달성 방법, 코딩 포트폴리오, 직장인 자기계발, 자기계발, 자기개발, 직장인 자기개발, IT 자기계발, IT 자기개발, 새해 자기계발, 새해 자기개발, 자기관리, 코딩 실습 강의, 왕초보 코딩강의, 웹개발, html, css, 왕초보",
        ogUrl: `${process.env.BASE_URL}/online/special/bucketlist`,
        ogType: "website",
      };
    case "photos":
      return {
        title: "인생사진 쏙쏙 코딩네컷 | 스파르타코딩클럽 무료특강",
        description:
          "1시간 만에 코딩을 무료로 배워 인생사진을 모아 추억을 기록하는 네컷사진으로 만들어보세요.",
        ogTitle: "인생사진 쏙쏙 코딩네컷 | 스파르타코딩클럽 무료특강",
        ogDescription:
          "1시간 만에 코딩을 무료로 배워 인생사진을 모아 추억을 기록하는 네컷사진으로 만들어보세요.",
        ogSiteName: "인생사진 쏙쏙 코딩네컷 | 스파르타코딩클럽 무료특강",
        keywords:
          "스파르타무료특강, 코딩 무료 강의, 무료코딩강의, 무료교육, 무료강의, 코딩강의, 코딩공부, 코딩, 프로그래밍, 코딩 스터디, 스타트업, 생산성, 사이드프로젝트, 직장인 자기계발, 자기계발, 자기개발, 직장인 자기개발, 코딩 실습 강의, 왕초보 코딩강의, 웹개발, html, css, 왕초보, 인생네컷, 사진편집, 이미지 편집, 추억기록, 비디오제작, 비디오, 영상, 사진",
        ogUrl: `${process.env.BASE_URL}/online/special/photos`,
        ogType: "website",
      };
    case "chang":
      return {
        title: "IT 창업, 실패 확률 줄이는 방법 | 스파르타코딩클럽 무료 특강",
        description:
          "1시간 만에 IT 창업에 적합한 아이템 선정과 팀빌딩 그리고 방법론까지 알려드립니다.",
        ogTitle: "스파르타코딩클럽 무료특강 | IT 창업, 실패 확률 줄이는 방법",
        ogDescription:
          "1시간 만에 IT 창업에 적합한 아이템 선정과 팀빌딩 그리고 방법론까지 알려드립니다.",
        ogSiteName:
          "스파르타코딩클럽 무료특강 | IT 창업, 실패 확률 줄이는 방법",
        keywords:
          "스파르타무료특강, 창업 무료 강의, 무료창업강의, 무료교육, 무료강의, 코딩강의, 코딩공부, 코딩, 프로그래밍, 코딩 스터디, 스타트업, 스타트업창업, 창업, 사이드프로젝트, IT창업, 기술창업, 사업, 부업, 직장인 창업, N잡러, 인디펜던트 워커, 개발자, 창업가, 앱, 어플, 웹사이트, startup",
        ogUrl: `${process.env.BASE_URL}/online/special/chang`,
        ogType: "website",
      };
    case "hr":
      return {
        title:
          "교육 담당자를 위한 DT교육 설계 로드맵 | 스파르타코딩클럽 무료 특강",
        description:
          "우리 회사를 디지털 기반의 조직으로 변화하기 위한 준비. DT와 DX의 모든 것 스파르타코딩클럽에서 1시간만에 무료로 알려드립니다.",
        ogTitle:
          "스파르타코딩클럽 무료특강 | 교육 담당자를 위한 DT교육 설계 로드맵",
        ogDescription:
          "가장 임팩트 있고 효과적인 성과를 내는 DT교육 설계를 알려드립니다. 직무교육도 스파르타에서 시작하세요.",
        ogSiteName:
          "스파르타코딩클럽 무료특강 | 교육 담당자를 위한 DT교육 설계 로드맵",
        keywords:
          "스파르타무료특강, 무료강의, 무료교육, 무료코딩, 무료코딩강의, DT, DX, Digital Transformation, 디지털 트랜스포메이션, 디지털 교육, 직무교육, 기업교육, 디지털 전환, 임직원 교육, 기업 복지, 복지, DT 로드맵, 역량 교육, C레벨 교육, IT 전환, IT, 컨설팅, 데이터 기반 문화, 데이터 컨설팅, 디지털 전환 컨설팅, 코딩, 프로그래밍, 자동화, 전산화",
        ogUrl: `${process.env.BASE_URL}/online/special/hr`,
        ogType: "website",
      };
    case "teenager":
      return {
        title: "중고등학생 코딩교육 준비 전략 | 스파르타코딩클럽 무료 특강",
        description:
          "고등학생이 코딩을 입시와 취업에 활용하는 방법부터 학습방법과 포트폴리오까지 알려드리겠습니다.",
        ogTitle: "스파르타코딩클럽 무료특강 | 중고등학생 코딩교육 준비 전략",
        ogDescription:
          "중고등학생이 코딩을 입시와 취업에 활용하는 방법부터 학습방법과 포트폴리오까지 알려드리겠습니다.",
        ogSiteName: "스파르타코딩클럽 무료특강 | 중고등학생 코딩교육 준비 전략",
        keywords:
          "스파르타무료특강, 무료강의, 무료교육, 무료코딩, 무료코딩강의, 코딩교육, 어린이코딩, 청소년코딩, 중고등학생 코딩, 코딩입시, 코딩 포트폴리오, 코딩, 소프트웨어학과, 전산학과, 개발자, 입시, 진로, 진학, 취업, 아이코딩, 초등학생 코딩, 초등 코딩, IT 특성화고, 코딩교육 전략, 코딩입시, 코딩학원, 코딩학습지, 온라인코딩, 코딩독학, 엔트리코딩, 코딩자격증, 코딩과외",
        ogUrl: `${process.env.BASE_URL}/online/special/teenager`,
        ogType: "website",
      };
    case "chatgpt":
      return {
        title:
          "chatGPT로 10분 만에 웹사이트 만들기 | 스파르타코딩클럽 무료특강",
        description:
          "코딩에 chatGPT를 더해 누구보다 빠르게 웹을 구현해보세요. 생산성과 효율성을 극대화 하는 chatGPT 제일 잘 쓰는 방법을 알려드려요.",
        ogTitle:
          "chatGPT로 10분 만에 웹사이트 만들기 | 스파르타코딩클럽 무료특강",
        ogDescription:
          "코딩에 chatGPT를 더해 누구보다 빠르게 웹을 구현해보세요. 생산성과 효율성을 극대화 하는 chatGPT 제일 잘 쓰는 방법을 알려드려요.",
        ogSiteName:
          "chatGPT로 10분 만에 웹사이트 만들기 | 스파르타코딩클럽 무료특강",
        keywords:
          "스파르타무료특강, 코딩 무료 강의, 무료코딩강의, 무료교육, 무료강의, 코딩강의, 코딩공부, 코딩, 프로그래밍, 코딩 스터디, 스타트업, 생산성, 사이드프로젝트, 직장인 자기계발, 자기계발, 자기개발, 직장인 자기개발, 코딩 실습 강의, 왕초보 코딩강의, 웹개발, html, css, 왕초보, chatGPT, GPT, AI, 인공지능, 챗GPT, 쳇GPT, CHATGPT사용법, 오픈AI, openAI, AI코딩",
        ogUrl: `${process.env.BASE_URL}/online/special/chatgpt`,
        ogType: "website",
      };
    case "nbfree":
      return {
        title:
          "내일배움카드 발급부터 활용의 모든 것 | 스파르타코딩클럽 무료특강",
        description:
          "혼자 발급받기 어려운 내일배움카드, 스파르타코딩클럽이 도와드릴게요. 내일배움카드 신청 방법부터 최대 활용 방법까지 알려드립니다.",
        ogTitle:
          "내일배움카드 발급부터 활용의 모든 것 | 스파르타코딩클럽 무료특강",
        ogDescription:
          "혼자 발급받기 어려운 내일배움카드, 스파르타코딩클럽이 도와드릴게요. 내일배움카드 신청 방법부터 최대 활용 방법까지 알려드립니다.",
        ogSiteName:
          "내일배움카드 발급부터 활용의 모든 것 | 스파르타코딩클럽 무료특강",
        keywords:
          "스파르타무료특강, 코딩 무료 강의, 무료코딩강의, 무료교육, 무료강의, 코딩강의, 코딩공부, 코딩, 프로그래밍, 코딩 스터디, 스타트업, 생산성, 사이드프로젝트, 직장인 자기계발, 자기계발, 자기개발, 직장인 자기개발, 코딩 실습 강의, 왕초보 코딩강의, 웹개발, html, css, 왕초보, 워크넷, 사용처, 신청방법, 청년, 학원, 학습, 자격증, 국비지원, 시험, 훈련, 상담, 직업, 내일배움카드, 내일배움단, 국비지원카드, hrdnet, hrd넷, 국비지원교육종류, 국비지원코딩, 국비지원컴퓨터, 컴퓨터학원, 코딩학원, 코딩교육, 국비지원취업",
        ogUrl: `${process.env.BASE_URL}/online/special/nbfree`,
        ogType: "website",
      };
    case "wf_free":
      return {
        title: "코드 없이 AI로 웹사이트 하나가 뚝딱 | 스파르타코딩클럽",
        description:
          "AI와 노코드로 만들고 싶은 웹사이트를 뚝딱 만들어보세요. 생산성과 효율성 극대화 꿀팁을 알려드릴게요.",
        ogTitle: "코드 없이 AI로 웹사이트 하나가 뚝딱 | 스파르타코딩클럽",
        ogDescription:
          "AI와 노코드로 만들고 싶은 웹사이트를 뚝딱 만들어보세요. 생산성과 효율성 극대화 꿀팁을 알려드릴게요.",
        ogSiteName: "코드 없이 AI로 웹사이트 하나가 뚝딱 | 스파르타코딩클럽",
        keywords:
          "코딩강의, 코딩공부, 코딩무료, 무료코딩, 스파르타코딩클럽 무료특강, 스파르타코딩클럽 무료, 코딩, 프로그래밍, 코딩 스터디, 스타트업, 업무생산성, 생산성, 웹사이트 만들기, 사이드프로젝트, 직장인 업무 자동화, 업무자동화, 직장인 자기계발, 자기계발, 자기개발, 직장인 자기개발, 코딩 실습 강의, 왕초보 코딩강의, 웹개발, html, css, 왕초보, webflow, Webflow, 웹플로우, ChatGPT, chatGPT, GPT, 노코드, Nocode, no코드, 코드 없이, 코딩 없이, AI, 디자인템플릿, 박스 모델, gpt 질문 꿀팁, Image Creator from Microsoft Bing, Microsoft, 챗GPT, 쳇GPT, CHATGPT사용법, 머신러닝, 오픈AI, openAI, AI코딩",
        ogUrl: `${process.env.BASE_URL}/online/special/wf_free`,
        ogType: "website",
      };
    case "ai-virtual":
      return {
        title: "AI로 나만의 버추얼 캐릭터 만들기 | 스파르타코딩클럽",
        description:
          "생성 AI를 활용해서 나만의 버추얼 캐릭터를 만들어보세요. AI를 잘 다루는 법만 알면 세상에 없던 나만의 결과물을 만들어낼 수 있어요.",
        ogTitle: "AI로 나만의 버추얼 캐릭터 만들기 | 스파르타코딩클럽",
        ogDescription:
          "생성 AI를 활용해서 나만의 버추얼 캐릭터를 만들어보세요. ",
        ogSiteName: "AI로 나만의 버추얼 캐릭터 만들기 | 스파르타코딩클럽",
        keywords:
          "스파르타코딩클럽, 코딩 강의, 국비지원, 생성AI, 캐릭터, 클론코딩, 인공지능, 오픈AI, 코딩, 챗봇, 스파르타",
        ogUrl: `${process.env.BASE_URL}/online/special/ai-virtual`,
        ogType: "website",
      };
    case "codingtest":
      return {
        title: "1시간만에 정복하는 코딩테스트 합격법 | 스파르타코딩클럽",
        description:
          "코딩테스트 합격의 비밀, 이 강의에서 모두 공개합니다! 1시간 만에 나만의 알고리즘 학습 로드맵까지 만들 수 있어요. ",
        ogTitle: "1시간만에 정복하는 코딩테스트 합격법 | 스파르타코딩클럽",
        ogDescription: "1시간 만에 정복하는 코딩 테스트 합격법! ",
        ogSiteName: "1시간만에 정복하는 코딩테스트 합격법 | 스파르타코딩클럽",
        keywords:
          "스파르타코딩클럽, 코딩 강의, 무료 특강, 무료 강의, 국비지원, 코딩테스트, 코딩 테스트, 코딩테스트 합격비법, 코딩테스트 준비, 코딩테스트 출제, 알고리즘, 자료구조, 파이썬, 자바, C++, C, 알고리즘 공부, 알고리즘 학습법, 프로그래머스, LeetCode, HackerRank, SWEA, codingtest",
        ogUrl: `${process.env.BASE_URL}/online/special/codingtest`,
        ogType: "website",
      };
  }
};

export const getSpecialPaymentData = (url: string): Payment => {
  switch (url) {
    case "myprofile":
      return {
        cid: "62a739bfc826b9902f9d9ad1",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: MyProfile,
      };
    case "momentum":
      return {
        cid: "6358e2f6d5e61148f86b3267",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: Momentum,
      };
    case "bucketlist":
      return {
        cid: "637dc32b33db1e53a14a6d29",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: Bucketlist,
      };
    case "photos":
      return {
        cid: "63d5e477263dfb150c1c3c94",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: Photos,
      };
    case "chang":
      return {
        cid: "632bec62293ed67329e02c62",
        title: courseTitles[url],
        displayPrice: 50000,
        discountPrice: 0,
        courseData: ItStartUp,
      };
    case "hr":
      return {
        cid: "632ad50b2b63868e4030eb5b",
        title: courseTitles[url],
        displayPrice: 50000,
        discountPrice: 0,
        courseData: DataTransfomation,
      };
    case "teenager":
      return {
        cid: "6334259bd6566f2cb23ec7f7",
        title: courseTitles[url],
        displayPrice: 50000,
        discountPrice: 0,
        courseData: Youth,
      };
    case "chatgpt":
      return {
        cid: "6420ea5f7e6e4c8f5d0af5d0",
        title: courseTitles[url],
        displayPrice: 50000,
        discountPrice: 0,
        courseData: ChatGPT,
      };
    case "nbfree":
      return {
        cid: "642540848a9def2d6766b27a",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: NBFree,
      };
    case "wf_free":
      return {
        cid: "642c2dfff6dfefee6dc47bfb",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: WfFree,
      };
    case "ai-virtual":
      return {
        cid: "64939ff350f4c9159ab204d8",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: AiVirtual,
      };
    case "codingtest":
      return {
        cid: "64e32bb1fd878f27f5acae5f",
        title: courseTitles[url],
        displayPrice: 89000,
        discountPrice: 0,
        courseData: CodingTest,
      };
  }
};

export const getSpecialHeroData = (url: string): Hero => {
  switch (url) {
    case "myprofile":
      return {
        chips: ["인스타프로필", "무료특강", "왕초보"],
        title: courseTitles[url],
        checkPoints: [
          "코딩의 ㅋ도 모르는 왕초보 대상",
          "직접 만들어서 인스타 프로필 링크 바꾸기",
        ],
        displayPrice: 89000,
        discountPrice: 0,
        customCTA: "수강신청하기",
        month: 10,
      };
    case "momentum":
      return {
        chips: ["크롬익스텐션", "모멘텀", "월간코딩"],
        title: courseTitles[url],
        checkPoints: [
          "코딩의 ㅋ도 모르는 왕초보 대상",
          "매일매일 동기부여를 할 수 있는 홈화면",
        ],
        displayPrice: 89000,
        discountPrice: 0,
        thumbnail: true,
        customCTA: "수강신청하기",
        month: 11,
      };
    case "bucketlist":
      return {
        chips: ["새해 계획 세우기", "자기계발", "버킷리스트"],
        title: courseTitles[url],
        checkPoints: [
          "코딩의 ㅋ도 모르는 왕초보 대상",
          "직접 만들고 알림까지 받을 수 있는 버킷리스트",
        ],
        displayPrice: 89000,
        discountPrice: 0,
        customCTA:
          MONTHLY_SPECIAL === url ? "선착순 수강신청하기" : "수강신청하기",
        month: 1,
      };
    case "photos":
      return {
        chips: [],
        title: courseTitles[url],
        checkPoints: [],
        displayPrice: 89000,
        discountPrice: 0,
        customCTA:
          MONTHLY_SPECIAL === url ? "선착순 수강신청하기" : "수강신청하기",
        month: 2,
      };
    case "chang":
      return {
        chips: ["IT창업", "무료특강", "왕초보"],
        title: courseTitles[url],
        checkPoints: [
          "IT 창업을 꿈꾸고 있는 누구나 수강 가능",
          "아이템 선정과 팀빌딩 그리고 방법론까지",
        ],
        displayPrice: 50000,
        discountPrice: 0,
      };
    case "hr":
      return {
        chips: ["Digital Transformation", "DX", "직무교육"],
        title: courseTitles[url],
        thumbnail: true,
        checkPoints: [
          "DT 교육을 준비하는 HR / 교육 / 인사담당자 대상",
          "교육 설계부터 꼭 알아야 하는 팁까지",
        ],
        displayPrice: 50000,
        discountPrice: 0,
      };
    case "teenager":
      return {
        chips: ["청소년코딩교육", "코딩포트폴리오", "국영수코"],
        title: courseTitles[url],
        thumbnail: true,
        checkPoints: [
          "청소년 자녀를 두고 진로를 준비하는 부모님",
          "코딩 교육 정보부터 적절한 교육준비 방법까지",
        ],
        displayPrice: 50000,
        discountPrice: 0,
      };
    case "webplus":
      return {
        chips: [],
        title: courseTitles[url],
        thumbnail: true,
        checkPoints: [],
        displayPrice: 50000,
        discountPrice: 0,
      };
    case "chatgpt":
      return {
        chips: [],
        title: "ChatGPT로 10분 만에\n웹사이트 만들기",
        checkPoints: [],
        customCTA: "선착순 수강신청하기",
        displayPrice: 89000,
        discountPrice: 0,
      };
    case "nbfree":
      return {
        chips: [],
        title: courseTitles[url],
        checkPoints: [],
        customCTA: "선착순 수강신청하기",
        displayPrice: 89000,
        discountPrice: 0,
      };
    case "wf_free":
      return {
        chips: [],
        title: "코드 없이 AI로\n웹사이트 하나가 뚝딱 ",
        checkPoints: [],
        customCTA: "선착순 수강신청하기",
        displayPrice: 89000,
        discountPrice: 0,
      };
    case "ai-virtual":
      return {
        chips: [],
        title: "AI로 나만의 버추얼 캐릭터 만들기",
        checkPoints: [],
        customCTA: "선착순 수강신청하기",
        displayPrice: 89000,
        discountPrice: 0,
        videoId: "udzQ3cdAx2A",
      };
    case "codingtest":
      return {
        chips: [],
        title: "1시간 만에 정복하는\n코딩테스트 합격법",
        checkPoints: [],
        customCTA: "선착순 수강신청하기",
        displayPrice: 89000,
        discountPrice: 0,
      };
    case "androidvsios":
      return {
        chips: [],
        title: "앱 개발의 모든 것\n안드로이드 vs iOS",
        checkPoints: [],
        customCTA: "선착순 수강신청하기",
        displayPrice: 89000,
        discountPrice: 0,
      };
  }
};

export const getSpecialBannerData = (url: string): Banner => {
  const BannerTitle = (url) => {
    switch (url) {
      case "myprofile":
        return (
          <>
            1시간만에 코딩을 배워, <br />
            나만의 링O트리 페이지를 만드세요.
          </>
        );
      case "momentum":
        return (
          <>
            나의 동기부여가 되어주던 문장들 <br />
            코딩을 배워 직접 홈화면에 넣어보세요.
          </>
        );
      case "bucketlist":
        return (
          <>
            40분만에 코딩을 배워
            <br />
            특별한 버킷리스트를 만들어보세요.
          </>
        );
      case "photos":
        return (
          <>
            무료로 1시간만에 코딩을 배워
            <br />
            추억기록 네컷 사진으로 만들어보세요.
          </>
        );
      case "chang":
        return (
          <>
            IT 창업에 적합한 아이템 선정과 팀빌딩
            <br />
            그리고 방법론까지 알려드립니다.
          </>
        );
      case "hr":
        return (
          <>
            우리회사 DT를 성공적으로 이끌고, <br />
            디지털 기반의 조직으로 변화해보세요.
          </>
        );
      case "teenager":
        return (
          <>
            코딩으로 중고등학생에게
            <br />
            남다른 경쟁력을 만들어주세요.
          </>
        );
      case "chatgpt":
        return (
          <>
            코딩에 chatGPT를 더해
            <br />
            누구보다 빠르게 웹을 구현해보세요.
          </>
        );
      case "nbfree":
        return (
          <>
            <S.SubTitle>만 19세 이상 국민이라면 주목!</S.SubTitle>
            <Gap height={"8px"} />
            내일배움카드로 2,000만 원 상당
            <br />
            교육비 혜택 받는 방법을 알려드려요.
          </>
        );
      case "wf_free":
        return (
          <>
            AI와 No코드로 만들고 싶은
            <br />
            웹사이트를 뚝딱 만들어보세요.
          </>
        );
      case "ai-virtual":
        return (
          <>
            ChatGPT와 생성 AI로
            <br />
            나만의 버추얼 캐릭터를 만들어 보세요.
          </>
        );
      case "codingtest":
        return (
          <>
            코딩 테스트 합격의 비밀,
            <br />이 강의에서 모두 공개합니다!
          </>
        );
      case "androidvsios":
        return (
          <>
            현직 앱 개발자의 모든 것,
            <br />
            스파르타에서만 공개합니다!
          </>
        );
    }
  };
  const getBannerDesc = (url) => {
    switch (url) {
      case "myprofile":
        return (
          <>
            고민만 계속 하고 계시지는 않으셨나요? <br />
            <Gap height={"17px"} />
            코딩의 ‘ㅋ’자도 몰라도 1시간만에 수강 가능해요! <br />
            코딩 경험이 전혀없는 왕보초도 배울 수 있는 <br /> 강의로
            준비했습니다.
          </>
        );
      case "momentum":
        return (
          <>
            코딩을 전혀 모르는 왕초보를 위해 준비했습니다. <br />
            <Gap height={"17px"} />
            동기부여 문장들이 새로고침 할 때마다 자동으로 바뀌고, <br />
            나에게 하는 다짐이나 목표는 고정으로 해둘 수도 있습니다. <br />
            왕초보도 어렵지 않게 만들 수 있어요.
          </>
        );
      case "bucketlist":
        return (
          <>
            혹시 {isNewYear ? "작년" : "올해"} 계획도 작심삼일로 끝났나요?{" "}
            <br />
            <Gap height={"17px"} />
            2023 새해는 꼭 이루고 싶은 목표를 함께 설정하고
            <br />
            절대 잊을 수 없도록 스파르타가 관리해드릴게요!
          </>
        );
      case "photos":
        return (
          <>
            열심히 찍은 사진, 휴대폰 사진첩에 묵혀두고만 있지 않나요?
            <br />
            <Gap height={"17px"} />
            새로운 취미로 코딩을 배우고, 내손으로 직접!
            <br />
            기록 페이지로 만들어 소중한 사람과 추억을 나눠보세요.
          </>
        );
      case "chang":
        return (
          <>
            IT 창업은 여러 번 시도 하고, <S.MobileOnlyBr />
            수많은 실패를 마주하며 나아가야 합니다.
            <br />
            다만, 그 모든 실패가 필연적이지는 않습니다.
            <Gap height={"17px"} />
            전단지 붙이던 코딩 과외에서 연매출 <S.MobileOnlyBr />
            300억의 스타트업이 되기까지.
            <br />
            저희 사례를 바탕으로 시행착오를 <S.MobileOnlyBr />
            최소화 하실 수 있도록 돕겠습니다.
          </>
        );
      case "hr":
        return (
          <>
            이런 고민 해보지 않으셨나요? <br />
            <Gap height={"17px"} />
            임직원과 회사 모두가 만족하는 교육을 위해 <br />
            밤낮없이 고민하는 담당자님들을 위해 준비했습니다.
          </>
        );
      case "teenager":
        return (
          <div style={{ fontWeight: "600" }}>
            요즘은 단순히 점수만으로 학생을 판단하지 않습니다.
            <br />
            포트폴리오 및 경험 등 다양한 기준으로
            <S.MobileOnlyBr />
            학생의 잠재력을 가늠합니다.
            <Gap height={"17px"} />
            뚜렷한 결과물과 성과를 빠르게 만들어 줄 수 있는 코딩은,
            <br />
            남다른 경험을 어필할 수 있는 최적의 기회입니다.
          </div>
        );
      case "chatgpt":
        return (
          <div style={{ fontWeight: "600" }}>
            웹사이트를 만들 때 어렵게 공부하지 않아도 괜찮아요.
            <br />
            <br />
            간단한 사이트 정도는 10분 만에 뚝딱!
            <br />
            chatGPT 제일 잘 쓰는 방법을 알려드립니다.
          </div>
        );
      case "nbfree":
        return (
          <div style={{ fontWeight: "600" }}>
            나만의 취미, 자기 계발을 하고 싶었지만
            <br />
            금전적인 문제로 늘 고민하지 않았나요?
            <br />
            <br />
            이제, 내일배움카드로 비용부담 없이
            <S.MobileOnlyBr /> 마음껏 성장하세요!
          </div>
        );
      case "wf_free":
        return (
          <div style={{ fontWeight: "600" }}>
            웹사이트를 만들 때 어렵게 공부하지 않아도 괜찮아요.
            <br />
            <br />
            간단한 사이트 정도는 10분 만에 뚝딱!
            <br />
            ChatGPT 제일 잘 쓰는 방법을 알려드립니다.
          </div>
        );
      case "ai-virtual":
        return (
          <div style={{ fontWeight: "600" }}>
            ChatGPT와 생성 AI,
            <S.MobileOnlyBr /> 스파르타코딩클럽과 함께라면 어렵지 않아요!
            <br />
            <br />
            코딩을 몰라도 AI를 잘 다루는 법만 알면
            <br />
            세상에 없던 나만의 결과물을 만들어 낼 수 있어요.
          </div>
        );
      case "codingtest":
        return (
          <div style={{ fontWeight: "600" }}>
            어디서부터 준비해야 할지 몰라 막막했던 코딩 테스트,
            <br />
            합격을 위한 공부법은 따로 있습니다.
            <br />
            <br />
            이 강의를 통해 최근 출제 트렌드를 파악하고
            <br />
            나만의 학습 로드맵을 만들어 보세요!
          </div>
        );
      case "androidvsios":
        return (
          <div style={{ fontWeight: "600" }}>
            앱 개발자의 생생한 업무 경험담부터 커리어 전망까지!
            <br />
            현직 개발자만 들려줄 수 있는 이야기로 준비했습니다.
            <br />
            <br />
            지금 선착순 100명 한정 무료로 직접 들어보세요.
          </div>
        );
    }
  };
  return {
    title: BannerTitle(url),
    subCopy: getBannerDesc(url),
  };
};

export const getSpecialFutureData = (url: string): Future => {
  const Futures = (url: string) => {
    switch (url) {
      case "myprofile":
        return [
          <>
            누구나 다 사용하는 기본 템플릿이 아닌, <br />
            <b>나만의 개성있는 프로필 페이지를 가지게 됩니다.</b>
          </>,
          <>
            문득 떠오르는 아이디어를 <br />
            <b>내손으로 뚝딱뚝딱 구현할 수 있게 됩니다.</b>
          </>,
          <>
            직접 코딩으로 페이지를 만들어보면서 <br />
            <b>코딩에 대한 적성을 확인할 수 있습니다.</b>
          </>,
        ];
      case "momentum":
        return [
          <>
            크롬 홈화면을 내 손으로 직접 꾸며보면서, <br />
            <b>동기부여를 자극하는 페이지를 만듭니다.</b>
          </>,
          <>
            문득 떠오르는 아이디어를 <br />
            <b>내손으로 뚝딱뚝딱 구현할 수 있게 됩니다.</b>
          </>,
          <>
            왕초보도 할 수 있는 간단한 실습을 통해 <br />
            <b>코딩을 알고, 할 수 있는 사람으로 거듭납니다.</b>
          </>,
        ];
      case "bucketlist":
        return [
          <>
            매년 다짐만하고 실패하는 새해 목표를 <br />
            <b>직접 코딩으로 구현하여 페이지로 만듭니다.</b>
          </>,
          <>
            문득 떠오르는 아이디어를 <br />
            <b>코딩으로 뚝딱뚝딱 구현할 수 있게 됩니다.</b>
          </>,
          <>
            왕초보도 할 수 있는 간단한 실습을 통해
            <br />
            <b>코딩을 알고, 할 수 있는 사람으로 거듭납니다.</b>
          </>,
        ];
      case "photos":
        return [
          <>
            취미로 시작한 코딩으로, 간단한 만들기를 하며
            <br />
            <b>개발에 대한 나의 가능성을 확인하게 됩니다.</b>
          </>,
          <>
            문득 떠오르는 아이디어를 <br />
            <b>코딩으로 뚝딱뚝딱 구현할 수 있게 됩니다.</b>
          </>,
          <>
            왕초보도 할 수 있는 간단한 실습을 통해
            <br />
            <b>코딩을 알고, 할 수 있는 사람으로 거듭납니다.</b>
          </>,
        ];
      case "chang":
        return [
          <>
            내가 하고 싶은 아이템과 <br />
            <b>시장에서 원하는 아이템을 구분할 수 있습니다.</b>
          </>,
          <>
            나와 잘 맞는 공동 창업자의 기준을 알고,
            <br />
            <b>곱하기가 되는 팀을 만들어 낼 수 있습니다.</b>
          </>,
          <>
            실패에 두려워 하지 않고,
            <br />
            <b>지속해서 창업에 도전할 수 있는 기초 체력을 기르게 됩니다.</b>
          </>,
        ];
      case "hr":
        return [
          <>
            가장 임팩트 있고 효과적인 성과를 내는 <br />
            <b>교육설계를 배우게 됩니다.</b>
          </>,
          <>
            교육 뿐만 아니라, 성공적인 DT에 꼭 필요한
            <br />
            <b>핵심 플랜을 갖추게 됩니다.</b>
          </>,
          <>
            우리 회사의 상황에 딱 맞는 교육이 무엇인지
            <br />
            <b>명확한 방향성을 그릴 수 있습니다.</b>
          </>,
        ];
      case "teenager":
        return [
          <>
            입시 및 취업시장에서 코딩이 어떤 의미인지
            <br />
            <b>교육 현황 및 정보들을 파악하게 됩니다.</b>
          </>,
          <>
            우리 아이가 코딩을 가장 효과적으로
            <br />
            <b>배우고, 학습하고, 결과를 내는 법을 알게 됩니다.</b>
          </>,
          <>
            아직 아리송한 코딩에 대해
            <br />
            <b>개념을 익히고 아이와 소통할 수 있게 됩니다.</b>
          </>,
        ];
      case "chatgpt":
        return [
          <>
            간단한 웹사이트는 chatGPT의 도움을 받아
            <br />
            10~20분만에 완성할 수 있습니다.
          </>,
          <>
            chatGPT를 활용하여 생산성과 효율성을
            <br />
            높이는 다양한 방법들을 알게 됩니다.
          </>,
          <>
            왕초보도 따라할 수 있는 간단한 실습을 통해
            <br />
            코딩을 쉽게 접할 수 있습니다.
          </>,
        ];
      case "wf_free":
        return [
          <>
            간단한 웹사이트는 각종 AI의 도움을 받아
            <br />
            10~20분만에 완성할 수 있습니다.
          </>,
          <>
            ChatGPT를 활용하여 생산성과 효율성을
            <br />
            높이는 다양한 방법들을 알게 됩니다.
          </>,
          <>
            왕초보도 따라할 수 있는 간단한 실습을 통해
            <br />
            코딩을 쉽게 접할 수 있습니다.
          </>,
        ];
      case "ai-virtual":
        return [
          <>
            ChatGPT와 생성 AI 툴을 활용해 몇 가지 <br />
            키워드 만으로 원하는 이미지를 만들 수 있어요.
          </>,
          <>
            생성 AI 툴을 활용해 이미지에 음성을
            <br />
            입힐 수 있어요.
          </>,
          <>
            왕초보도 따라할 수 있는 간단한 실습으로 <br />
            생성 AI를 체험해 볼 수 있어요.
          </>,
        ];
      case "codingtest":
        return [
          <>
            어떤 알고리즘을 공부해야 하는지
            <br />
            나만의 학습 로드맵을 만들 수 있어요.
          </>,
          <>
            코딩 테스트에서 활용할 수 있는
            <br />
            필살 알고리즘 노트를 만들 수 있어요.
          </>,
          <>기업 별 코딩 테스트 출제 경향까지 파악할 수 있어요.</>,
        ];
      case "androidvsios":
        return [
          <>안드로이드 vs iOS : 시작하기에 더 좋은 과정은?</>,
          <>앱 개발자의 현주소 : 연봉, 커리어, 미래 전망</>,
          <>취업 노하우 : 포트폴리오부터 면접 준비까지</>,
        ];
    }
  };
  const Caption = (url) => {
    switch (url) {
      case "hr":
      case "teenager":
      case "chang":
        return "실제 강의 자료";
      case "chatgpt":
        return "chatGPT를 활용한 결과물 예시";
      case "wf_free":
        return "Webflow를 활용한 결과물 예시";
      case "ai-virtual":
        return "AI 툴을 활용한 결과물 예시";
      case "codingtest":
        return "";
      case "androidvsios":
        return "";
      default:
        return `${courseTitles[url]} 응용 결과물 예시`;
    }
  };
  return {
    futures: Futures(url),
    caption: Caption(url),
  };
};

export const getSpecialNewBenefitData = (url: string): NewBenefit => {
  switch (url) {
    case "myprofile":
      return {
        section: "특별 수강 혜택",
        title: (
          <>
            <b>디자인 템플릿 8종</b> 무료 증정!
          </>
        ),
        desc: {
          up: (
            <>
              링O트리로는 만들 수 없는
              <br /> 이런 깔끔한 페이지
            </>
          ),
          down: (
            <>
              왕초보도 <b>30분만에 뚝딱</b>
              <br /> 내 페이지로 만들 수 있어요!
            </>
          ),
        },
        caution: "* 디자인템플릿은 강의 5강에서 증정됩니다.",
      };
    case "momentum":
      return {
        section: "특별 수강 혜택",
        title: (
          <>
            <b>랜덤 배경화면 DIY 코드</b> 무료 증정!
          </>
        ),
        desc: {
          up: (
            <>
              크롬 배경화면을 새로고침 할 때 마다
              <br /> 내가 좋아하는 이미지만 나오도록,
            </>
          ),
          down: (
            <>
              직접 이미지를 적용할 수 있는 코드를
              <br /> 무료로 드립니다!
            </>
          ),
        },
        caution: "* 코드템플릿은 완강 시 문자메시지를 통해 지급됩니다.",
      };
    case "bucketlist":
      return {
        section: `${new Date().getFullYear()}년 ${
          new Date().getMonth() + 1
        }월 한정 특별 혜택`,
        title: (
          <>
            <b>작심삼일러를 위한 리마인드 서비스!</b>
          </>
        ),
        desc: {
          up: (
            <>
              매년 결심하고 ‘작심삼일’로 끝나는 <br />
              새해 버킷리스트를 2023년에는 꼭,
            </>
          ),
          down: (
            <>
              잊지 않도록 맞춤형 알림 서비스를
              <br />
              무료로 제공해드립니다!
            </>
          ),
        },
        caution: `* 목표달성 알림 서비스는 ${
          new Date().getMonth() + 1
        }월 신청자 한에서만 제공됩니다.`,
      };
    case "photos":
      return {
        section: "특별 수강 혜택",
        title: (
          <>
            특별한 기능을 추가할 수 있는
            <S.MobileOnlyBr /> 시크릿 코드
          </>
        ),
        desc: {
          up: (
            <>
              코딩을 하면서 조금만 더 예쁘고 특별하게
              <br />
              만들고 싶은 열정있는 당신을 위해,
            </>
          ),
          down: (
            <>
              강의에서는 배우지 않는 기능을
              <S.MobileOnlyBr /> 만들 수 있는 시크릿 코드를 드려요!
              <br />
              어떤 기능인지는 수강을 완주해야 알 수 있어요.
            </>
          ),
        },
        caution:
          "* 수강 혜택은 완주 후 만족도조사 제출시\n 문자메세지를 통해 지급됩니다.",
      };
    case "chang":
      return {
        section: "이범규 대표의 추천 도서",
        title: (
          <>
            <b>창업가 필독서 Top 10</b> 공유
          </>
        ),
        desc: {
          up: (
            <>
              어떤 책을 읽으면 도움이 될까요?
              <br />
              이범규 대표가 직접 엄선했습니다.
            </>
          ),
          down: (
            <>
              창업가 마인드부터 제품, 마케팅, 문화 등<br />
              <b>각 분야에서의 필독서</b>가 포함되어 있어요!
            </>
          ),
        },
        caution: "* 창업가 필독서 리스트는 강의 5강에서 증정됩니다.",
      };
    case "hr":
      return {
        section: "특별 수강 혜택",
        title: <>HRD 담당자 강의 무료체험 이벤트</>,
        desc: {
          up: (
            <>
              이 강의를 듣고 기업교육 상담을 신청한 <S.MobileOnlyBr />
              HRD 담당자님께는
            </>
          ),
          down: (
            <>
              직접 경험해보실 수 있도록 <br />
              온라인 강의 무료체험을 도와드릴게요.
            </>
          ),
        },
        caution: "* 수강기간 및 체험 과목은 상담을 통해 안내드립니다.",
      };
    case "chatgpt":
      return {
        section: "특별 수강 혜택",
        title: (
          <>
            chatGPT 스프레드시트
            <S.MobileOnlyBr /> 자동화 템플릿
          </>
        ),
        desc: {
          up: (
            <>
              일잘러들의 업무 생산성 증가를 위해, <br />
              구글 스프레드시트를 활용한
              <S.MobileOnlyBr /> 자동화 템플릿을 드립니다!
            </>
          ),
          down: (
            <>
              강의를 끝까지 완주 하신 분들께만
              <S.MobileOnlyBr /> 쏙- 보내드립니다.
            </>
          ),
        },
        caution:
          "* 수강 혜택은 완주 후 만족도조사 제출 시\n 카카오톡 알림톡을 통해 지급됩니다.",
      };
    case "nbfree":
      return {
        section: "특별 수강 혜택",
        title: (
          <>
            chatGPT 스프레드시트
            <S.MobileOnlyBr /> 자동화 템플릿
          </>
        ),
        desc: {
          up: (
            <>
              일잘러들의 업무 생산성 증가를 위해, <br />
              구글 스프레드시트를 활용한
              <S.MobileOnlyBr /> 자동화 템플릿을 드립니다!
            </>
          ),
          down: (
            <>
              강의를 끝까지 완주 하신 분들께만
              <S.MobileOnlyBr /> 쏙- 보내드립니다.
            </>
          ),
        },
        caution:
          "* 수강 혜택은 완주 후 만족도조사 제출 시\n 문자 메세지를 통해 지급됩니다.",
      };
    case "wf_free":
      return {
        section: "특별 수강 혜택",
        title: (
          <>
            Webflow 디자인 시스템
            <S.MobileOnlyBr /> 템플릿 무료 제공
          </>
        ),
        desc: {
          up: (
            <>
              자주 쓰이는 디자인 에셋을 자유롭게 꺼내 쓸 수 있는
              <br />
              디자인 시스템 템플릿을 무료로 제공해드립니다.
            </>
          ),
          down: (
            <>
              단, 강의를 끝까지 완주 하신 분들만
              <br />
              받아보실 수 있습니다.
            </>
          ),
        },
        caution:
          "* 수강 혜택은 완주 후 만족도조사 제출 시\n 카카오톡 알림톡을 통해 지급됩니다.",
      };
    case "ai-virtual":
      return {
        section: "SNS 공유 이벤트 (~7/11)",
        title: (
          <>
            내가 만든 버추얼 캐릭터,
            <br />
            인스타그램 스토리로 업로드해주세요!
          </>
        ),
        desc: {
          up: <>매일 5명씩 추첨해서 배민 1만원 상품권을 드려요</>,
          down: (
            <S.NewBenefitCustom>
              <S.CustomTitleText>참여방법</S.CustomTitleText>

              <S.CustomDesc>
                내가 만든 캐릭터를 인스타그램 스토리로 <br />
                @spartacodingclub 태그와 함께 업로드하면 끝!
              </S.CustomDesc>
            </S.NewBenefitCustom>
          ),
        },
        caution: "",
      };
  }
};

export const getSpecialAdditionalBenefitData = (url: string) => {
  switch (url) {
    case "myprofile":
      return {
        condition: (
          <>
            <br />
            숙제와 만족도 조사를 제출하면
          </>
        ),
        benefits: [
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "benefit-material-myprofile.png",
          },
          {
            cond: "끝까지 완강시",
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
        ],
      };
    case "momentum":
      return {
        condition: (
          <>
            <br />
            숙제와 만족도 조사를 제출하면
          </>
        ),
        benefits: [
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "benefit-material-momentum.png",
          },
          {
            cond: "끝까지 완강시",
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
        ],
      };
    case "bucketlist":
      return {
        condition: (
          <>
            <br />
            완주하면
          </>
        ),
        benefits: [
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "benefit-material-bucketlist.png",
          },
          {
            cond: "끝까지 완강시",
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
          {
            cond: "완강 혜택 하나 더!",
            title: "월간코딩 무료 쿠폰 지급",
            thumbnail: "benefit-coupon-free.png",
          },
        ],
      };
    case "photos":
      return {
        condition: (
          <>
            <br />
            완주하면
          </>
        ),
        benefits: [
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "benefit-material-photos.png",
          },
          {
            cond: "끝까지 완강시",
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
          {
            cond: "완강 혜택 하나 더!",
            title: "월간코딩 무료 쿠폰 지급",
            thumbnail: "benefit-coupon-free.png",
          },
        ],
      };
    case "chang":
      return {
        section: "특별 수강 혜택",
        condition: (
          <>
            <S.WebOnlyBr />
            강의를 완주하고 <S.MobileOnlyBr />
            만족도 조사를 제출하면
          </>
        ),
        benefits: [
          {
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
          {
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "benefit-material-chang.png",
          },
        ],
      };
    case "hr":
      return {
        condition: (
          <>
            <S.WebOnlyBr />
            강의를 완주하고 <S.MobileOnlyBr />
            만족도 조사를 제출하면
          </>
        ),
        benefits: [
          {
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
          {
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "benefit-material-hr.png",
          },
        ],
      };
    case "teenager":
      return {
        section: " ",
        condition: (
          <>
            <S.WebOnlyBr />
            강의를 완주하고 <S.MobileOnlyBr />
            만족도 조사를 제출하면
          </>
        ),
        benefits: [
          {
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "benefit-material-teenager.png",
          },
          {
            title: "스파르타코딩클럽 전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
        ],
      };
    case "chatgpt":
      return {
        section: "특별 수강 혜택",
        condition: (
          <>
            완주하면
            <br />
          </>
        ),
        benefits: [
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장",
            thumbnail: "benefit-material-teenager.png",
          },
          {
            cond: "끝까지 완강 시",
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
        ],
      };
    case "nbfree":
      return {
        section: "오직 스파르타에서만 만나는",
        condition: (
          <>
            완주하면
            <br />
          </>
        ),
        benefits: [
          {
            cond: "세상에서 가장 친절한",
            title: "내일배움카드 발급 방법",
            thumbnail: "nbfree_benefit_1.png",
          },
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장!",
            thumbnail: "nbfree_benefit_2.png",
          },
          {
            cond: "내일배움카드 200% 활용 꿀팁",
            title: "포트폴리오, 자격증, 취업/이직 꿀팁까지!",
            thumbnail: "nbfree_benefit_3.png",
          },
        ],
        subTitle: "내일배움카드 발급 강의만의 특징",
      };
    case "wf_free":
      return {
        section: "특별 수강 혜택",
        condition: (
          <>
            완주하면
            <br />
          </>
        ),
        benefits: [
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장",
            thumbnail: "benefit-material-wf_free.png",
          },
          {
            cond: "끝까지 완강 시",
            title: "전과목 3만원 할인쿠폰 지급",
            thumbnail: "benefit-coupon.png",
          },
        ],
      };
    case "ai-virtual":
      return {
        section: "특별 수강 혜택",
        condition: (
          <>
            완주하면
            <br />
          </>
        ),
        benefits: [
          {
            cond: "숙제까지 제출하면",
            title: "강의자료 및 영상 평생소장",
            thumbnail: "benefit-material-ai-virtual.png",
          },
          {
            cond: "바로 사용 가능한",
            title: "전과목 할인쿠폰 패키지",
            thumbnail: "benefit-coupon-white.png",
          },
        ],
      };
    case "codingtest":
      return {
        section: "기간 한정 이벤트",
        condition: (
          <>
            완주하면
            <br />
          </>
        ),
        benefits: [
          {
            cond: "",
            title: "개발자 합격 포트폴리오 및 이력서 꿀팁",
            thumbnail: "benefit-material-codingtest-1.png",
          },
          {
            cond: "",
            title: "주요 IT 기업 분석 자료",
            thumbnail: "benefit-material-codingtest-2.png",
          },
        ],
        subTitle: "코딩 테스트 꿀팁 자료 2종\n무료 제공",
      };
    case "androidvsios":
      return {
        section: "완주자 한정 혜택",
        benefits: [
          {
            cond: "",
            title: "개발자 합격 포트폴리오 및 \n이력서 꿀팁",
            thumbnail: "benefit-material-androidvsios-1.png",
          },
          {
            cond: "",
            title: "전액 국비지원\n" + "앱 개발 부트캠프 서류 가산점",
            thumbnail: "benefit-material-androidvsios-2.png",
          },
        ],
        subTitle: "개발자 합격 포트폴리오부터\n" + "부트캠프 서류 가산점까지",
        subCopy:
          "무료특강을 듣고, 앱 개발 부트캠프 합류 신청 시\n" +
          "아래 혜택을 모두 받을 수 있어요.",
      };
  }
};

export const getSpecialEventBenefitData = (url: string): EventBenefit => {
  switch (url) {
    case "myprofile":
      return {
        image: "img_starbucks.png",
        title: (
          <>
            내가 만든 결과물 자랑하고,
            <br /> 커피 받아가세요!
          </>
        ),
        paragraphs: [
          <>
            만들어진 결과물을
            <S.MobileOnlyBr /> 인스타그램 피드나 스토리에 공유하고,
            <br /> <b>@spartacodingclub</b>을 태그해주세요.
            <br />
            <br />
            * 피드로 올릴 시,
            <br />
            <b>#스파르타코딩클럽 #코딩 #코딩공부</b>
            <S.MobileOnlyBr /> 태그 기재 필수!
          </>,
          <>
            매주 금요일 추첨을 통해 20분께 스타벅스 커피 기프티콘을 드립니다!
          </>,
        ],
      };
    case "momentum":
      return {
        image: "img_diary.png",
        title: (
          <>
            내가 만든 결과물 자랑하고,
            <br /> 2023 다이어리 받아가세요!
          </>
        ),
        paragraphs: [
          <>
            만들어진 결과물을
            <S.MobileOnlyBr /> 인스타그램 피드나 스토리에 공유하고,
            <br /> <b>@spartacodingclub</b>을 태그해주세요.
            <br />
            <br />
            * 피드로 올릴 시,
            <br />
            <b>#스파르타코딩클럽 #코딩 #코딩공부</b>
            <S.MobileOnlyBr /> 태그 기재 필수!
          </>,
          <>
            추첨을 통해 20분께
            <br />
            오롤리데이 다이어리를 드립니다!
            <span>* 당첨자 발표 : 12월 1일 (화)</span>
          </>,
        ],
        notice: [
          <>* 본 이벤트는 스파르타코딩클럽 인스타그램에서 진행됩니다.</>,
          <>
            * 당첨자 발표일에 DM으로 연락드리며, 회신이 없을 시 당첨자 선정에서
            제외됩니다.
          </>,
        ],
      };
    case "bucketlist":
      return isNewYear
        ? null
        : {
            image: "img_chicken_coupon.png",
            title: (
              <>
                내가 만든 결과물 자랑하고,
                <br /> 치킨쿠폰까지 받아가세요!
              </>
            ),
            paragraphs: [
              <>
                만들어진 결과물을
                <S.MobileOnlyBr /> 인스타그램 피드나 스토리에 공유하고,
                <br /> <b>@spartacodingclub</b>을 태그해주세요.
                <br />
                <br />
                * 피드로 올릴 시, 아래 4개 태그 필수!
                <br />
                <b>
                  #스파르타코딩클럽 #코딩 #자기계발 <S.MobileOnlyBr />
                  #이벤트
                </b>
                <S.MobileOnlyBr />
              </>,
              <>
                추첨을 통해 20분의 당첨자분들께
                <br />
                치킨기프티콘을 발송해드립니다!
                <span>* 당첨자 발표 : 23년 1월 2일 (월)</span>
              </>,
            ],
            notice: [
              <>* 본 이벤트는 스파르타코딩클럽 인스타그램에서 진행됩니다.</>,
              <>
                * 당첨자 발표일에 DM으로 연락드리며, 회신이 없을 시 당첨자
                선정에서 제외됩니다.
              </>,
            ],
            custumSnsEventTitle: "갓생! 인증 SNS 이벤트",
          };
    case "chang":
      return {
        image: "img_culture_money.png",
        title: (
          <>
            내 창업일지를 공유하고,
            <br /> 책 받아가세요!
          </>
        ),
        paragraphs: [
          <>
            창업일지를 개인 블로그에 작성하고,
            <S.MobileOnlyBr /> 제출해주세요!
            <br />
            <br />
            * 창업일지는 학습 내용과 느낀점, <S.MobileOnlyBr />
            목표 등을 작성하는 것으로, <S.WebOnlyBr />
            <b>완주자 대상 안내 문자를 통해 확인</b> 하실 수 있습니다.
          </>,
          <>
            매주 금요일 추첨을 통해 5분께
            <br /> 창업가 필독서를 구매하실 수 있는 문화상품권을 드립니다!
          </>,
        ],
      };
  }
};

const getTutors = (name: string) => {
  switch (name) {
    case "이범규":
      return {
        name: "이범규",
        bios: [
          "스파르타코딩클럽 대표",
          "전) 본엔젤스 벤처캐피털 투자심사역",
          "전) 배달의민족 Software Engineer",
          "KAIST 산업 및 시스템공학과 졸",
        ],
      };
    case "황순영":
      return {
        name: "황순영",
        bios: [
          "팀스파르타 공동창업 및 CMO",
          "전) 미소 신사업 매니저",
          "전) 피플펀드 구조화금융 매니저",
          "고려대학교 정치외교학과 졸",
        ],
      };
    case "이동현":
      return {
        name: "이동현",
        bios: [
          "현) 스파르타코딩클럽 온라인개발팀 팀장",
          "전) 오픈갤러리 개발팀장",
          "KAIST 전산학과 졸",
          "경남과학고 졸",
        ],
      };
    case "황영상":
      return {
        name: "황영상",
        bios: [
          "스파르타코딩클럽 커리큘럼 개발자",
          "[컴퓨터를 몰라도 할 수 있는 코딩 첫걸음] 외 3개 강의 튜터",
          "메이킹 챌린지 튜터",
          "메이킹 챌린지 튜터",
        ],
      };
    case "최원장":
      return {
        name: "최원장",
        bios: [
          "미국 E-commerce 기업 개발자",
          "SI 대기업 개발자",
          "금융권 개발자",
          "인터넷 은행 개발자",
        ],
      };
    case "김진홍":
      return {
        name: "김진홍",
        bios: [
          "현) 노코드해커스, 노코드 컨설턴트",
          "현) 마켓핏랩, 그로스 컨설턴트",
          "전) 쿼타북, 사업개발 팀장",
          "전) 시니어생활연구소, 공동 창업자",
          "전) 카카오벤처스, 투자팀 인턴",
          "고려대학교 경영학과 졸",
        ],
      };
    case "최지웅":
      return {
        name: "최지웅",
        bios: [
          "현) 스파르타코딩클럽 콘텐츠 리드",
          "전) 삼성 청년 SW 아카데미(SSAFY) 파이썬 트랙 대표 교수",
          "전) 코드잇 Contents Producer",
          "전) 에이럭스 전임 연구원",
        ],
      };
    case "이동준":
      return {
        name: "이동준",
        subject: "안드로이드",
        bios: [
          "현) 내일배움캠프 Android 대표 튜터",
          "현) 지티소프트 개발 팀장",
          "전 ) LG전자",
          "전) 트레블월렛",
        ],
      };
    case "이현호":
      return {
        name: "이현호",
        subject: "iOS",
        bios: [
          "현) 내일배움캠프 iOS 대표 튜터",
          "현) 포항 공과대학 연구원",
          "전) 와디즈 개발자",
          "전) 와디즈 데이터분석가",
        ],
      };
  }
};

export const getSpecialTutorData = (url: string): Tutor => {
  switch (url) {
    case "myprofile":
      return {
        tutor: getTutors("이범규"),
        title: courseTitles[url],
        greeting: "나만의 프로필 만들기 튜터",
        comment: (
          <>
            는 코딩에 재밌게 입문하고 싶은 왕초보 분들을 위한 수업입니다. <br />{" "}
            <br />
            눈에 보이는 결과물을 만들어보는 실습 위주의 커리큘럼으로 코딩과
            빠르게 친해질 수 있도록 준비했습니다.
            <br />
            <br />
            코딩, 아예 손도 안대봤어도 걱정하지 마세요.
          </>
        ),
      };
    case "momentum":
      return {
        tutor: getTutors("황영상"),
        title: courseTitles[url],
        comment: (
          <>
            <b>
              는 <br />
              자기계발을 위해 코딩을 시작하시는
              <br />
              왕초보분들을 위해 준비했습니다.
            </b>
            <br />
            <br />
            모멘텀의 뜻은 추진력입니다.
            <br />
            왕초보도 할 수 있는 간단한 코딩으로
            <br />
            동기부여를 시작하고 추진력을 얻어보세요.
          </>
        ),
      };
    case "bucketlist":
      return {
        tutor: getTutors("이범규"),
        title: courseTitles[url],
        greeting: "스파르타코딩클럽 대표",
        comment: (
          <>
            <b>
              는<br /> 매년 결심하고 ‘작심삼일’로 무너지는 새해목표를 <br />
              새해에는 꼭 성공할 수 있도록 준비했습니다.
            </b>
            <br />
            <br />
            코딩을 배워 직접 만드는 나만의 버킷리스트, <br />
            왕초보도 할 수 있는 간단한 코딩으로
            <br />
            활기찬 새해를 시작할 수 있는 추진력을 얻어보세요.
          </>
        ),
      };
    case "photos":
      return {
        tutor: getTutors("최원장"),
        title: courseTitles[url],
        greeting: "",
        comment: (
          <>
            <b>
              은<br /> 사진과 영상을 네컷으로 만들어서
              <br />
              추억을 기록하고 선물할 수 있도록 준비했습니다.
            </b>
            <br />
            <br />
            그저 남 얘기였던 코딩이 어떤 원리로 동작하는지 알아보고,
            <br />
            막연했던 코딩을 이제는 뚜렷하게 알아갈 수 있습니다.
            <br />
            코딩으로 나의 새로운 가능성을 발견해보세요.
          </>
        ),
      };
    case "chang":
      return {
        tutor: getTutors("황순영"),
        title: courseTitles[url],
        comment: (
          <>
            은<br />
            <b>IT 창업이 하고 싶은 모든 분들을 위한 강의입니다.</b>
            <br />
            <br />
            <b>
              본 강의를 통해 빠르게 창업으로 나아가실 수 있도록
              <br />
              아이템 선정, 팀빌딩 등 시작 단계에서의 이야기를 주로 담았습니다.
            </b>
            <br />
            <br />
            IT 창업에 있어 &quot;정답&quot; 이라는 것은 존재하지 않지만,
            <br />
            성공 사례를 바탕으로 실패 확률을 줄이는 방법을 알려드리겠습니다.
          </>
        ),
      };
    case "hr":
      return {
        tutor: getTutors("이범규"),
        title: courseTitles[url],
        greeting: "스파르타코딩클럽 대표",
        comment: (
          <>
            <b>
              은 <S.MobileOnlyBr />
              스파르타코딩클럽의 <S.WebOnlyBr />
              기업교육 경험을 모아서, 고민이
              <S.MobileOnlyBr /> 많으실 담당자님들께 도움이 되고자{" "}
              <S.WebOnlyBr />
              만들었습니다.
            </b>
            <br />
            <br />
            <b>
              직무 및 규모별 교육 설계 노하우부터 교육 전후로
              <S.MobileOnlyBr /> 준비해야 할 것까지, <S.WebOnlyBr />
              기업 교육의 A to Z를 다룹니다.
            </b>
            <br />
            <br />
            임직원의 역량 강화를 위해 가장 최적화된 교육이 <S.MobileOnlyBr />
            무엇인지 <S.WebOnlyBr />
            알려드리겠습니다.
          </>
        ),
      };
    case "teenager":
      return {
        tutor: getTutors("이동현"),
        title: courseTitles[url],
        greeting: "스파르타코딩클럽 개발팀장",
        comment: (
          <>
            <b>
              은 자녀의 <S.MobileOnlyBr />
              코딩교육을 고민하고
              <S.WebOnlyBr />
              준비하고자 알아보는 부모님들을
              <S.MobileOnlyBr /> 위해 준비했습니다.
            </b>
            <br />
            <br />
            <b>
              코딩은 앞으로 점점 더 많은 교육기관에서 요구하고,
              <S.MobileOnlyBr /> 취업에서의 <S.WebOnlyBr />
              영향력 또한 확대될 것입니다.
            </b>
            <br />
            <br />
            남들보다 앞서 미리 준비하시면, <br />
            아이에게 남다른 경쟁력을 만들어줄 수 있습니다.
          </>
        ),
      };
    case "chatgpt":
      return {
        tutor: getTutors("이범규"),
        title: courseTitles[url],
        greeting: "chatGPT 강의 튜터",
        comment: (
          <>
            <b>
              chatGPT의 도움을 조금만 받으면
              <br />
              코딩이 훨-씬 편해진다는 사실을 아시나요?
            </b>
            <br />
            <br />
            간단한 웹사이트조차 만들기 어려웠던 분들도,
            <br />
            이젠 정말 쉽고 빠르게 코딩을 시작할 수 있습니다.
            <br />
            걱정마시고 저만 믿고 따라오세요!
          </>
        ),
      };
    case "wf_free":
      return {
        tutor: getTutors("김진홍"),
        title: courseTitles[url],
        greeting: "무료특강 튜터",
        comment: (
          <>
            <b>
              Webflow와 ChatGPT로 웹사이트 하나 정도는
              <br /> 뚝딱 만들 수 있다는 사실을 아시나요?
            </b>
            <br />
            <br />
            간단한 웹사이트조차 만들기 어려웠던 분들도,
            <br />
            이젠 정말 쉽고 빠르게 코딩을 시작할 수 있습니다.
            <br />
            걱정마시고 저만 믿고 따라오세요!
          </>
        ),
      };
    case "ai-virtual":
      return {
        tutor: getTutors("이범규"),
        title: courseTitles[url],
        greeting: "튜터",
        comment: (
          <>
            <b>
              코딩을 몰라도 ChatGPT와 AI를
              <br />잘 활용할 수 있다는 사실 알고 계셨나요?
            </b>
            <br />
            <br />
            평소 내가 원하던 이미지나 영상을
            <br />
            아주 가뿐하고 쉽게 만들어 보세요.
            <br />
            제가 재미있게 알려드릴게요.
            <br />
          </>
        ),
      };
    case "codingtest":
      return {
        tutor: getTutors("최지웅"),
        title: courseTitles[url],
        greeting: "코딩 테스트 합격법 튜터",
        comment: (
          <>
            <b>교육을 통해 누군가를 성장시키는 일에 진심입니다.</b>
            <br />
            <br />
            어려운 게 아니라 낯선 겁니다.
            <br />
            재밌게 따라오시다 보면 분명 내 것이 될 거예요.
          </>
        ),
      };
    case "androidvsios":
      return {
        title: courseTitles[url],
        greeting: "현직 앱 개발자",
        tutors: [
          {
            ...getTutors("이동준"),
            comment: (
              <>
                <b>
                  왜 안드로이드를 선택했냐고요?
                  <br />
                  세상에서 가장 많이 써서요.
                </b>
                <br />
                <br />
                개발을 처음 배울 때, 좋은 튜터님 코칭이 <br />
                가장 도움이 되었어요.
              </>
            ),
          },
          {
            ...getTutors("이현호"),
            comment: (
              <>
                <b>
                  왜 IOS를 선택했냐구요?
                  <br />
                  혁신의 아이콘이니까요.
                </b>
                <br />
                <br />
                개발을 공부하기에 가장 좋은 시기는 바로 &quot;지금&quot;입니다.
              </>
            ),
          },
        ],
      };
  }
};

export const getSpecialRecommendData = (url: string): Recommend => {
  switch (url) {
    case "myprofile":
      return {
        targets: {
          mobile: [
            <>
              올해 목표로 코딩 배워야겠다고 생각은 했는데 <br />
              아직 엄두를 못 내는 분들
            </>,
            <>
              1시간만에 재미있고 실용적인 예시로 코딩과 <br />
              가까워지고 싶은 분들
            </>,
            <>
              웹사이트는 어떻게 만들까? 개념 이해뿐만 <br />
              아니라 내 손으로 직접 만들어보고 싶은 분들
            </>,
          ],
          web: [
            <>
              인스타 프로필 링크를 잘 꾸며서 <Gap height={"3px"} />
              퍼스널 브랜딩을 시작하고 싶은 분들
            </>,
            <>
              웹서비스의 기본 개념을 익히고 <Gap height={"3px"} />
              문득 떠오른 아이디어를 구현하고 싶은 분들
            </>,
            <>
              직접 뚝딱뚝딱 코딩의 손맛을 느껴보고 <Gap height={"3px"} />
              코딩이 적성에 잘 맞을지 알아보고 싶은 예비 개발자
            </>,
          ],
        },
      };
    case "momentum":
      return {
        targets: {
          mobile: [
            <>
              새로운 자기계발을 동기부여와 함께
              <br />
              코딩으로 시작하고 싶은 왕초보
            </>,
            <>
              웹서비스의 기본 개념을 익히고 <br />
              문득 떠오른 아이디어를 구현하고 싶은 분
            </>,
            <>
              명언 랜덤 페이지, 투두리스트 페이지 등 <br />
              인터넷 홈화면을 직접 꾸며보고 싶은 분
            </>,
          ],
          web: [
            <>
              새로운 자기계발을 동기부여와 함께
              <Gap height={"3px"} />
              코딩으로 시작하고 싶은 왕초보
            </>,
            <>
              웹서비스의 기본 개념을 익히고 <Gap height={"3px"} />
              문득 떠오른 아이디어를 구현하고 싶은 분
            </>,
            <>
              명언 랜덤 페이지, 투두리스트 페이지 등 <Gap height={"3px"} />
              인터넷 홈화면을 직접 꾸며보고 싶은 분
            </>,
          ],
        },
      };
    case "bucketlist":
      return {
        targets: {
          mobile: [
            <>
              2023 새해를 맞이하여, 새로운 자기개발을 <br />
              코딩으로 시작하고 싶은 왕초보 분
            </>,
            <>
              웹서비스의 기본 개념을 익히고
              <br />
              문득 떠오른 아이디어를 쉽게 구현하고 싶은 분
            </>,
            <>
              위시리스트, 투두리스트 페이지 등 나만의
              <br />
              페이지를 직접 꾸며보고 싶은 분
            </>,
          ],
          web: [
            <>
              2023 새해를 맞이하여, 새로운 자기개발을 <br />
              코딩으로 시작하고 싶은 왕초보 분
            </>,
            <>
              웹서비스의 기본 개념을 익히고
              <br />
              문득 떠오른 아이디어를 쉽게 구현하고 싶은 분
            </>,
            <>
              위시리스트, 투두리스트 페이지 등 나만의
              <br />
              페이지를 직접 꾸며보고 싶은 분
            </>,
          ],
        },
      };
    case "photos":
      return {
        targets: {
          mobile: [
            <>
              코딩이 무엇인지는 아직 잘 모르지만,
              <br />
              짧은 시간 맛보기로 해보고 싶은 바쁜 직업인
            </>,
            <>
              코딩을 처음 해보지만, 내가 만든 코드로
              <br />
              감동적인 선물을 선사하고 싶은 로맨티스트
            </>,
            <>
              IT 기술, 그 자체인 현대 시대에서
              <br />
              앞으로의 직업으로 IT업계를 고민하는 취준생
            </>,
          ],
          web: [
            <>
              코딩이 무엇인지는 아직 잘 모르지만,
              <br />
              짧은 시간 맛보기로 해보고 싶은 바쁜 직업인
            </>,
            <>
              코딩을 처음 해보지만, 내가 만든 코드로
              <br />
              감동적인 선물을 선사하고 싶은 로맨티스트
            </>,
            <>
              IT 기술, 그 자체인 현대 시대에서
              <br />
              앞으로의 직업으로 IT업계를 고민하는 취준생
            </>,
          ],
        },
      };

    case "chatgpt":
      return {
        targets: {
          mobile: [
            <>
              코딩이 무엇인지는 아직 잘 모르지만,
              <br />
              짧은 시간 맛보기로 해보고 싶은 바쁜 직업인
            </>,
            <>
              코딩을 처음 해보지만, 내가 만든 코드로
              <br />
              감동적인 선물을 선사하고 싶은 로맨티스트
            </>,
            <>
              IT 기술, 그 자체인 현대 시대에서
              <br />
              앞으로의 직업으로 IT업계를 고민하는 취준생
            </>,
          ],
          web: [
            <>
              코딩이 무엇인지는 아직 잘 모르지만,
              <br />
              짧은 시간 맛보기로 해보고 싶은 바쁜 직업인
            </>,
            <>
              코딩을 처음 해보지만, 내가 만든 코드로
              <br />
              감동적인 선물을 선사하고 싶은 로맨티스트
            </>,
            <>
              IT 기술, 그 자체인 현대 시대에서
              <br />
              앞으로의 직업으로 IT업계를 고민하는 취준생
            </>,
          ],
        },
      };
  }
};

export const getSpecialTargetData = (url: string): Target => {
  switch (url) {
    case "chang":
      return {
        targets: [
          <>
            실패를 되풀이 하지 않고,
            <br />
            다시 창업하고 싶은 기창업가
          </>,
          <>
            6개월 내에 반드시
            <br />
            창업을 하고 싶은 예비 창업가
          </>,
          <>
            지금은 아니지만 언젠가
            <br />
            창업을 하고 싶은 모든 분들
          </>,
        ],
      };
    case "hr":
      return {
        targets: [
          <>
            전사적인
            <br />
            Digital Transformation을
            <br />
            준비하는 교육 담당자
          </>,
          <>
            임직원 역량 향상을 위해
            <br />
            실무에 도움이 되는 교육을
            <br />
            고민하는 C레벨
          </>,
          <>
            IT 전환을 통해
            <br />
            성과를 창출하고 싶은
            <br />
            임직원 누구나
          </>,
        ],
      };
    case "teenager":
      return {
        targets: [
          <>
            코딩 교육이 필요할 것 같긴 한데,
            <br />
            정보가 부족해서 어떻게 해야할지
            <br />
            모르겠는 분
          </>,
          <>
            자녀가 꾸준히 배워
            <br />
            눈에 보이는 성과를 만들 수 있는
            <br />
            교육 방법이 궁금한 분
          </>,
          <>
            코딩을 배워서 입시와 취업에
            <br />
            어떻게 활용할 수 있을지
            <br />
            예시가 궁금한 분
          </>,
        ],
      };
    case "chatgpt":
      return {
        targets: [
          <>
            chatGPT를 예제와 함께 배우며,
            <br />
            다양한 활용 방법을 배우고 싶은 사람
          </>,
          <>
            코딩, chatGPT도 잘 모르지만
            <br />
            웹사이트를 직접 만들어보고 싶은 사람
          </>,
          <>코딩을 시작하기 두려웠던 진짜 왕초보</>,
        ],
      };
    case "nbfree":
      return {
        targets: [
          <>
            <b>학교 3학년 이상의 재학생, 취업준비생</b>
            <br />
            취업에 도움이 될 꿀팁과 비전공자 IT스펙 쌓기 방법을 알려드릴게요.
          </>,
          <>
            <b>IT회사에 재직 혹은 이직을 생각중인 직장인</b>
            <br />
            커리어 점프를 위해 무엇을 어떻게 배우면 좋을 지 알려드릴게요.
          </>,
          <>
            <b>부담없이 자기계발, 부업을 시작하고 싶은 분</b>
            <br />
            교육비 수령방법부터, 웹/앱서비스 만들기 공부 방법까지 알려드릴게요.
          </>,
        ],
      };
    case "wf_free":
      return {
        targets: [
          <>
            아직 코딩과 AI가 낯설지만
            <br />
            웹사이트를 직접 만들어보고 싶은 사람
          </>,
          <>
            화제의 ChatGPT, Webflow, No-code를
            <br />한 번에 입문하고 싶은 사람
          </>,
          <>코딩을 시작하기 두려웠던 진짜 왕초보</>,
        ],
      };
    case "ai-virtual":
      return {
        targets: [
          <>AI가 막연하게 어떤 것인지 궁금하신 분</>,
          <>
            화제의 ChatGPT와 생성 AI 툴을 <br />
            사용해 보고 싶은 사람
          </>,
          <>
            생성 AI로 나만의 콘텐츠를 쉽고 재밌게 <br />
            만들어보고 싶은 사람
          </>,
        ],
      };
    case "codingtest":
      return {
        targets: [
          <>코딩 테스트 준비를 하고 싶지만 막막한 분들</>,
          <>
            알고리즘, 자료구조를 어떻게 공부해야 할지
            <br />
            모르는 분들
          </>,
          <>기업별 코딩 테스트 출제 경향이 궁금하신 분들</>,
        ],
      };
    case "androidvsios":
      return {
        targets: [
          <>앱 개발에 관심이 있지만 시작이 막막한 분들</>,
          <>주위 현직 개발자가 없어 조언이 필요한 분들</>,
          <>개발자 취업 부트캠프를 고려하고 계신 분들</>,
        ],
      };
  }
};

export const getSpecialCurriculumData = (url: string): Curriculum => {
  switch (url) {
    case "myprofile":
      return {
        lectures: {
          duration: "1시간",
          details: [
            {
              title: "오늘 배울 것",
              desc: "아주 기초적인 웹페이지 동작원리를 배우며 함께 프로그램을 설치해요.",
            },
            {
              title: "HTML, CSS 기초",
              desc: "간단한 실습을 통해 HTML과 CSS를 직접 만져봐요.",
            },
            {
              title: "프로필 만들기",
              desc: "내 프로필이 될 페이지의 배경색을 넣고, 이미지와 타이틀 텍스트도 넣어봅니다.",
            },
            {
              title: "링크 만들기",
              desc: "링크가 들어갈 버튼을 여러개 만들어보고 마음에 드는 폰트를 적용해봐요.",
            },
            {
              title: "배포하기",
              desc: "온라인 공간에 내 프로필페이지를 올릴 수 있는 보관소를 만들어봐요.",
            },
          ],
        },
      };
    case "momentum":
      return {
        lectures: {
          duration: "1시간",
          details: [
            {
              title: "오늘 배울 것",
              desc: "아주 기초적인 웹페이지 동작원리를 배우며 함께 프로그램을 설치해요.",
            },
            {
              title: "HTML, CSS 기초",
              desc: "간단한 실습을 통해 HTML과 CSS를 직접 만져봐요.",
            },
            {
              title: "나만의 동기부여 페이지 뼈대 만들기",
              desc: "웹페이지의 구조를 배워보며, 나만의 동기부여 페이지의 뼈대를 만들어봐요.",
            },
            {
              title: "나만의 시작페이지 꾸미기",
              desc: "배경화면 이미지 넣기, 글씨 크기와 여백 넣는 법을 다뤄봐요.",
            },
            {
              title: "시간, 명언 기능 넣기",
              desc: "현재 시간, 랜덤 명언 기능을 붙여봐요.",
            },
            {
              title: "배포하기",
              desc: "Github에 업로드하여 나만의 페이지를 만들고, 주소를 공유해봐요.",
            },
          ],
        },
      };
    case "bucketlist":
      return {
        lectures: {
          duration: "40분",
          details: [
            {
              title: "오늘 배울 것",
              desc: "아주 기초적인 웹페이지 동작원리를 배우며 함께 프로그램을 설치해요.",
            },
            {
              title: "HTML, CSS 기초",
              desc: "간단한 실습을 통해 HTML과 CSS를 직접 만져봐요.",
            },
            {
              title: "신년계획 뼈대 만들기",
              desc: "웹페이지의 구조를 배워보며, 나만의 2023 버킷리스트 뼈대를 만들어봐요.",
            },
            {
              title: "신년계획 꾸미기(1)",
              desc: "다양한 버킷리스들을 넣고, 글씨 크기와 여백 넣는 법을 다뤄봐요.",
            },
            {
              title: "신년계획 꾸미기(2)",
              desc: "버킷리스트 순서 바꾸기, 태그 생성, 목표 달성 시 도장까지 찍는 기능을 붙여봐요.",
            },
            {
              title: "드디어 배포해보기!",
              desc: "Github에 업로드하여 내가 만든  페이지를 만들고, 주소를 공유해봐요.",
            },
          ],
        },
      };
    case "photos":
      return {
        lectures: {
          duration: "1시간",
          details: [
            {
              title: "코딩네컷 만들기 준비",
              desc: "아주 기초적인 웹페이지 동작원리를 배우며 함께 프로그램을 설치해요.",
            },
            {
              title: "HTML, CSS 기초",
              desc: "간단한 실습을 통해 HTML과 CSS를 직접 만져봐요.",
            },
            {
              title: "코딩네컷 실전 (1) - 뼈대 만들기",
              desc: "건물을 만들 때 건축뼈대를 잡는 것 처럼, 웹페이지의 구조를 잡아봐요.",
            },
            {
              title: "코딩네컷 실전 (2) - 기본 스타일링",
              desc: "글씨 폰트를 수정하고 여백을 넣어 웹페이지를 예쁘게 꾸며봐요.",
            },
            {
              title: "코딩네컷 실전 (3) - 스타일링 마무리",
              desc: "이미지를 넣어서 디자인적으로 완성된 웹페이지를 만날 수 있어요.",
            },
            {
              title: "한걸음 더 나아가기",
              desc: "동영상을 넣어보고, 마우스 오버 기능을 구현할 수 있어요.",
            },
            {
              title: "드디어 인터넷 세상에 공개해보기!",
              desc: "Github에 업로드하여 내가 만든 페이지를 배포하고, 주소를 공유해봐요.",
            },
          ],
        },
      };
    case "chang":
      return {
        title: "실제 사례를 바탕으로 \n진정성있게 준비했습니다.",
        lectures: {
          duration: "1시간",
          details: [
            {
              title: "오늘 배울 것",
              desc: "IT 창업의 개념과 장점/단점, 성공 확률을 알아봅니다.",
            },
            {
              title: "좋은 시장과 좋은 아이템",
              desc: "어떤 아이템으로 어떤 시장을 공략해야 하는지 알아봅니다.",
            },
            {
              title: "어떻게 시작하는 걸까?",
              desc: "팀스파르타의 시작과 피봇팅 사례를 바탕으로 초기 창업에 임하는 자세를 알아봅니다.",
            },
            {
              title: "누구나 할 수 있을까?",
              desc: "IT 창업의 사이클을 이해하고, 빠르게 성공하기 위한 창업가의 자질을 알아봅니다.",
            },
            {
              title: "누구와 해야 될까?",
              desc: "공동창업자 선정 기준과 나에게 적합한 팀 빌딩 방식을 알아봅니다.",
            },
          ],
        },
      };
    case "hr":
      return {
        lectures: {
          duration: "30분",
          details: [
            {
              title: "DT시대 직무교육에 왜 코딩이 필요할까요?",
              desc: "IT시장의 배경과 전망을 살펴봅니다.",
            },
            {
              title: "담당자는 무엇을 어떻게 준비해야할까요?",
              desc: "교육 전 꼭 필요한 세팅과 꿀팁을 배워봅니다.",
            },
            {
              title:
                "본격적으로, 스파르타코딩클럽의 사례와 노하우를 공개합니다.",
              desc: "DT 및 IT 교육의 성공사례를 살펴봅니다.",
            },
            {
              title: "우리 회사는 어떤 언어로 교육을 해야할까요?",
              desc: "우리 회사 교육에 사용해야하는 언어는 무엇인지 알아봅니다.",
            },
            {
              title: "직무에 따라 어떻게 교육을 진행해야 할까요?",
              desc: "임직원의 직무에 따라 적합한 교육을 소개합니다.",
            },
            {
              title: "회사의 규모에 따라 어떻게 교육을 진행해야 할까요?",
              desc: "대기업, 중견&중소기업, 스타트업에 따라 달라지는 교육방식을 안내합니다.",
            },
            {
              title: "교육 업체 선정에는 어떤 기준이 필요하나요?",
              desc: "어떤 조건이 교육의 성과에 영향을 주는지 확인해봅니다.",
            },
            {
              title:
                "그럼 구체적으로 우리 회사에 딱 맞는 교육설계는 무엇입니까?",
              desc: "우리 회사에 딱 맞는 교육설계 방법을 소개합니다.",
            },
          ],
        },
      };
    case "teenager":
      return {
        lectures: {
          duration: "30분",
          details: [
            {
              title: "중고등학생이 코딩을 배워야 하는 이유",
              desc: "시대적 배경과 함께 입시와 취업에서의 활용도에 대해 알아봅니다.",
            },
            {
              title: "그래서, 코딩이란 무엇인가요?",
              desc: "찰진 비유와 적절한 개념을 통해 코딩이 무엇인지 맛을 봅니다.",
            },
            {
              title: "그럼, 우리아이 교육은 어떻게 시작해야 하나요?",
              desc: "청소년 코딩교육에 꼭 필요한 핵심이 무엇인지 알아봅니다.",
            },
            {
              title: "자주 물어보시는 질문들",
              desc: "부모님들께서 가장 궁금해하는 질문을 모아 답변해봅니다.",
            },
            {
              title: "습관형성을 위한 코딩학습 프로그램",
              desc: "스파르타코딩클럽의 교육 시스템을 살펴보며 꼭 필요한 항목들을 확인해봅니다.",
            },
          ],
        },
      };
    case "chatgpt":
      return {
        lectures: {
          duration: "1시간",
          details: [
            {
              title: "오늘 배울 것",
              desc: "chatGPT 가입부터 필수 프로그램 설치까지 함께 해봐요.",
            },
            {
              title: "HTML, CSS 기초",
              desc: "아주 기초적인 웹페이지 동작원리를 배워봐요.",
            },
            {
              title: "GPT에 명령 내리기",
              desc: "코딩을 할 때 chatGPT에게 적절한 질문을 하는 방법을 배워봐요.",
            },
            {
              title: "GPT로 카드 만들기",
              desc: "chatGPT를 활용해서 웹사이트를 이루는 카드 부분을 만들어봐요.",
            },
            {
              title: "카드 꾸미기",
              desc: "chatGPT에게 물어봐가면서 웹사이트를 이루는 요소를 수정해봐요.",
            },
            {
              title: "배포하기",
              desc: "Github에 업로드하여 내가 만든 페이지를 배포하고, 주소를 공유해봐요.",
            },
          ],
        },
      };
    case "nbfree":
      return {
        lectures: {
          duration: "30분",
          details: [
            {
              title: "내일배움카드 발급 신청하기",
              desc: "정말 하나 하나 짚어드릴 거에요. 영상을 보고 따라하면, 30분이면 발급 완료!",
            },
            {
              title: "내일배움카드 최대 활용 꿀팁",
              desc: "무료 코딩 강의 추천부터, 요즘 핫한 개발자 취업 캠프까지 알려드려요.",
            },
          ],
        },
      };
    case "wf_free":
      return {
        lectures: {
          duration: "1시간",
          details: [
            {
              title: "오늘 배울 것",
              desc: "아주 기초적인 웹페이지 동작원리를 배우며 함께 필수 프로그램을 설치해요.",
            },
            {
              title: "Webflow 핵심 기본기 배우기 ",
              desc: "웹플로우의 유저 인터페이스를 살펴보며, 기본 동작원리를 함께 실행해보아요.",
            },
            {
              title: "Webflow 무작정 따라해보기",
              desc: "망설이지 않고, 웹플로우 디자인까지 자유롭게 적용하면서 섹션을 만들어요.",
            },
            {
              title: "AI로 빠르게 페이지 구성하기",
              desc: "화제의 ChatGPT부터 AI로 이미지 생성까지 빠르게 필요한 내용들을 채워요.",
            },
            {
              title: "웹 페이지에 움직임 넣기",
              desc: "링크 설정하기부터 마우스 호버, 간단한 애니메이션까지 추가해볼게요.",
            },
            {
              title: "배포하기",
              desc: "Github에 업로드하여 내가 만든 페이지를 배포하고, 주소를 공유해봐요.",
            },
          ],
        },
      };
    case "ai-virtual":
      return {
        lectures: {
          duration: "20분",
          details: [
            {
              title: "오늘 배울 것",
              desc: "생성 AI로 손쉽게 이미지와 영상을 만드는 방법을 소개합니다.",
            },
            {
              title: "ChatGPT로 이미지 기획하기",
              desc: "버추얼 캐릭터의 특징을 구체적으로 묘사합니다.",
            },
            {
              title: "이미지로 말하는 동영상 만들기",
              desc: "앞서 제작한 캐릭터 이미지에 목소리를 입혀봅니다.",
            },
            {
              title: "숙제 제출하기",
              desc: "제작한 영상을 업로드하고 자랑해봅니다.",
            },
          ],
        },
      };
    case "codingtest":
      return {
        lectures: {
          duration: "1시간",
          details: [
            {
              title: "오늘 배울 것",
              desc: "코딩 테스트가 무엇인지부터 알아가며, 알고리즘 학습 로드맵까지 완성해봐요.",
            },
            {
              title: "코딩 테스트가 뭘까요?",
              desc: "기업에서는 코딩 테스트를 왜 볼까? 코딩 테스트의 중요성을 배웁니다.",
            },
            {
              title: "기업 별 코딩 테스트 유형 분석!",
              desc: "주요 기업 별 풀이시간부터 문제 유형을 한눈에 알아보아요.",
            },
            {
              title: "알고리즘 학습 로드맵! 최단 시간 내로 정복하자!",
              desc: "코딩 테스트 합격을 위한 올바른 알고리즘 학습 방법을 알아보세요.",
            },
            {
              title: "문제 풀이 비밀 노트! 코딩 문제, 더이상 두려워 마세요!",
              desc: "네카라쿠배 기출 문제 풀이를 통해 나만의 알고리즘 포트폴리오까지 만들어요.",
            },
            {
              title: "코딩 테스트 정복을 위한 꿀팁 대방출!",
              desc: "본격 코딩 테스트 준비 전, 주의사항을 알려드립니다.",
            },
          ],
        },
      };
    case "androidvsios":
      return {
        lectures: {
          duration: "17분",
          details: [
            {
              title: "앱 개발의 모든 것, 안드로이드 vs iOS",
              desc: "개발자 커리어를 쌓기 전, 꼭 비교 분석해보고 시작하세요!",
            },
          ],
        },
      };
  }
};

export const getSpecialCompactData = (url) => {
  switch (url) {
    case "chang":
      return {
        subTitle: "예비 창업가분들의 시간을 아끼기 위해",
        points: [
          {
            thumbnail: "mobile",
            title: "모바일로 수강 가능합니다.",
            desc:
              "실습이 필요없는 강의로 언제 어디에서나 모바일로\n" +
              "수강이 가능합니다.",
          },
          {
            thumbnail: "quick-chang",
            title: "짧은 시간 안에 빠르게 끝낼 수 있습니다. ",
            desc:
              "출퇴근 시간에 30분씩 2번이면 모든 강의 내용을\n" +
              "수강하실 수 있습니다. 쓸데없는 내용을 모두 걷어내고\n" +
              "압축적으로 핵심만 담았습니다.",
          },
          {
            thumbnail: "all-chang",
            title: "궁금하실 내용을 모두 담았습니다.",
            desc:
              "아이템 선정과 팀빌딩, 그 외 노하우까지 창업을\n" +
              "준비하면서 궁금해하실 만한 부분들을 모두 담았습니다.",
          },
        ],
      };
    case "hr":
      return {
        subTitle: "바쁘신 담당자님들 시간을 아끼기 위해",
        points: [
          {
            thumbnail: "mobile",
            title: "모바일로 수강 가능합니다.",
            desc:
              "실습이 필요없는 강의로 언제 어디에서나 모바일로\n" +
              "수강이 가능합니다.",
          },
          {
            thumbnail: "quick-hr",
            title: "짧은 시간 안에 빠르게 끝낼 수 있습니다. ",
            desc:
              "출퇴근 시간에 15분씩 2번이면 모든 강의 내용을\n" +
              "수강하실 수 있습니다. 쓸데없는 내용을 모두 걷어내고\n" +
              "압축적으로 핵심만 담았습니다.",
          },
          {
            thumbnail: "all-hr",
            title: "궁금하실 내용을 모두 담았습니다.",
            desc:
              "시장현황, 교육방법, 그외 꿀팁까지 코딩 교육을\n" +
              "준비하면서 궁금해하실 만한 부분들을 모두 담았습니다.",
          },
        ],
      };
    case "teenager":
      return {
        subTitle: "바쁘신 부모님들의 시간을 아끼기 위해",
        points: [
          {
            thumbnail: "mobile-teenager",
            title: "모바일로 수강 가능합니다.",
            desc:
              "실습이 필요없는 강의로 언제 어디에서나 모바일로\n" +
              "수강이 가능합니다.",
          },
          {
            thumbnail: "quick-teenager",
            title: "짧은 시간 안에 빠르게 끝낼 수 있습니다. ",
            desc:
              "출퇴근 시간에 15분씩 2번이면 모든 강의 내용을\n" +
              "수강하실 수 있습니다. 쓸데없는 내용을 모두 걷어내고\n" +
              "압축적으로 핵심만 담았습니다.",
          },
          {
            thumbnail: "all-teenager",
            title: "궁금하실 내용을 모두 담았습니다.",
            desc:
              "시장현황, 교육방법, 그외 꿀팁까지 코딩 교육을\n" +
              "준비하면서 궁금해하실 만한 부분들을 모두 담았습니다.",
          },
        ],
      };
    case "chatgpt":
      return {
        subTitle: "바쁘신 부모님들의 시간을 아끼기 위해",
        points: [
          {
            thumbnail: "mobile-teenager",
            title: "모바일로 수강 가능합니다.",
            desc:
              "실습이 필요없는 강의로 언제 어디에서나 모바일로\n" +
              "수강이 가능합니다.",
          },
          {
            thumbnail: "quick-teenager",
            title: "짧은 시간 안에 빠르게 끝낼 수 있습니다. ",
            desc:
              "출퇴근 시간에 15분씩 2번이면 모든 강의 내용을\n" +
              "수강하실 수 있습니다. 쓸데없는 내용을 모두 걷어내고\n" +
              "압축적으로 핵심만 담았습니다.",
          },
          {
            thumbnail: "all-teenager",
            title: "궁금하실 내용을 모두 담았습니다.",
            desc:
              "시장현황, 교육방법, 그외 꿀팁까지 코딩 교육을\n" +
              "준비하면서 궁금해하실 만한 부분들을 모두 담았습니다.",
          },
        ],
      };
  }
};

export const getSpecialLimitData = (url: string) => {
  return {
    title: courseTitles[url],
    month: new Date().getMonth() + 1,
    displayPrice: 89000,
  };
};

const specialFaq: SpecialFAQ = {
  minSpec: {
    name: "CurriDetailMinSpec",
    question: `학습에 필요한 PC 최소 사양은 무엇인가요?`,
    answer: `<b>Window</b>
- 윈도우 버전 10 이상
- RAM 8G 이상 
- i5 이상 
- 64 bit 이상 

<b>Mac OS</b>
- 11.7 (Big Sur) 이상 

*내 노트북 사양 확인 방법하기 
- Window : 컴퓨터 설정 > 시스템 > 정보 
- Mac : 화면 좌측 상단 Apple 로고 > 이 Mac에 관하여`,
  },
  fastQna: {
    name: "CurriDetailfastQna",
    question: `학습 중 어려운 것이 생기면 어떻게 질문하나요?`,
    answer: `본 과목은 즉문즉답 서비스 제공 과목입니다. 
스파르타코딩클럽 > 커뮤니티 > 학습질문 게시판에 질문을 남겨주시면, 매일 아침 9시부터 밤 12시 사이에는 10분 내 답변을 남겨드려요.
그 외 시간대에는 최대 24시간 내 답변을 남겨드립니다. 

*학습질문은 수강기간 (복습기간 제공 과목의 경우, 복습기간까지 포함) 종료 후 3개월까지 제공됩니다.`,
  },
  in24HoursAnswer: {
    name: "CurriDetailIs24HoursAnswer",
    question: `학습 중 어려운 것이 생기면 어떻게 질문하나요?`,
    answer: `스파르타코딩클럽 > 커뮤니티 > 학습질문 게시판에 질문을 남겨주시면, 최대 24시간 내에 튜터님께서 답변을 남겨드립니다. 

    *학습질문은 수강기간 (복습기간 제공 과목의 경우, 복습기간까지 포함) 종료 후 3개월까지 제공됩니다.`,
  },
  channelTalkAnswer: {
    name: "CurriDetailChannelTalkAnswer",
    question: `학습 중 어려운 것이 생기면 어떻게 질문하나요?`,
    answer: `강의를 듣다 궁금한 점이 생기면 아래 링크로 문의해주세요.

https://spartacodingclub.channel.io/support-bots/41731`,
  },
  benefit: {
    name: "CurriDetailBenefit",
    question: "수료 기준은 무엇이며, 수료하면 무엇이 좋은가요?",
    answer: `[일반 결제] 
1) 수료 기준: 정규 수강기간 내 진도율 100% 달성 시
2) 수료 혜택: 강의 평생소장 가능
3) 수료증 발급: 수강기간 종료일 다음날부터 마이페이지에서 다운로드 가능`,
  },
  pointBenefit: {
    name: "CurriDetailBenefit",
    question: "수료 기준은 무엇이며, 수료하면 무엇이 좋은가요?",
    answer: `[일반 결제] 
1) 수료 기준: 정규 수강기간 내 진도율 100% 달성 시
2) 수료 혜택: 즉시 사용 가능한 1만 포인트 자동 지급
3) 수료증 발급: 수강기간 종료일 다음날부터 마이페이지에서 다운로드 가능`,
  },
  duration: {
    name: "CurriDetailDuration",
    question: "강의는 언제까지 수강할 수 있나요?",
    answer: `신청당시 확인되는 해당 기수의 정규 수강기간 내 수강이 가능하며,
정상 수강 기간 내 진도율 100% 달성시 강의 평생소장 혜택이 제공되어, 언제든 수강할 수 있습니다.`,
  },
  additionalDuration: {
    name: "CurriDetailDuration",
    question: "강의는 언제까지 수강할 수 있나요?",
    answer: `본 강의는 정규 수강기간에 더해, 정규 수강기간 만큼의 복습 기간이 제공됩니다. 
(예. 정규 수강기간이 2주인 경우, 2주의 복습기간이 추가로 제공되어 총 4주 간 수강 가능)
복습 기간까지 활용해 알차게 수강해주세요.`,
  },
  lifetimeDuration: {
    name: "CurriDetailDuration",
    question: "강의는 언제까지 수강할 수 있나요?",
    answer: `본 강의는 정해진 수강기간이 없는 평생소장 강의입니다.`,
  },
};

export const getSpecialFaqData = (url: string) => {
  if (isFastQna(url)) {
    return [
      specialFaq.minSpec,
      specialFaq.fastQna,
      specialFaq.benefit,
      specialFaq.duration,
    ];
  } else if (isIn24HoursAnswer(url)) {
    return [
      specialFaq.minSpec,
      specialFaq.in24HoursAnswer,
      specialFaq.benefit,
      specialFaq.duration,
    ];
  } else if (isChannelTalkAnswer(url)) {
    return [
      specialFaq.minSpec,
      specialFaq.channelTalkAnswer,
      specialFaq.benefit,
      specialFaq.duration,
    ];
  } else if (url === "myprofile" || url === "momentum") {
    return [
      specialFaq.minSpec,
      specialFaq.fastQna,
      specialFaq.pointBenefit,
      specialFaq.lifetimeDuration,
    ];
  } else if (url === "bucketlist") {
    return [
      {
        name: "CurriDetailFAQIsEasy",
        question: "기초가 전혀없는 왕초보도 정말 가능한가요?",
        answer:
          "저희가 가장 많이 받는 질문인데요! 네, 정말 괜찮답니다. 11살 초등학생부터 70대 선생님까지 이미 아주 많이 다녀가셨어요. 스파르타는 왕초보 탈출에 특화되어 있답니다.",
      },
      {
        name: "CurriDetailFAQQuesion",
        question: "제 컴퓨터로도 수강할 수 있나요?",
        answer:
          "<b>딱 1분, PC 사양을  확인해 보세요.🙂</b><br/>\n<br/>\n - 윈도우: 컴퓨터 설정 -> 시스템 -> 정보<br/>\n - Mac: 화면 왼쪽 상단 Apple 로고 -> 이 Mac에 관하여<br/><br/>\n<b>권장사양은 아래와 같습니다.😊</b><br/><br/>\n<b>윈도우</b><br/>\n - 윈도우버전 10 이상 <br/>\n - RAM 8G 이상 <br/>\n - i5 이상<br/>\n - 64bit 이상 <br/>\n <br/>\n<b>Mac OS</b> <br/>\n - 11.7 (Big Sur) 이상",
      },
      {
        name: "CurriDetailFAQTime",
        question: "온라인인가요? 오프라인인가요?",
        answer:
          "녹화된 강의로 온라인100% 진행되기 때문에 시간과 장소 제한없이 자유롭게 수강할 수 있습니다!",
      },
      {
        name: "CurriDetailFAQReimburse",
        question: "함께 모여서 수업을 듣나요?",
        answer:
          "아닙니다! 따로 수강생분들끼리 실시간으로 모여서 수강하지 않기 때문에 편하게 원하시는 시간에 자유롭게 수강할 수 있습니다 😊",
      },
      {
        name: "CurriDetailFAQOverseas",
        question: "수강 중 모르는 것은 어떻게 질문하나요?",
        answer: `본 과목은 즉문즉답 서비스 제공 과목으로, 매일 아침 9시부터 밤 12시까지 튜터님의 실시간 답변을 받아볼 수 있어요! 스파르타코딩클럽 커뮤니티 학습 질문 게시판에 질문을 남겨주시면 된답니다. 이 외의 시간에도 언제든 질문을 남겨주시면 최대 24시간 내로 답변을 드립니다.<br/>*학습 질문 이용기간: 구매일로부터 + 3개월`,
      },
      {
        name: "CurriDetailFAQNotebook",
        question: "수강기간이 어떻게 되나요?",
        answer:
          "기본 수강기간은 3일입니다. 단! 24시간 내 마지막 강의까지 완주하신 분들은 언제든 필요할 때 강의와 강의자료를 꺼내보실 수 있도록 평생소장의 혜택이 적용되어 수강기간 이후에도 수강이 가능하십니다!",
      },
      {
        name: "1",
        question: "할인, 포인트 정책은 어떻게 되나요?",
        answer: `본 무료강의의 결제금액은 0원으로, 할인 적용 및 포인트 사용이 불가합니다.\n단, 본 무료강의를 끝까지 수강하시면 다른 유료 강의 신청시 즉시 사용할 수 있는 할인 쿠폰 패키지가 지급됩니다.`,
      },
      {
        name: "2",
        question: "궁금한 점이 더 있어요!!",
        answer:
          "이외의 모든 문의는 1:1 채팅 상담으로 받고 있습니다.<br/> 홈페이지 우측 하단 문의하기를 이용하시면 자세한 상담을 받으실 수 있습니다.",
      },
    ];
  } else {
    return [
      {
        name: "CurriDetailFAQIsEasy",
        question: "기초가 전혀없는 왕초보도 정말 가능한가요?",
        answer:
          "저희가 가장 많이 받는 질문인데요! 네, 정말 괜찮답니다. 11살 초등학생부터 70대 선생님까지 이미 아주 많이 다녀가셨어요. 스파르타는 왕초보 탈출에 특화되어 있답니다.",
      },
      {
        name: "CurriDetailFAQQuesion",
        question: "제 컴퓨터로도 수강할 수 있나요?",
        answer:
          "<b>딱 1분, PC 사양을  확인해 보세요.🙂</b><br/>\n<br/>\n - 윈도우: 컴퓨터 설정 -> 시스템 -> 정보<br/>\n - Mac: 화면 왼쪽 상단 Apple 로고 -> 이 Mac에 관하여<br/><br/>\n<b>권장사양은 아래와 같습니다.😊</b><br/><br/>\n<b>윈도우</b><br/>\n - 윈도우버전 10 이상 <br/>\n - RAM 8G 이상 <br/>\n - i5 이상<br/>\n - 64bit 이상 <br/>\n <br/>\n<b>Mac OS</b> <br/>\n - 11.7 (Big Sur) 이상",
      },
      {
        name: "CurriDetailFAQTime",
        question: "온라인인가요? 오프라인인가요?",
        answer:
          "녹화된 강의로 온라인100% 진행되기 때문에 시간과 장소 제한없이 자유롭게 수강할 수 있습니다!",
      },
      {
        name: "CurriDetailFAQReimburse",
        question: "함께 모여서 수업을 듣나요?",
        answer:
          "아닙니다! 따로 수강생분들끼리 실시간으로 모여서 수강하지 않기 때문에 편하게 원하시는 시간에 자유롭게 수강할 수 있습니다 😊",
      },
      {
        name: "CurriDetailFAQOverseas",
        question: "수강 중 모르는 것은 어떻게 질문하나요?",
        answer: `본 과목은 즉문즉답 서비스 제공 과목으로, 매일 아침 9시부터 밤 12시까지 튜터님의 실시간 답변을 받아볼 수 있어요! 스파르타코딩클럽 커뮤니티 학습 질문 게시판에 질문을 남겨주시면 된답니다. 이 외의 시간에도 언제든 질문을 남겨주시면 최대 24시간 내로 답변을 드립니다.<br/><br/>
            *학습 질문 이용기간: 정규/복습 기간 + 3개월`,
      },
      {
        name: "CurriDetailFAQNotebook",
        question: "수강기간이 어떻게 되나요?",
        answer:
          "특별히 수강기간은 평생 적용됩니다 :) 언제든 필요할 때 강의와 강의자료를 꺼내보실 수 있어요! ",
      },
      {
        name: "1",
        question: "할인, 포인트 정책은 어떻게 되나요?",
        answer: `본 무료강의의 결제금액은 0원으로, 할인 적용 및 포인트 사용이 불가합니다.\n단, 본 무료강의를 끝까지 수강하시면 다른 유료 강의 신청시 즉시 사용할 수 있는 할인 쿠폰 패키지가 지급됩니다.`,
      },
      {
        name: "2",
        question: "궁금한 점이 더 있어요!!",
        answer:
          "이외의 모든 문의는 1:1 채팅 상담으로 받고 있습니다.<br/> 홈페이지 우측 하단 문의하기를 이용하시면 자세한 상담을 받으실 수 있습니다.",
      },
    ];
  }
};

export const getSpecialReviewData = (url: string) => {
  switch (url) {
    case "androidvsios":
      return [
        {
          name: "손*주",
          tag: "개발자",
          content:
            "스파르타 무료강의에서 현직 개발자 분들의 최신 개발 상황을 말해주셔서 좋았어요. 앱 개발자로 직무를 바꾸려 고민하던 와중 큰 도움이 되었습니다.",
        },
        {
          name: "박*수",
          tag: "직장인",
          content:
            "앱 개발.. 시작해보고 싶다는 생각만 있었는데 주위에 인맥이 없어서 힘들었어요 ㅠ 멘토님이 친절하게 알려주셔서 앱 개발자로 시작할 용기가 생겼어요!!! 감사합니다 튜터님들~",
        },
        {
          name: "최*임",
          tag: "학생",
          content:
            "졸업하면서 개발자로 시작하려 찾아보던 중, 저도 무료로 할 수 있더라고요! 어렵지 않고 재밌게 말씀을 너무 잘하셔서 귀에 쏙쏙 들어와요!!",
        },
      ];
    default:
      return [
        {
          name: "박*희",
          tag: "비전공자",
          content:
            "혼자 HTML을 공부할 때는 너무 문법위주의 공부와 이를 외우려고 했는데 이 수업을 통해 쉽고 빠르게 멋진 웹페이지를 만들어 볼 수 있었다는게 신기했습니다! 더 자주 쓰이는 문법도 알고 싶어요!!",
        },
        {
          name: "최*경",
          tag: "직장인",
          content:
            "코딩.. 막연하게 배워보고 싶다는 생각만 했었는데 강의도 짧고 이해도 잘 되서 너무 좋아요 ㅠ 멘토님도 너무 친절하시고... 이런 강의 또 열어주세요!!",
        },
        {
          name: "김*진",
          tag: "주부",
          content:
            "처음 접해보는 코딩.. 중간에 많이 해맸지만 잘 해낼 수 있어서 너무 신기했습니다! 웹페이지의 구성과 기초 부분에 대해서 정말 쉽지만 구체적으로 이해할 수 있도록 내용이 알차게 들어가 있었어요!",
        },
      ];
  }
};

import {
  Cafe24Ohsquare,
  Cafe24Surround,
  Dokrip,
  DsDigital,
  DungGeunMo,
  EBSHunminjeongeumSBA,
  GmarketSans,
  NanumHandWritingDaughter,
  Pretendard,
  SbAggroBold,
  SpoqaHanSans,
  SpoqaHanSansNeo,
  ThefaceshopInklipquid,
  TmoneyRoundWind,
  YoonDokrip,
} from "./fonts";

export const fontFace = `
    ${SpoqaHanSansNeo}
    ${GmarketSans}
    ${Pretendard}
    ${SpoqaHanSans}
    ${Cafe24Ohsquare}
    ${NanumHandWritingDaughter}
    ${ThefaceshopInklipquid}
    ${SbAggroBold}
    ${DungGeunMo}
    ${TmoneyRoundWind}
    ${Cafe24Surround}
    ${EBSHunminjeongeumSBA}
    ${Dokrip}
    ${DsDigital}
    ${YoonDokrip}
`;

export * from "./variables";

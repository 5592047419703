import { useAtom } from "jotai";
import { toastStateAtom } from "src/stores/_common/modal";
import * as S from "./Toast.style";

export const useToast = () => {
  const [toastState, setToastState] = useAtom(toastStateAtom);

  const showToast = (message: string, isCenter = false) => {
    if (toastState.isVisible) return;
    setToastState({ message, isVisible: true, isCenter });

    setTimeout(() => {
      setToastState((current) => ({
        ...current,
        isVisible: false,
      }));
    }, 4000);
  };

  return showToast;
};

export const Toast = () => {
  const [toast] = useAtom(toastStateAtom);

  return (
    <>
      {toast.isVisible && (
        <S.ToastWrapper isVisible={toast.isVisible} isCenter={toast.isCenter}>
          <S.ToastContainer
            isVisible={toast.isVisible}
            isCenter={toast.isCenter}
          >
            <S.ToastText>{toast.message}</S.ToastText>
          </S.ToastContainer>
        </S.ToastWrapper>
      )}
    </>
  );
};

import React from "react";

class Share {
  sendScrap: (param1: object) => void;
  sendCustom: (param1: object) => void;
}

class Kakao {
  init: (param1: string) => void;
  Share: Share;
  isInitialized: () => boolean;
  Link: any;
}

declare global {
  interface Window {
    Kakao: Kakao;
  }
}

export const InitKakao = () => {
  if (typeof window !== "undefined") {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.KAKAO_API_KEY);
    }
    console.log("k: ", window.Kakao?.isInitialized());
  }
};

export const shareKakao = async (templateId, args) => {
  InitKakao();

  window.Kakao.Share.sendCustom({
    templateId: templateId,
    ...(args && { templateArgs: args }),
  });
};

export const fontSpoqaHanSansNeo = "SpoqaHanSansNeo, serif;";
export const fontGmarketSans = "GmarketSans, serif;";
export const fontGangwonEduPower = "GangwonEduPower, serif;";
export const fontPretendard =
  'Pretendard, -apple-system, “system-ui”, "Malgun Gothic", "맑은 고딕", sans-serif;';
export const fontSpoqaHanSans = "SpoqaHanSans, serif;";
export const fontCafe24Ohsquare = "Cafe24Ohsquare, serif;";
export const fontNanumHandWritingDaughter = "NanumHandWritingDaughter, serif";
export const fontThefaceshopInklipquid = "ThefaceshopInklipquid, serif";
export const fontSbAggroBold = "SbAggroBold, serif;";
export const fontDungGeunMo = "DungGeunMo, serif;";
export const fontEBS = "EBSHunminjeongeumSBA, serif;";
export const fontJeju = "'Jeju Hallasan', cursive;";

export const fontCafe24Surround = "Cafe24Surround, serif";
export const fontDokrip = "Dokrip, serif";
export const fontDsDigital = "DsDigital, serif";

import styled from "@emotion/styled";
import {
  useClickAnchor,
  useIsTargetPage,
} from "../../../../businesslogics/_layout/gnb/v2";
import {
  ArrowDropDownLine,
  ArrowDropUpLine,
  neutralDay,
  wBody1,
} from "@teamsparta/design-system";
import { SystemicDevice } from "../../../../styles/themes/device";

export const GnbAnchorDesktop = ({ anchor, isCategory = false }) => {
  const onClickAnchor = useClickAnchor(anchor);
  const isMatch = useIsTargetPage(anchor.href);

  return (
    <GnbAnchor
      isLighter={!isCategory}
      isActive={isMatch}
      onClick={onClickAnchor}
    >
      {anchor.name}
      {anchor.tag && <Tag>{anchor.tag}</Tag>}
    </GnbAnchor>
  );
};

export const GnbTopAnchorDesktop = ({
  anchor,
  isCategory = false,
  isDropdownOpen,
  onClickDropdown,
}) => {
  const onClickAnchor = useClickAnchor(anchor);
  const isMatch = useIsTargetPage(anchor.href);
  const combinedClickHandler = (e) => {
    onClickDropdown(e);
    onClickAnchor();
  };

  return (
    <GnbTopAnchor
      isLighter={!isCategory}
      isActive={isMatch}
      onClick={anchor.onClick ? (e) => combinedClickHandler(e) : onClickAnchor}
    >
      {anchor.name}
      {anchor.onClick &&
        (isDropdownOpen ? (
          <ArrowDropUpLine size={16} color={"#B4BFC6"} />
        ) : (
          <ArrowDropDownLine size={16} color={"#B4BFC6"} />
        ))}
    </GnbTopAnchor>
  );
};

const GnbAnchor = styled.div<{ isLighter?; isActive }>`
  ${wBody1};
  padding: 9px 10px 8px;
  color: ${({ isLighter, isActive }) =>
    isActive ? "#E8344E" : isLighter ? "#81898F" : "#141617"};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  :hover {
    display: inline-flex;
    padding: 9px 10px 8px;
    border-radius: 6px;
    background: ${neutralDay.gray5};
  }
`;

const GnbTopAnchor = styled.div<{ isLighter?; isActive }>`
  color: ${neutralDay.gray70};
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Tag = styled.div`
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${neutralDay.gray10};
  color: ${neutralDay.gray70};
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 15px */
  ${SystemicDevice.TabletAndDesktop} {
    height: 18px;
  }
`;

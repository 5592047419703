import React, { useEffect, useState } from "react";
import NbGuideGnbTablet from "./NbGuideGnb.tablet";
import NbGuideGnbMobile from "./NbGuideGnb.mobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { nbGuideGnbTablet } from "./nbGuideGnb.style";
import { useRouter } from "next/router";
const NbGuideGnb = () => {
  const router = useRouter();
  const isDesktop = useMediaQuery(nbGuideGnbTablet);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [router.pathname]);

  return !isDesktop ? (
    <NbGuideGnbMobile isOpen={isOpen} setIsOpen={setIsOpen} />
  ) : (
    <NbGuideGnbTablet isOpen={isOpen} setIsOpen={setIsOpen} />
  );
};

export default NbGuideGnb;

// Jotai implementation
import { atom } from "jotai";

export const Categories = [
  "sparta",
  "coding-guide",
  "article",
  "impact-library",
];

export const currentBlogCategoryAtom = atom<string>("all");

import { createInstance, HackleProvider } from "@hackler/react-sdk";
import HackleDevTools from "@hackler/javascript-devtools";
import { getDeviceId } from "../../../businesslogics/_common/utils/uniqueId";
import { getCookie } from "../../../businesslogics/_common/window/cookie";

const Hackle = ({ children }) => {
  let hackleClient;

  const user = {
    id: typeof window !== "undefined" ? getDeviceId() : undefined,
    userId: typeof window !== "undefined" ? getCookie("user_id") : undefined,
    deviceId: typeof window !== "undefined" ? getDeviceId() : undefined,
  };

  const config = {
    debug: false,
    auto_track_page_view: true,
    devTool: HackleDevTools,
    autoOpenDevTool: !process.env.IS_PROD,
  };

  if (process.browser) {
    console.log("go hackle");
    // @ts-ignore
    hackleClient = createInstance(process.env.HACKLE_SDK_KEY, config);
  }

  return hackleClient ? (
    <HackleProvider hackleClient={hackleClient} user={user} supportSSR>
      {children}
    </HackleProvider>
  ) : (
    <>{children}</>
  );
};

export default Hackle;

// import { createInstance, HackleProvider } from "@hackler/react-sdk";
// import React, { useEffect, useState } from "react";
// import { Layout } from "../templates/Layout";
// import { getCookie } from "../../businesslogics/NbDiv/window/cookie";
//
// const Hackle = ({ children }) => {
//   let hackleClient;
//   const [user, setUser] = useState({});
//   useEffect(() => {
//     setUser({
//       id: getDeviceId(),
//       userId: getCookie("user_id"), // 사용자 ID (핵클 통합 식별자 사용가능)
//       deviceId: getDeviceId(), // 디바이스 ID (핵클 통합 식별자 사용가능)
//       identifiers: {
//         myCustomId: "42", // Custom ID
//       },
//     });
//   }, []);
//
//   console.log(user);
//   if (process.browser) {
//     console.log("go hackle");
//     hackleClient = createInstance(process.env.HACKLE_SDK_KEY, {
//       debug: !process.env.IS_PROD,
//       auto_track_page_view: true,
//     });
//   }
//   console.log(hackleClient);
//   return hackleClient ? (
//     <HackleProvider hackleClient={hackleClient} user={user}>
//       {children}
//     </HackleProvider>
//   ) : (
//     <>{children}</>
//   );
// };
//
// export default Hackle;

const excludedFooterPaths = [
  "/community/freeboard/[category]/[postId]/[title]",
  "/community/freeboard/[category]",
  "/community/freeboard/freeboard_edit_mobile",
  "/community/fastqna/[category]",
  "/community/fastqna/[category]/[postId]/[title]",
  "/community/fastqna/editing_mobile",
  "/community/myActivity/[behavior]",
  "/community/mobile/edit_fastqna/[...url]",
  "/community/mobile/freeboardEdit",
  "/coding1010",
];

export const isExcludeFooterMobile = (path) => {
  return excludedFooterPaths.includes(path);
};

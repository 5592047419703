import { useState, useEffect } from "react";
import { getFallbackImageSrc } from "./getFallbackImageSrc";

export const useFallbackImageSrc = (src) => {
  const { primarySrc, secondarySrc } = getFallbackImageSrc(src);

  const [imgSrc, setImgSrc] = useState("");

  const onErrorImage = () => {
    setImgSrc(secondarySrc);
  };

  //TODO: src가 isMobile 등으로 변경되는 것 알아차리기 위해 src 추가 다만 2번 요청하는 문제
  useEffect(() => {
    setImgSrc(primarySrc);
  }, [src, primarySrc]);

  return { imgSrc, primarySrc, onErrorImage };
};

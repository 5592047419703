import instance from "axios";
import { useQuery } from "react-query";
import { useUserId } from "../../businesslogics/_common/auth";

export type UserMetaData = {
  is_enrolled_exist: boolean;
  point_amount: number;
  active_coupon_count: number;
  active_voucher_count: number;
};

export interface UserMetaDto {
  ok: boolean;
  context: UserMetaData;
}

export const getUserMetaData = async (userId: string) => {
  try {
    const response = await instance.get<UserMetaDto>(
      `${process.env.ONLINE_API_URL}/v2/users/meta?user_id=${userId}`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useUserMetaQuery = () => {
  const userId = useUserId();
  return useQuery<UserMetaDto | undefined, Error>(["userMeta", userId], () => {
    if (userId) {
      return getUserMetaData(userId);
    }
  });
};

import {ThemeProvider} from "@emotion/react";
import theme from "../src/styles/themes";
import "react-quill/dist/quill.snow.css";
import "../src/components/molecules/community/Q_Q/QuillEditor/QuillEditor.css";
import "../src/components/pwa/IDE/editor.css";
import Head from "next/head";
import "../styles/globals.css";
import {fontFace} from "../src/styles/fonts";
import {globalStyles} from "../src/styles/themes/GlobalStyles";
import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider as StateProvider} from "jotai";
import {ApplyDefaultLayout} from "../src/components/templates/Layout";
import {Router, useRouter} from "next/router";
import {useUserId, useUserInfo} from "../src/businesslogics/_common/auth";
import {resetCookieByLength} from "../src/businesslogics/_common/window/cookie";
import Hackle from "../src/components/templates/Hackle/Hackle";
import {NextPage} from "next";
import {AppProps} from "next/app";
import {Toast} from "src/components/atoms/Toast/Toast";
import {ErrorBoundary} from "react-error-boundary";
import * as Sentry from "@sentry/nextjs";
import {BeusableScript} from "lib/beusable/Script";
import {KakaoScripts} from "lib/kakao/scripts";
import dynamic from "next/dynamic";
import GoogleTagManagerBodyNoscript from "../lib/gtm/noscript";
import GoogleTagManagerHeadScript from "../lib/gtm/script";
import NProgress from "nprogress";
import ModalTemplate from "../src/components/molecules/_common/BottomSheetOrModal";

const CustomError = dynamic(
    () => import("src/components/organisms/customError/CustomError"),
    {ssr: false}
);

Router.events.on("routeChangeStart", () => {
  NProgress.configure({
    template: '<div class="bar" role="bar"><div class="peg"></div></div>',
  });
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

declare global {
  interface Window {
    ChannelIO: (param1: string, param2: object, param3: Function) => void;
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({Component, pageProps}) {
  const [queryClient] = useState(() => new QueryClient());

  /** CustomLayout **/
  const applyLayout = Component.applyLayout || ApplyDefaultLayout;

  /** Hook **/
  useEffect(() => {
    resetCookieByLength("userinfo", 1000);
  }, []);

  return (
      <QueryClientProvider client={queryClient}>
        <StateProvider>
          <ErrorBoundary
              fallback={
                /*@ts-ignore*/
                <CustomError/>
              }
              onError={(error, errorInfo) => {
                Sentry.withScope((scope) => {
                  scope.setTag("error-catch", "error-boundary");
                  const extras: Record<string, any> = {
                    componentStack: errorInfo.componentStack,
                  };
                  scope.setExtras(extras);
                  Sentry.captureException(error);
                });
              }}
          >
            <ThemeProvider theme={theme}>
              <meta charSet="utf-8"/>
              <Head>
                <title>스파르타코딩클럽</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
                />
                <link
                    rel="shortcut icon"
                    href={`${process.env.STATIC_PATH}/css/images/favicon.png`}
                />
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="yeti" content="index, follow"/>
                <meta name="author" content="Sparta Coding Club"/>
                <meta
                    property="og:site_name"
                    content="스파르타코딩클럽"
                    key="site_name"
                />
                <meta property="og:type" content="article" key="type"/>
                <meta property="og:locale" content="ko_KR"/>
                <link rel="preconnect" href={`${process.env.STATIC_PATH}`}/>
                <link
                    rel="preconnect"
                    href="https://t1.daumcdn.net"
                    crossOrigin={"anonymous"}
                />
                <link
                    rel="preconnect"
                    href="https://cdn.channel.io"
                    crossOrigin={"anonymous"}
                />
                <GoogleTagManagerHeadScript/>
                <KakaoScripts/>
                <BeusableScript/>
                {globalStyles}
                <style>{fontFace}</style>
              </Head>
              <GoogleTagManagerBodyNoscript/>
              <div id="modal"/>
              <Toast/>
              <Hackle>{applyLayout(<Component {...pageProps} />)}</Hackle>
            </ThemeProvider>
          </ErrorBoundary>
        </StateProvider>
      </QueryClientProvider>
  );
}

export default MyApp;

import Script from "next/script";
import React from "react";
import { InitKakao } from "./index";

export const KakaoScripts = () => (
  <Script
    src="https://t1.kakaocdn.net/kakao_js_sdk/2.0.1/kakao.min.js"
    integrity="sha384-PFHeU/4gvSH8kpvhrigAPfZGBDPs372JceJq3jAXce11bVA6rMvGWzvP4fMQuBGL"
    crossOrigin="anonymous"
    onLoad={InitKakao}
  />
);

import { useAtom } from "jotai";
import { useState } from "react";
import { showAnimationAtom } from "src/stores/event/hyoneung";

type UseAnimation = [showAnimation: boolean, triggerAnimation: () => void];

export const useAnimation = (
  initialState = false,
  timeout = 2000,
): UseAnimation => {
  const [showAnimation, setShowAnimation] = useState(initialState);

  const triggerAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, timeout);
  };

  return [showAnimation, triggerAnimation];
};

export const useAnimationAtom = (timeout = 2000): UseAnimation => {
  const [showAnimation, setShowAnimation] = useAtom(showAnimationAtom);

  const triggerAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, timeout);
  };

  return [showAnimation, triggerAnimation];
};

type FadeStatus = "fadeIn" | "fadeOut" | "none";

export const useFadeAnimation = (timeout = 500) => {
  const [fadeStatus, setFadeStatus] = useState<FadeStatus>("none");

  const triggerFadeIn = () => {
    setFadeStatus("fadeIn");
    setTimeout(() => {
      setFadeStatus("none");
    }, timeout);
  };

  const triggerFadeOut = () => {
    setFadeStatus("fadeOut");
    setTimeout(() => {
      setFadeStatus("none");
    }, timeout);
  };

  return { fadeStatus, triggerFadeIn, triggerFadeOut };
};

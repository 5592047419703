import React, { useRef } from "react";
import * as S from "./LnbMolecule.style";
import { useLnb } from "../../../../businesslogics/_layout/gnb/v2";
import { MultiPageLnbItem } from "../../../atoms/_layout/lnb/MultiPageLnbItem";
import { useScrollToActiveItem } from "../../../../businesslogics/_common/lnb";
import { showLineBannerAtom } from "src/stores/showLineBannerAtom";
import { useAtom } from "jotai";
import { useIsMobile } from "../../../../businesslogics/_layout/window";
import { useRouter } from "next/router";
import { CodingLandLnb } from "./CodingLand/CodingLandLnb";
import { Sparthon4Lnb } from "./Sparthon4/Sparthon4Lnb";

const LnbOverMultiPages = () => {
  const router = useRouter();
  const lnbModel = useLnb();

  const wrapperRef = useRef(null);
  const [showLineBanner] = useAtom(showLineBannerAtom);
  useScrollToActiveItem(wrapperRef);

  const isMobile = useIsMobile();

  return (
    <>
      {/*코딩의땅 이벤트 종료 후 삭제*/}
      {router.asPath.includes("/codingland") ? (
        <CodingLandLnb />
      ) : router.asPath.includes("/sparthon4") ? (
        <Sparthon4Lnb />
      ) : (
        lnbModel && (
          <S.LnbWrapper
            ref={wrapperRef}
            showLineBanner={showLineBanner}
            isMobile={isMobile}
          >
            <S.LnbDiv>
              <S.LnbUl>
                {lnbModel.map((item) => (
                  <MultiPageLnbItem item={item} key={"lnb_" + item.name} />
                ))}
              </S.LnbUl>
            </S.LnbDiv>
          </S.LnbWrapper>
        )
      )}
    </>
  );
};

export default LnbOverMultiPages;

import React from "react";
import {
  useHasLnb,
  useIsTargetPage,
} from "../../../../businesslogics/_layout/gnb/v2";
import LnbOverMultiPages from "../../../molecules/_layout/lnb/LnbOverMultiPages";

export const Lnb = () => {
  const hasLnb = useHasLnb();
  const isCatalog = useIsTargetPage("/catalog");
  return (
    <>
      {hasLnb && (
        // (isCatalog ? (
        //   // SinglePage Lnb 는 Ref 를 주입해줘야 하는데, model 에서 주입해주면 실시간 ref 가 반영되지 않음
        //   // 그래서 여기서 직접 주입해줌
        //   // <LnbInSinglePage
        //   //   sectionElemList={[BannerRef, catalogTrialRef, AllRef]}
        //   //   sectionNameMap={CatalogLnbModel}
        //   // />
        // ) : (
        <LnbOverMultiPages />
      )}
    </>
  );
};

import React from "react";
import { atom, useAtom } from "jotai";
import * as S from "./BusinessInfo.style";
import { FooterSNS } from "../../../../organisms/_layout/footer/FooterSNS";

interface Props {
  infoDropDownText: string; //기업정보 드랍다운 텍스트
  infoDropDownIconImg: string; //기업정보 드랍다운 토클 아이콘 이미지
  businessInfoItems: Array<any>; //기업정보 배열
  footerSNSItems: Array<any>; //SNS 목록 구성요소
}

const showInfoAtom = atom(false);

export const BusinessInfo = ({
  infoDropDownText,
  infoDropDownIconImg,
  businessInfoItems,
  footerSNSItems,
}: Props) => {
  const [showInfo, setShowInfo] = useAtom(showInfoAtom);

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <S.BusinessInfo>
      <S.InfoDropDown onClick={toggleShowInfo}>
        {infoDropDownText}&nbsp;
        <S.InfoDropDownIcon
          isActive={showInfo}
          src={infoDropDownIconImg}
          alt={infoDropDownText}
        />
      </S.InfoDropDown>
      <S.InfoDetail show={showInfo}>
        {businessInfoItems.map((item, i) => {
          if (item.href) {
            return (
              <S.InfoDetailLine key={item.value}>
                <S.InfoDetailLink target="_blank" href={item.href}>
                  {item.value}
                </S.InfoDetailLink>
              </S.InfoDetailLine>
            );
          } else {
            return (
              <React.Fragment key={item.value}>
                <S.InfoDetailLine>
                  <S.InfoDetailLineTitle>{item.title}</S.InfoDetailLineTitle>
                  <S.InfoDetailLineValue>{item.value}</S.InfoDetailLineValue>
                  {i < businessInfoItems.length - 1 && (
                    <S.Divider> | </S.Divider>
                  )}
                </S.InfoDetailLine>
              </React.Fragment>
            );
          }
        })}
      </S.InfoDetail>
    </S.BusinessInfo>
  );
};

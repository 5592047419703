export const size = {
  mobile: 320,
  largeMobile: 576,
  tablet: 768,
  desktop: 1024,
  gnbDesktop: 1096,
  largeWeb: 1200,
};

export const device = {
  mobile: `(min-width: ${size.mobile}px)`,
  tabletAndDesktop: `(min-width: ${size.tablet}px)`,
  largeMobile: `(min-width: ${size.largeMobile}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  gnbDesktop: `(min-width: ${size.gnbDesktop}px)`,
  largeWeb: `(min-width: ${size.largeWeb}px)`,
  lessThanLargeMobile: `(max-width: ${size.largeMobile - 1}px)`,
  lessThanTablet: `(max-width: ${size.tablet - 1}px)`,
  lessThanDesktop: `(max-width: ${size.desktop - 1}px)`,
};

export const deviceCorrect = {
  mobile: `(max-width: ${size.tablet}px)`,
  tablet: `(max-width: ${size.desktop}px) and (min-width: ${size.tablet}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
};

export const Device = {
  Mobile: `@media ${device.mobile} `,
  TabletAndDesktop: `@media ${device.tabletAndDesktop}`,
  LargeMobile: `@media ${device.largeMobile} `,
  Tablet: `@media ${device.tablet}`,
  Desktop: `@media ${device.desktop}`,
  LargeWeb: `@media ${device.largeWeb}`,
  LessThanLargeMobile: `@media ${device.lessThanLargeMobile}`,
  LessThanTablet: `@media ${device.lessThanTablet}`,
  LessThanDesktop: `@media ${device.lessThanDesktop}`,
};

/** 디자인 시스템 도입중*/
export const systemicBreakpoints = {
  /** 각 기기의 최소값임*/
  mobile: 320,
  tablet: 820,
  desktop: 1024,
};

export const SystemicDeviceWithoutMediaQuery = {
  Mobile: `(min-width: ${systemicBreakpoints.mobile}px)`,
  Tablet: `(max-width: ${systemicBreakpoints.desktop - 1}px) and (min-width: ${
    systemicBreakpoints.tablet
  }px)`,
  Desktop: `(min-width: ${systemicBreakpoints.desktop}px)`,
  MobileAndTablet: `(max-width: ${systemicBreakpoints.desktop - 1}px)`,
  TabletAndDesktop: `(min-width: ${systemicBreakpoints.tablet}px)`,
};

export const SystemicDevice = {
  Mobile: `@media (min-width: ${systemicBreakpoints.mobile}px)`,
  Tablet: `@media (max-width: ${systemicBreakpoints.desktop}px) and (min-width: ${systemicBreakpoints.tablet}px)`,
  Desktop: `@media (min-width: ${systemicBreakpoints.desktop}px)`,
  MobileAndTablet: `@media (max-width: ${systemicBreakpoints.desktop - 1}px)`,
  TabletAndDesktop: `@media (min-width: ${systemicBreakpoints.tablet}px)`,
};

/** 모바일~태블릿 / 태블릿~데스크탑 둘로 나누는 시스템 **/
export const bisectionBreakpoint = 834;
export const Bisection = {
  Narrow: `@media (max-width: ${bisectionBreakpoint - 1}px)`,
  Wide: `@media (min-width: ${bisectionBreakpoint}px)`,
};

export const systemicBisectionBreakpoint = 820;

export const SystemicBisection = {
  Narrow: `@media (max-width: ${systemicBisectionBreakpoint - 1}px)`,
  Wide: `@media (min-width: ${systemicBisectionBreakpoint}px)`,
};

/** 태블릿 뷰까지 포함한 최신(23.8.23) 디자인 시스템*/
export const breakpoints = {
  /** 각 기기의 최소값임*/
  mobile: 320,
  miniTablet: 768,
  tablet: 820,
  miniDesktop: 1024, //footer v2에서 필요(23.9.7)
  desktop: 1200,
};

export const DisplayResolution = {
  /** 320px ~ 767px */
  Mobile: `@media (min-width: ${breakpoints.mobile}px) and (max-width: ${
    breakpoints.miniTablet - 1
  }px)`,

  /** 768px ~ 819px */
  MiniTablet: `@media (min-width: ${
    breakpoints.miniTablet
  }px) and (max-width: ${breakpoints.tablet - 1}px)`,

  /** 820px ~ 1023px */
  Tablet: `@media (min-width: ${breakpoints.tablet}px) and (max-width: ${
    breakpoints.miniDesktop - 1
  }px)`,

  /** 1024px ~ 1199px */
  MiniDesktop: `@media (min-width: ${
    breakpoints.miniDesktop
  }px) and (max-width: ${breakpoints.desktop - 1}px)`,

  /** 1200px ~ */
  Desktop: `@media (min-width: ${breakpoints.desktop}px)`,

  /** 두개 이상의 단위를 포괄하는 경우
   * 320px ~ 819px
   */
  MobileAndMiniTablet: `@media(min-width: ${
    breakpoints.mobile
  }px) and (max-width: ${breakpoints.tablet - 1}px)`,

  /**1024px ~ */
  MiniDesktopAndDesktop: `@media(min-width: ${breakpoints.miniDesktop}px)`,

  /** desktop 디자인은 tablet 디자인에서 좌우 여백만 추가됨 820px ~ */
  TabletAndDesktop: `@media (min-width: ${breakpoints.tablet}px)`,
};

const homeV3Breakpoints = {
  smallMobile: 320,
  mediumMobile: 400,
  largeMobile: 529,
  tablet: 820,
};
export const HomeV3 = {
  SmallMobile: `@media (min-width: ${
    homeV3Breakpoints.smallMobile
  }px) and (max-width: ${homeV3Breakpoints.mediumMobile - 1}px)`,
  MediumMobile: `@media (min-width: ${
    homeV3Breakpoints.mediumMobile
  }px) and (max-width: ${homeV3Breakpoints.largeMobile - 1}px)`,
  SmallAndMediumMobile: `@media (max-width: ${
    homeV3Breakpoints.largeMobile - 1
  }px)`,
  LargeMobile: `@media (min-width: ${
    homeV3Breakpoints.largeMobile
  }px) and (max-width: ${homeV3Breakpoints.tablet - 1}px)`,
  MediumAndLargeMobile: `@media (min-width: ${
    homeV3Breakpoints.mediumMobile
  }px) and (max-width: ${homeV3Breakpoints.tablet - 1}px)`,
  Tablet: `@media (min-width: ${homeV3Breakpoints.tablet}px)`,
  LargeMobileAndTablet: `@media (min-width: ${homeV3Breakpoints.largeMobile}px)`,
};

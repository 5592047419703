import styled from "@emotion/styled";
import { fontPretendard } from "../../../../../styles/fonts";
import themes from "../../../../../styles/themes";
import { Device, device } from "../../../../../styles/themes/device";
import {
  neutralDay,
  mBody2,
  wBody1,
  wBody2,
  wBody3,
  wBody4,
} from "@teamsparta/design-system";

export const NewBenefitDiv = styled.div<{ url?: string }>`
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#F1FEFF";
      case "chang":
        return "#F3FBFF";
      case "hr":
        return "#CFEAFF";
      case "bucketlist":
        return "#FFF8F6";
      case "photos":
        return "#31544B";
      case "chatgpt":
      case "wf_free":
      case "ai-virtual":
        return "#141617";
      default:
        return "#e8f5ff";
    }
  }};
  @media ${device.desktop} {
    min-height: 437px;
    padding-bottom: 0;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    ${({ url }) => url === "chang" && 'background-color: "#F3FBFF"'};
  }
`;

export const NewBenefitDivRight = styled.div`
  margin-top: 32px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.desktop} {
    margin-top: 0;
    margin-left: 37px;
    width: fit-content;
    /* min-width: 50%; */
    align-items: flex-start;
  }
`;
export const NewBenefitDivThumb = styled.img<{ url }>`
  aspect-ratio: 375/281;
  width: 100%;
  @media ${device.desktop} {
    aspect-ratio: auto;
    width: auto;
    height: 100%;
    max-width: 700px;
    max-height: 437px;
    object-fit: contain;
  }
`;

export const NewBenefitUpTitle = styled.div<{ url }>`
  font-family: "Pretendard", serif;
  font-weight: bold;
  font-size: 16px;
  color: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#0092A6";
      case "bucketlist":
        return "#F63E16";
      case "photos":
        return "#AFD3BD";
      case "chatgpt":
        return "#E0D2FF";
      case "wf_free":
        return "#FF7E55";
      case "ai-virtual":
        return "#5265FF";
      default:
        return "#0882db";
    }
  }};
  margin-bottom: 12px;
  @media ${device.desktop} {
    text-align: left;
  }
`;

export const NewBenefitDownTitle = styled.div<{ url }>`
  font-family: "Pretendard", serif;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: ${({ url }) => {
    switch (url) {
      case "photos":
      case "wf_free":
      case "chatgpt":
      case "ai-virtual":
        return "#ffffff";
      default:
        return "#000000";
    }
  }};
  margin-bottom: 36px;
  @media ${device.desktop} {
    text-align: left;
  }

  b {
    padding-bottom: 5px;
    background: linear-gradient(
      to top,
      ${({ url }) => {
          switch (url) {
            case "momentum":
              return "#BFF4F8";
            case "bucketlist":
              return "#FFC9BD";
            default:
              return "#cfeaff";
          }
        }}
        55%,
      transparent 45%
    );
  }
`;

export const NewBenefitUpDesc = styled.div<{ url }>`
  font-family: ${fontPretendard};
  font-weight: 500;
  line-height: 1.5;
  font-size: 16px;

  color: ${({ url }) => {
    switch (url) {
      case "photos":
        return "#ffffff";
      case "wf_free":
      case "chatgpt":
        return "#ffffff";
      case "ai-virtual":
        return "#C1FFF8";
      default:
        return "#000000";
    }
  }};

  @media ${device.desktop} {
    text-align: left;
  }
`;
export const NewBenefitDownDesc = styled.div<{ url }>`
  margin-top: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: ${({ url }) => {
    switch (url) {
      case "photos":
      case "wf_free":
      case "chatgpt":
      case "ai-virtual":
        return "#ffffff";
      default:
        return themes.colors.gray600;
    }
  }};
  font-family: ${fontPretendard};

  b {
    color: #0882db;
  }

  @media ${device.desktop} {
    text-align: left;
  }
`;

export const NewBenefitCaution = styled.p<{ url }>`
  color: ${themes.colors.gray450};
  font-weight: 500;
  margin-top: 32px;
  line-height: 1.5;
  font-size: 14px;

  font-family: ${fontPretendard};

  color: ${({ url }) => {
    switch (url) {
      case "photos":
      case "wf_free":
      case "ai-virtual":
        return "#C7D2D8";
      default:
        return "#6b6e72";
    }
  }};
  white-space: pre-line;

  @media ${device.desktop} {
    margin-top: 36px;
    white-space: normal;
  }
`;

export const BenefitCardPoint = styled.div<{ url; color?: string }>`
  height: 23px;
  border-radius: 56px;
  background-color: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#0092A6";
      case "teenager":
        return "#FF6635";
      case "bucketlist":
        return "#F63E16";
      case "chatgpt":
        return "#7543E0";
      case "photos":
        return "#1F8648";
      case "nbfree":
        return "#30725D";
      case "wf_free":
        return "#FF7E55";
      case "ai-virtual":
        return "#5265FF";
      case "codingtest":
        return "#31B32E";
      case "androidvsios":
        return "#8B1900";
      default:
        return "#0882db";
    }
  }};
  padding: 3px 8px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 10px;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1;
  font-family: ${fontPretendard};
`;

export const BenefitCardTitle = styled.div`
  font-family: "Pretendard", serif;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #1c1d1e;
  margin-bottom: 4px;
`;

export const NewAdditionalDiv = styled.div<{ url }>`
  padding: 80px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#BFF4F8";
      case "hr":
        return "#F3FBFF";
      case "teenager":
        return "#FFEFE9";
      case "bucketlist":
        return "#FFE9E4";
      case "photos":
        return "#DFEDE4";
      case "chatgpt":
        return "#EFE8FF";
      case "nbfree":
        return "#D4EBE4";
      case "wf_free":
        return "#FFECE7";
      case "ai-virtual":
        return "#CDD8F8";
      case "codingtest":
        return "#C8F1C8";
      case "androidvsios":
        return "#FFDFDF";
      default:
        return "#cfeaff";
    }
  }};
  @media ${device.lessThanDesktop} {
    padding: 48px 16px;
  }
`;

export const NewAdditionalTitle = styled.div<{ url }>`
  font-size: 16px;
  font-family: "Pretendard", serif;
  font-weight: bold;
  align-items: center;
  color: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#0092A6";
      case "bucketlist":
        return "#F63E16";
      case "photos":
        return "#1F8648";
      case "chatgpt":
        return "#7543E0";
      case "nbfree":
        return "#30725D";
      case "wf_free":
        return "#FF7E55";
      case "ai-virtual":
        return "#5265FF";
      case "codingtest":
        return "#31B32E";
      case "androidvsios":
        return "#8B1900";
      default:
        return "#0882db";
    }
  }};
  margin-bottom: 12px;
  @media ${device.lessThanDesktop} {
    margin-bottom: 8px;
  }
`;

export const NewAdditionalSubTitle = styled.div`
  font-size: 24px;
  font-family: "Pretendard", serif;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
  white-space: pre-wrap;
  @media ${device.lessThanDesktop} {
    font-size: 22px;
  }
`;

export const NewAdditionalSubCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 32px;
`;

export const NewAdditionalSubCopy = styled.p`
  margin-bottom: 32px;
  font-family: ${fontPretendard};
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  color: ${themes.colors.gray600};
  white-space: pre-line;

  b {
    font-weight: 700;
  }
`;

export const NewAdditionalSubCopy2 = styled(NewAdditionalSubCopy)`
  margin-bottom: 0;
`;

export const NewAdditionalSubCaption = styled.p`
  ${wBody4};
  color: ${neutralDay.gray80};
  line-height: 160%;
`;

export const NewAdditionalCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media ${device.lessThanDesktop} {
    flex-direction: column;
    max-width: 328px;
  }
`;

export const NewAdditionalCardCaption = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  color: ${neutralDay.gray70};
  margin-top: 36px;
  text-align: center;
`;

export const NewAdditionalCard = styled.div<{ url }>`
  border-radius: 8px;
  background-color: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#F1FEFF";
      case "teenager":
        return "#FFE2D8";
      case "bucketlist":
        return "#FFF8F7";
      case "photos":
        return "#F7FAF7";
      case "chatgpt":
        return "#F8F4FF";
      case "nbfree":
        return "#F7FAF7";
      case "wf_free":
        return "#FFF7F4";
      case "ai-virtual":
        return "#EAEFFF";
      case "codingtest":
        return "#E7F9E7";
      case "androidvsios":
        return "#FFF2F2";
      default:
        return "#e8f5ff";
    }
  }};
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 400px;
  @media ${device.lessThanDesktop} {
    width: 328px;
  }
`;

export const NewAdditionalCard2 = styled.div`
  border-radius: 8px;
  background-color: #e8f5ff;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 400px;
  @media ${device.lessThanDesktop} {
    width: 328px;
  }
`;

export const NewAdditionalCardSubTitle = styled.div`
  font-size: 18px;
  font-family: "Pretendard", serif;
  font-weight: bold;
  text-align: center;
  color: #1c1d1e;
  margin-bottom: 10px;
  white-space: pre-line;
  @media ${device.lessThanDesktop} {
    font-size: 16px;
  }
`;

export const NewAdditionalCardImg = styled.img`
  width: auto;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
`;

export const MonthlyPaidCardImg = styled.img`
  height: 224px;

  ${Device.Desktop} {
    width: auto;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
  }
`;

export const NewEventBenefitDiv = styled.section<{ isDesktop: boolean; url? }>`
  padding: 48px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-color: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#00B9C5";
      case "bucketlist":
        return "#FC6645";
      default:
        return "#0882db";
    }
  }};
  background-image: ${({ isDesktop, url }) => {
    switch (url) {
      case "momentum":
        return `url(${
          process.env.CDN_BASE_URL
        }/TeamSparta-Inc/scc-frontend/assets/images/special_curri_detail/bg-event-${
          isDesktop ? "web" : "mo"
        }-momentum.svg)`;

      case "bucketlist":
        return `url(${
          process.env.CDN_BASE_URL
        }/TeamSparta-Inc/scc-frontend/assets/images/special_curri_detail/bg-event-${
          isDesktop ? "web" : "mo"
        }-bucklist.svg)`;

      default:
        return `url(${
          process.env.CDN_BASE_URL
        }/TeamSparta-Inc/scc-frontend/assets/images/special_curri_detail/bg-event-${
          isDesktop ? "web" : "mo"
        }.svg)`;
    }
  }};

  @media ${device.desktop} {
    padding: 80px 0;
  }
`;
export const NewEventBenefitTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
export const NewEventBenefitTitleContainer = styled.div`
  margin-left: 10px;
`;
export const NewEventBenefitTitleThumb = styled.img`
  height: 92px;
  @media ${device.desktop} {
    height: 120px;
  }
`;
export const NewEventBenefitTitleLabel = styled.label`
  font-family: ${fontPretendard};
  color: #f1feff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
`;
export const NewEventBenefitTitle = styled.p`
  color: #fff;
  margin-top: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  font-family: ${fontPretendard};
  white-space: nowrap;
  @media ${device.desktop} {
    font-size: 28px;
    margin-top: 12px;
  }
`;
export const NewEventBenefitDetailDiv = styled.div<{ url }>`
  background: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#00AAB5";
      case "bucketlist":
        return "#CB2F0D";
      default:
        return "#0271c0";
    }
  }};
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  max-width: 580px;
`;
export const NewEventBenefitDetailMethod = styled.div<{ url }>`
  color: white;
  background: ${({ url }) => (url === "momentum" ? "#0092A6" : "#0882db")};
  background: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#0092A6";
      case "bucketlist":
        return "#F63E16";
      default:
        return "#0882db";
    }
  }};
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 100px;
  font-size: 16px;
  line-height: 1.4;
  font-family: ${fontPretendard};
`;
export const NewEventBenefitDetail1 = styled.div`
  background: #fff;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  font-family: ${fontPretendard};
  @media ${device.desktop} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
export const NewEventBenefitDetail2 = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  font-family: ${fontPretendard};
  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;
export const NewEventBenefitDetailThumb = styled.div<{ url }>`
  width: 24px;
  height: 24px;
  background: ${({ url }) => {
    switch (url) {
      case "momentum":
        return "#00b9c5";
      case "bucketlist":
        return "#FC6645";
      default:
        return "#0882DB";
    }
  }};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GmarketSans;
  font-weight: 600;
  font-size: 12.4915px;
  line-height: 1;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

export const NewEventBenefitDetailParagraph = styled.p<{ url }>`
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  font-weight: 500;
  font-family: ${fontPretendard};

  b {
    color: ${({ url }) => {
      switch (url) {
        case "momentum":
          return "#0092A6";

        case "bucketlist":
          return "#F63E16";

        default:
          return "#0882db";
      }
    }};
    font-weight: 500;
  }

  span {
    display: block;
    color: #81898f;
  }
`;
export const NewEventBenefitCautionDiv = styled.div`
  width: 100%;
  margin: 20px auto 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: ${themes.colors.white};
  font-family: ${fontPretendard};
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${device.desktop} {
    gap: 0;
    width: fit-content;
    margin-top: 40px;
    color: ${themes.colors.white};
    font-family: ${fontPretendard};
  }
`;

export const MobileOnlyBr = styled.br`
  display: block;
  @media ${device.desktop} {
    display: none;
  }
`;

export const WebOnlyBr = styled.br`
  display: none;
  @media ${device.desktop} {
    display: block;
  }
`;

export const SubTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`;

export const NewBenefitCustom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  @media ${device.desktop} {
    text-align: left;
    margin-top: 14px;
  }
`;

export const CustomTitleText = styled.p`
  ${wBody3};
  color: ${neutralDay.white}!important;

  @media ${device.desktop} {
    ${wBody1};
  }
`;

export const CustomDesc = styled.p`
  ${mBody2};
  color: ${neutralDay.gray10}!important;

  @media ${device.desktop} {
    ${wBody2};
  }
`;

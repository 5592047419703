export const NbCategory = {
  전체: "전체",
  BEST: "신청 BEST",
  AI_GPT: "AI ∙ GPT",
  DEV_BEGINNER: "개발 입문",
  DEV_APPLICATION: "개발 응용",
  DATA: "데이터",
  WORK_AUTOMATION: "업무 자동화",
} as const;

export const KDC_MINOR_CATEGORY = {
  BEST: "신청 BEST",
  AI_GPT: "AI ∙ GPT",
  DEV: "개발",
  DATA_AUTOMATION: "데이터 ∙ 자동화",
  DEV_WORK: "개발 실무",
} as const;

export const KDC_MAJOR_CATEGORY = {
  왕초보: "왕초보",
  스킬업: "스킬업",
} as const;

export const KDC_MAJOR_CATEGORY_MAP = {
  [KDC_MAJOR_CATEGORY.왕초보]: [
    KDC_MINOR_CATEGORY.BEST,
    KDC_MINOR_CATEGORY.AI_GPT,
    KDC_MINOR_CATEGORY.DEV,
    KDC_MINOR_CATEGORY.DATA_AUTOMATION,
  ],
  [KDC_MAJOR_CATEGORY.스킬업]: [
    KDC_MINOR_CATEGORY.DEV,
    KDC_MINOR_CATEGORY.DEV_WORK,
  ],
} as const;

export type NbCategoryType = (typeof NbCategory)[keyof typeof NbCategory];

export type KdcCategoryType =
  (typeof KDC_MINOR_CATEGORY)[keyof typeof KDC_MINOR_CATEGORY];

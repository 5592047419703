import { MutableRefObject } from "react";

export type LnbModel = {
  name: string;
  ref?: MutableRefObject<any>;
  href?: string;
  isAbsoluteMatch?: boolean;
  sectionId?: string;
  key?: string;
}[];

export const NbLnbModel: LnbModel = [
  { name: "온라인 강의", href: "/nb" },
  {
    name: "개발자 부트캠프 [모집 중]",
    href: "https://nbcamp.spartacodingclub.kr/",
  },
];

export const CodingLandLnBModel: LnbModel = [
  {
    name: "캠페인",
    sectionId: "CodingLandTopSection",
  },
  {
    name: "이벤트",
    sectionId: "CodingLandEventSection",
    // sectionId: "CodingLandCodingPeopleNudgeSection",
  },
  {
    name: "강의 추천",
    sectionId: "CodingLandRecommendCourseSection",
  },
];

export const Sparthon4LnBModel: LnbModel = [
  {
    name: "행사 안내",
    sectionId: "Sparthon4Summary",
    key: "sparthon4",
  },
  {
    name: "세션 소개",
    sectionId: "Sparthon4Sessions",
    key: "sparthon4",
  },
  {
    name: "혜택",
    sectionId: "Sparthon4Benefits",
    key: "sparthon4",
  },
];

export const BlogLnbModel: LnbModel = [
  { name: "홈", href: "/blog", isAbsoluteMatch: true },
  { name: "스파르타 소식", href: "/blog/category/sparta" },
  { name: "코딩 가이드", href: "/blog/category/coding-guide" },
  { name: "아티클", href: "/blog/category/article" },
  { name: "큰일 라이브러리", href: "/blog/category/impact-library" },
];

export const LnbPages = {
  // catalog: CatalogLnbModel, 재사용 가능성이 있어 남겨둠
  nb: NbLnbModel,
  codingland: CodingLandLnBModel,
  sparthon4: Sparthon4LnBModel,
  // community: CommunityLnbModel, 커뮤니티 개편으로 일단 제거
  blog: BlogLnbModel,
};

export const SpoqaHanSansNeo = `
    @font-face {
    font-display: swap;
       font-family: 'SpoqaHanSansNeo';
       font-style: normal;
       font-weight: 400;
       src: url('https://static.spartacodingclub.kr/static/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff') format('woff'),
      url('https://static.spartacodingclub.kr/static/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.otf') format('otf')
     }
`;

export const Pretendard = `
@font-face {
  font-display: swap;
  font-family: 'Pretendard';
  font-weight: 100;
  font-style: normal;
  src: local(Pretendard Thin), url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Thin.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Thin.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Thin.otf');
}

@font-face {
  font-display: swap;
  font-family: 'Pretendard';
  font-weight: 200;
  font-style: normal;
  src: local(Pretendard ExtraLight), url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-ExtraLight.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-ExtraLight.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-ExtraLight.otf');
}

@font-face {
  font-display: swap;
  font-family: 'Pretendard';
  font-weight: 300;
  font-style: normal;
  src: local(Pretendard Light), url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Light.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Light.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Light.otf');
}

@font-face {
font-display: swap;
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src: local(Pretendard Regular), url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Regular.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Regular.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Regular.otf');
}

@font-face {
font-display: swap;
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: local(Pretendard Medium), url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Medium.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Medium.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Medium.otf');
}

@font-face {
font-display: swap;
  font-family: 'Pretendard';
  font-weight: 600;
  font-style: normal;
  src: local(Pretendard SemiBold), url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-SemiBold.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-SemiBold.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-SemiBold.otf');
}

@font-face {
font-display: swap;
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: local(Pretendard Bold), url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Bold.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Bold.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Bold.otf');
}

@font-face {
font-display: swap;
  font-family: 'Pretendard';
  font-weight: 800;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-ExtraBold.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-ExtraBold.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-ExtraBold.otf');
}

@font-face {
font-display: swap;
  font-family: 'Pretendard';
  font-weight: 900;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Black.subset.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Black.subset.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Black.otf');
}

`;

export const GmarketSans = `
@font-face {
font-display: swap;
  font-family: 'GmarketSans';
  font-weight: 300;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/GmarketSans/GmarketSansLight.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/GmarketSans/GmarketSansLight.woff') format("woff");
}

@font-face {
font-display: swap;
  font-family: 'GmarketSans';
  font-weight: 400;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/GmarketSans/GmarketSansMedium.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/GmarketSans/GmarketSansMedium.woff') format("woff");
}

@font-face {
font-display: swap;
  font-family: 'GmarketSans';
  font-weight: 700;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/GmarketSans/GmarketSansBold.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/GmarketSans/GmarketSansBold.woff') format("woff");
}

`;

export const SpoqaHanSans = `
@font-face {
font-display: swap;
  font-family: 'SpoqaHanSans';
  font-weight: 300;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/SpoqaHanSans/SpoqaHanSans-Light.woff') format('woff');
}

@font-face {
font-display: swap;
  font-family: 'SpoqaHanSans';
  font-weight: 400;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/SpoqaHanSans/SpoqaHanSans-Regular.woff') format('woff');
}

@font-face {
font-display: swap;
  font-family: 'SpoqaHanSans';
  font-weight: 700;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/SpoqaHanSans/SpoqaHanSans-Bold.woff') format('woff');
}
`;

export const Cafe24Ohsquare = `
@font-face {
font-display: swap;
  font-family: 'Cafe24Ohsquare';
  font-weight: 500;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/Cafe24Ohsquare/Cafe24Ohsquare.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Cafe24Ohsquare/Cafe24Ohsquare.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/Cafe24Ohsquare/Cafe24Ohsquare.ttf');
}
`;

export const SbAggroBold = `
@font-face {
  font-family: 'SbAggroBold';
  font-weight: 700;
  font-style: normal;
  src: url('https://static.spartacodingclub.kr/static/fonts/SbAggro/SB_aggro_bold.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/SbAggro/SB_aggro_bold.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/SbAggro/SB_aggro_bold.ttf');
}
`;

export const NanumHandWritingDaughter = `
@font-face {
  font-family: 'NanumHandWritingDaughter';
  font-style: normal;
  font-weight: 400;
  src: url('https://static.spartacodingclub.kr/static/fonts/NanumHandWritingDaughter.subset.woff2') format("woff2");
}
`;

export const ThefaceshopInklipquid = `
@font-face {
  font-family: 'ThefaceshopInklipquid';
  font-style: normal;
  font-weight: 400;
  src: url('https://static.spartacodingclub.kr/static/fonts/ThefaceshopInklipquid/ThefaceshopInklipquid.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/ThefaceshopInklipquid/ThefaceshopInklipquid.ttf');
}
`;

export const DungGeunMo = `
@font-face {
  font-family: 'DungGeunMo';
  font-style: normal;
  font-weight: 400;
  src: url('https://static.spartacodingclub.kr/static/fonts/DungGeunMo/DungGeunMo.woff') format("woff"),
  url('https://static.spartacodingclub.kr/static/fonts/DungGeunMo/DungGeunMo.ttf');
}
`;

export const TmoneyRoundWind = `
@font-face {
  font-family: 'Tmoney RoundWind';
  font-style: normal;
  font-weight: 400;
  src: url('https://static.spartacodingclub.kr/static/fonts/TmoneyRoundWindExtraBold.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/TmoneyRoundWindExtraBold.ttf');
}
`;

export const Cafe24Surround = `
@font-face {
  font-family: 'Cafe24Surround';
  font-style: normal;
  font-weight: 400;
  src: url('https://static.spartacodingclub.kr/static/fonts/Cafe24Ssurround-v2.0.woff2') format("woff2"),
  url('https://static.spartacodingclub.kr/static/fonts/Cafe24Ssurround-v2.0.woff') 
}
`;

export const YoonDokrip = `
@font-face {
  font-family: 'YoonDokrip';
  font-style: normal;
  font-weight: 500;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Dokrip.woff') format('woff');
  url('https://static.spartacodingclub.kr/static/fonts/YoonDokrip.woff') format('woff'), 
  url('https://static.spartacodingclub.kr/static/fonts/YoonDokrip.otf') format('otf');
}
`;

export const EBSHunminjeongeumSBA = `
@font-face {
  font-family: 'EBSHunminjeongeumSBA';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/EBSHunminjeongeum.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
`;

export const Dokrip = `
@font-face {
    font-family: 'Dokrip';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Dokrip.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
`;

export const DsDigital = `
@font-face {
    font-family: 'DsDigital';
    src: url('/fonts/DsDigital/DS-DIGIB.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}
`;

import styled from "@emotion/styled";
import { zIndexGnb } from "src/styles/variables";
import {
  mBody2,
  mTitle2,
  neutralDay,
  scc,
  wBody1,
  wBody4,
} from "@teamsparta/design-system";
import {
  NbGuideDesktopGnbHeight,
  NbGuideMobileGnbHeight,
} from "src/components/templates/nbGuide/Layout/nbGuideLayout.style";

export const nbGuideGnbTablet = `@media (min-width: ${900}px)`;

export const zIndexOverlay = zIndexGnb - 2;
const zIndexOverlayMobile = zIndexGnb + 1;

const zIndexSnb = zIndexGnb - 1;
const zIndexSnbMobile = zIndexGnb + 2;
const zIndexDivider = zIndexGnb - 2;
const dividerHeight = 1;
const gnbTransition = "all 0.2s ease-in-out";

const logoImageWidth = 100;

export const GnbContainer = styled.div`
  padding: 0 12px;
  z-index: ${zIndexGnb};
  background-color: ${neutralDay.white};
  width: 100%;

  height: ${NbGuideMobileGnbHeight}px;
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  ${nbGuideGnbTablet} {
    height: ${NbGuideDesktopGnbHeight}px;
    display: flex;
    padding: 0px 12px;
    align-items: center;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
`;

export const LogoGuideText = styled.div`
  ${wBody1}
`;

export const LogoImage = styled.img`
  width: ${logoImageWidth}px;
`;

export const Padding = styled.div`
  width: 100%;
  height: 40px;
`;

export const LeftPadding = styled.div`
  width: ${logoImageWidth}px;
  height: 40px;
`;

export const Anchor = styled.div<{ isSub; isActive; isAlive }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  transition: ${gnbTransition};

  ${wBody1};
  ${({ isSub }) => isSub && wBody4};

  :hover {
    ${({ isSub }) => isSub && `background-color: ${neutralDay.gray5};`}
  }

  ${({ isSub, isActive }) =>
    !isSub && isActive ? `color: ${scc.red100};` : ``}

  ${({ isAlive }) => (isAlive ? `cursor: pointer;` : `cursor: default;`)}
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: row;
`;

export const Divider = styled.div`
  position: sticky;
  top: ${NbGuideMobileGnbHeight}px;
  z-index: ${zIndexDivider};
  border-bottom: ${`${dividerHeight}px solid ${neutralDay.gray30}`};

  ${nbGuideGnbTablet} {
    top: ${NbGuideDesktopGnbHeight}px;
  }
`;

export const SnbContainer = styled.div<{ isVisible }>`
  position: fixed;
  top: calc(${NbGuideDesktopGnbHeight}px - ${dividerHeight}px);
  z-index: ${zIndexSnb};
  background-color: ${neutralDay.white};

  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;

  justify-content: center;
  align-items: center;
  width: 100%;

  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0)" : "translateY(-100%)"};

  transition: ${gnbTransition};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Overlay = styled.div<{ isVisible }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(20, 22, 23, 0.4);

  z-index: ${zIndexOverlay};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  transition: ${gnbTransition};
`;

/*
 * only for mobile
 */
export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;

  :hover {
    cursor: pointer;
  }
`;

export const SnbMobileContainer = styled.div<{ isVisible }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100lvh;
  width: 300px;
  background-color: ${neutralDay.white};
  display: flex;
  flex-direction: column;

  z-index: ${zIndexSnbMobile};
  transform: ${({ isVisible }) =>
    isVisible ? "translateX(0)" : "translateX(100%)"};
  box-shadow: ${({ isVisible }) =>
    isVisible ? "0px 20px 24px rgba(20, 22, 23, 0.24)" : "none"};
  transition: ${gnbTransition};
`;

export const OverlayMobile = styled(Overlay)`
  top: 0;
  z-index: ${zIndexOverlayMobile};
`;

export const SnbMobileHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: ${NbGuideMobileGnbHeight + 1}px; // 우피 디자인과 동일하게 하기 위함
  padding: 0 12px;
`;

export const InnerContainerMobile = styled(InnerContainer)`
  flex-direction: column;
  gap: 32px;
  padding: 28px 0px;
`;

export const Row = styled.div`
  width: 100%;
`;

export const RowHeader = styled.div``;
export const RowBody = styled.div`
  margin-left: 16px;
  border-left: 2px solid ${neutralDay.gray100};
`;

export const AnchorMobile = styled.div<{ isAlive; isSub; isCurrentUrl }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  ${({ isSub }) => (isSub ? mBody2 : mTitle2)};
  font-weight: 700;

  ${({ isSub }) =>
    isSub &&
    `
      height: 40px;
      padding: 9px 12px;
      font-weight: 500;
      `}

  :active, :hover {
    ${({ isAlive }) =>
      isAlive &&
      `
        color: ${scc.red100};
        background-color: ${neutralDay.gray5};
        cursor: pointer;
      `}
  }
  ${({ isCurrentUrl }) => isCurrentUrl && `color: ${scc.red100};`}

  & svg {
    position: absolute;
    right: 20px;
  }
`;

export const GnbItem = styled.div<{ isCurrentUrl }>`
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${wBody1};

  ${({ isCurrentUrl }) => isCurrentUrl && `color: ${scc.red100};`}
  cursor: pointer;
`;

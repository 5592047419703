import { ImageWrapperWithFallback } from "src/components/atoms/_common/ImageWrapperWithFallback/ImageWrapperWithFallback";
import * as S from "./FooterV2.style";
import { FooterIconSVG } from "./FooterV2.style.svg";
import React from "react";

export const FooterSNS = ({ footerSNSItems, isMobile, onClick }) => {
  return (
    <S.FooterSNSWrapper isMobile={isMobile}>
      <S.FooterSNS>
        {footerSNSItems.map((item) => (
          <S.FooterSNSItem
            key={item.text}
            href={item.href}
            target="_blank"
            onClick={() => onClick(item.text)}
          >
            <FooterIconSVG item={item.img} />
          </S.FooterSNSItem>
        ))}
      </S.FooterSNS>
      <S.SignatureContainer>
        <ImageWrapperWithFallback>
          <S.FooterSignature
            src="/assets/images/footer_signature.png"
            loading={"lazy"}
          />
        </ImageWrapperWithFallback>
        <S.SignatureText>
          2022-23 올해의 브랜드 대상
          <br />
          코딩교육 부문 2년 연속 1위
        </S.SignatureText>
      </S.SignatureContainer>
    </S.FooterSNSWrapper>
  );
};

import { useCallback, useEffect, useRef } from "react";
export * from "./hooks";

export const useInterval = (callback, delay) => {
  const call = useCallback(callback, [callback]);
  useEffect(() => {
    if (delay) {
      let id = setInterval(call, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [call, delay]);
};

export const genYoutubeIframeUrl = (url) => `${url}&controls=0`;

export const round = (value: number, precision: number): number => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const animate = (
  targetValue: number,
  currentValue: number,
  setCurrentValue: Function,
  animSpeed: number,
  decimal: number,
) => {
  const valueDiff = (targetValue - currentValue) / animSpeed;
  if (currentValue * 1.05 < targetValue) {
    setCurrentValue(round(currentValue + valueDiff, decimal));
  }
};

export const getReviewNameText = (name) => `${name}님`;

export const getLikeCTAText = (hasLiked) =>
  hasLiked ? "도움이 됐어요" : "도움이 돼요";

export const getTextReviewCourseURL = (courseKey) => {
  return `/online/${courseKey}`;
};

export const getReviewCurriCTAText = (title) => `${title} 보러가기 →`;

export const getFormattedWrittenDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
};

export const getTagText = (rawText) => `#${rawText}`;

export const textReviewFilterFn = (reviewItem, filter) => {
  const categoriesNotSetDefault = Object.keys(filter).filter(
    (key) => !filter[key].includes("default"),
  );
  let isAllTrue = true;
  categoriesNotSetDefault.forEach((category) => {
    if (!isAllTrue) return;

    let filterForCategory = [...filter[category]];
    if (filter[category].includes("50대+")) {
      filterForCategory = [
        ...filterForCategory,
        "50대",
        "60대",
        "70대",
        "80대",
        "90대",
        "100대",
      ];
    }
    if (!filterForCategory.includes(reviewItem[category])) {
      isAllTrue = false;
    }
  });
  return isAllTrue;
};

export interface VideoReview {
  youtubeUrl: string;
  name: string;
  course_tag: string;
  age: string;
  major: string;
  title: string;
  blogUrl: string;
}

export * from "./hooks";

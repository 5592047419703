import { logout } from "../../../../businesslogics/_common/auth";

export type Anchor = {
  key: string;
  name: string;
  href?: string;
  onClick?: Function;
  className?: string;
  isLoginNeededToNavigate?: boolean;
  brand?: string;
  subTitle?: string;
  tag?: string;
  mobileText?: string;
  isSub?: boolean;
};

export const sccAnchor: Anchor = {
  key: "scc",
  name: "스파르타코딩클럽",
  href: "/",
  brand: "온라인",
};

const catalogAnchor: Anchor = {
  key: "catalogv3",
  name: "전체 강의",
  href: "/catalog",
  brand: "온라인",
};

const catalogMobileAnchor: Anchor = {
  key: "catalogv3",
  name: "전체 강의",
  brand: "온라인",
};

const nbAnchor: Anchor = {
  key: "nb",
  name: "내일배움캠프",
  href: "https://nbcamp.spartacodingclub.kr/",
  brand: "내일배움캠프",
  subTitle: "개발자 코스",
  tag: "국비지원",
};

const kdtAnchor = {
  key: "kdt",
  name: "부트캠프",
  href: "https://nbcamp.spartacodingclub.kr/",
  brand: "온라인",
  tag: "국비",
};

const intellipickAnchor: Anchor = {
  key: "intellipick",
  name: "개발자 채용 공고",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
};

const intellipickMobileAnchor: Anchor = {
  key: "intellipick",
  name: "인텔리픽",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
  mobileText: "신입 개발자 채용 공고를 한 곳에서",
};

const ddingdongMobileAnchor: Anchor = {
  key: "ddingdong",
  name: "띵동코딩",
  href: "https://ddingdong.spartacodingclub.kr/",
  brand: "띵동코딩",
  mobileText: "자녀의 미래를 위한 코딩의 시작, 초등 코딩 교육",
};
export const blogAnchor: Anchor = {
  key: "blog",
  name: "블로그",
  href: "/blog",
  brand: "블로그",
};

export const communityAnchor: Anchor = {
  key: "community",
  name: "커뮤니티",
  href: "/community",
  brand: "커뮤니티",
};

export const eventAnchor: Anchor = {
  key: "event",
  name: "이벤트",
  href: "/event",
  brand: "이벤트",
};

export const exhibitionAnchor: Anchor = {
  key: "exhibition",
  name: "수강생 작품",
  href: "/exhibition",
  brand: "수강생 작품",
};

export const faqAnchor: Anchor = {
  key: "faq",
  name: "고객센터",
  href: "https://support.spartacodingclub.kr/",
};

export const BizAnchor: Anchor = {
  key: "bisness",
  name: "기업 서비스",
  onClick: () => {},
  brand: "기업 서비스",
};

export const bizEducationAnchor: Anchor = {
  key: "bizEducation",
  name: "기업 교육",
  href: "https://b2b.spartacodingclub.kr/",
  brand: "기업 교육",
  isSub: true,
};

export const bizOutsourcingAnchor: Anchor = {
  key: "bizOutsourcing",
  name: "외주 개발",
  href: "https://sparta-builders.com/",
  brand: "외주 개발",
  isSub: true,
};

export const bizRecruitAnchor: Anchor = {
  key: "bizRecruit",
  name: "신입 개발자 채용",
  href: "https://intellipick.spartacodingclub.kr/company",
  brand: "신입 개발자 채용",
  isSub: true,
};

export const voucherAnchor: Anchor = {
  key: "voucher",
  name: "수강권",
  href: `${process.env.ONLINE_URL}/mypage/myvoucher`,
  isLoginNeededToNavigate: true,
};

export const registrationStatusAnchor: Anchor = {
  key: "registrationStatus",
  name: "국비 신청 내역",
  href: `/kdc/registration_status`,
  isLoginNeededToNavigate: true,
};

export const classroomAnchor: Anchor = {
  key: "classroom",
  name: "내 강의실",
  href: `${process.env.ONLINE_URL}/classroom`,
};

export const homeworkFeedbackAnchor = {
  key: "homework",
  name: "숙제 피드백",
  href: `${process.env.ONLINE_URL}/homework/feedback`,
};

export const certificateAnchor: Anchor = {
  key: "certificate",
  name: "수강증/수료증",
  href: `${process.env.ONLINE_URL}/mypage/mycertificate`,
};

export const basicInfoAnchor: Anchor = {
  key: "basicInfo",
  name: "계정",
  href: `${process.env.ONLINE_URL}/mypage/mybasicinfo`,
};

export const logoutAnchor: Anchor = {
  key: "logout",
  name: "로그아웃",
  onClick: () => {
    const next = encodeURIComponent(window.location.href);
    window.location.href = `${process.env.ONLINE_URL}/logout?next=${next}`;
  },
};

export const rcmdsfreeAnchor: Anchor = {
  key: "rcmdsfree",
  name: "친구추천",
  href: `${process.env.BASE_URL}/rcmdsfree`,
};

export const catalogAllAnchor: Anchor = {
  key: "catalogAll",
  name: "전체",
  href: `/catalog?selectedKeyword=전체`,
  brand: "전체",
  isSub: true,
};

export const catalogEventAnchor: Anchor = {
  key: "catalogEvent",
  name: "새해에는 코딩",
  href: `/catalog?selectedKeyword=새해에는 코딩`,
  brand: "새해에는 코딩",
  isSub: true,
};

export const catalogKdcAnchor: Anchor = {
  key: "catalogAll",
  name: "국비지원",
  href: `/catalog?selectedKeyword=국비지원`,
  brand: "국비지원",
  isSub: true,
};

export const catalogAiAnchor: Anchor = {
  key: "catalogAi",
  name: "AI",
  href: `/catalog?selectedKeyword=AI`,
  brand: "AI",
  isSub: true,
};

export const catalogAppGameAnchor: Anchor = {
  key: "catalogAppGame",
  name: "앱∙게임 개발",
  href: `/catalog?selectedKeyword=앱∙게임 개발`,
  brand: "앱∙게임 개발",
  isSub: true,
};

export const catalogDataGameAnchor: Anchor = {
  key: "catalogData",
  name: "데이터",
  href: `/catalog?selectedKeyword=데이터`,
  brand: "데이터",
  isSub: true,
};

export const catalogAutomationAnchor: Anchor = {
  key: "catalogAutomation",
  name: "업무 자동화",
  href: `/catalog?selectedKeyword=업무 자동화`,
  brand: "업무 자동화",
  isSub: true,
};

export const catalogNocode: Anchor = {
  key: "catalogNocode",
  name: "노코드",
  href: `/catalog?selectedKeyword=노코드`,
  brand: "노코드",
  isSub: true,
};

export const bizDropdownList = [
  bizEducationAnchor,
  bizOutsourcingAnchor,
  bizRecruitAnchor,
];
export const topAnchors = [];

export const topRightAnchors = [faqAnchor, BizAnchor];

export const mainAnchor = [
  catalogAnchor,
  kdtAnchor,
  communityAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileMainAnchor = [
  catalogMobileAnchor,
  kdtAnchor,
  communityAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileBottomAnchors = [intellipickMobileAnchor];

export const mobileMyPageAnchors = [
  classroomAnchor,
  registrationStatusAnchor,
  voucherAnchor,
  certificateAnchor,
  homeworkFeedbackAnchor,
  basicInfoAnchor,
];

export const catalogDropdownList = [
  catalogAllAnchor,
  catalogEventAnchor,
  catalogKdcAnchor,
  catalogAiAnchor,
  catalogAppGameAnchor,
  catalogDataGameAnchor,
  catalogAutomationAnchor,
  catalogNocode,
];

export const customGnbV3 = {
  nb: {
    pageName: "국비지원",
  },
  event: {
    pageName: "이벤트",
  },
  exhibition: {
    pageName: "수강생 작품",
  },
  community: {
    pageName: "커뮤니티",
  },
  blog: {
    pageName: "블로그",
  },
  catalog: {
    pageName: "전체 강의",
  },
};

import instance from "axios";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { isShowPrizeModalAtom } from "src/stores/event/hyoneung";

const getEventCount = async (userId: string) => {
  try {
    const response = await instance.get(
      `${process.env.API_URL}/lotto/count?user_id=${userId}`,
      // `/api/count?user_id=${userId}`
    );

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const useGetEventCountQuery = (userId = undefined) => {
  const { data, refetch } = useQuery(["eventCount", userId], () =>
    getEventCount(userId),
  );

  const [open_count, setOpenCount] = useState(null);
  const [chicken_count, setChickenCount] = useState(45);

  useEffect(() => {
    if (data) {
      setOpenCount(data.open_count);
      setChickenCount(data.chicken_count);
    }
  }, [data]);

  return { open_count, chicken_count, refetch };
};

const fetchPrize = async (userId, f_uid = undefined) => {
  if (!userId) {
    alert("뚝배기가 끓는 중...");
    window.location.reload();
  }
  const response = await instance.post(
    `${process.env.API_URL}/lotto/draw`,
    // `/api/draw/`,
    {
      user_id: userId,
      f_uid,
    },
  );

  return response.data;
};

export const usePrizeMutation = (userId, f_uid = undefined) => {
  const mutation = useMutation(() => fetchPrize(userId, f_uid));

  return mutation;
};

const fetchMarketingAgreement = async (userId) => {
  const response = await instance.post(
    `${process.env.API_URL}/lotto/marketing-agreement`,
    {
      user_id: userId,
    },
  );

  return response.data;
};

export const useMarketingMutation = (userId) => {
  const mutation = useMutation(() => fetchMarketingAgreement(userId));

  return mutation;
};

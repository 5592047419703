import { Anchor } from "src/components/templates/gnbV3/models/gnbV3.models";
import { useCategoriesV2 } from "src/queries/catalog/hooks";

export const useDropDownList = (): Anchor[] => {
  const allCategoriesV2 = useCategoriesV2();

  return allCategoriesV2.data?.map((category: any) => {
    return {
      key: `catalog${category.title}`,
      name: category.title,
      href: `/catalog?selectedKeyword=${category?.title}`,
      brand: category.title,
      isSub: true,
    };
  });
};

const theme = {
  colors: {
    accent: "#e8344e",
    primary: "#f50000",
    secondary: "#55A6FC",
    danger: "#d32f2f",
    alert: "#ffa000",
    success: "#388e3c",
    white: "#fff",
    black: "#000000",
    kakao: "#ffe812",
    cream: "#F1F3F5",
    red: "#f50000",
    brown: "#563c00",
    grey: "#e4e4e4",
    nbGreen: "#acc975",
    transparent: "transparent",
    neutralDay: [
      "#212121",
      "#414141",
      "#616161",
      "#9e9e9e",
      "#bdbdbd",
      "#e0e0e0",
      "#eeeeee",
      "#ffffff",
    ],
    opacityscale: [0.9, 0.8, 0.7, 0.6, 0.5],

    offblack: "#1d1e1f",
    bgblack: "#1c1d1e",
    btnblack: "#1c1d1e",

    gray0: "#F6F9FA",
    gray1: "#f1f1f1",
    gray2: "#e4e4e4",
    gray3: "#8b8b8b",
    gray4: "#696969",
    gray5: "#6B6e7e",
    gray6: "#f4f5f6",
    gray7: "#505254",
    gray8: "#838689",
    gray9: "#eaebed",
    gray450: "#6B6E72",
    gray600: "#1C1D1E",
    gray40: "#C7D2D8",
    gray50: "#F4F5F6",
    gray70: "#81898F",
  },

  fontStyle: {
    h1: `
          font-size: 9.6rem;
          letter-spacing: -0.15rem;
          font-weight: 300;
      `,
    h2: `
          font-size: 6rem;
          letter-spacing: -0.05rem;
          font-weight: 300;
      `,
    h3: `
          font-size: 4.8rem;
          font-weight: 400;
      `,
    h4: `
          font-size: 3.4rem;
          letter-spacing: -0.25rem;
          font-weight: 400;
      `,
    h5: `
          font-size: 2.4rem;
          font-weight: 400;
      `,
    h6: `
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.015rem;
      `,
    subtitle1: `
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.015rem;
      `,
    subtitle2: `
        font-size: 1.4rem;
        font-weight: 500;
        letter-spacing: 0.01rem;
      `,
    body1: `
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.05rem;
      `,
    body2: `
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.025rem;
      `,
    button: `
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.0125rem;
      `,
    caption: `
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.04rem;
      `,
    overline: `
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.015rem;
      `,
    input: `
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.015rem;
      `,
  },

  maxWidth: {
    mobile: "320px",
    pc: "1230px",
  },
};

export default theme;

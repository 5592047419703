import * as S from "./Sparthon4Lnb.style";
import { MultiPageLnbItem } from "../../../../atoms/_layout/lnb/MultiPageLnbItem";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useLnb } from "../../../../../businesslogics/_layout/gnb/v2";
import { useIsMobile } from "../../../../../businesslogics/_layout/window";

export const Sparthon4Lnb = () => {
  const router = useRouter();
  const lnbModel = useLnb();
  const isMobile = useIsMobile();
  const [activeSectionId, setActiveSectionId] = useState(null);
  const [isMobileScrolled, setIsMobileScrolled] = useState(false);
  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    if (router.asPath.includes("/sparthon4") && lnbModel?.length > 0) {
      setActiveSectionId(lnbModel[0].sectionId); // 첫 번째 섹션의 ID로 초기화
    }
    const handleScroll = () => {
      if (isMobile) {
        if (window.scrollY > 100) {
          setIsMobileScrolled(true); // 상태 변경
        }
      }
      lnbModel?.forEach((item) => {
        const section = document.getElementById(item.sectionId);
        if (section) {
          const bounds = section.getBoundingClientRect();
          // Check if the section is within the viewport
          if (bounds.top <= 200 && bounds.bottom >= 0) {
            setActiveSectionId(item.sectionId);
          }
        }
      });
    };
    const debouncedHandleScroll = debounce(handleScroll, 100);

    document.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      document.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [lnbModel]);

  const scrollToSection = (sectionId) => {
    setActiveSectionId(sectionId); // 클릭된 섹션 ID를 상태로 저장
    const element = document.querySelector(`#${sectionId}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <S.Sparthon4LnbWrapper isMobileScrolled={isMobileScrolled}>
      <S.LnbItemsContainer>
        <S.LnbItemWrapper>
          {lnbModel?.map((item) => (
            <div
              key={item.name}
              onClick={() => scrollToSection(item.sectionId)}
            >
              <MultiPageLnbItem
                isEventLnbActive={activeSectionId === item.sectionId}
                item={item}
                key={"lnb_" + item.name}
              />
            </div>
          ))}
        </S.LnbItemWrapper>
      </S.LnbItemsContainer>
    </S.Sparthon4LnbWrapper>
  );
};

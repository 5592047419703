import { useRouter } from "next/router";
import { useUserId } from "../auth";
import { useExecuteWithLoginV2 } from "../login";
import { useToast } from "../../../components/atoms/Toast/Toast";
import { useIsMobile } from "@teamsparta/auth-frontend";
import { isAndroid } from "../../events/hyoneung/hyoneung";

//TODO: 중복 코드 제거, 리팩토링
export const shareUrl = async (
  shareUrl,
  onSuccess,
  onFail,
  title = "",
  text = ""
) => {
  if (navigator.share) {
    navigator
      .share({
        title: title,
        text: text,
        url: shareUrl,
      })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        console.log("Sharing failed", e);
      });
  } else {
    onFail();
    console.log("Share API not supported.");
  }
};

export const shareWithoutUrl = async (
  onSuccess,
  onFail,
  title = "",
  text = ""
) => {
  if (navigator.share) {
    navigator
      .share({
        title: title,
        text: text,
      })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        console.log("Sharing failed", e);
      });
  } else {
    onFail();
    console.log("Share API not supported.");
  }
};

export const copyToClipboardWithDocument = (shareUrl) => {
  const el = document.createElement("textarea");
  el.value = shareUrl;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const copyToClipboard = async (shareUrl) => {
  try {
    await navigator.clipboard.writeText(encodeURI(shareUrl));
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

//TODO: 토스트 문구 추상화 하기
export const useOnClickEventShare = () => {
  const userId = useUserId();
  const withLogin = useExecuteWithLoginV2();
  const router = useRouter();
  const showToast = useToast();
  const isMobile = useIsMobile();

  const url = `${process.env.BASE_URL}${router.pathname}?f_uid=${userId}`;

  return (logFn) => {
    withLogin(
      () => {
        if (isMobile) {
          shareUrl(
            url,
            () => {
              showToast("링크가 복사되었어요. \n 지금 친구에게 공유해보세요.");
            },
            () => {
              if (isAndroid()) {
                copyToClipboardWithDocument(url);
              } else {
                copyToClipboard(url);
              }
              showToast("링크가 복사되었어요. \n지금 친구에게 공유해보세요.");
            }
          );
        } else {
          copyToClipboard(url);
          showToast("링크가 복사되었어요. 지금 친구에게 공유해보세요.");
        }
        logFn();
      },
      () => {
        showToast("친구에게 추천하고 혜택을 받으려면 \n로그인이 필요해요.");
      }
    );
  };
};

export const useOnClickEventShareWithoutLogin = () => {
  const userId = useUserId();
  const router = useRouter();
  const showToast = useToast();
  const isMobile = useIsMobile();

  const url = userId
    ? `${process.env.BASE_URL}${router.pathname}?f_uid=${userId}`
    : `${process.env.BASE_URL}${router.pathname}`;

  return (logFn) => {
    if (isMobile) {
      shareUrl(
        url,
        () => {
          // showToast("링크가 복사되었어요. 지금 친구에게 공유해보세요.");
        },
        () => {
          if (isAndroid()) {
            copyToClipboardWithDocument(url);
          } else {
            copyToClipboard(url);
          }
          showToast("링크가 복사되었어요. 지금 친구에게 공유해보세요.");
        }
      );
    } else {
      copyToClipboard(url);
      showToast("링크가 복사되었어요. 지금 친구에게 공유해보세요.");
    }
    logFn();
  };
};

import { useRouter } from "next/router";
import React, { ReactNode } from "react";
import { getLogEventName } from "src/models/_layout/gnbV2.models";
import {
  getCourseSummary,
  getCurriSummary,
  nbCourseId,
} from "../../../models/nb/nbMinimal/nbMinimal.models";
import { getTutorName } from "../../../models/online/tutors/tutors.models";
import { getUrlSplitted } from "../../_layout/gnb/v2";
import { sendCPLog } from "../logging";
import { LogApplyCourse } from "./type";

export const logNbDetailPage = (
  type,
  url,
  courseDetail = null,
  buttonLocation = null,
  buttonText?,
) => {
  // default
  const eventName = `nb_courseDetailPage_${type}`;
  const brand = "내일배움단";
  const productCategory = "국비지원";
  const isFree = false;
  const discountReason = "국비지원";
  const pageTitle = document.title;
  // from nbMinimal.model
  const courseSummary = getCourseSummary(url);
  const curriSummary = getCurriSummary(url);
  const courseTitle = courseSummary?.title;
  const coursePreview = courseSummary?.subTitle;
  const language = curriSummary?.techSpec;

  switch (type) {
    case "apply_click":
      sendCPLog(eventName, {
        course_title: courseTitle,
        course_id: courseDetail.round.course_id,
        brand: brand,
        product_category: productCategory,
        tags: courseDetail.tags,
        tutor_id: courseDetail.tutor_id,
        level: courseDetail.level,
        language: language,
        course_preview: coursePreview,
        location: buttonLocation,
        page_title: pageTitle,
        button_text: buttonText,
      });
      break;
    case "view":
      sendCPLog(eventName, {
        course_title: courseTitle,
        course_id: courseDetail.round.course_id,
        brand: brand,
        product_category: productCategory,
        tags: courseDetail.tags,
        tutor: getTutorName(url),
        level: courseDetail.level,
        language: language,
        is_free: isFree,
      });
      break;
    case "click_rcmdCourse":
      // 현재 페이지의 강의가 아니라, 클릭한 강의 기준의 정보를 보내야 함.
      sendCPLog(eventName, {
        course_title: courseDetail.title,
        course_id: nbCourseId[url],
        brand: brand,
        product_category: productCategory,
        tags: courseDetail.tags,
        tutor: getTutorName(url),
        language: courseDetail.lang,
        course_preview: courseDetail.desc,
        show_price: courseDetail.price,
        discount_reason: discountReason,
        display_price: courseDetail.originalPrice,
        is_free: isFree,
        page_title: pageTitle,
      });
      break;

    default:
      break;
  }
};

const getCurrentBrand = (pathArray) => {
  return pathArray[0] === "nb" ? "nb" : "scc";
};

const getCurrentPageType = (pathArray) => {
  let currentPageType = "";
  switch (pathArray[0]) {
    case "nb":
      const getNbCurrentPage = () => {
        if (pathArray.length === 1) return "mainPage";
        switch (pathArray[1]) {
          case "guide":
            if (!pathArray[2]) {
              return "mainGuidePage";
            } else if (pathArray[2] === "noCard") {
              return "cardGuidePage";
            } else if (pathArray[2] === "hrdRegFin") {
              return "enrollGuidePage";
            } else if (pathArray[2] === "regFin") {
              return "paymentGuidePage";
            } else if (pathArray[2] === "enroll") {
              return "enrollGuidePage";
            }
            break;
          default:
            return "courseDetailPage";
        }
      };
      currentPageType = getNbCurrentPage();
      break;
    case "catalog":
      currentPageType = "catalogPage";
      break;
    case "online":
      currentPageType = "courseDetailPage";
      break;
    case "hyoneung":
      currentPageType = "event";
      break;
    default:
      currentPageType = "mainPage";
      break;
  }
  return currentPageType;
};

const getEventName = (path, type?) => {
  const pathArray = getUrlSplitted(path);

  const currentBrand = getCurrentBrand(pathArray);
  const currentPageType = getCurrentPageType(pathArray);

  return type
    ? `${currentBrand}_${currentPageType}_${type}`
    : `${currentBrand}_${currentPageType}`;
};

export const useCurrentEventPath = () => {
  const { asPath } = useRouter();
  return getEventName(asPath);
};

export const sendLog = (eventName, data = {}) => {
  sendCPLog(eventName, data);
};

export const sendLogV2 = (type, currentPath, data?) => {
  currentPath = currentPath.replace(process.env.BASE_URL, "");

  const eventName = getEventName(currentPath, type);
  const defaultData = {};
  sendCPLog(eventName, { ...data, ...defaultData });
};

export const logClickCourseCard = (path, data) => {
  const pathArray = getUrlSplitted(path);
  const currentBrand = getCurrentBrand(pathArray);
  const currentPageType = getCurrentPageType(pathArray);

  let type = "";
  if (currentPageType === "catalogPage") {
    type = "courseCard_click";
  } else if (currentPageType === "courseDetailPage") {
    type = "rcmdCourse_click";
  }

  sendCPLog(`${currentBrand}_${currentPageType}_${type}`, data);
};

export const logCourseDetailView = (data, isTrial = false) => {
  sendCPLog("scc_courseDetailPage_view", data);
};

export const logApplyCourse = (data: Partial<LogApplyCourse>) => {
  sendCPLog("scc_courseDetailPage_apply_click", data);
};

export const logGnbClick = (keyName, data) => {
  const logEventName = getLogEventName(keyName);
  sendCPLog(logEventName, data);
};

export const extractTextContent = (element: ReactNode, onlyFirstChild?) => {
  if (onlyFirstChild) {
    element = React.Children.map(element, (child, index) =>
      index === 0 ? child : null,
    );
  }

  if (typeof element === "string") {
    return element;
  }

  if (React.isValidElement(element)) {
    if (element.type === "br") {
      return " ";
    }
    if (element.props.children) {
      return extractTextContent(element.props.children);
    }
  }

  if (Array.isArray(element)) {
    return element.map(extractTextContent).join("");
  }

  return "";
};

export const logCourseDetailLogger = (logName, data = {}) => {
  sendCPLog(`scc_courseDetailPage_${logName}`, data);
};

export const logChtCTAClick = () => {
  sendCPLog("cht_cta_click", {});
};

import { useIsMobile } from "../../../../../businesslogics/_layout/window";

export const CategoryLogoSVG = ({ category }) => {
  switch (category) {
    case "catalog":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#E8344E" />
          <path
            d="M12.2041 6V6.62738H12.8027V7.25476H13.4014V7.88215H13.9995V8.50953H13.4014V9.13691H12.8027V9.76429H12.2046V10.3917H11.0078V9.76429H11.6059V9.13691H12.2041V8.50953H12.8027V7.88215H12.2046V7.25423H11.6065V6.62685H11.0083V6H12.2041Z"
            fill="white"
          />
          <path
            d="M10.2418 6.11078H9.19807V6.71642H8.60838V7.33213H8.05036V7.93724H7.46068V8.55349H6.90214V9.15859H6.31245V9.77484H5.75391V10.3906H6.79813V9.77484H7.35615V9.1692H7.94584V8.55349H8.50385V7.94838H9.09407V7.33213H9.65208V6.72702H10.2418V6.11078Z"
            fill="white"
          />
          <path
            d="M3.79546 10.4463V9.8189H3.1968V9.19151H2.59814V8.56413H2V7.93675H2.59814V7.30937H3.1968V6.68199H3.79493V6.05566H4.99173V6.68305H4.3936V7.31043H3.79546V7.93781H3.1968V8.56519H3.79493V9.19257H4.39307V9.81996H4.9912V10.4473H3.79546V10.4463Z"
            fill="white"
          />
        </svg>
      );
    case "catalogv3":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <path
              id="Vector 160"
              d="M4 13.3345H12"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <rect
              id="Rectangle 7872"
              x="2.6687"
              y="2.66553"
              width="10.6667"
              height="8"
              rx="1.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Rectangle 7873"
              d="M7 5.60142V7.73192C7 7.99373 7.28798 8.15334 7.51 8.01458L9.2144 6.94933C9.42329 6.81878 9.42329 6.51456 9.2144 6.384L7.51 5.31875C7.28798 5.17999 7 5.3396 7 5.60142Z"
              fill="#3A3E41"
            />
          </g>
        </svg>
      );
    case "nb":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#419400" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.04947 5.81899L3.84355 9.27844L7.00224 10.4262L7.00224 9.42584L4.42754 7.59591L4.60245 7.29295L2.04947 5.81899ZM2.05209 5.81908L5.94511 5.64306L8.09297 7.94954L8.00574 8.92567L4.77999 6.99009L4.60508 7.29304L2.05209 5.81908Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9505 5.8185L12.1565 9.27795L8.99776 10.4257L8.99776 9.42535L11.5725 7.59542L11.3975 7.29246L13.9505 5.8185ZM13.9479 5.81895L10.0549 5.64294L7.90703 7.94941L7.99426 8.92555L11.22 6.98996L11.3949 7.29292L13.9479 5.81895Z"
            fill="white"
          />
          <path d="M7.5 10.0001H8.5L9 14.0001H7L7.5 10.0001Z" fill="white" />
        </svg>
      );
    case "chang":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1839_22874)">
            <rect width="16" height="16" rx="8" fill="#0F16AE" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.08023 13.4908C6.12032 13.5915 6.36689 13.6101 6.9999 14.3305C8.03436 15.5079 6.9999 26 6.9999 26H7.98223H8.01757H8.9999C8.9999 26 7.96544 15.5079 8.9999 14.3305C9.63291 13.6101 9.87948 13.5915 9.91957 13.4908C9.94499 13.4269 9.88736 13.33 9.79257 13H8.01757H7.98223H6.20723C6.11244 13.33 6.05481 13.4269 6.08023 13.4908Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 3L5 7.69301L6.32 12H7.5V7.21346H8V7.21352H8.5V12H9.68L11 7.69306L8 3.00005V3Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1839_22874">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "swcamp":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <rect x="0.789062" width="16" height="16" rx="8" fill="#141617" />
          <path
            d="M4.78906 5.55196V7.21696L10.6907 10.448H6.52V9.39535H4.78906V10.448V11.058V12H12.4782V10.448H11.2913L11.2866 8.89844L6.52 6.39976V5.55196H10.7449V6.60465H12.4782V5.55196V4.94201V4H4.78906V5.55196Z"
            fill="white"
          />
        </svg>
      );
    case "intellipick":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3190_45714)">
            <rect width="16" height="16" rx="8" fill="#6E50FF" />
            <path
              d="M7.57688 7.8871C6.98613 7.17742 6.76763 6.91129 6.27399 6.32258C5.30289 6.6129 4.89827 6.73387 4 7.00806C4.78497 8.65323 4.9711 9.00806 5.90173 11H9.05781C9.72139 7.8871 13.9618 3.45161 18 3C12.5457 3 9.52717 5.32258 7.57688 7.87903V7.8871Z"
              fill="#FFCB40"
            />
          </g>
          <defs>
            <clipPath id="clip0_3190_45714">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "ddingdong":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#FF7005" />
          <path
            d="M11.262 9.0794V7.7199C11.262 6.15908 10.1624 4.85452 8.69945 4.5377V3.99257C8.69945 3.60586 8.38728 3.2937 8.00057 3.2937C7.61386 3.2937 7.3017 3.60586 7.3017 3.99257V4.5377C5.83873 4.85452 4.73916 6.15908 4.73916 7.7199V9.0794L4.13813 9.68043C3.84461 9.97396 4.04961 10.4772 4.46427 10.4772H11.5322C11.9469 10.4772 12.1565 9.97396 11.863 9.68043L11.262 9.0794Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.50012 11.4771C6.66074 12.1819 7.27131 12.7064 8 12.7064C8.72869 12.7064 9.33927 12.1819 9.49988 11.4771H6.50012Z"
            fill="white"
          />
        </svg>
      );
    case "community":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 3.99886C2 3.26248 2.59695 2.66553 3.33333 2.66553H12.6667C13.403 2.66553 14 3.26248 14 3.99886V9.99886C14 10.7352 13.403 11.3322 12.6667 11.3322H10.0362C9.80631 11.3322 9.59266 11.4506 9.47083 11.6455L8 13.9989L6.52917 11.6455C6.40734 11.4506 6.1937 11.3322 5.96384 11.3322H3.33333C2.59695 11.3322 2 10.7352 2 9.99886V3.99886Z"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <rect
            x="4.58203"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill="#3A3E41"
          />
          <rect
            x="7.25"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill="#3A3E41"
          />
          <rect
            x="9.91797"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill="#3A3E41"
          />
        </svg>
      );
    case "blog":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.66675 4H13.3334"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66675 8H13.3334"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66675 12H13.3334"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <circle cx="3.4165" cy="4" r="0.75" fill="#3A3E41" />
          <circle cx="3.4165" cy="8" r="0.75" fill="#3A3E41" />
          <circle cx="3.4165" cy="12" r="0.75" fill="#3A3E41" />
        </svg>
      );
    case "event":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <rect
              id="Rectangle 6370"
              x="2.66663"
              y="3.3335"
              width="10.6667"
              height="10.6667"
              rx="1.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Vector 168"
              d="M2.66663 6.6665L13.3333 6.6665"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Vector 564"
              d="M5.33337 2V3.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Vector 565"
              d="M10.6666 2V3.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Star 4"
              d="M7.63468 8.39867C7.78141 8.10137 8.20534 8.10137 8.35207 8.39867L8.68206 9.06731C8.74033 9.18536 8.85295 9.26719 8.98324 9.28612L9.72112 9.39334C10.0492 9.44102 10.1802 9.84421 9.9428 10.0756L9.40887 10.5961C9.31459 10.688 9.27157 10.8204 9.29383 10.9501L9.41987 11.685C9.47592 12.0118 9.13295 12.261 8.83949 12.1067L8.17951 11.7597C8.06298 11.6985 7.92377 11.6985 7.80724 11.7597L7.14725 12.1067C6.8538 12.261 6.51083 12.0118 6.56687 11.685L6.69292 10.9501C6.71518 10.8204 6.67216 10.688 6.57788 10.5961L6.04394 10.0756C5.80654 9.84421 5.93754 9.44102 6.26563 9.39334L7.00351 9.28612C7.1338 9.26719 7.24642 9.18536 7.30469 9.06731L7.63468 8.39867Z"
              fill="#3A3E41"
            />
          </g>
        </svg>
      );
    case "faq":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <rect width="16" height="16" fill="white" />
            <path
              id="Vector 1450"
              d="M13.3334 12V13.3333C13.3334 13.7015 13.0349 14 12.6667 14H9.33337"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector 1448"
              d="M2.66663 7.33333V7.33333C2.66663 4.38782 5.05444 2 7.99996 2V2C10.9455 2 13.3333 4.38782 13.3333 7.33333V7.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Rectangle 19794"
              d="M1.33337 8.26651C1.33337 7.38285 2.04972 6.6665 2.93337 6.6665C3.52248 6.6665 4.00004 7.14407 4.00004 7.73317V10.9332C4.00004 11.5223 3.52248 11.9998 2.93337 11.9998C2.04972 11.9998 1.33337 11.2835 1.33337 10.3998V8.26651Z"
              fill="white"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Rectangle 19795"
              d="M12 7.73317C12 7.14407 12.4776 6.6665 13.0667 6.6665C13.9503 6.6665 14.6667 7.38285 14.6667 8.2665V10.3998C14.6667 11.2835 13.9503 11.9998 13.0667 11.9998C12.4776 11.9998 12 11.5223 12 10.9332V7.73317Z"
              fill="white"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <circle
              id="Ellipse 2734"
              cx="6.66667"
              cy="9.33317"
              r="0.666667"
              fill="#3A3E41"
            />
            <circle
              id="Ellipse 2735"
              cx="9.33329"
              cy="9.33317"
              r="0.666667"
              fill="#3A3E41"
            />
            <path
              id="Vector 1449"
              d="M6 6.66683C6.11111 6.55572 7 5.3335 7 5.3335C8.33333 6.3335 8.93333 6.40016 10 6.66683"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );
    case "bisness":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <path
              id="Rectangle 19796"
              d="M12.6666 7.33333V3.33333C12.6666 2.59695 12.0697 2 11.3333 2H3.99996C3.26358 2 2.66663 2.59695 2.66663 3.33333V14"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Vector 1451"
              d="M14 14V9.33333C14 8.59695 13.403 8 12.6666 8H9.99996C9.26358 8 8.66663 8.59695 8.66663 9.33333V14"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              id="Rectangle 19797"
              x="4.66663"
              y="4"
              width="2"
              height="2.66667"
              rx="0.666667"
              fill="#3A3E41"
            />
            <rect
              id="Rectangle 19799"
              x="4.66663"
              y="8"
              width="2"
              height="2.66667"
              rx="0.666667"
              fill="#3A3E41"
            />
            <rect
              id="Rectangle 19798"
              x="8.66663"
              y="4"
              width="2"
              height="2.66667"
              rx="0.666667"
              fill="#3A3E41"
            />
          </g>
        </svg>
      );
    case "hanghae":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#F50000" />
          <path
            d="M6.53178 4.3145C5.51711 3.62038 3.95173 4.11406 3.03513 5.41323C2.11854 6.7124 2.20152 8.32709 3.21996 9.02122C4.23463 9.71535 5.80001 9.22166 6.71661 7.92249C7.62943 6.62332 7.54645 5.00863 6.53178 4.3145ZM4.02717 7.87424C3.50664 7.51789 3.46514 6.69384 3.93287 6.0257C4.4006 5.36127 5.20026 5.10886 5.7208 5.4652C6.24134 5.82154 6.28283 6.64559 5.8151 7.31374C5.34737 7.97446 4.54771 8.22687 4.02717 7.87424Z"
            fill="white"
          />
          <path
            d="M7.05967 7.30261L4.58901 12.1392L3.6875 11.2335L5.4113 7.96705L7.05967 7.30261Z"
            fill="white"
          />
          <path
            d="M12.2076 4.3145C11.1929 3.62038 9.62751 4.11406 8.71092 5.41323C7.79432 6.7124 7.8773 8.32709 8.89574 9.02122C9.91419 9.71535 11.4758 9.22166 12.3924 7.92249C13.3052 6.62332 13.226 5.00863 12.2076 4.3145ZM9.70295 7.87424C9.18242 7.51789 9.14093 6.69384 9.60865 6.0257C10.0764 5.35755 10.8798 5.10886 11.4004 5.46149C11.9209 5.81783 11.9624 6.64188 11.4947 7.31002C11.0269 7.97446 10.2235 8.22687 9.70295 7.87424Z"
            fill="white"
          />
          <path
            d="M12.6607 7.48822L10.1938 12.8L9.27344 11.8943L11.0123 7.96706L12.6607 7.48822Z"
            fill="white"
          />
        </svg>
      );
    case "exhibition":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99976 1.24988C8.31582 1.24988 8.59796 1.44802 8.70524 1.74531L8.7971 1.99988H7.20243L7.29429 1.74531C7.40157 1.44802 7.68371 1.24988 7.99976 1.24988ZM5.18862 12H10.8109L11.6244 14.2544C11.765 14.6441 12.1948 14.8459 12.5845 14.7054C12.9741 14.5648 13.176 14.1349 13.0354 13.7453L12.4056 12H13.67C14.4045 12 15 11.4045 15 10.67V3.33C15 2.59546 14.4045 2 13.67 2H2.33C1.59546 2 1 2.59546 1 3.33V10.67C1 11.4045 1.59546 12 2.33 12H3.59395L2.96416 13.7453C2.82357 14.1349 3.02545 14.5648 3.41507 14.7054C3.80469 14.8459 4.23452 14.6441 4.37511 14.2544L5.18862 12ZM2.5 9.43937V3.5H13.5V6.82821L12.5981 5.74591C11.7665 4.74802 10.2339 4.74803 9.40229 5.74591L7.789 7.68185L7.4605 7.36541C6.64408 6.57898 5.34827 6.5911 4.5467 7.39266L2.5 9.43937ZM13.5 9.17129V10.5H3.56069L5.60736 8.45333C5.83088 8.22981 6.19221 8.22643 6.41986 8.44573L7.32886 9.32134C7.47886 9.46583 7.6822 9.54144 7.89017 9.53006C8.09814 9.51868 8.29201 9.42132 8.42534 9.26132L10.5546 6.70619C10.7865 6.42793 11.2139 6.42793 11.4458 6.70619L13.5 9.17129Z"
            fill="#3A3E41"
          />
        </svg>
      );
    case "kdt":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33337 2L2.02435 12.9635C1.72899 13.4066 2.04659 14 2.57905 14H13.421C13.9535 14 14.2711 13.4066 13.9757 12.9635L6.66671 2"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M5.33337 14.0002L7.42121 10.3464C7.67714 9.89857 8.32294 9.89857 8.57887 10.3464L10.6667 14.0002H5.33337Z"
            fill="#3A3E41"
          />
        </svg>
      );
    default:
      return <></>;
  }
};

export const MyPageLogoSVG = ({ active }) => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={isMobile ? "20" : "24"}
      height={isMobile ? "20" : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="8"
        r="4"
        stroke={active ? "#E8344E" : "#141617"}
        strokeWidth="2"
      />
      <path
        d="M4.20703 19.998C5.76317 17.3079 8.67172 15.498 12.003 15.498C15.3343 15.498 18.2428 17.3079 19.799 19.998"
        stroke={active ? "#E8344E" : "#141617"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const CloseBtnSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16797 4.16675L15.8346 15.8334"
      stroke="#141617"
      strokeWidth="1.66667"
      strokeLinecap="round"
    />
    <path
      d="M15.832 4.16675L4.16536 15.8334"
      stroke="#141617"
      strokeWidth="1.66667"
      strokeLinecap="round"
    />
  </svg>
);

export const HamburgerSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33203 5H16.6654"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M3.33203 10H16.6654"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M3.33203 15H16.6654"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
  </svg>
);

export const RightArrowSVG = ({ accent = false }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.13373 11.4697C5.84084 11.7626 5.84084 12.2374 6.13373 12.5303C6.42663 12.8232 6.9015 12.8232 7.19439 12.5303L6.13373 11.4697ZM10.6641 8L11.1944 8.53033C11.4873 8.23744 11.4873 7.76256 11.1944 7.46967L10.6641 8ZM7.19439 3.46967C6.9015 3.17678 6.42663 3.17678 6.13373 3.46967C5.84084 3.76256 5.84084 4.23744 6.13373 4.53033L7.19439 3.46967ZM7.19439 12.5303L11.1944 8.53033L10.1337 7.46967L6.13373 11.4697L7.19439 12.5303ZM11.1944 7.46967L7.19439 3.46967L6.13373 4.53033L10.1337 8.53033L11.1944 7.46967Z"
      fill={accent ? "#E8344E" : "#81898F"}
    />
  </svg>
);

export const RightArrowGraySVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.66602 12L10.666 8L6.66602 4"
      stroke="#5F666B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownArrowSVG = ({ accent = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 8.33203L10 13.332L15 8.33203"
      stroke="#9DA7AE"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MyPageBalloonSVG = () => (
  <svg
    width="306"
    height="604"
    viewBox="0 0 306 604"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2011_73786)">
      <mask id="path-1-inside-1_2011_73786" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M273.25 16.9971H282C288.627 16.9971 294 22.3697 294 28.9971V575.997C294 582.625 288.627 587.997 282 587.997H24C17.3726 587.997 12 582.625 12 575.997V28.9971C12 22.3697 17.3726 16.9971 24 16.9971H258.75L264.698 8.67002C265.336 7.77666 266.664 7.77666 267.302 8.67002L273.25 16.9971Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.25 16.9971H282C288.627 16.9971 294 22.3697 294 28.9971V575.997C294 582.625 288.627 587.997 282 587.997H24C17.3726 587.997 12 582.625 12 575.997V28.9971C12 22.3697 17.3726 16.9971 24 16.9971H258.75L264.698 8.67002C265.336 7.77666 266.664 7.77666 267.302 8.67002L273.25 16.9971Z"
        fill="white"
      />
      <path
        d="M273.25 16.9971L272.436 17.5783L272.735 17.9971H273.25V16.9971ZM258.75 16.9971V17.9971H259.265L259.564 17.5783L258.75 16.9971ZM264.698 8.67002L265.512 9.25126V9.25125L264.698 8.67002ZM267.302 8.67002L266.488 9.25125V9.25126L267.302 8.67002ZM273.25 17.9971H282V15.9971H273.25V17.9971ZM282 17.9971C288.075 17.9971 293 22.9219 293 28.9971H295C295 21.8174 289.18 15.9971 282 15.9971V17.9971ZM293 28.9971V575.997H295V28.9971H293ZM293 575.997C293 582.072 288.075 586.997 282 586.997V588.997C289.18 588.997 295 583.177 295 575.997H293ZM282 586.997H24V588.997H282V586.997ZM24 586.997C17.9249 586.997 13 582.072 13 575.997H11C11 583.177 16.8203 588.997 24 588.997V586.997ZM13 575.997V28.9971H11V575.997H13ZM13 28.9971C13 22.9219 17.9249 17.9971 24 17.9971V15.9971C16.8203 15.9971 11 21.8174 11 28.9971H13ZM24 17.9971H258.75V15.9971H24V17.9971ZM259.564 17.5783L265.512 9.25126L263.884 8.08878L257.936 16.4158L259.564 17.5783ZM265.512 9.25125C265.751 8.91625 266.249 8.91625 266.488 9.25125L268.116 8.08878C267.079 6.63707 264.921 6.63707 263.884 8.08878L265.512 9.25125ZM266.488 9.25126L272.436 17.5783L274.064 16.4158L268.116 8.08878L266.488 9.25126Z"
        fill="#E4EBF0"
        mask="url(#path-1-inside-1_2011_73786)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2011_73786"
        x="0"
        y="0"
        width="306"
        height="603.997"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2011_73786"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2011_73786"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

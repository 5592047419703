import React from "react";

const GoogleTagManagerBodyNoscript = () => {
  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5DJWLLCK"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      }}
    />
  );
};

export default GoogleTagManagerBodyNoscript;

import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { scrollYAtom, windowSizeAtom } from "../../_layout/window";

const readOnlyScrollYAtom = atom((get) => get(scrollYAtom));

export const useReadOnlyWindowScrollY = () => {
  const [scrollY] = useAtom(readOnlyScrollYAtom);
  return scrollY;
};

const readOnlyWindowSizeAtom = atom((get) => get(windowSizeAtom));

export const useReadOnlyWindowSize = () => {
  const [windowSize] = useAtom(readOnlyWindowSizeAtom);

  return windowSize.width > 1024 ? "desktop" : "mobile";
};

export const scrollToRef = (targetRef) => {
  if (window) {
    let offset = 0;
    let pageElement = targetRef.current;
    while (pageElement != null) {
      offset += pageElement.offsetTop;
      pageElement = pageElement.offsetParent;
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  }
};

export const useWindowScrollAndResize = (onScroll, onResize) => {
  const Y = useReadOnlyWindowScrollY();
  const width = useReadOnlyWindowSize();
  useEffect(() => {
    onScroll();
    onResize();
  }, [Y, width]);
};

export const getFallbackImageSrc = (src) => {
  if (src.includes("http")) {
    return {
      primarySrc: src,
      secondarySrc: src,
    };
  }

  const primarySrc = process.env.IS_PROD
    ? `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend${src}`
    : src;

  const secondarySrc = process.env.IS_PROD
    ? src
    : `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend${src}`;

  return {
    primarySrc,
    secondarySrc,
  };
};

import { MONTHLY_SPECIAL } from "../online/special";
import {
  BaseCourse,
  ComingSoonCourse,
  FreeCourse,
  FundingCourse,
  MonthlyCourse,
  Package,
  SpecialCourse,
  Track,
} from "./catalog";
import { isB2GSqlEnded } from "../../businesslogics/online";

/**
 event?: 이벤트 내용(ex.1억 챌린지),
 isFree: 무료강의 여부,
 isMonthly: 월간코딩 강의 여부,
 price: 할인가 적용 가격,
 originalPrice: 원가,
 discountRate: 할인율,
 amount: 강의 분량
 */

export const App: BaseCourse = {
  id: "5f0ae408765dae0006002817",
  url: "/online/app",
  thumbnail: "app",
  title: "앱개발 종합반",
  desc: "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
  category: ["Best", "코딩 입문"],
  tags: ["구글 애드몹", "왕초보"],
  lang: ["Flutter"],

  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const JAVA: BaseCourse = {
  id: "642108fb5ca424f842d26759",
  url: "/online/java",
  thumbnail: "java",
  title: "Java 문법 종합반",
  desc: "Java 언어의 기초 개념부터 심화까지, 실습을 통해 확실하게 마스터해요.",
  category: ["실전/응용"],
  tags: ["왕초보"],
  lang: ["Java"],

  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const JAVASCRIPT: BaseCourse = {
  id: "641bfa2198c9ad6cbd2cbf89",
  url: "/online/javascript",
  thumbnail: "javascript",
  title: "JavaScript 문법 종합반",
  desc: "개발 스펙의 초석이 되는\nJavaScript의 A to Z를 학습합니다.",
  category: ["실전/응용"],
  tags: ["왕초보"],
  lang: ["JavaScript"],

  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const Csharp: BaseCourse = {
  id: "646ebfc18a00fb4c309e13ac",
  url: "/online/csharp",
  thumbnail: "csharp",
  title: "C# 문법 종합반",
  desc: "C# 기초 문법부터 고급 기능까지 실습과 함께 마스터할 수 있는 올인원 강의를 만나보세요.",
  category: ["실전/응용"],
  tags: ["개발자", "문법", "C#", "종합반", "입문"],
  lang: ["C#"],

  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const Data: BaseCourse = {
  id: "6361d2ce4239a844b30d4163",
  url: "/online/data_v2",
  thumbnail: "data_v2",
  title: "데이터 분석 종합반",
  desc: "데이터 기반 사고와 코딩을 통해 분석 결과를 세상에 내어놓는 하드스킬까지 한번에 갖출 수 있습니다.",
  category: ["코딩 입문"],
  tags: ["파이썬", "판다스", "맷플롯립"],
  lang: ["Python"],

  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const Automation: BaseCourse = {
  id: "62973372bf6e0e4f799fc76e",
  url: "/online/finance_automation",
  thumbnail: "automation",
  title: "금융인을 위한 파이썬 업무자동화",
  desc: "데이터를 필요한 형태로 조합하고, 원하는 모양으로 시각화할 수 있습니다.",
  category: ["코딩 입문"],
  tags: ["왕초보", "파이썬", "업무자동화"],
  lang: ["Python"],

  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const Nocode: BaseCourse = {
  id: "63d8ec592ad417e6257ffef8",
  url: "/online/nocode",
  thumbnail: "nocode",
  title: "노코드로 빠르게 웹 서비스 만들기",
  desc: "전문적인 개발 지식 없이, 웹서비스를 빠르게 만들 수 있는 강의입니다. 노션, 우피, 재피어 등 노코드 툴을 활용하여 코딩 없이 홈페이지를 완성해 봅니다",
  category: ["노코드"],
  tags: ["노코드", "노션", "우피", "재피어", "HTML"],
  lang: ["No-code"],
  price: "310,000원",
  originalPrice: "500,000원",
  discountRate: "34%",
  amount: "4주 과정",
};

export const FirstStep: BaseCourse = {
  id: "63d8cb5e2ad417e6257ffd44",
  url: "/online/firststep",
  thumbnail: "firststep",
  title: "컴퓨터를 몰라도 할 수 있는 코딩 첫걸음",
  desc: "컴퓨터가 익숙하지 않은 왕초보를 위한 입문 수업입니다. 누구나 쉽게 배울 수 있는 강의를 통해 컴퓨터와 코딩이 무엇인지부터 활용까지 배워보세요!",
  category: ["코딩 입문"],
  tags: ["왕초보", "컴퓨터", "HTML", "CSS"],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "4주 과정",
};

export const Webflow: BaseCourse = {
  id: "6412734baa727445ce9374b0",
  url: "/online/webflow",
  thumbnail: "webflow",
  title: "노코드 종합반",
  desc: "페이지 제작, 데이터베이스, 자동화까지 할 수 있는 노코드 올인원 클래스.",
  category: ["Best", "인기 급상승"],
  tags: ["webflow", "zapier", "airtable", "GA4"],
  lang: ["No-code"],
  price: "310,000원",
  originalPrice: "500,000원",
  discountRate: "34%",
  amount: "5주 과정",
};

export const Ga4: BaseCourse = {
  id: "64671aa77509c0fec824b08f",
  url: "/online/ga4",
  thumbnail: "ga4",
  title: "GA4로 한 시간 만에 노코드 데이터 정복하기",
  desc: "구글의 무료 웹 로그 분석 툴 GA, 설치부터 분석 보고서 활용까지 한 번에!",
  category: ["실전/응용"],
  tags: ["zapier", "airtable", "GA4"],
  lang: ["No-code"],
  price: "129,000원",
  originalPrice: "150,000원",
  discountRate: "14%",
  amount: "1주 과정",
};

export const Spring_v2: BaseCourse = {
  id: "648fc643f9b1fac7aced4966",
  url: "/online/spring_v2",
  thumbnail: "spring_v2",
  title: "웹개발의 봄 Spring",
  desc: "Spring의 기초부터 JPA, 인증/세션 관리, Spring Security, 테스트 코드까지! 올인원으로 담았어요.",
  category: ["실전/응용"],
  tags: ["웹", "웹개발", "스프링", "Spring"],
  lang: ["Java", "Spring"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "4주 과정",
};

export const AlgorithmForever: BaseCourse = {
  id: "6467063d187f226a50090d43",
  url: "/online/algorithm",
  thumbnail: "algorithmForever",
  title: "알고리즘 종합반",
  desc: "코딩테스트부터 기술 면접까지 대비할 수 있는 핵심 알고리즘 강의입니다.",
  category: ["실전/응용"],
  tags: ["개발자", "알고리즘", "코테", "코딩테스트"],
  lang: ["Python"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const Typescript: BaseCourse = {
  id: "6476d7cd41baaebbdf24176b",
  url: "/online/typescript",
  thumbnail: "typescript",
  title: "Typescript 문법 종합반",
  desc: "TypeScript 기초부터 고급 개념까지 실습과 함께 마스터할 수 있는 올인원 강의입니다.",
  category: ["실전/응용"],
  tags: ["개발자", "개발", "타입스크립트", "typescript"],
  lang: ["TypeScript"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "4주 과정",
};

export const Kotlin: BaseCourse = {
  id: "646ecce1c2b1d12303b401e6",
  url: "/online/kotlin",
  thumbnail: "kotlin",
  title: "Kotlin 문법 종합반",
  desc: "간결한 Kotlin 문법으로 개발 환경에 생산성을 더해보세요.",
  category: ["실전/응용"],
  tags: ["개발자", "개발", "코틀린", "kotlin"],
  lang: ["Kotlin"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "4주 과정",
};

export const Figma: BaseCourse = {
  id: "649adfc45d0330dcf1a41240",
  url: "/online/figma",
  thumbnail: "figma",
  title: "PPT보다 쉬운 피그마",
  desc: "파워포인트로 한땀한땀 하던 기획, 발표 자료 준비. 이젠 피그마로 더 쉽게 더 멋지게 만드세요.",
  category: ["코딩 입문"],
  tags: ["피피티", "파워포인트", "피그마", "PPT"],
  lang: [],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "4주 과정",
};

export const WebBasic: BaseCourse = {
  id: "649938ae1e7a5e8c1b9dfb37",
  url: "/online/web_basic",
  thumbnail: "web_basic",
  title: "[왕초보] 웹개발 종합반",
  desc: "약 4만 명이 인증한 시그니처 강의로 보다 쉽고 재미있게 웹개발에 입문하세요.",
  category: ["코딩 입문"],
  tags: [
    "Best",
    "입문 추천",
    "코딩 기초",
    "개발자",
    "개발",
    "html",
    "css",
    "웹종",
  ],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const Gpt300Online: BaseCourse = {
  id: "654c451dd6aa5411421c298e",
  url: "/online/gpt_300_online",
  thumbnail: "gpt_300_online",
  title: "ChatGPT 300% 활용하기",
  desc: "일상생활에 제일 많이 쓰이는 ChatGPT, 최신버전인 4o버전과 함께 다양한 예제들로 직접 적용해봅니다.",
  category: [],
  tags: [],
  lang: [],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const WorkflowAutomation: BaseCourse = {
  id: "650abff5535c9f5382d509ee",
  url: "/online/workflow_automation",
  thumbnail: "workflow_automation",
  title: "업무자동화 종합반",
  desc: "5주 만에 일 잘하는 직장인으로 성장해 보세요! 실무에 바로 적용할 수 있는 프로젝트로만 구성했어요.",
  category: ["직장인 IT 실무"],
  tags: ["ChatGPT", "직장인", "엑셀", "python", "파이썬", "황영상"],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const GenerativeAi: BaseCourse = {
  id: "65110da220492d270e776895",
  url: "/online/generative_ai",
  thumbnail: "generative_ai",
  title: "AI 종합반",
  desc: "인공지능의 원리부터 활용까지, 21개의 실습 콘텐츠로 만들면서 배웁니다.",
  category: ["직장인 IT 실무"],
  tags: ["인공지능", "업무자동화", "최지웅", "취업", "알고리즘", "최지웅"],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const ComputerCertificateN: BaseCourse = {
  id: "651294ddda3f908daf61d875",
  url: "/online/computer_certificate_n",
  thumbnail: "computer_certificate_n",
  title: "컴퓨터활용능력 1급 필기",
  desc: "개념과 기출을 모두 담은 압축 커리큘럼으로 한 번에 필기 합격하세요.",
  category: ["직장인 IT 실무"],
  tags: ["컴활", "자격증", "컴퓨터활용능력", "엑셀", "직장인", "커미조아"],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const ComputerCertificateP: BaseCourse = {
  id: "6512945b535c9f5382d66d57",
  url: "/online/computer_certificate_p",
  thumbnail: "computer_certificate_p",
  title: "컴퓨터활용능력 1급 실기",
  desc: "최신 트렌드를 반영한 문제 풀이와 맞춤 해설로 실전을 탄탄하게 대비합니다.",
  category: ["직장인 IT 실무"],
  tags: ["컴활", "자격증", "컴퓨터활용능력", "엑셀", "직장인", "커미조아"],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const SqldScc: BaseCourse = {
  id: "650ab32920492d270e767be1",
  url: "/online/SQLD-scc",
  thumbnail: "SQLD-scc",
  title: "SQLD 자격증 코스",
  desc: "SQLD 자격증 취득에 필요한 핵심만 빠르게! 이론부터 실전 모의고사까지 탄탄하게 준비할 수 있어요.",
  category: ["직장인 IT 실무"],
  tags: [
    "SQLD",
    "SQL",
    "왕초보",
    "데이터 자격증",
    "직장인 스펙업",
    "직장인",
    "데이터",
    "쿼리",
    "임우재",
  ],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "300,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const Sql_2023: BaseCourse = {
  id: "64956fe61e7a5e8c1b9c6e50",
  url: "/online/sql_2023",
  thumbnail: "sql_2023",
  title: "엑셀보다 쉽고 빠른 SQL",
  desc: "SQL 쿼리 한 줄로 내게 필요한 데이터를 볼 수 있게 만들어드리겠습니다.",
  category: ["코딩 입문"],
  tags: ["기획자", "PM", "데이터", "SQLD", "엑셀", "SQL"],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "4주 과정",
};

export const WebChatgpt: BaseCourse = {
  id: "64a653c7640351900b810a40",
  url: "/online/web_chatgpt",
  thumbnail: "web_chatgpt",
  title: "[GPT] 웹개발 종합반",
  desc: "입문자부터 실무자까지 개발자 커리어에 꼭 필요한 웹 페이지 제작의 모든 것을 배웁니다.",
  category: ["코딩 입문"],
  tags: ["웹종", "왕초보", "웹개발", "chatgpt"],
  lang: ["HTML · CSS"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const ChallengeCourseUrl = [
  "/online/web",
  "/online/app",
  "/online/sql",
  "/online/data_v2",
  "/online/game",
];

export const MachineLearning: BaseCourse = {
  id: "602a022f5b5001847b616df9",
  url: "/online/ml_basic",
  thumbnail: "ml_python",
  title: "가장 쉽게 배우는 \n머신러닝",
  desc: "수학과 복잡한 그래프에 겁먹지 마세요. 가장 쉽게 머신러닝을 배웁니다.",
  category: ["Best", "인기 급상승", "실전/응용"],
  tags: ["데이터 사이언스", "머신러닝", "왕초보"],
  lang: ["Python"],
  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "4주 과정",
};

export const PythonGrammar: BaseCourse = {
  id: "5feffb89f85b7ed4b4bdc7bd",
  url: "/online/lang/python",
  thumbnail: "python-grammar",
  title: "파이썬 문법 \n뽀개기",
  desc: "가장 많이 배우는 파이썬 문법 A to Z를 다루는 수업입니다.",
  category: ["코딩 입문"],
  tags: ["파이썬", "문법"],
  lang: ["Python"],
  price: "65,000원",
  originalPrice: "100,000원",
  discountRate: "35%",
  amount: "2시간 분량",
};

export const CppGrammar: BaseCourse = {
  id: "606bfc0fc11b70df6da82da5",
  url: "/online/lang/cpp",
  thumbnail: "cpp-grammar",
  title: "C++ 문법 \n뽀개기",
  desc: "현대 코딩의 뿌리! C++기초를 다지고 간단한 틱택토 게임을 만들어봅니다.",
  category: ["주니어 개발자"],
  tags: ["평생소장", "C++ 문법", "왕초보"],
  lang: ["C++"],

  price: "65,000원",
  originalPrice: "100,000원",
  discountRate: "35%",
  amount: "5시간 분량",
};

export const Game: BaseCourse = {
  id: "6083eaca6305e019d3e0c3b4",
  url: "/online/game",
  thumbnail: "game",
  title: "게임개발 종합반",
  desc: "모바일 캐주얼 게임을 내 손으로 만들어보고, 수익화도 함께 해봅니다.",
  category: ["코딩 입문"],
  tags: ["게임개발", "Unity", "C#", "왕초보"],
  lang: ["Unity"],

  price: "410,000원",
  originalPrice: "500,000원",
  discountRate: "18%",
  amount: "5주 과정",
};

export const AutomationGenai: BaseCourse = {
  id: "64c28536360d083f8a4d5716",
  url: "/online/automation_genai",
  thumbnail: "automation_genai",
  title: "ChatGPT와 함께 노코드로 시작하는 업무효율화",
  desc: "ChatGPT와 생성형 AI를 활용해 3분 만에 PPT 발표 자료를 제작해보세요!",
  category: [""],
  tags: [
    "실무",
    "ChatGPT",
    "챗지피티",
    "생성형",
    "AI",
    "노코드",
    "업무",
    "효율화",
  ],
  lang: [""],
  price: "180,000원",
  originalPrice: "300,000원",
  discountRate: "40%",
  amount: "2주 과정",
};

export const AutomationPython: BaseCourse = {
  id: "64c2859f9e284e546b64d2e8",
  url: "/online/automation_python",
  thumbnail: "automation_python",
  title: "비개발자도 할 수 있는 GPT 활용 파이썬 업무자동화",
  desc: "500+명의 대기업 임직원들이 업무 자동화를 위해 선택한 ChatGPTx파이썬 강의!",
  category: ["코딩 입문"],
  tags: [
    "실무",
    "업무자동화",
    "GPT",
    "챗지피티",
    "ChatGPT",
    "비개발자",
    "활용",
    "파이썬",
    "업무",
    "자동화",
  ],
  lang: [""],
  price: "180,000원",
  originalPrice: "300,000원",
  discountRate: "40%",
  amount: "2주 과정",
};

//무료
export const Coding101: FreeCourse = {
  id: "6178b82938d078df2971b63f",
  url: "/online/coding101",
  thumbnail: "coding101",
  title: "직장인 필수 \n코딩 용어 해설",
  desc: "일하면서 꼭 필요한 코딩용어를 1시간만에 끝낼 수 있습니다.",
  category: ["무료 강의"],
  tags: ["왕초보", "무료"],
  lang: [""],
  isFree: true,

  price: "0",
  originalPrice: "50,000원",
  discountRate: "0",
  amount: "1시간 분량",
};

export const Developer101: FreeCourse = {
  id: "625963fa9d5b4ee7f14b61d9",
  url: "/online/developer101",
  thumbnail: "developer101",
  title: "개발자 취업 준비의 \n모든 것",
  desc: "개발자 취업 시장을 살펴보고, 취업 로드맵을 그립니다.",
  category: ["무료 강의"],
  tags: ["HTML", "CSS", "무료"],
  lang: [""],
  isFree: true,

  price: "0",
  originalPrice: "80,000원",
  discountRate: "0",
  amount: "30분 분량",
};

export const DataTransfomation: FreeCourse = {
  id: "632ad50b2b63868e4030eb5b",
  url: "/online/special/hr",
  thumbnail: "hr",
  title: "교육 담당자를 위한\n" + "DT교육 설계 로드맵",
  desc: "우리 회사에 딱 맞는 DT 교육 로드맵을 그려봅니다.",
  category: ["무료 강의", "무료"],
  tags: ["왕초보"],
  lang: [""],
  isFree: true,

  price: "0",
  originalPrice: "50,000원",
  discountRate: "0",
  amount: "30분 분량",
};

export const ItStartUp: FreeCourse = {
  id: "632bec62293ed67329e02c62",
  url: "/online/special/chang",
  thumbnail: "chang",
  title: "IT 창업, 실패 확률 줄이는 방법",
  desc: "아이템 선정과 팀빌딩, 그리고 방법론까지 알려드립니다.",
  category: ["무료 강의", "무료"],
  tags: ["왕초보"],
  lang: [""],
  isFree: true,

  price: "0",
  originalPrice: "50,000원",
  discountRate: "0",
  amount: "30분 분량",
};

export const Youth: FreeCourse = {
  id: "",
  url: process.env.DDINGDONG_URL,
  thumbnail: "youth",
  title: "초등학교 코딩 교육, 띵동코딩",
  desc: "6주 만에 파이썬 코딩 완성! 파이썬으로 진짜 코딩을 시작하세요.",
  category: ["Best", "코딩 입문", "청소년"],
  tags: ["중고등학생", "포트폴리오"],
  lang: ["Scratch", "Python", "Javascript"],
  price: "256,000원",
  originalPrice: "320,000원",
  discountRate: "20%",
  amount: "6주",
  isFree: false,
};

export const Teenager: FreeCourse = {
  id: "6334259bd6566f2cb23ec7f7",
  url: "/online/special/teenager",
  thumbnail: "teenager",
  title: "중고등학생 코딩교육\n" + "준비 전략",
  desc: "어떻게 코딩교육을 시작해야 할까요? 아이를 위한 코딩을 모두 알려드립니다.",
  category: ["무료 강의", "청소년", "무료"],
  tags: ["왕초보"],
  lang: [""],
  isFree: true,

  price: "0",
  originalPrice: "50,000원",
  discountRate: "0",
  amount: "30분 분량",
};

export const ChatGPT: FreeCourse = {
  id: "6420ea5f7e6e4c8f5d0af5d0",
  url: "/online/special/chatgpt",
  thumbnail: "chatgpt",
  title: "chatGPT로 10분 만에 \n" + "웹사이트 만들기",
  desc: "chatGPT를 활용해 빠르게 웹사이트를 만들어봅니다.",
  category: ["무료 강의", "ChatGPT"],
  tags: ["왕초보", "무료"],
  lang: ["HTML · CSS", "Python", "ChatGPT"],
  isFree: true,

  price: "0",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "1시간 분량",
};

export const NBFree: FreeCourse = {
  id: "",
  url: "/online/special/nbfree",
  thumbnail: "nbfree",
  title: "내일배움카드 발급부터 \n활용의 모든 것",
  desc: "놓치면 안 되는 국비지원 혜택을 30분 만에 알려드립니다.",
  category: ["무료 강의"],
  tags: ["왕초보", "무료"],
  lang: [""],
  price: "0",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "30분 분량",
  isFree: true,
};

export const WfFree: FreeCourse = {
  id: "642c2dfff6dfefee6dc47bfb",
  url: "/online/special/wf_free",
  thumbnail: "wf_free",
  title: "코드 없이 AI로 웹사이트 하나가 뚝딱",
  desc: "ChatGPT와 웹플로우를 활용하여 코딩 없이 웹사이트를 만들어봅니다.",
  category: ["무료 강의", "노코드"],
  tags: ["노코드", "무료"],
  lang: ["No-code", "Ai"],
  isFree: true,
  price: "0",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "1시간 분량",
};

export const AiVirtual: FreeCourse = {
  id: "64939ff350f4c9159ab204d8",
  url: "/online/special/ai-virtual",
  thumbnail: "ai-virtual",
  title: "AI로 나만의 버추얼 캐릭터 만들기",
  desc: "생성 AI를 활용해서 나만의 버추얼 캐릭터를 만들어보세요. ",
  category: ["무료 강의"],
  tags: [
    "ChatGPT",
    "챗지피티",
    "gpt",
    "노코드",
    "무료",
    "ai",
    "캐릭터",
    "버추얼",
  ],
  lang: ["No-code"],
  isFree: true,
  price: "0",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "20분 분량",
};

// 월간코딩
export const MyProfile: MonthlyCourse = {
  id: "",
  url: "/online/special/myprofile",
  thumbnail: "myprofile",
  title: "나만의 \n프로필 링크 만들기",
  desc: "포트폴리오와 이력서를 담은 페이지를 직접 만들어보세요.",
  category: ["코딩 입문"],
  tags: ["왕초보", "월간코딩", "친구추천"],
  lang: ["HTML · CSS", "Python"],

  isMonthly: true,
  price: "89,000원",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "30분 분량",
  month: 10,
};

export const Momentum: MonthlyCourse = {
  id: "",
  url: "/online/special/momentum",
  thumbnail: "momentum",
  title: "나만의 동기부여 \n홈화면 만들기",
  desc: "휴대폰 브라우저 첫 화면을 나만의 동기부여 페이지로 만들어보세요!",
  category: ["코딩 입문"],
  tags: ["왕초보", "월간코딩", "친구추천"],
  lang: ["HTML · CSS", "Python"],

  isMonthly: true,
  price: "89,000원",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "1시간 분량",
  month: 11,
};

export const Bucketlist: MonthlyCourse = {
  id: "",
  url: "/online/special/bucketlist",
  thumbnail: "bucketlist",
  title: "2023 나만의 \n버킷리스트 만들기",
  desc: "40분만에 코딩을 배워 작심365일 특별한 버킷리스트를 만들어 보세요.",
  category: ["코딩 입문"],
  tags: ["왕초보", "월간코딩", "친구추천"],
  lang: ["HTML · CSS"],

  isMonthly: true,
  price: "89,000원",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "40분 분량",
  month: 1,
};

export const Photos: FreeCourse = {
  id: "63d5e477263dfb150c1c3c94",
  url: "/online/special/photos",
  thumbnail: "photos",
  title: "인생사진 쏙쏙 \n코딩네컷",
  desc: "1시간만에 코딩을 배워 네컷 사진을 만들어 보세요.",
  category:
    MONTHLY_SPECIAL === "photos"
      ? ["월간코딩", "무료 강의"]
      : ["월간코딩", "무료 강의"],
  tags: ["웹페이지 기초", "추억기록"],
  lang: ["HTML · CSS"],

  // isMonthly: true,
  // month: 4,
  isFree: true,

  price: "0",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "1시간 분량",
};

export const KdcTrack: Track = {
  id: "",
  url: "/nb",
  thumbnail: "nb",
  isHeightAuto: true,
  title: "국비지원 코딩강의",
  desc: "인기 코딩 강의를 0원에 \n수강하고 싶다면?",
  badge: "국비지원",
  isTrack: true,
  isDesktop: false,
  targetCategory: "무료/국비지원",

  category: [""],
  tags: [],
  lang: [],
  price: "",
  originalPrice: "",
  discountRate: "",
  amount: "",
};

// 내배단 수업 카드
export const NbWeb: SpecialCourse = {
  id: "",
  url: "/nb/web",
  thumbnail: "web",
  title: "[왕초보] 비개발자를 위한, 웹개발 종합반(프로그래밍 실무, 풀스택)",
  desc: "코딩이 처음이라면, 기본이 되는 웹개발을 시작으로 넓고 얕게 코딩을 만나보세요.",
  category: ["국비지원"],
  tags: ["파이썬", "풀스택", "왕초보"],
  lang: ["HTML · CSS", "JavaScript", "Python"],

  price: "41,000원",
  originalPrice: "410,000원",
  discountRate: "90%",
  amount: "5주 과정",
  isNb: true,
};

export const NbApp: SpecialCourse = {
  id: "63ca26845b3bac30b29bf2a3",
  url: "/nb/app",
  thumbnail: "nb/app",
  title: "[왕초보] 플러터(Flutter)로 시작하는 앱개발 종합반",
  desc: "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
  category: ["국비지원"],
  tags: ["안드로이드 앱", "iOS 앱", "부수입"],
  lang: ["Flutter"],

  price: "49,000원",
  originalPrice: "490,000원",
  discountRate: "90%",
  amount: "5주 과정",
  isNb: true,
};

export const NbSQL: SpecialCourse = {
  id: "65d2b38b1076f1d4cc075361",
  url: "/nb/sql",
  thumbnail: isB2GSqlEnded() ? "nb/sql_end" : "nb/sql",
  title: "[왕초보] 엑셀보다 쉽고 빠른 SQL",
  desc: "당장 내 업무에 필요한 데이터를 추출하고, 분석할 수 있는 힘을 기릅니다.",
  category: ["국비지원"],
  tags: ["SQL", "데이터분석", "왕초보"],
  lang: ["SQL"],
  price: "41,000원",
  originalPrice: "410,000원",
  discountRate: "90%",
  amount: "4주 과정",
  isNb: true,
};

export const NbPython: SpecialCourse = {
  id: "62f09f2af3ce1804901b3f5c",
  url: "/nb/python",
  thumbnail: "nb/python",
  title: "[왕초보] 주식 데이터를 활용한 파이썬 데이터분석",
  desc: "데이터를 필요한 형태로 조합하고, 원하는 모양으로 시각화할 수 있습니다.",
  category: ["국비지원"],
  tags: ["왕초보"],
  lang: ["Python"],

  price: "49,000원",
  originalPrice: "490,000원",
  discountRate: "90%",
  amount: "5주 과정",
  isNb: true,
};

export const NbData: SpecialCourse = {
  id: "63ca5b43dfbe98f4c8ef20bf",
  url: "/nb/data_v2",
  thumbnail: "nb/data_v2",
  title: "[왕초보] 마케터, 기획자를 위한 실전 데이터 분석",
  desc: "데이터를 필요한 형태로 조합하고, 원하는 모양으로 시각화할 수 있습니다.",
  category: ["국비지원"],
  tags: [],
  lang: ["Python"],

  price: "49,000원",
  originalPrice: "490,000원",
  discountRate: "90%",
  amount: "5주 과정",
  isNb: true,
};

export const NbGame: SpecialCourse = {
  id: "62f09eebfeb2d564bacb0262",
  url: "/nb/game",
  thumbnail: "nb/game",
  title: "[왕초보] 유니티로 만드는 게임개발 종합반",
  desc: "모바일 캐주얼 게임을 내 손으로 만들어보고, 수익화도 함께 해봅니다.",
  category: ["국비지원"],
  tags: ["게임"],
  lang: ["Unity"],

  price: "49,000원",
  originalPrice: "490,000원",
  discountRate: "90%",
  amount: "5주 과정",
  isNb: true,
};

export const GptClone: SpecialCourse = {
  id: "64269c728a9def2d676781b5",
  url: "/online/chatgpt_clone",
  thumbnail: "gpt_clone",
  title: "손쉽게 따라하는 \nChatGPT 클론코딩",
  desc: "자주 사용하는 인기 3종 앱서비스를 ChatGPT로 뚝딱 만들어봐요!",
  category: ["Best", "인기 급상승"],
  tags: ["chatGPT"],
  lang: ["HTML · CSS", "Python", "ChatGPT"],

  price: "129,000원",
  originalPrice: "200,000원",
  discountRate: "36%",
  amount: "2주 과정",
  isPaidGpt: true,
};

export const CodingTest: FreeCourse = {
  id: "64e32bb1fd878f27f5acae5f",
  url: "/online/special/codingtest",
  thumbnail: "codingtest",
  title: "1시간 만에 정복하는\n코딩 테스트 합격법",
  desc: "출제 트렌드를 파악하고, 나만의 알고리즘 학습 로드맵까지 완성합니다.",
  category: ["Best", "인기 급상승"],
  tags: ["chatGPT"],
  lang: ["HTML · CSS", "Python", "ChatGPT"],

  isFree: true,
  price: "0",
  originalPrice: "89,000원",
  discountRate: "0",
  amount: "1시간 분량",
};

export const Moonwish: FreeCourse = {
  id: "64efe93e3c4f28c927faf0bf",
  url: "/online/monthly/moonwish",
  thumbnail: "moonwish",
  title: "코딩으로 보름달에 소원 보내기",
  desc: "ChatGPT의 도움을 받아 소원 보내는 웹사이트를 만들며 쉽고 재밌게 코딩을 익힙니다.",
  category: ["Best", "인기 급상승"],
  tags: ["chatGPT"],
  lang: ["HTML · CSS", "Python", "ChatGPT"],

  isFree: true,
  price: "0",
  originalPrice: "8,900원",
  discountRate: "0",
  amount: "1시간 분량",
};

export const AiNews: FreeCourse = {
  id: "6504029282a304dd31edfb7f",
  url: "/online/monthly/ai_news",
  thumbnail: "ai_news",
  title: "코딩으로 나만의 특종 신문 만들기",
  desc: "코딩과 ChatGPT를 활용해 내 꿈을 담은 특종 신문을 만들어보세요.",
  category: ["Best", "인기 급상승"],
  tags: ["chatGPT"],
  lang: [],

  isFree: true,
  price: "0",
  originalPrice: "8,900원",
  discountRate: "0",
  amount: "1시간 분량",
};

export const GptAutomation: SpecialCourse = {
  id: "6426a044e09a454370af1d3c",
  url: "/online/chatgpt_automation",
  thumbnail: "gpt_automation",
  title: "21세기 일잘러! ChatGPT \n활용한 업무자동화",
  desc: "“Hey GPT, 나 대신 일해줘” ChatGPT를 가장 현명하게 쓰는 법!",
  category: ["Best", "인기 급상승"],
  tags: ["chatGPT"],
  lang: ["Python", "ChatGPT"],

  price: "129,000원",
  originalPrice: "200,000원",
  discountRate: "36%",
  amount: "2주 과정",
  isPaidGpt: true,
};
export const GptStock: SpecialCourse = {
  id: "6426a0fe8a9def2d67678479",
  url: "/online/chatgpt_data",
  thumbnail: "gpt_stock",
  title: "주식 초보 탈출! \nChatGPT를 활용한 주식 데이터 꿀팁",
  desc: "요즘 핫한 ChatGPT로 주식을 입문하고 수익화 꿀팁까지 배울 수 있어요!",
  category: ["Best", "인기 급상승", "데이터 분석"],
  tags: [""],
  lang: [""],

  price: "129,000원",
  originalPrice: "200,000원",
  discountRate: "36%",
  amount: "2주 과정",
  isPaidGpt: true,
};
export const ProjectManaging: FundingCourse = {
  id: "",
  url: "/online/funding/6437aedd6d88f11f04a685a1",
  thumbnail: "project_managing",
  title: "현직 PO에게 배우는 \n프로덕트 매니징의 모든 것",
  desc: '"데이터? UX? 어떤 것부터 시작해야 하지?" 방황하는 초보 PM을 위한 단 하나의 실전 지침서!',
  category: ["강의 펀딩"],
  tags: [""],
  lang: [""],
  price: "150,000원",
  originalPrice: "500,000원",
  discountRate: "70%",
  amount: "4주 과정",
  fundingEndDate: "2023-04-27",
  courseId: "6437aedd6d88f11f04a685a1",
  fundingStatus: "funding",
};

export const ChatGptMyService: FundingCourse = {
  id: "",
  url: "/online/funding/6437afa9915a0b020aff8686",
  thumbnail: "chat_gpt_my_service",
  title: "ChatGPT API로 \n나만의 AI 서비스 만들기",
  desc: "ChatGPT로 세상에 없던 나만의 AI 서비스를 만들어 발빠르게 수익화에 도전해보세요!",
  category: ["강의 펀딩"],
  tags: [""],
  lang: [""],
  price: "80,000원",
  originalPrice: "200,000원",
  discountRate: "60%",
  amount: "2주 과정",
  fundingEndDate: "2023-04-27",
  courseId: "6437afa9915a0b020aff8686",
  fundingStatus: "funding",
};

export const WebCrawlingMarket: FundingCourse = {
  id: "",
  url: "/online/funding/6437af48915a0b020aff85af",
  thumbnail: "web_crawling_market",
  title: "마케터/MD 필수! 웹크롤링으로 시장 조사 끝내기",
  desc: "웹 크롤링부터 키워드 분석까지! 잘 팔리는 제품을 위한 인사이트를 빠르게 뽑아보세요.",
  category: ["강의 펀딩"],
  tags: [""],
  lang: [""],
  price: "80,000원",
  originalPrice: "200,000원",
  discountRate: "60%",
  amount: "2주 과정",
  fundingEndDate: "2023-04-27",
  courseId: "6437af48915a0b020aff85af",
  fundingStatus: "funding",
};

// 패키지 강의

export const packageSql: Package = {
  id: "643e10c8ae797ba22b4a8c22",
  url: "https://sparta-package-sql.oopy.io/",
  thumbnail: "packageSql",
  title: "SQL+SQLD \n데이터 완전 정복",
  desc: "필수 역량이 된 SQL, 실무 스킬부터 자격증 준비까지 패키지로 한 번에!",
  category: ["전체", "데이터 분석", "인기 급상승"],
  tags: ["SQL", "SQLD", "자격증", "데이터"],
  lang: ["SQL"],
  price: "480,000원",
  originalPrice: "650,000원",
  discountRate: "31%",
  amount: "5주 과정",
  isPackage: true,
  event: {
    title: "패키지 할인 특가",
    name: "패키지 할인 기획전",
    promotion: 31,
    discounted_price: 480000,
  },
};

export const packageChatGPT: Package = {
  id: "643e10c8ae797ba22b4a8c2b",
  url: "https://sparta-package-chatgpt.oopy.io/",
  thumbnail: "packageChatGpt",
  title: "ChatGPT 300% \n활용 모음집",
  desc: "클론코딩부터 업무자동화, 주식까지! ChatGPT 꿀팁 3종을 만나보세요.",
  category: ["전체", "인기 급상승"],
  tags: ["ChatGPT", "클론코딩", "업무 자동화", "주식", "데이터"],
  lang: ["HTML · CSS", "Python", "ChatGPT"],
  price: "199,000원",
  originalPrice: "600,000원",
  discountRate: "70%",
  amount: "5주 과정",
  isPackage: true,
  event: {
    title: "패키지 할인 특가",
    name: "패키지 할인 특가",
    promotion: 67,
    discounted_price: 199000,
  },
};

export const packageNocode: Package = {
  id: "643e10c8ae797ba22b4a8c36",
  url: "https://sparta-package-nocode.oopy.io/",
  thumbnail: "packageNocode",
  title: "개발 걱정 NO! \n노코드 올인원",
  desc: "노코드 툴로 간단한 웹사이트부터 고퀄리티 서비스까지 자유롭게 구현해요.",
  category: ["전체", "노코드", "패키지"],
  tags: ["webflow", "zapier", "airtable", "GA4", "노션", "우피", "재피어"],
  lang: ["No-code"],
  price: "550,000원",
  originalPrice: "1,000,000원",
  discountRate: "45%",
  amount: "5주 과정",
  isPackage: true,
  event: {
    title: "패키지 할인 특가",
    name: "패키지 할인 특가",
    promotion: 45,
    discounted_price: 550000,
  },
};

export const packageWebApp: Package = {
  id: "643e10c8ae797ba22b4a8c3f",
  url: "https://sparta-package-maker.oopy.io/",
  thumbnail: "packageWebApp",
  title: "웹 + 앱 \n코딩 메이커",
  desc: "웹, 앱개발 스킬을 갖춰 나만의 아이디어를 코딩으로 구현해보세요.",
  category: ["전체", "코딩 입문"],
  tags: ["파이썬", "풀스택", "구글 애드몹", "웹사이트", "왕초보"],
  lang: ["HTML · CSS", "Python", "Javascript", "Flutter"],
  price: "650,000원",
  originalPrice: "1,000,000원",
  discountRate: "35%",
  amount: "5주 과정",
  isPackage: true,
  event: {
    title: "패키지 할인 특가",
    name: "패키지 할인 특가",
    promotion: 35,
    discounted_price: 650000,
  },
};

export const packageNocodeGa4: Package = {
  id: "649e631c89ddea656ee54fa0",
  url: "https://sparta-package-ga4.oopy.io/",
  thumbnail: "packageNocodeGa4",
  title: "노코드로 웹사이트 \n만들고 분석하기",
  desc: "웹, 앱개발 스킬을 갖춰 나만의 아이디어를 코딩으로 구현해보세요.",
  category: ["전체"],
  tags: ["패키지", "GA", "GA4", "노코드", "웹사이트", "분석", "구글애널리틱스"],
  lang: [],
  price: "390,000원",
  originalPrice: "650,000원",
  discountRate: "40%",
  amount: "5주 과정",
  isPackage: true,
  event: {
    title: "패키지 할인 특가",
    name: "패키지 할인 특가",
    promotion: 40,
    discounted_price: 260000,
  },
};

export const ChatGptWeb: ComingSoonCourse = {
  isComingSoon: true,
  thumbnail: "chatgptWeb",
  openDate: "7월 19일",
  title: "[GPT] 웹개발 종합반",
  desc: "ChatGPT 검색 전략과 재미있고 다양한 실습으로 웹개발 기초를 탄탄하게 다져보세요.",
  category: ["공개 예정"],
};

import { useEffect, useState } from "react";

export const getCookie = (name: string) => {
  const cookieString = `; ${document.cookie}`;
  const parts = cookieString.split(`; ${name}=`);
  if (parts.length === 2) {
    return decodeURIComponent(parts.pop().split(";").shift());
  } else return "";
};

export const setCookie = (name, value, days = null) => {
  let expires = "";
  const domain = document.location.origin.includes(".spartacodingclub.kr")
    ? ".spartacodingclub.kr"
    : document.location.hostname;
  const sameSite = document.location.origin.includes(".spartacodingclub.kr")
    ? "; SameSite=None; Secure"
    : "";
  let date = new Date();
  date.setTime(
    days ? date.getTime() + days * 24 * 60 * 60 * 1000 : date.getTime(),
  );
  expires = `; domain=${domain}; expires=${date.toUTCString()}${sameSite}`;
  document.cookie =
    name + "=" + encodeURIComponent(value || "") + expires + "; path=/";
};

export const resetCookieByLength = (name: string, length: number) => {
  getCookie(name) && getCookie(name).length > length && setCookie(name, "", 0);
};

export const useCookie = (key: string): string => {
  const [value, setValue] = useState(undefined);
  const cookies =
    typeof document !== "undefined" ? document?.cookie : undefined;

  useEffect(() => {
    if (cookies) {
      setValue(getCookie(key));
    }
  }, [cookies]);
  return value;
};

export const updateCookie = (key: string, value?: string | string[]) => {
  if (value) {
    setCookie(key, value.toString(), value ? 1 : null);
  }
};
export const updateCookies = (
  queries: { key: string; value?: string | string[] }[],
) => {
  queries.map((query) => updateCookie(query.key, query.value));
};

export const useUpdateCookie = (key: string, value?: string | string[]) => {
  useEffect(() => {
    updateCookie(key, value);
  }, [value]);
};

export const useCleanKoreanCookie = () => {
  function isKorean(str) {
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);

      if (
        (charCode >= 0x3131 && charCode <= 0x318e) || // Hangul Compatibility Jamo
        (charCode >= 0xac00 && charCode <= 0xd7a3) || // Hangul Syllables
        (charCode >= 0x1100 && charCode <= 0x11ff) || // Hangul Jamo
        (charCode >= 0xa960 && charCode <= 0xa97f) || // Hangul Jamo Extended-A
        (charCode >= 0xd7b0 && charCode <= 0xd7ff) // Hangul Jamo Extended-B
      ) {
        return true;
      }
    }

    return false;
  }
  useEffect(() => {
    let utm_source = getCookie("utm_source");
    let utm_medium = getCookie("utm_medium");
    let utm_campaign = getCookie("utm_campaign");
    let utm_content = getCookie("utm_content");
    let utm_term = getCookie("utm_term");
    let utm = {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
    };
    for (let key in utm) {
      if (utm[key] && isKorean(utm[key])) {
        setCookie(key, encodeURIComponent(utm[key]));
      }
    }
  }, []);
};

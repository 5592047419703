import styled from "@emotion/styled";
import { fontPretendard } from "../../../../styles/fonts";
import { device, DisplayResolution } from "../../../../styles/themes/device";
import { maxWidth } from "../../../../styles/variables";
import themes from "../../../../styles/themes";
import {
  mCaption2,
  neutralDay,
  scc,
  wBody1,
  wBody4,
  wCaption1,
} from "@teamsparta/design-system";

export const Footer = styled.footer`
  width: 100%;
  background: ${neutralDay.white};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-width: ${maxWidth};
  border-top: 1px solid ${neutralDay.gray20};

  * {
    font-family: ${fontPretendard};
    line-height: 1.5;
  }
`;
export const FooterContentWrap = styled.footer`
  flex-grow: 1;
  max-width: 1200px;
  width: 100%;
  margin: 40px auto 100px;
  padding: 0 24px;
  ${DisplayResolution.MobileAndMiniTablet} {
    margin: 16px auto 100px;
    padding: 0 16px;
  }
  ${DisplayResolution.Tablet} {
    margin: 40px auto 100px;
  }
  ${DisplayResolution.MiniDesktopAndDesktop} {
    margin: 32px auto 100px;
  }
`;
export const GridContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${DisplayResolution.Tablet} {
    display: grid;

    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    & > :first-child {
      grid-row: 1 / span 1;
      grid-column: 1 / span 4;
    }

    & > :nth-child(2) {
      grid-row: 2;
      grid-column: 1;
    }

    & > :nth-child(3) {
      grid-row: 2;
      grid-column: 2;
    }

    & > :nth-child(4) {
      grid-row: 2;
      grid-column: 3;
    }

    & > :nth-child(5) {
      grid-row: 2;
      grid-column: 4;
    }
  }
  ${DisplayResolution.MiniDesktopAndDesktop} {
    display: grid;
    gap: 24px;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }
`;

export const GridItem = styled.div<{ isOpen?; isLastItem? }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${DisplayResolution.MobileAndMiniTablet} {
    display: flex;
    padding: 16px 0;
    justify-content: flex-start;

    ${({ isOpen, isLastItem }) =>
      !isOpen || !isLastItem
        ? `border-bottom: 1px solid ${neutralDay.gray20};`
        : ""}
  }
`;

export const FooterTitle = styled.div`
  ${wBody1};
  color: ${neutralDay.gray80};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
`;

export const Spacer10 = styled.div`
  height: 12px; // 원하는 간격 값을 조절합니다.
`;
export const Spacer6 = styled.div`
  width: 6px; // 원하는 간격 값을 조절합니다.
`;
export const FooterContentContainer = styled.div<{ isCs? }>`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  gap: 6px;
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: ${({ isCs }) => (isCs ? "12px 4px" : "16px 12px")};
    margin: 0;
  }
`;

export const FooterCS = styled.button`
  ${wBody4}
  color:${neutralDay.gray80};
  font-weight: 500;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
  gap: 5px;
`;

export const FooterContent = styled.div`
  ${wCaption1};
  color: ${neutralDay.gray70};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  ${DisplayResolution.MobileAndMiniTablet} {
    flex-direction: row;
    gap: 6px;
  }
`;

export const PointerArea = styled.span`
  padding: 4px;
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

export const Tag = styled.div`
  display: flex;
  padding: 1px 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: ${scc.red15};
  color: ${scc.red75};
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 15px */
`;

export const FMenu = styled.section`
  width: 100%;

  @media ${device.desktop} {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
    align-items: center;
  }
`;
export const FooterNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.desktop} {
    &:nth-of-type(2) {
      margin-left: 65px;
    }
  }
`;

export const FooterLabel = styled.p`
  color: ${themes.colors.white};
  font-size: 16px;
  font-weight: bold;
  @media ${device.desktop} {
    font-size: 18px;
  }
`;

export const FooterNav = styled.ul`
  padding: 0;
  font-size: 13px;
  color: #d4d4d4;
  display: flex;
  margin-bottom: 24px;
  flex-wrap: wrap;

  @media ${device.desktop} {
    font-size: 14px;
    height: inherit;
    align-items: center;
    margin: 0;
  }
`;

export const FooterNavItemWrapper = styled.li`
  padding: 0;
  margin-right: 24px;
  margin-top: 16px;
  list-style: none;
  white-space: nowrap;
  @media ${device.desktop} {
  }
`;

export const FooterNavItem = styled.a`
  ${wCaption1};
  color: ${neutralDay.gray70};
  width: 100%;
  white-space: nowrap;
`;

export const FooterNavItemBold = styled.a`
  color: ${neutralDay.gray80};
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 19.5px */
  white-space: nowrap;
`;

export const FooterSNSWrapper = styled.div<{ isMobile? }>`
  width: 100%;
  gap: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${DisplayResolution.TabletAndDesktop} {
  }
  ${DisplayResolution.MobileAndMiniTablet} {
    justify-content: space-between;
    width: 100%;

    height: 32px;
  }
`;

export const FooterSNS = styled.div`
  white-space: nowrap;
`;
interface BackgroundImg {
  backgroundImg: string;
}

export const FooterSNSItem = styled.a`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-size: 100% 100%;
  text-indent: -9999px;
`;

//SignatureContainer
export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const FooterSignature = styled.img`
  align-items: center;
  width: 36px;
  height: 36px;
`;

export const SignatureText = styled.div`
  ${wCaption1};
  color: ${neutralDay.gray60};
  width: 100%;
  white-space: nowrap;
  ${DisplayResolution.MobileAndMiniTablet} {
    font-size: 12px;
  }
`;

export const FooterLine = styled.div`
  height: 1px;
  align-self: stretch;
  width: 100%;
  background: ${neutralDay.gray20};
  margin: 24px 0;
  ${DisplayResolution.MobileAndMiniTablet} {
    height: 0;
  }
`;

export const Contacts = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${DisplayResolution.MobileAndMiniTablet} {
    gap: 24px;
  }
`;

export const ContactsLine = styled.ul`
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  color: #d4d4d4;
  margin-bottom: 12px;
  ${DisplayResolution.MobileAndMiniTablet} {
    flex-direction: column;
    gap: 24px;
  }
  ${DisplayResolution.TabletAndDesktop} {
    margin-bottom: 0;
    font-size: 14px;
  }
`;

export const ContactsLineItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ContactsLineItem = styled.li`
  padding: 0;
  margin-right: 24px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-size: 12px;
  }
`;

export const CopyRight = styled.p`
  ${mCaption2};
  font-size: 12px;
  color: ${neutralDay.gray60};
  padding-top: 16px;

  @media ${device.desktop} {
    text-align: right;
  }
`;

export const BottomSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const RightChevronSVG = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.66565L6.33333 6.99898L1 12.3323"
      stroke="#141617"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HamburgerSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33331 5H16.6666"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M3.33331 10H16.6666"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M3.33331 15H16.6666"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
  </svg>
);

export const CloseBtnSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16669 4.16669L15.8334 15.8334"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M15.8333 4.16669L4.16665 15.8334"
      stroke="#141617"
      strokeWidth="1.75"
      strokeLinecap="round"
    />
  </svg>
);

export const HomeSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.82905 1.84571L1.82902 6.90126C1.3084 7.27727 1 7.88042 1 8.52262V16.5C1 17.6046 1.89543 18.5 3 18.5H3.85714H7C7.55229 18.5 8 18.0523 8 17.5V13C8 12.4477 8.44772 12 9 12H11C11.5523 12 12 12.4477 12 13V17.5C12 18.0523 12.4477 18.5 13 18.5H15.1429H17C18.1046 18.5 19 17.6046 19 16.5V8.52262C19 7.88041 18.6916 7.27727 18.171 6.90126L11.171 1.84571C10.472 1.34085 9.52809 1.34085 8.82905 1.84571Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const MessageSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V15C21 16.1046 20.1046 17 19 17H15.0542C14.7095 17 14.389 17.1776 14.2062 17.47L12 21L9.79375 17.47C9.61101 17.1776 9.29054 17 8.94575 17H5C3.89543 17 3 16.1046 3 15V6Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect x="7" y="9.5" width="2" height="2" rx="1" fill="currentColor" />
    <rect x="11" y="9.5" width="2" height="2" rx="1" fill="currentColor" />
    <rect x="15" y="9.5" width="2" height="2" rx="1" fill="currentColor" />
  </svg>
);

export const SpartaSVG = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_29_17210)">
      <path
        d="M11.4134 1.17725L2.58549 3.11263L1 8.30669L4.5866 12.4366L7.30266 11.8406L8.08967 14.8228L11.6726 10.985L13.4145 10.5033L15 5.30716L11.4134 1.17725Z"
        fill="#B4BFC6"
      />
      <path
        d="M11.2186 5.43018V5.9168H11.683V6.40342H12.1473V6.89004H12.6113V7.37666H12.1473V7.86328H11.683V8.34991H11.2191V8.83653H10.2908V8.34991H10.7547V7.86328H11.2186V7.37666H11.683V6.89004H11.2191V6.40301H10.7551V5.91639H10.2912V5.43018H11.2186Z"
        fill="white"
      />
      <path
        d="M9.69776 5.51611H8.88822V5.98587H8.43084V6.46344H7.99802V6.93279H7.54063V7.41077H7.10741V7.88012H6.65002V8.3581H6.2168V8.83567H7.02674V8.3581H7.45956V7.88834H7.91694V7.41077H8.34976V6.94143H8.80755V6.46344H9.24037V5.9941H9.69776V5.51611Z"
        fill="white"
      />
      <path
        d="M4.69646 8.87867V8.39205H4.23212V7.90543H3.76777V7.41881H3.30383V6.93219H3.76777V6.44557H4.23212V5.95894H4.69605V5.47314H5.62434V5.95977H5.1604V6.44639H4.69646V6.93301H4.23212V7.41963H4.69605V7.90625H5.15999V8.39288H5.62393V8.8795H4.69646V8.87867Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_29_17210">
        <rect width="14" height="14" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
);

export const QuestionMarkSVG = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.33337" cy="8" r="6" stroke="#B4BFC6" strokeWidth="1.5" />
    <path
      d="M6.68652 6.56885C6.68652 6.23551 6.88652 5.50281 7.68652 5.23614C8.68652 4.90281 9.78564 5.39923 9.95319 6.23617C10.0865 6.90218 9.68652 7.23551 9.35319 7.56936C9.02011 7.90295 8.33236 8.23551 8.33236 8.90218"
      stroke="#B4BFC6"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="7.66772"
      y="10.1655"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#B4BFC6"
    />
  </svg>
);

export const GoToLinkSVG = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6666 9.83333V12.1667C12.6666 12.8083 12.1416 13.3333 11.5 13.3333H4.49998C3.85831 13.3333 3.33331 12.8083 3.33331 12.1667V5.16667C3.33331 4.525 3.85831 4 4.49998 4H6.83331"
      stroke="#B4BFC6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.99994 2.6665H13.9999V6.6665"
      stroke="#B4BFC6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 2.6665L8.66663 7.99984"
      stroke="#B4BFC6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

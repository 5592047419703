export const getRandomRtanProfile = () => {
  const randomNum = Math.floor(Math.random() * 35) + 1;
  return `${process.env.STATIC_PATH}/assets/images/profile/${randomNum}.png`;
};

export const getHideMiddleName = (name) => {
  if (name.length < 3) return name; // 이름 길이가 3 미만일 경우 그대로 반환
  const middle = Math.floor(name.length / 2); // 가운데 인덱스 계산
  return (
    name.substring(0, middle) +
    "*" +
    name.substring(middle + 1, middle + 3) +
    name.substring(middle + 3)
  );
};

export const convertAndCalculateDistribution = (scores) => {
  const convertedScores = scores.map((score) => Math.ceil(score / 2));

  const counts = [0, 0, 0, 0, 0];
  for (let i = 0; i < convertedScores.length; i++) {
    counts[convertedScores[i] - 1]++;
  }
  counts.reverse(); // 5점부터 시작하도록
  const distribution = counts.map((count) =>
    Math.ceil((count / scores.length) * 100),
  );

  return { distribution, counts };
};

export const roundUpToFirstDecimal = (num) => {
  const powerOfTen = Math.pow(10, 1);
  return Math.ceil(num * powerOfTen) / powerOfTen;
};

export const isOldWebEnded = () => {
  return new Date() >= new Date("2023-07-31T00:00:00+09:00");
};

export const isB2GSqlEnded = () => new Date() >= new Date(2023, 6, 31, 22);

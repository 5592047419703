import * as S from "./Gnb.desktop.style";
import {
  mainAnchor,
  sccAnchor,
  topAnchors,
  topRightAnchors,
} from "../models/gnbV3.models";
import { useClickAnchor } from "../../../../businesslogics/_layout/gnb/v2";
import { GnbMyPage } from "../molecules/GnbMyPage";
import { GnbMyPageLogo } from "../../../atoms/_layout/gnb/v2/GnbMyPageLogo";
import { useAtom } from "jotai";
import { showLineBannerAtom } from "../../../../stores/showLineBannerAtom";
import { LineBanner } from "../../../organisms/_layout/LineBanner/LineBanner";
import {
  GnbAnchorDesktop,
  GnbTopAnchorDesktop,
} from "../atoms/GnbAnchor.desktop";
import { BizDropdown } from "../molecules/GnbDropDown";
import React, { useEffect, useRef, useState } from "react";
import { LoginButton } from "../molecules/LoginButton";
import { useIsLoggedInV2 } from "../../../../businesslogics/_common/login";
import { RemoteSpartaLogo } from "src/components/atoms/Icons/SpartaLogo/RemoteSpartaLogo";

const pagesForNotFixedGnb = [
  "catalog",
  "nb",
  "online",
  "blog",
  "coding1010",
  "sparthon4",
  "online-preview",
  "exhibition",
];

export const GnbDesktopV3 = () => {
  const isLogin = useIsLoggedInV2();
  const onClickAnchor = useClickAnchor(sccAnchor);
  const [showTopWrapper, setShowTopWrapper] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [showLineBanner] = useAtom(showLineBannerAtom);
  const path = window.location.pathname;
  const textAfterSlash = path.split("/")[1];

  const DROPDOWN_COMPONENTS = {
    bisness: BizDropdown,
  };

  const [dropdownOpen, setDropdownOpen] = useState(null); // 현재 열린 드롭다운을 관리
  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const isCodingland = window.location.pathname.includes("codingland");

    if (isCodingland) {
      if (currentScrollY < lastScrollY) {
        setShowTopWrapper(true);
      } else {
        setShowTopWrapper(false);
      }
    } else {
      setShowTopWrapper(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const toggleDropdown = (key, e) => {
    if (dropdownOpen === key) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(key);
    }
    e.stopPropagation();
  };

  return (
    <>
      <S.TopWrapper
        ref={dropdownRef}
        fixed={!pagesForNotFixedGnb.includes(textAfterSlash)}
        show={showTopWrapper}
        showLineBanner={showLineBanner}
        onClick={() => dropdownOpen !== null && setDropdownOpen(null)}
      >
        <S.InnerContainer>
          <S.ColumnContainer>
            {topAnchors.map((anchor, index) => {
              return (
                <React.Fragment key={`gnb-desktop-category${anchor.key}`}>
                  <S.CursorWrapper>
                    <GnbTopAnchorDesktop
                      key={`gnb-desktop-category${anchor.key}`}
                      anchor={anchor}
                      isCategory={true}
                      isDropdownOpen={dropdownOpen === anchor.key}
                      onClickDropdown={(e) => toggleDropdown(anchor.key, e)}
                    />
                  </S.CursorWrapper>
                  {index !== topAnchors.length - 1 && <S.Divider />}
                </React.Fragment>
              );
            })}
          </S.ColumnContainer>
          <S.ColumnContainer>
            {topRightAnchors.map((anchor, index) => {
              const DropdownComponent = DROPDOWN_COMPONENTS[anchor.key];
              return (
                <React.Fragment key={`gnb-desktop-category${anchor.key}`}>
                  <S.CursorWrapper key={`gnb-desktop-category${anchor.key}`}>
                    <GnbTopAnchorDesktop
                      key={`gnb-desktop-category${anchor.key}`}
                      anchor={anchor}
                      isCategory={true}
                      isDropdownOpen={dropdownOpen === anchor.key}
                      onClickDropdown={(e: any) =>
                        toggleDropdown(anchor.key, e)
                      }
                    />
                  </S.CursorWrapper>
                  {index !== topRightAnchors.length - 1 && <S.Divider />}
                  {dropdownOpen === anchor.key && <DropdownComponent />}
                </React.Fragment>
              );
            })}
          </S.ColumnContainer>
        </S.InnerContainer>
      </S.TopWrapper>
      <S.MainWrapper
        fixed={!pagesForNotFixedGnb.includes(textAfterSlash)}
        show={showTopWrapper}
        showLineBanner={showLineBanner}
        onClick={() => dropdownOpen !== null && setDropdownOpen(null)}
      >
        <S.InnerContainerBottom>
          <S.ColumnContainer>
            <S.LogoWrapper onClick={onClickAnchor}>
              <RemoteSpartaLogo />
            </S.LogoWrapper>
            {mainAnchor.map((anchor) => (
              <GnbAnchorDesktop
                key={`gnb-desktop-category${anchor.key}`}
                anchor={anchor}
                isCategory={true}
              />
            ))}
          </S.ColumnContainer>
          <S.ColumnContainer>
            {isLogin ? (
              <>
                <GnbMyPageLogo />
                <GnbMyPage />
              </>
            ) : (
              <LoginButton />
            )}
          </S.ColumnContainer>
        </S.InnerContainerBottom>
        <LineBanner />
      </S.MainWrapper>
    </>
  );
};

import {
  NbApp,
  NbData,
  NbPython,
  NbSQL,
} from "src/models/catalog/courses.model";
import { NbGame } from "../../catalog/courses.model";
import { isB2GSqlEnded } from "../../../businesslogics/online";

export const nbCourseId = {
  web: "60a5ee42bf272bef32a58fb9",
  app: "63ca26845b3bac30b29bf2a3",
  sql: "616d4349460cf1cf6f9579ec",
  data_v2: "63ca5b43dfbe98f4c8ef20bf",
  game: "62f09eebfeb2d564bacb0262",
  python: "62f09f2af3ce1804901b3f5c",
};

export const getHrdCourseInfo = (url) => {
  switch (url) {
    case "web":
      return {
        tracseId: "웹개발 종합반",
        tracseTime: "A000000004",
      };
    case "app":
      return {
        name: "앱개발 종합반",
        id: "A000000005",
      };
    case "sql":
      return {
        name: "SQL 자격증",
        id: "A000000006",
      };
    case "data_v2":
      return {
        name: "데이터분석 종합반",
        id: "A000000007",
      };
    case "python":
      return {
        name: "파이썬 자격증",
        id: "A000000008",
      };
    case "game":
      return {
        name: "게임개발 종합반",
        id: "A000000009",
      };
  }
};

export const getCoursePrice = (url: string) => {
  switch (url) {
    case "app":
      return {
        price: 490000,
        discountPrice: 49000,
      };
    case "sql":
      return {
        price: 410000,
        discountPrice: 41000,
      };
    case "data_v2":
      return {
        price: 490000,
        discountPrice: 49000,
      };
    case "python":
      return {
        price: 490000,
        discountPrice: 49000,
      };
    case "game":
      return {
        price: 490000,
        discountPrice: 49000,
      };
  }
};

export const getIntroTitle = (url: string) => {
  switch (url) {
    case "app":
      return "내가 가진 아이디어를 앱으로 구현하고\n든든한 부수입을 만들어 보세요.";
    case "sql":
      return "데이터 기반으로 의사 결정을 내리는\n업무 효율형 인재로 거듭나세요.";
    case "data_v2":
      return "이론이 아닌 실전에 강한\n데이터 분석 인재로 거듭나세요";
    case "python":
      return "파이썬 문법을 자유롭게 사용해\n나만의 업무 자동화 프로그램을 만드세요";
    case "game":
      return "내가 좋아하는 게임으로\n부수입을 창출해보세요";
  }
};

export const getIntroDesktopImage = (url: string) => {
  switch (url) {
    case "game":
      return [
        "/assets/images/nbMinimal/slider/game_1.png",
        "/assets/images/nbMinimal/slider/game_2.png",
        "/assets/images/nbMinimal/slider/game_3.png",
        "/assets/images/nbMinimal/slider/game_4.png",
      ];
  }
};

export const getSingleIntro = (url) => {
  switch (url) {
    case "app":
      return {
        title: getIntroTitle(url),

        mobileImgSrc: "/assets/images/nbMinimal/slider/app_mo_1.png",
        desktopImgSrc: "/assets/images/nbMinimal/slider/app_1.png",
      };
    case "sql":
      return {
        title: getIntroTitle(url),

        mobileImgSrc: "/assets/images/nbMinimal/slider/sql_mo_1.png",
        desktopImgSrc: "/assets/images/nbMinimal/slider/sql_1.png",
      };
    case "data_v2":
      return {
        title: getIntroTitle(url),

        mobileImgSrc: "/assets/images/nbMinimal/slider/data_v2_mo_1.png",
        desktopImgSrc: "/assets/images/nbMinimal/slider/data_v2_1.png",
      };
    case "python":
      return {
        title: getIntroTitle(url),

        mobileImgSrc: "/assets/images/nbMinimal/slider/python_mo_1.png",
        desktopImgSrc: "/assets/images/nbMinimal/slider/python_1.png",
      };
  }
};

export const getIntroMobileImage = (url: string) => {
  switch (url) {
    case "game":
      return [
        "/assets/images/nbMinimal/slider/game_mo_1.png",
        "/assets/images/nbMinimal/slider/game_mo_2.png",
        "/assets/images/nbMinimal/slider/game_mo_3.png",
        "/assets/images/nbMinimal/slider/game_mo_4.png",
      ];
  }
};

export const getMetaData = (url: string) => {
  switch (url) {
    case "app":
      return {
        title: "내일배움단 | [왕초보] 플러터(Flutter)로 시작하는 앱개발 종합반",
        description:
          "코딩교육 1위 스파르타코딩클럽의 인기 강의, 내일배움단에서 국비지원으로 수강해보세요!",
        ogTitle:
          "내일배움단 | [왕초보] 플러터(Flutter)로 시작하는 앱개발 종합반",
        ogDescription:
          "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요. 내일배움카드가 있다면 국비지원으로 90%의 수강료를 지원 받을 수 있습니다.",
        ogImage: "/assets/images/og_images/nb/5f0ae408765dae0006002817.png",
        ogSiteName: "스파르타 내일배움단",
        keywords:
          "내일배움단, 국비지원, 스파르타코딩클럽, 스파르타 내일배움단, 스파르타 내일배움카드, 코딩교육, 코딩, 프로그래밍, 코딩강의, 코딩공부, 내일배움카드, 데이터, SQL, 파이썬, 웹개발, 앱개발, 취준, 취준생, 개발자 취업, 개발자 연봉, 개발자, 평생교육, 국민취업지원, 자퇴, 실업, 보조금24",
        ogUrl: `${process.env.BASE_URL}/nb/app`,
        ogType: "website",
      };
    case "sql":
      return {
        title: "내일배움단 | [왕초보] 엑셀보다 쉬운 SQL",
        description:
          "코딩교육 1위 스파르타코딩클럽의 인기 강의, 내일배움단에서 국비지원으로 수강해보세요!",
        ogTitle: "내일배움단 | [왕초보] 엑셀보다 쉬운 SQL",
        ogDescription:
          "당장 내 업무에 필요한 데이터를 추출하고, 분석할 수 있는 힘을 길러 보세요. 내일배움카드가 있다면 국비지원으로 90%의 수강료를 지원 받을 수 있습니다.",
        ogImage: "/assets/images/og_images/nb/sql.png",
        ogSiteName: "스파르타 내일배움단",
        keywords:
          "내일배움단, 국비지원, 스파르타코딩클럽, 스파르타 내일배움단, 스파르타 내일배움카드, 코딩교육, 코딩, 프로그래밍, 코딩강의, 코딩공부, 내일배움카드, 데이터, SQL, 파이썬, 웹개발, 앱개발, 취준, 취준생, 개발자 취업, 개발자 연봉, 개발자, 평생교육, 국민취업지원, 자퇴, 실업, 보조금24",
        ogUrl: `${process.env.BASE_URL}/nb/sql`,
        ogType: "website",
      };
    case "data_v2":
      return {
        title: "내일배움단 | [왕초보] 마케터, 기획자를 위한 실전 데이터 분석",
        description:
          "코딩교육 1위 스파르타코딩클럽의 인기 강의, 내일배움단에서 국비지원으로 수강해보세요!",
        ogTitle: "내일배움단 | [왕초보] 마케터, 기획자를 위한 실전 데이터 분석",
        ogDescription:
          "취업에 필요한 데이터 분석 역량과 풍부한 실습 경험까지 한번에 얻어가세요. 내일배움카드가 있다면 국비지원으로 90%의 수강료를 지원 받을 수 있습니다.",
        ogImage: "/assets/images/og_images/nb/6083eb1f6305e019d3e0c483.png",
        ogSiteName: "스파르타 내일배움단",
        keywords:
          "내일배움단, 국비지원, 스파르타코딩클럽, 스파르타 내일배움단, 스파르타 내일배움카드, 코딩교육, 코딩, 프로그래밍, 코딩강의, 코딩공부, 내일배움카드, 데이터, SQL, 파이썬, 웹개발, 앱개발, 취준, 취준생, 개발자 취업, 개발자 연봉, 개발자, 평생교육, 국민취업지원, 자퇴, 실업, 보조금24",
        ogUrl: `${process.env.BASE_URL}/nb/data_v2`,
        ogType: "website",
      };
    case "python":
      return {
        title: "내일배움단 | [왕초보] 주식 데이터를 활용한 파이썬 데이터분석",
        description:
          "코딩교육 1위 스파르타코딩클럽의 인기 강의, 내일배움단에서 국비지원으로 수강해보세요!",
        ogTitle: "내일배움단 | [왕초보] 주식 데이터를 활용한 파이썬 데이터분석",
        ogDescription:
          "자유자재로 데이터를 조합하고 시각화해서 의사결정에 활용하세요. 내일배움카드가 있다면 국비지원으로 90%의 수강료를 지원 받을 수 있습니다.",
        ogImage: "/assets/images/og_images/nb/python.png",
        ogSiteName: "스파르타 내일배움단",
        keywords:
          "내일배움단, 국비지원, 스파르타코딩클럽, 스파르타 내일배움단, 스파르타 내일배움카드, 코딩교육, 코딩, 프로그래밍, 코딩강의, 코딩공부, 내일배움카드, 데이터, SQL, 파이썬, 웹개발, 앱개발, 취준, 취준생, 개발자 취업, 개발자 연봉, 개발자, 평생교육, 국민취업지원, 자퇴, 실업, 보조금24",
        ogUrl: `${process.env.BASE_URL}/nb/python`,
        ogType: "website",
      };
    case "game":
      return {
        title: "내일배움단 | [왕초보] 유니티로 만드는 게임개발 종합반",
        description:
          "코딩교육 1위 스파르타코딩클럽의 인기 강의, 내일배움단에서 국비지원으로 수강해보세요!",
        ogTitle: "내일배움단 | [왕초보] 유니티로 만드는 게임개발 종합반",
        ogDescription:
          "내 손으로 모바일 게임을 만들고 수익화까지 시작하세요. 내일배움카드가 있다면 국비지원으로 90%의 수강료를 지원 받을 수 있습니다.",
        ogImage: "/assets/images/og_images/nb/6083eaca6305e019d3e0c3b4.png",
        ogSiteName: "스파르타 내일배움단",
        keywords:
          "내일배움단, 국비지원, 스파르타코딩클럽, 스파르타 내일배움단, 스파르타 내일배움카드, 코딩교육, 코딩, 프로그래밍, 코딩강의, 코딩공부, 내일배움카드, 데이터, SQL, 파이썬, 웹개발, 앱개발, 취준, 취준생, 개발자 취업, 개발자 연봉, 개발자, 평생교육, 국민취업지원, 자퇴, 실업, 보조금24",
        ogUrl: `${process.env.BASE_URL}/nb/game`,
        ogType: "website",
      };
  }
};

export const getCourseSummary = (url) => {
  switch (url) {
    case "app":
      return {
        topTitle: "스파르타 국비지원",
        title: "[왕초보] 플러터(Flutter)로 시작하는 앱개발 종합반",
        subTitle:
          "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
        imgSrcDesktop:
          "/assets/images/nbMinimal/storycard_thumb/nb_app_desktop.png",
        imgSrc: "/assets/images/nbMinimal/storycard_thumb/nb_app.png",
      };
    case "sql":
      return {
        topTitle: "스파르타 국비지원",
        title: "[왕초보] 엑셀보다 쉬운 SQL",
        subTitle:
          "당장 내 업무에 필요한 데이터를 추출하고,\n분석할 수 있는 힘을 기릅니다.",
        imgSrcDesktop: `/assets/images/nbMinimal/storycard_thumb/nb_sql_desktop${
          isB2GSqlEnded() ? "_end" : ""
        }.png`,
        imgSrc: `/assets/images/nbMinimal/storycard_thumb/nb_sql${
          isB2GSqlEnded() ? "_end" : ""
        }.png`,
      };
    case "data_v2":
      return {
        topTitle: "스파르타 국비지원",
        title: "[왕초보] 마케터, 기획자를 위한 실전 데이터 분석",
        subTitle:
          "취업에 필요한 데이터 분석 역량과 풍부한 실습 경험까지\n한번에 얻어가세요.",
        imgSrcDesktop:
          "/assets/images/nbMinimal/storycard_thumb/nb_data_v2_desktop.png",
        imgSrc: "/assets/images/nbMinimal/storycard_thumb/nb_data_v2.png",
      };
    case "python":
    case "automation":
      return {
        topTitle: "스파르타 국비지원",
        title: "[왕초보] 주식 데이터를 활용한 파이썬 데이터분석",
        subTitle:
          "자유자재로 데이터를 조합하고 시각화해서\n의사결정에 활용하세요.",
        imgSrcDesktop:
          "/assets/images/nbMinimal/storycard_thumb/nb_python_desktop.png",
        imgSrc: "/assets/images/nbMinimal/storycard_thumb/nb_python.png",
      };
    case "game":
      return {
        topTitle: "스파르타 국비지원",
        title: "[왕초보] 유니티로 만드는 게임개발종합반",
        subTitle: "내 손으로 모바일 게임을 만들고 수익화까지 시작하세요.",
        imgSrcDesktop:
          "/assets/images/nbMinimal/storycard_thumb/nb_game_desktop.png",
        imgSrc: "/assets/images/nbMinimal/storycard_thumb/nb_game.png",
      };
  }
};

export const getSkillsData = (url) => {
  switch (url) {
    case "app":
      return [
        {
          title: "프로그래밍 언어 Dart 사용을 자유자재로",
          desc: "Flutter는 구글이 만든 프레임워크로, Dart라는 언어로 쉽게 앱개발을 할 수 있어요.",
        },
        {
          title: "iOS와 Android, 모두 플러터로 한 번에",
          desc: "ios앱과 android앱을 Flutter로 한 번에 만들고 앱스토어에 등록할 수 있어요.",
        },
        {
          title: "핵심 라이브러리를 자유자재로",
          desc: "라이브러리를 이용해 내 앱서비스에 다양한 콘텐츠를 채워넣을 수 있도록 활용해봅니다.",
        },
      ];
    case "sql":
      return [
        {
          title: "원하는 데이터를 자유자재로",
          desc: "엑셀로 작업하던 반복작업을 쿼리로 빠르게 보고 싶은 데이터를 추출해요.",
        },
        {
          title: "데이터에 기반하여 논리적으로",
          desc: "명확한 데이터에 기반해 논리적으로 자신의 의견을 제시할 수 있어요.",
        },
        {
          title: "팀원과의 소통을 자유롭게",
          desc: "필요한 데이터의 요청사항을 정확하게 전달할 수 있어요.",
        },
      ];
    case "data_v2":
      return [
        {
          title: "데이터 중심 사고방식",
          desc: "데이터로 생각하는 사고부터 쉽게 가르쳐 드려요. 데이터를 분석 가능한 소재로 바라보며 가설을 설계할 수 있어요.",
        },
        {
          title: "취업/이직에 피력할 수 있는 실습 포트폴리오",
          desc: "실제 직장에서 활용하는 업무 사례로 실습하며 실전에 강한 데이터 분석 경험을 쌓을 수 있어요.",
        },
        {
          title: "실무에 적용 가능한 데이터 분석 경험",
          desc: "데이터 분석 전체 주기를 반복적으로 경험하며, 다양한 난이도의 데이터 분석 상황에 익숙해질 수 있어요.",
        },
      ];
    case "python":
      return [
        {
          title: "파이썬 문법 이해도",
          desc: "어디서나 들리는 이름 파이썬, 이젠 내 포트폴리오에도 넣을 수 있어요.",
        },
        {
          title: "업무 자동화를 통한 효율성",
          desc: "보고서 작성은 물론, 메일까지 자동 전송할 수 있어요.",
        },
        {
          title: "실무에 바로 쓰는 데이터 분석 역량",
          desc: "엑셀에서는 분석하기 어려운 데이터, 실용적으로 분석할 수 있어요.",
        },
      ];
    case "game":
      return [
        {
          title: "나만의 게임으로 만드는 부수입",
          desc: "내가 좋아하는 일로 재미있게 부수입을 만들 수 있어요.",
        },
        {
          title: "게임 업계 진입에 필요한 경험",
          desc: "게임개발의 A to Z를 압축 경험하면서 전반적인 이해도를 쌓아요.",
        },
        {
          title: "유니티와 C#까지 확장되는 코딩 역량",
          desc: "이미 개발자라면, 코딩 스킬을 확장해 만능 개발자로 발돋움할 수 있어요.",
        },
      ];
  }
};

export const getCurriSummary = (url) => {
  switch (url) {
    case "app":
      return {
        techSpec: "Flutter, Dart, OpenAPI, Google, Admob",
        targetFunctions:
          "로그인 페이지\n" +
          "당근마켓/왓챠피디아 클론코딩 페이지\n" +
          "플랫폼 서비스의 좋아요 기능\n" +
          "실제 데이터 생성/수정/삭제 기능\n" +
          "개인 디바이스 앱 설치\n" +
          "구글 플레이스토어 앱 배포",
      };
    case "sql":
      return {
        techSpec: "SQL",
        targetFunctions:
          "특정 데이터만 가져오기\n" +
          "데이터 묶기, 정렬하기\n" +
          "데이터 테이블 연결하기\n" +
          "다양한 조건을 걸어 데이터 가져오기",
      };
    case "data_v2":
      return {
        techSpec: "Python",
        targetFunctions:
          "데이터 분석 가설 설계하기\n" +
          "분석용 데이터 전처리하기\n" +
          "데이터 시각화하기\n" +
          "데이터 통계량 추출하기",
      };
    case "python":
    case "automation":
      return {
        techSpec: "Python",
        targetFunctions:
          "분석용 데이터 수집하기\n" +
          "주식 데이터 분석하기\n" +
          "그래프 그리기\n" +
          "데이터 기반 전략 수립하기",
      };
    case "game":
      return {
        techSpec: "Unity, C#",
        targetFunctions:
          "유니티 기본 사용법\n" +
          "C# 기본 문법\n" +
          "게임에 부가 기능 넣기\n" +
          "게임에 광고 붙이기\n" +
          "게임 출시하기",
      };
  }
};

export const getCurriWeek = (url) => {
  switch (url) {
    case "sql":
      return [
        {
          weekTitle: "튜토리얼",
          weekSubTitle:
            "수강에 필요한 환경을 셋팅하고 학습 질문 이용 방법을 배웁니다.",
          details: [
            "수강환경 튜토리얼",
            "학습 질문 튜토리얼",
            "개발일지 튜토리얼",
          ],
        },
        {
          weekTitle: "Database 및 SQL 기초, Select/Where 문 연습",
          weekSubTitle:
            "SQL로 원하는 데이터를 조회하는 기본 구조를 이해합니다.",
          details: [
            "1주차 오늘 배울 것",
            "필수 프로그램 설치 안내",
            "SQL과 데이터베이스 살펴보기",
            "Select, Where 절 등 문법 연습해보기",
            "같이 삽질해보기",
            "퀴즈 풀어보기",
            "끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "통계 내고 정렬하기! Group by/Order by 연습",
          weekSubTitle:
            "원하는 형태로 편집하여 데이터를 조회하는 방법에 대해 배웁니다.",
          details: [
            "2주차 오늘 배울 것",
            "범주의 통계를 내주는 Group by",
            "Group by, Order by 사용해보기",
            "같이 삽질해보기",
            "Order by, Group by 같이 연습해보기",
            "이외 유용한 문법 배워보기",
            "끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "Left/Inner Join 및 Subquery 연습",
          weekSubTitle:
            "하나의 테이블이 아닌 여러 개의 테이블에서 원하는 데이터를 뽑아서 조회해 봅니다.",
          details: [
            "3주차 오늘 배울 것",
            "여러 테이블을 연결해보자: Join 이란?",
            "이제는 실전! 본격 쿼리 작성해보기",
            "이렇게 끝내면 아쉽죠? 한번 더 총복습!",
            "Left Join - 안써보니까 섭섭했죠?",
            "결과물 합치기! Union 배우기",
            "끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "실전 데이터분석을 위한 SQL 자체 함수 배우기",
          weekSubTitle:
            "서브쿼리로 더 간단하게 날 것의 데이터에서 원하는 데이터를 조회해 봅니다.",
          details: [
            "오늘 배울 것",
            "원하는 데이터를 더 쉽게: Subquery",
            "Subquery 연습해보기 (where, select, from, inner join)",
            "with절 연습하기",
            "실전에서 유용한 SQL 문법 (문자열, Case)",
            "SQL 문법 복습. 또 복습!",
            "끝 & 숙제 설명",
          ],
        },
      ];
    case "app":
      return [
        {
          weekTitle: "튜토리얼",
          weekSubTitle:
            "수강에 필요한 환경을 셋팅하고 학습 질문 이용 방법을 배웁니다.",
          details: [
            "수강환경 튜토리얼",
            "학습 질문 튜토리얼",
            "개발일지 튜토리얼",
          ],
        },
        {
          weekTitle: "Flutter 앱 개발 맛보기 & Dart 문법 익히기",
          weekSubTitle:
            "누구나 쉽게 배울 수 있는 Flutter 개발을 이해하고 Dart 문법을 배웁니다.",
          details: [
            "Intro",
            "why flutter",
            "설치하기",
            "flutter 이해하기",
            "프로젝트 준비",
            "로그인 페이지 만들기 1",
            "로그인 페이지 만들기 2",
            "flutter 공부 방법",
            "Dart 문법 1",
            "Dart 문법 2",
            "숙제 - Movie Reviews 만들기",
          ],
        },
        {
          weekTitle: "다양한 위젯을 활용해 화면 그리기",
          weekSubTitle:
            "잘 만들어진 템플릿인 위젯을 활용하여 당근마켓을 클론코딩 해봅니다.",
          details: [
            "flutter 위젯 이해하기",
            "프로젝트 준비",
            "당근마켓 화면 만들기 (1)",
            "당근마켓 화면 만들기 (2)",
            "파일 분리",
            "좋아요 & 피드 리스트 만들기",
            "다양한 피드 이미지 보여주기",
            "숙제 - Shazam 클론 코딩",
          ],
        },
        {
          weekTitle: "패키지 사용법 익히기 & 앱의 기능 만들기",
          weekSubTitle:
            "다른 사람이 만든 코드인 패키지 사용법과 데이터의 생성/수정/삭제 방법을 배웁니다.",
          details: [
            "패키지",
            "마이메모 앱 만들기",
            "상태관리 패키지 Provider 준비하기",
            "마이메모 앱에 Provider 적용하기",
            "shared_preferences 를 이용해 메모 데이터 기기에 저장하기",
            "숙제 - 마이메모 추가 기능 구현",
          ],
        },
        {
          weekTitle: "API 사용법 익히기",
          weekSubTitle:
            "데이터를 전달하는 약속인 API를 배우고 활용하여 앱을 만들어봅니다.",
          details: [
            "API 이해하기",
            "비동기 이해하기",
            "프로젝트 준비(왓챠피디아)",
            "API 연결하기 - 1",
            "API 연결하기 - 2",
            "API 연결하기 - 3",
            "좋아요 구현하기 - 1",
            "좋아요 구현하기 - 2",
            "책 상세 페이지 WebView로 보여주기",
            "숙제 - 왓챠피디아 추가기능 구현",
          ],
        },
        {
          weekTitle: "구글 애드몹 & 플레이스토어 배포",
          weekSubTitle:
            "만들어진 앱을 내 핸드폰에 설치하고, 구글 플레이스토어에 배포해봅니다.",
          details: [
            "프로젝트 준비",
            "구글 애드몹 연결하기",
            "내 핸드폰에 앱 설치하기",
            "구글 플레이스토어에 앱 배포하기",
            "숙제 - 다양한 유형의 광고 추가하기",
          ],
        },
      ];
    case "data_v2":
      return [
        {
          weekTitle: "튜토리얼",
          weekSubTitle:
            "수강에 필요한 환경을 셋팅하고 학습 질문 이용 방법을 배웁니다.",
          details: [
            "수강환경 튜토리얼",
            "학습 질문 튜토리얼",
            "개발일지 튜토리얼",
          ],
        },
        {
          weekTitle: "데이터 전처리, 파이썬&라이브러리 개념 익히기",
          weekSubTitle:
            "실전 데이터 분석을 시작하기 전에 익숙한 프로그램으로 분석의 감을 익혀봅니다.",
          details: [
            "1주차 우리가 오늘 배울 것",
            "타이타닉 생존자의 비밀 파헤치기",
            "타이타닉 데이터 분석하기",
            "분석 결과 시각화 하기 그리고 최종 결론 내기",
            "파이썬 기초지식 엿보기",
            "1주차 끝 & 숙제 설명",
          ],
        },
        {
          weekTitle:
            "Pandas, Matplotlib를 활용해서 파이썬 데이터 분석 입문하기",
          weekSubTitle:
            "파이썬의 기초, 테이블 형태의 데이터 분석 및 데이터 시각화를 배웁니다.",
          details: [
            "2주차 오늘 배울 것",
            "colab 시작하기",
            "데이터 분석에 꼭 필요한 파이썬 문법",
            "Pandas를 이용하여 데이터 분석하기",
            "matplotlib를 이용하여 분석 결과 시각화하기",
            "[한 걸음 더] - 타이타닉 생존자의 나이에 숨겨진 비밀 파헤치기!",
            "2주차 끝 & 숙제 설명",
          ],
        },
        {
          weekTitle:
            "Numpy, Folium, MarkerCluster를 활용해 데이터 분석 집중 훈련하기",
          weekSubTitle:
            "Pandas 데이터프레임 및 데이터 시각화 심화 예제를 실습합니다.",
          details: [
            "3주차 오늘 배울 것",
            "데이터 분석 세팅",
            "[미션 1] 가장 적절한 고객 관리 타이밍_분석 준비하기",
            "[미션 1] 가장 적절한 고객 관리 타이밍_분석 및 시각화",
            "[미션 2] 제품 수요가 많은 지역을 찾아라!_라인 그래프 그리기",
            "[미션 2] 제품 수요가 많은 지역을 찾아라!_지도에서 한눈에 보기",
            "숙제_최적의 즉문즉답 시간대를 찾아라!",
          ],
        },
        {
          weekTitle: "가설 설계부터 실제 분석까지 반복 실습하기",
          weekSubTitle:
            "실제 서비스 데이터를 활용해서 현업에서 필요한 형태로 분석을 시작해봅니다.",
          details: [
            "4주차 오늘 배울 것",
            "광고 효율이 나지 않는 매체를 찾아라_전처리",
            "광고 효율이 나지 않는 매체를 찾아라_시각화 및 결론",
            "시각화 한 스푼 더 떠먹기",
            "패키지 상품 기획하기_가설 수립 및 전처리",
            "패키지 상품 기획하기_추가 가설 및 시각화하기",
            "할인은 정말 효과적인 선택일까?_전처리하기",
            "할인은 정말 효과적인 선택일까?_데이터 분석 및 시각화",
            "4주차 끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "실무 기반 데이터 분석 실습 프로젝트 및 전략 검증해보기",
          weekSubTitle:
            "현업 데이터를 집계해 각종 통계량을 파악하고 이를 기반으로 매출 전략을 검증합니다.",
          details: [
            "5주차 오늘 배울 것",
            "데이터 분석 시작하기",
            "우리는 적절한 타겟에게 판매를 하고 있을까?",
            "찐한관리를 하면 완주율이 높아질까?_가설 및 전처리",
            "찐한관리를 하면 완주율이 높아질까?_분석 및 시각화",
            "프로덕트 개선은 정말 도움이 되었을까?_가설",
            "프로덕트 개선은 정말 도움이 되었을까?_분석 및 시각화",
            "프로덕트 개선은 정말 도움이 되었을까?_잘 보이는 그래프 만들기",
            "5주차 끝 & 숙제 설명",
          ],
        },
      ];
    case "python":
      return [
        {
          weekTitle: "튜토리얼",
          weekSubTitle:
            "수강에 필요한 환경을 셋팅하고 학습 질문 이용 방법을 배웁니다.",
          details: [
            "수강환경 튜토리얼",
            "학습 질문 튜토리얼",
            "개발일지 튜토리얼",
          ],
        },
        {
          weekTitle: "파이썬 기초 & 업무 자동화 실습 준비",
          weekSubTitle:
            "파이썬 기초 문법을 배우고, 업무 자동화에 필요한 데이터를 수집합니다.",
          details: [
            "오늘 배울 것",
            "Colab 구동하기",
            "파이썬 기초",
            "업무자동화 - 스크래핑 실습",
            "업무자동화 - 엑셀다루기",
            "업무자동화 - 파일 다운로드, 이름바꾸기",
            "업무자동화 - 이미지 다운로드",
            "1주차 끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "Pandas 기초 & 주식 데이터 분석 준비하기",
          weekSubTitle:
            "Pandas의 DataFrame 활용법을 익히고 해외 주식 데이터로 실습합니다.",
          details: [
            "오늘 배울 것",
            "Pandas 기초",
            "엑셀 가져오기",
            "Pandas 실전",
            "해외주식 다루기 - yfinance",
            "분석하기(1) : 전략 세우기, 데이터 모으기",
            "분석하기(2) : 분석하기",
            "2주차 끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "API 키 발급부터 데이터 활용까지 실습하기",
          weekSubTitle:
            "Dart-fss 라이브러리를 사용해 상장, 비상장 종목을 분석합니다.",
          details: [
            "오늘 배울 것",
            "Data OpenAPI 키 발급받기",
            "Dart 라이브러리 활용하기",
            "종목 정리하기",
            "Dart API 사용해보기",
            "상장 종목 분석하기(1)",
            "비상장 종목 분석하기",
            "3주차 끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "주식 데이터로 그래프 그려보고 전략 세우기",
          weekSubTitle:
            "백테스팅 전략에 대해 이해하고, 파이썬으로 그래프를 그려 실습합니다.",
          details: [
            "오늘 배울 것",
            "백테스팅 전략 세우기 & 주가 가져오기",
            "간단한 그래프 그려보기",
            "이동평균값 만들기 (3일)",
            "buy & sell 표기",
            "수익률 구하기(1)",
            "단기/장기이평선 적용하기",
            "4주차 끝 & 숙제 설명",
          ],
        },
        {
          weekTitle: "주가 데이터 가져와서 분석하고 전략 세우기",
          weekSubTitle:
            "변동성 돌파 전략에 대해 이해하고, 나만의 최적화 전략을 구현합니다.",
          details: [
            "오늘 배울 것",
            "변동성 돌파 전략 - 설명",
            "주가 가져오기",
            "사야하는 날, 파는 날 가격 구하기",
            "최적의 k 구하고 나만의 전략 구현하기",
            "요일 표기와 주별 가격 붙이기",
            "최적화 종목 찾기",
            "5주차 끝 & 숙제 설명",
          ],
        },
      ];
    case "game":
      return [
        {
          weekTitle: "튜토리얼",
          weekSubTitle:
            "수강에 필요한 환경을 셋팅하고 학습 질문 이용 방법을 배웁니다.",
          details: [
            "수강환경 튜토리얼",
            "학습 질문 튜토리얼",
            "개발일지 튜토리얼",
          ],
        },
        {
          weekTitle: "게임개발에 필요한 기초 문법 익히기",
          weekSubTitle: "유니티의 기본 사용법과 C#의 기본 문법을 익힙니다.",
          details: [
            "1주차 오늘 배울 것",
            "유니티 설치하기",
            "기본 씬 구성 및 애니메이션 맛보기",
            "캐릭터 움직이기",
            "빗방울 코딩하기",
            "점수 올라가게 하기",
            "게임 끝내기",
            "숙제 - 빨간 빗방울 만들기",
          ],
        },
        {
          weekTitle: "미니게임 제작하며 게임개발 실습 시작하기",
          weekSubTitle: "유명 게임을 완성하며 유니티 기본 사용법을 복습합니다.",
          details: [
            "2주차 오늘 배울 것",
            "기본 씬 구성하기",
            "풍선 만들기",
            "마우스 만들기",
            "네모 만들기(1) - 내려오기",
            "네모 만들기(1) - 나타나기",
            "시간 올라가게 하기",
            "게임 끝내기(1) - 판넬 만들기",
            "게임 끝내기(2) - 판넬 나타내기",
            " 최고 점수 나타내기",
            " 풍선 애니메이션 전환하기",
          ],
        },
        {
          weekTitle: "기초 토대를 갖춘 게임 완성해보기",
          weekSubTitle:
            "hp바와 레벨 시스템을 만들면서 게임의 완성도를 높여봅니다.",
          details: [
            "3주차 오늘 배울 것",
            "기본 씬 구성하기",
            "시작 씬 만들기",
            "밥 쏘기",
            "고양이 나타내기(1) - 만들기",
            "고양이 나타내기(2) - 내려오기/충돌",
            "고양이 나타내기(3) - 등장하기&종료",
            "레벨 구성하기(1) - 레벨업 표기하기",
            "레벨 구성하기(2) - 레벨 반영하기",
            " 마무리 - 게임 즐겨보기/버그잡기",
          ],
        },
        {
          weekTitle: "보드 게임, 카드 뒤집기 게임 만들기",
          weekSubTitle: "실습을 통해 게임에 필요한 로직을 경험하며 복습합니다.",
          details: [
            "4주차 오늘 배울 것",
            "카드 만들기 - 한 장",
            "시간 가게 하기",
            "카드(1)_배치하기",
            "카드(2)_르탄이 넣기, 애니메이션",
            "카드(3)_뒤집기, 매칭하기",
            "게임 끝내기",
            "숙제 - 30초가 지나면 게임 끝내기",
          ],
        },
        {
          weekTitle: "게임 런칭에 필요한 기술 학습하기",
          weekSubTitle:
            "게임에 소리 넣기부터 광고 넣기, 출시까지 직접 경험해봅니다",
          details: [
            "5주차 오늘 배울 것",
            "시작화면 만들기",
            "스플래시 이미지 만들기",
            "소리 & 배경음악 넣기",
            "빌드하기",
            "광고 붙이기",
            "게임제작 꿀팁_에셋 스토어, next step",
            "숙제 - 게임 둘러보기",
          ],
        },
      ];
  }
};

export const getRecommends = (url) => {
  switch (url) {
    case "app":
      return {
        mobileRecommends: [
          `나만의 앱 서비스를 빠르게 만들어서\n<span>부수입을 얻고 싶다.</span>`,
          `실제로 앱스토어에서 다운받을 수 있는\n<span>그럴싸한 앱을 만들고 싶다.</span>`,
          `앱 서비스 운영을 직접 하기 위해\n<span>커리어에 코딩역량을 추가하고 싶다.</span>`,
        ],
        desktopRecommends: [
          `나만의 앱 서비스를 빠르게 만들어서 <span>부수입을 얻고 싶다.</span>`,
          `실제로 앱스토어에서 다운받을 수 있는 <span>그럴싸한 앱을 만들고 싶다.</span>`,
          `앱 서비스 운영을 직접 하기 위해 <span>커리어에 코딩역량을 추가하고 싶다.</span>`,
        ],
      };
    case "sql":
      return {
        mobileRecommends: [
          `데이터를 요청할 때마다\n<span>개발자, 분석가의 눈치가 보인다.</span>`,
          `원하는 직무의\n<span>채용공고 우대사항에 SQL이 있다.</span>`,
          `내 데이터 역량을 보여줄 수 있는\n<span>SQLD 자격증을 취득하고 싶다.</span>`,
        ],
        desktopRecommends: [
          `데이터를 요청할 때마다 <span>개발자, 분석가의 눈치가 보인다.</span>`,
          `원하는 직무의 <span>채용공고 우대사항에 SQL이 있다.</span>`,
          `내 데이터 역량을 보여줄 수 있는 <span>SQLD 자격증을 취득하고 싶다.</span>`,
        ],
      };
    case "data_v2":
      return {
        mobileRecommends: [
          `파이썬 이론만 배우고\n<span>내 실력으로 직접 분석을 해본 경험이 없다.</span>`,
          `원하는 직무의 채용공고\n<span>우대사항에 ‘데이터 분석’ 경험이 있다.</span>`,
          `현업 마케터, 기획자, 금융권 종사자 등\n<span>업무에서 새로운 인사이트가 필요하다.</span>`,
        ],
        desktopRecommends: [
          `파이썬 이론만 배우고 <span>내 실력으로 직접 분석을 해본 경험이 없다.</span>`,
          `원하는 직무의 채용공고 <span>우대사항에 ‘데이터 분석’ 경험이 있다.</span>`,
          `현업 마케터, 기획자, 금융권 종사자 등 <span>업무에서 새로운 인사이트가 필요하다.</span>`,
        ],
      };
    case "python":
      return {
        mobileRecommends: [
          `파이썬 이론만 배워보고\n<span>실전에서는 사용해 본 적이 없다.</span>`,
          `무거운 엑셀 파일 속 데이터를\n<span>어떻게 활용해야 할지 모르겠다.</span>`,
          `매번 작성하는 실적집계 보고서를\n<span>효율적으로 만들고 빨리 퇴근하고 싶다.</span>`,
        ],
        desktopRecommends: [
          `파이썬 이론만 배워보고 <span>실전에서는 사용해 본 적이 없다.</span>`,
          `무거운 엑셀 파일 속 데이터를 <span>어떻게 활용해야 할지 모르겠다.</span>`,
          `매번 작성하는 실적집계 보고서를 <span>효율적으로 만들고 빨리 퇴근하고 싶다.</span>`,
        ],
      };
    case "game":
      return {
        mobileRecommends: [
          `게임을 좋아해서,\n<span>나만의 게임을 만들어보고 싶다는 꿈이 있다.</span>`,
          `내가 좋아하는 일로\n<span>부수입을 창출해보고 싶다.</span>`,
          `게임 업계 커리어에\n<span>도움이 되는 코딩 역량을 얻고 싶다.</span>`,
        ],
        desktopRecommends: [
          `게임을 좋아해서, <span>나만의 게임을 만들어보고 싶다는 꿈이 있다.</span>`,
          `내가 좋아하는 일로 <span>부수입을 창출해보고 싶다.</span>`,
          `게임 업계 커리어에 <span>도움이 되는 코딩 역량을 얻고 싶다.</span>`,
        ],
      };
  }
};

export const getAdventages = (url) => {
  switch (url) {
    case "app":
      return {
        topText: "내가 생각한 아이디어랑 똑같은데?\n생각은 이제 그만!",
        middleText:
          "쉽고 편리한 플러터를 이용해\n" +
          "나만의 아이디어를 기회로 바꾸세요.",
        bottomTexts: [
          "구글이 개발해 더욱 배우기 쉽고, 이미 만들어져 있는 UI를 사용할 수 있어요.",
          "기획부터 배포까지 내 아이디어를 앱으로 구현할 수 있어요.",
          "수익이 발생하도록 광고를 붙이고, 앱스토어에 내가 만든 앱을 등록할 수 있어요.",
        ],
      };
    case "sql":
      return {
        topText: "채용공고 우대사항 단골 SQL을\n준비하고 싶으신가요?",
        middleText:
          "사전지식 없이 직접 데이터 뽑기부터,\n" +
          "자격증 취득까지 지금 시작하세요.",
        bottomTexts: [
          "데이터를 원할 때 스스로 뽑는 능력자가 될 수 있어요.",
          "IT 회사의 필수 스킬인 SQL, 당당하게 ‘잘 쓴다’고 말할 수 있어요.",
          "팀원에게 원하는 데이터 요청사항을 명확하게 전달할 수 있어요.",
        ],
      };
    case "data_v2":
      return {
        topText:
          "어느 직무에서나 환영받는 데이터 분석 스킬,\n내 실력으로 만들고 싶으신가요?",
        middleText:
          "가설 설계부터 실전 데이터 분석까지,\n실무에 바로 적용할 수 있는 실습으로 시작하세요.",
        bottomTexts: [
          "데이터를 그냥 숫자가 아닌, 활용 가능한 소재로\n정제할 수 있어요.",
          "실무에서 사용되는 데이터와 주제로 분석 경험을\n쌓을 수 있어요.",
          "이론이 아닌 실전에 강한 데이터 분석 실력을\n기를 수 있어요.",
        ],
      };
    case "python":
      return {
        topText:
          "파이썬을 통한 업무 효율성 증대, 남의 이야기가\n아니라 내 경험담으로 만들고 싶으신가요?",
        middleText: `유용한 데이터를 수집하는 것부터 데이터 활용 전략 수립까지,\n실전형 실습으로 바로 시작하세요.`,
        bottomTexts: [
          "실습과 함께 파이썬 문법을 반복 학습할 수\n있어요.",
          "반복되는 일은 컴퓨터에게 맡기고,\n분석에 집중할 수 있어요.",
          "이론이 아닌 실전에 강한 데이터 분석 실력을\n기를 수 있어요.",
        ],
      };
    case "game":
      return {
        topText:
          "나만의 아이디어를 재미있는 게임으로 구현해,\n부수입 창출에 도전해보고 싶으신가요?",
        middleText:
          "C# 핵심 지식부터 액션 디테일까지,\n압축 커리큘럼으로 시작해보세요.",
        bottomTexts: [
          "내가 좋아하는 게임으로 부수입을 만들 수 있어요.",
          "높은 연봉의 게임 업계로 진입할 수 있는 스펙을\n쌓을 수 있어요.",
          "게임 클라이언트 개발자로 개발자 커리어를\n발전시킬 수 있어요.",
        ],
      };
  }
};

export const getRcmdCards = (url) => {
  switch (url) {
    case "app":
      return {
        title: "다른 수강생이 [앱개발 종합반]과\n함께 들은 강의",
        cards: [NbData, NbGame, NbPython],
      };
    case "sql":
      return {
        title: "다른 수강생이 [엑셀보다 쉬운 SQL]과\n함께 들은 강의",
        cards: [NbApp, NbData, NbPython],
      };
    case "data_v2":
      return {
        title: "다른 수강생이 함께 들은 강의",
        cards: [NbSQL, NbPython, NbApp],
      };
    case "python":
      return {
        title: "다른 수강생이 함께 들은 강의",
        cards: [NbApp, NbData, NbSQL],
      };
    case "game":
      return {
        title: "다른 수강생이 함께 들은 강의",
        cards: [NbApp, NbData, NbPython],
      };
  }
};

export const getTutorCheerText = (url) => {
  switch (url) {
    case "app":
      return "아이디어를 실현할 수 있는 \n첫 시작을 도와드릴게요!";
    case "sql":
      return "단 4주만에, \nSQL 왕초보 탈출 도와드릴게요!";
    case "data_v2":
      return "단 5주만에, \n데이터분석 왕초보 탈출 도와드릴게요!";
    case "python":
      return "단 5주만에, \n파이썬 왕초보 탈출 도와드릴게요!";
    case "game":
      return "단 5주만에, \n게임개발의 기초 완성을 도와드릴게요!";
  }
};

import { useEffect, useState } from "react";

export const useElementWidth = (ref) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  return width;
};

export const usePaginationInfo = (
  itemsInOrder,
  itemNumPerPage,
  currentPageIndex,
  dependencies,
) => {
  const [totalPageNum, setTotalPageNum] = useState(0);
  const [itemsInCurrentPage, setItemsInCurrentPage] = useState([]);

  useEffect(() => {
    if (typeof itemsInOrder !== "undefined") {
      setTotalPageNum(
        Math.floor(itemsInOrder.length / itemNumPerPage) +
          (itemsInOrder.length % itemNumPerPage > 0 ? 1 : 0),
      );
      setItemsInCurrentPage(
        itemsInOrder.slice(
          currentPageIndex * itemNumPerPage,
          (currentPageIndex + 1) * itemNumPerPage,
        ),
      );
    }
  }, [currentPageIndex, ...dependencies]);

  return [totalPageNum, itemsInCurrentPage] as const;
};

export const useFilterUpdate = (filter, updateFn) => {
  useEffect(() => {
    updateFn({ ...filter });
  }, [filter]);
};

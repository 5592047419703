import React from "react";
import { bizDropdownList } from "../models/gnbV3.models";
import * as S from "./GnbDropDown.style";
import { logGnbClick } from "../../../../businesslogics/_common/loggingV2";
import { useRouter } from "next/router";

export const BizDropdown = () => {
  const router = useRouter();
  return (
    <S.DropdownContainer>
      {bizDropdownList.map((item, index) => {
        return (
          <>
            <S.DropdownItem
              key={item.key}
              onClick={() => {
                logGnbClick(item.key, {
                  brand: item?.brand ?? "",
                  page_url: `${process.env.BASE_URL}${router.asPath}`,
                  page_title: document.title,
                  button_text: item.name,
                  button_href: item.href,
                });

                if (item.href) {
                  item.href.includes("://") &&
                  !item.href.includes(process.env.ONLINE_URL)
                    ? window.open(item.href)
                    : router.push(item.href);
                } else if (!!item.onClick) {
                  item.onClick();
                }
              }}
            >
              {item.name}
            </S.DropdownItem>
            {index !== bizDropdownList.length - 1 && <S.Divder />}
          </>
        );
      })}
    </S.DropdownContainer>
  );
};

import styled from "@emotion/styled";
import { device } from "../../../styles/themes/device";

export const ImageWrapper = styled.div<{
  mobileWidth: string | number;
  mobileHeight: string | number;
  tabletWidth: string | number;
  tabletHeight: string | number;
  mobileMargin?: string;
  tabletMargin?: string;
  mobilePadding?: string;
  tabletPadding?: string;
  borderRadius?: string;
}>`
  ::-moz-selection {
    background: rgba(0, 0, 0, 0);
  }

  ::selection {
    background: rgba(0, 0, 0, 0);
  }

  position: relative;
  width: ${({ mobileWidth }) => {
    if (typeof mobileWidth === "number") {
      return `${mobileWidth}px`;
    } else if (typeof mobileWidth === "string") {
      return mobileWidth;
    }
  }};
  height: ${({ mobileHeight }) => {
    if (typeof mobileHeight === "number") {
      return `${mobileHeight}px`;
    } else if (typeof mobileHeight === "string") {
      return mobileHeight;
    }
  }};
  ${({ mobileMargin }) => (mobileMargin ? `margin: ${mobileMargin}` : "")};
  ${({ mobilePadding }) => (mobilePadding ? `padding: ${mobilePadding}` : "")};
  @media ${device.tabletAndDesktop} {
    width: ${({ tabletWidth }) => {
      if (typeof tabletWidth === "number") {
        return `${tabletWidth}px`;
      } else if (typeof tabletWidth === "string") {
        return tabletWidth;
      }
    }};
    height: ${({ tabletHeight }) => {
      if (typeof tabletHeight === "number") {
        return `${tabletHeight}px`;
      } else if (typeof tabletHeight === "string") {
        return tabletHeight;
      }
    }};
    ${({ tabletMargin }) => (tabletMargin ? `margin: ${tabletMargin}` : "")};
    ${({ tabletPadding }) =>
      tabletPadding ? `padding: ${tabletPadding}` : ""};
  }
`;

export const ImageContainer = styled.div<{ borderRadius }>`
  ${({ borderRadius }) =>
    borderRadius
      ? `border-radius: ${borderRadius};
          overflow: hidden;
          width: 100%;
          height: 100%;
          position: relative;`
      : ""};
`;

type FormatType =
  | "korean"
  | "koreanEnd"
  | "dots"
  | "dots_short"
  | "short"
  | "monthDate"
  | "dots_zero"
  | "dots_zero_short"
  | "monthDataSlash";

export class DateFormatter {
  private static days = ["일", "월", "화", "수", "목", "금", "토"];

  constructor() {}

  static padZero(num: number): string {
    return num.toString().padStart(2, "0");
  }

  static format(time: Date | string, formatType: FormatType): string {
    if (typeof time === "string") time = new Date(time);

    switch (formatType) {
      case "koreanEnd":
        time.setDate(time.getDate() - 1);
        return `${time.getMonth() + 1}월 ${time.getDate()}일(${
          this.days[time.getDay()]
        })`;
      case "dots": //"2023.4.10"
        return `${time.getFullYear()}.${time.getMonth() + 1}.${time.getDate()}`;
      case "dots_short": //"23.04.10 (일)"
        return `${time.getFullYear().toString().slice(2, 4)}.${this.padZero(
          time.getMonth() + 1,
        )}.${this.padZero(time.getDate())}(${this.days[time.getDay()]})`;
      case "short":
        return `${time.getMonth() + 1}/${time.getDate()}(${
          this.days[time.getDay()]
        })`;
      case "monthDate": //"4월 10일"
        return `${time.getMonth() + 1}월 ${time.getDate()}일`;
      case "dots_zero": //"2023.04.10"
        return `${time.getFullYear()}.${this.padZero(
          time.getMonth() + 1,
        )}.${this.padZero(time.getDate())}`;
      case "dots_zero_short": //"23.04.10"
        return `${time.getFullYear().toString().slice(2, 4)}.${this.padZero(
          time.getMonth() + 1,
        )}.${this.padZero(time.getDate())}`;
      case "monthDataSlash": // "4/10(수)"
        return `${time.getMonth() + 1}/${time.getDate()}(${
          this.days[time.getDay()]
        })`;
      case "korean":
      default:
        //지원하지 않는 포맷 입력시 korean으로 반환
        // "4월 10일(일)"
        return `${time.getMonth() + 1}월 ${time.getDate()}일(${
          this.days[time.getDay()]
        })`;
    }
  }
}

export const krStringToDatetime = (date) => {
  return new Date(date.slice(0, -1));
};

export const getTimedelta = (bigDate, smallDate) => {
  return bigDate.getTime() / 1000 - smallDate.getTime() / 1000;
};

export const secondsDict = {
  sec: 1,
  min: 60,
  hour: 60 * 60,
  day: 60 * 60 * 24,
};

export const dateFormatter = (date) => {
  return timeDeltaFormatter(date, new Date());
};

export const ampmConvertor = (date) => {
  const hours = date.getHours() % 12 ? date.getHours() % 12 : 12;
  const minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const ampm = date.getHours() >= 12 ? "오후" : "오전";
  const convertedDate = `${ampm} ${hours}:${minutes}`;
  return convertedDate;
};

export const timeDeltaFormatter = (beforeDate, afterDate) => {
  const timedelta = getTimedelta(afterDate, beforeDate);
  const convertedTime = ampmConvertor(beforeDate);
  return (unit) =>
    ({
      sec: `${Math.floor(timedelta / secondsDict.sec)}초`,
      min: `${Math.floor(timedelta / secondsDict.min)}분`,
      hour: `${Math.floor(timedelta / secondsDict.hour)}시간`,
      day: `${Math.floor(timedelta / secondsDict.day)}일`,
      full: `${beforeDate.getFullYear()}년 ${
        beforeDate.getMonth() + 1
      }월 ${beforeDate.getDate()}일 ${convertedTime}`,
    })[unit];
};

export const dynamicTimeDeltaFormat = (beforeDate, afterDate, formatDict) => {
  const f = timeDeltaFormatter(beforeDate, afterDate);
  const timedelta = getTimedelta(afterDate, beforeDate);
  if (timedelta < secondsDict.min) {
    return f(formatDict.sec);
  }

  if (timedelta < secondsDict.hour) {
    return f(formatDict.min);
  }

  if (timedelta < secondsDict.day) {
    return f(formatDict.hour);
  }

  return f(formatDict.day);
};

export const dynamicFormat = (date, formatDict) => {
  return dynamicTimeDeltaFormat(date, new Date(), formatDict);
};

export const getDaysDifferenceFromToday = (date: Date) => {
  const today = new Date();

  const diffTime = Math.abs(date.getTime() - today.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const calculateDaysBetween = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());

  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const isDateAfterNow = (date: Date): boolean => {
  const now = new Date();
  return date > now;
};

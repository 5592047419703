import React, { useEffect, useState } from "react";
import * as S from "./FooterV2.style";
import { useRouter } from "next/router";
import { useWindowSize } from "../../../../businesslogics/_layout/window";
import { isExcludeFooterMobile } from "../../../../businesslogics/_common/utils/excludeFooterMobile";
import { sendLogV2 } from "../../../../businesslogics/_common/loggingV2";
import { FooterCS } from "./FooterV2.style";
import { FooterIconSVG } from "./FooterV2.style.svg";
import { PrivacyPolicy } from "./PrivacyPolicy";
import {
  BusinessInfoItems,
  CopyRightText,
  FooterInfoSecondLineItems,
  FooterSNSItems,
  InfoDropDownIconImg,
  InfoDropDownText,
} from "../../../../models/_layout/footer.models";
import {
  ArrowDropDownLine,
  ArrowDropUpLine,
  neutralDay,
} from "@teamsparta/design-system";

interface Props {
  footerCustomerService?: Array<any>;
  footerNavItems: Array<any>; //Footer Nav 항목 구성요소
  footerSNSItems: Array<any>; //SNS 목록 구성요소
  footerInfoFirstLineItems: Array<any>; //문의&안내 첫째줄 구성요소
  footerInfoSecondLineItems: Array<any>; //문의&안내 둘째줄 구성요소
  infoDropDownText: string; //기업정보 드랍다운 텍스트
  infoDropDownIconImg: string; //기업정보 드랍다운 토클 아이콘 이미지
  businessInfoItems: Array<any>; //기업정보 배열
  copyRightText: string; //카피라이트 안내 텍스트
}

export const FooterV2 = ({ footerCustomerService, footerNavItems }: Props) => {
  const router = useRouter();
  const device = useWindowSize();
  const isDesktop = device === "desktop";
  const isExcluded = isExcludeFooterMobile(router.asPath);

  const logLinkClick = (text) => {
    sendLogV2("footer_click", router.asPath, {
      button_text: text,
      page_title: document.title,
      page_url: `${process.env.BASE_URL}${router.asPath}`,
    });
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 820);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 820);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const clickCta = (model, navIdx, textIdx) => {
    const url = model[navIdx].url[textIdx];
    const text = model[navIdx].text[textIdx];
    logLinkClick(text);
    if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      router.push(url);
    }
  };

  const [expandedItems, setExpandedItems] = useState(
    Array(footerNavItems.length).fill(false)
  );

  const toggleItem = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  return (
    <>
      {isDesktop || !isExcluded ? (
        <S.Footer>
          <S.FooterContentWrap>
            <S.GridContainer>
              {footerCustomerService.map((item, idx) => (
                <S.GridItem key={idx}>
                  <S.FooterTitle>{item.title}</S.FooterTitle>
                  <S.FooterContentContainer isCs={true} style={{ gap: "0" }}>
                    {item.text?.map((text, textIdx) => (
                      <React.Fragment key={textIdx}>
                        {text.split("<br/>").map((line, lineIdx) => {
                          const isCs = text === "고객센터 바로가기";
                          if (isCs) {
                            return (
                              <FooterCS
                                key={lineIdx}
                                onClick={() =>
                                  clickCta(footerCustomerService, 0, 3)
                                }
                              >
                                <FooterIconSVG item={"cs"} />
                                {line}
                              </FooterCS>
                            );
                          }

                          return (
                            <S.FooterContent
                              style={{ padding: "0 4px", cursor: "auto" }}
                              key={lineIdx}
                            >
                              {line}
                              {lineIdx !== text.split("<br/>").length - 1 && (
                                <br />
                              )}
                            </S.FooterContent>
                          );
                        })}
                        {textIdx !== item.text.length - 1 && <S.Spacer10 />}
                      </React.Fragment>
                    ))}
                  </S.FooterContentContainer>
                </S.GridItem>
              ))}

              {!isMobile
                ? footerNavItems.map((item, navIdx) => (
                    <S.GridItem key={navIdx}>
                      <S.FooterTitle>{item.title}</S.FooterTitle>
                      <S.FooterContentContainer>
                        {item.text?.map((textString, textIdx) => (
                          <S.FooterContent key={textString}>
                            <S.PointerArea
                              onClick={() =>
                                clickCta(footerNavItems, navIdx, textIdx)
                              }
                            >
                              {textString}
                            </S.PointerArea>
                            {textIdx !== item.text.length - 1 && <S.Spacer6 />}
                            {textString === "인재 채용" && (
                              <S.Tag>{item.tag}</S.Tag>
                            )}
                          </S.FooterContent>
                        ))}
                      </S.FooterContentContainer>
                    </S.GridItem>
                  ))
                : footerNavItems.map((item, navIdx) => (
                    <S.GridItem
                      key={navIdx}
                      isOpen={expandedItems[navIdx]}
                      isLastItem={navIdx === footerNavItems.length - 1}
                    >
                      <S.FooterTitle onClick={() => toggleItem(navIdx)}>
                        {item.title}
                        {expandedItems[navIdx] ? (
                          <ArrowDropUpLine
                            size={16}
                            color={`${neutralDay.gray50}`}
                          />
                        ) : (
                          <ArrowDropDownLine
                            size={16}
                            color={`${neutralDay.gray50}`}
                          />
                        )}
                      </S.FooterTitle>

                      {expandedItems[navIdx] && (
                        <S.FooterContentContainer>
                          {item.text?.map((textString, textIdx) => (
                            <S.FooterContent key={textString}>
                              <S.PointerArea
                                onClick={() =>
                                  clickCta(footerNavItems, navIdx, textIdx)
                                }
                              >
                                {textString}
                                {textIdx !== item.text.length - 1 && (
                                  <S.Spacer6 />
                                )}
                                {textString === "인재 채용" && (
                                  <S.Tag>{item.tag}</S.Tag>
                                )}
                              </S.PointerArea>
                            </S.FooterContent>
                          ))}
                        </S.FooterContentContainer>
                      )}
                    </S.GridItem>
                  ))}
            </S.GridContainer>

            <S.FooterLine />
            <PrivacyPolicy
              footerSNSItems={FooterSNSItems}
              footerInfoSecondLineItems={FooterInfoSecondLineItems}
              businessInfoItems={BusinessInfoItems}
              infoDropDownText={InfoDropDownText}
              infoDropDownIconImg={InfoDropDownIconImg}
              copyRightText={CopyRightText}
              logLinkClick={logLinkClick}
            />
          </S.FooterContentWrap>
        </S.Footer>
      ) : null}
    </>
  );
};

import styled from "@emotion/styled";
import { fontPretendard } from "../../../../styles/fonts";
import { device, DisplayResolution } from "../../../../styles/themes/device";
import { mBody1, neutralDay, wBody1 } from "@teamsparta/design-system";

export const LnbItem = styled.div<{ iscodingland?: boolean; active: boolean }>`
  flex-grow: 1;
  text-align: center;
  font-family: ${fontPretendard};
  line-height: 1.5;
  font-size: 14px;
  font-weight: ${(props: { active: boolean }) => (props.active ? 700 : 500)};
  cursor: pointer;

  ${({ iscodingland }) =>
    iscodingland &&
    `
  padding: 17px 0 ;
  `}

  ${({ iscodingland, active }) =>
    iscodingland &&
    active &&
    `
  
  border-bottom: 3px solid ${neutralDay.gray100}; 
  `};

  &:last-of-type {
    margin-right: 0;
  }

  ${wBody1};

  @media ${device.tabletAndDesktop} {
    flex-grow: 0;
    font-size: 16px;
  }
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    ${mBody1};
    color: ${(props: { active: boolean }) =>
      props.active ? "#141617" : `#9DA7AE`};
  }

  color: ${(props: { active: boolean }) =>
    props.active ? "#141617" : `#9DA7AE`};
`;

export const LnbItemText = styled.div``;

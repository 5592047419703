import styled from "@emotion/styled";
import { Device } from "../../../styles/themes/device";

export const Contents = styled.div<{ unScrollable? }>`
  ${({ unScrollable }) =>
    unScrollable ? "position: fixed; width: 100%;" : ""};

  ${Device.LessThanTablet} {
    /* iOS only */

    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
`;

export const LoginModalWrapper = styled.div`
  & > div {
    z-index: 999;
  }
`;

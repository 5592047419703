import * as S from "./NextImageWrapper.style";
import React from "react";
import { StaticImageData } from "next/legacy/image";
import { NextImageWithFallback } from "../_common/NextImageWithFallback/NextImageWithFallback";

interface Props {
  src: string | StaticImageData;
  mobileWidth: string | number;
  mobileHeight: string | number;
  mobileMargin?: string;
  mobilePadding?: string;
  tabletWidth?: string | number;
  tabletHeight?: string | number;
  tabletMargin?: string;
  tabletPadding?: string;
  quality?: number;
  blurDataURL?: string;
  borderRadius?: string;
}

export const NextImageWrapper = ({
  src,
  mobileWidth,
  mobileHeight,
  mobileMargin,
  mobilePadding,
  tabletWidth,
  tabletHeight,
  tabletMargin,
  tabletPadding,
  quality,
  blurDataURL,
  borderRadius,
}: Props) => {
  return (
    <S.ImageWrapper
      mobileWidth={mobileWidth}
      mobileHeight={mobileHeight}
      mobileMargin={mobileMargin}
      mobilePadding={mobilePadding}
      tabletWidth={tabletWidth ? tabletWidth : mobileWidth}
      tabletHeight={tabletHeight ? tabletHeight : mobileHeight}
      tabletMargin={tabletMargin}
      tabletPadding={tabletPadding}
    >
      <S.ImageContainer borderRadius={borderRadius}>
        <NextImageWithFallback
          layout={"fill"}
          quality={quality ? quality : 100}
          src={src}
          blurDataURL={blurDataURL}
          placeholder={blurDataURL ? "blur" : "empty"}
        />
      </S.ImageContainer>
    </S.ImageWrapper>
  );
};

import { useAtom } from "jotai";
import {
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "../../../../stores/_layout/gnb";
import { showLineBannerAtom } from "../../../../stores/showLineBannerAtom";
import { GnbRowMobile } from "../atoms/GnbRow.mobile";
import {
  bizDropdownList,
  catalogDropdownList,
  mobileBottomAnchors,
  mobileMainAnchor,
  topRightAnchors,
} from "../models/gnbV3.models";
import * as S from "./GnbCategories.style";
import { NextImageWrapper } from "../../../atoms/NextImageWrapper";
import { useClickAnchor } from "../../../../businesslogics/_layout/gnb/v2";
import { sccAnchor } from "../../../../models/_layout/gnbV2.models";
import { CloseBtnSVG } from "../../../atoms/Icons/nbGuide/nbGuide.svg";
import React, { Fragment, useState } from "react";
import { DownArrowSVG } from "../../../atoms/_layout/gnb/v2/Gnb.style.svg";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { useDropDownList } from "src/businesslogics/catalog/useDropdownList";
import { useCustomRemoteConfig } from "src/businesslogics/_common/hackle/useCustomRemoteConfig";

export const GnbCategories = () => {
  const [isNavigatorOpen, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const [, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  const [show] = useAtom(isNavigatorOpenAtom);
  const [showLineBanner] = useAtom(showLineBannerAtom);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [showSubCatalog, setShowSubCatalog] = useState(false);
  const onClickAnchor = useClickAnchor(sccAnchor);
  const dropDownList = useDropDownList();
  // TODO: v2 안정화 시 제거 예정
  const CATALOG_V2 = useCustomRemoteConfig(
    "scc_catalog_v2_migration",
    "boolean"
  );

  const catalogDropdown = CATALOG_V2 ? dropDownList : catalogDropdownList;
  const handleArrowClick = (anchor) => {
    anchor.key === "catalogv3" && setShowSubCatalog(!showSubCatalog);
  };

  return (
    <S.Wrapper show={show} showLineBanner={showLineBanner}>
      <S.OverwrapTopWrapper showLineBanner={showLineBanner}>
        <S.LogoWrapper
          onClick={() => {
            onClickAnchor();
            setIsMyPageOpen(false);
            setShowSubCategory(false);
            setIsNavigatorOpen(!isNavigatorOpen);
          }}
        >
          <NextImageWrapper
            src={`/assets/icons/logo-active.png`}
            mobileWidth={100}
            mobileHeight={34}
          />
        </S.LogoWrapper>

        <S.CloseButtonWrapper
          onClick={() => {
            setIsMyPageOpen(false);
            setShowSubCategory(false);
            setIsNavigatorOpen(!isNavigatorOpen);
          }}
        >
          <CloseBtnSVG />
        </S.CloseButtonWrapper>
      </S.OverwrapTopWrapper>
      <S.NoMarginDivider />
      <S.CategoriesContainer showSubCategory={showSubCategory}>
        <S.Group>
          {mobileMainAnchor.map((anchor) => {
            return (
              <Fragment key={`gnb-category-${anchor.key}`}>
                <S.ArrowContainer onClick={() => handleArrowClick(anchor)}>
                  <GnbRowMobile anchor={anchor} />
                  {anchor.key === "catalogv3" && <DownArrowSVG />}
                </S.ArrowContainer>

                {showSubCatalog && anchor.key === "catalogv3" && (
                  <S.SubGroup>
                    {catalogDropdown?.map((anchor) => (
                      <GnbRowMobile
                        key={`gnb-category-${anchor.key}`}
                        anchor={anchor}
                        isSub={true}
                        onClickHandler={() => {
                          setShowSubCatalog(false);
                          sendCPLog("scc_gnb_catalogPage_category_click", {
                            type_title: anchor.name,
                          });
                        }}
                      />
                    ))}
                  </S.SubGroup>
                )}
              </Fragment>
            );
          })}
        </S.Group>

        <S.Divider />

        <S.Group>
          {topRightAnchors.map((anchor) => (
            <S.ArrowContainer
              onClick={() => setShowSubCategory(!showSubCategory)}
              key={`gnb-category-${anchor.key}`}
            >
              <GnbRowMobile anchor={anchor} />
              {anchor.onClick && <DownArrowSVG />}
            </S.ArrowContainer>
          ))}
          {showSubCategory && (
            <S.SubGroup>
              {bizDropdownList.map((anchor) => (
                <GnbRowMobile
                  key={`gnb-category-${anchor.key}`}
                  anchor={anchor}
                  isSub={true}
                />
              ))}
            </S.SubGroup>
          )}
        </S.Group>
        <S.ThickDivider />
        <S.Group>
          <S.SubTitle>둘러보기</S.SubTitle>
          {mobileBottomAnchors.map((anchor) => (
            <GnbRowMobile
              key={`gnb-category-${anchor.key}`}
              anchor={anchor}
              isProduct={true}
            />
          ))}
        </S.Group>
      </S.CategoriesContainer>
    </S.Wrapper>
  );
};

import { css, Global } from "@emotion/react";
import { device } from "./device";

const reset = css`
  * {
    margin: 0;
    line-height: 1.5;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: neutralDay;
  }

  html {
    font-size: 10px;
    scroll-behavior: smooth;
  }

  body {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    font-size: 1.4rem;
    box-sizing: border-box;

    @media screen and (max-width: 320px) {
      width: 352px;
      box-sizing: content-box;
    }
  }

  a,
  button {
    text-decoration: none;
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  h1 {
    font-size: 2.4rem;
    @media ${device.desktop} {
      font-size: 4rem;
    }
  }

  h2 {
    font-size: 2.2rem;
    @media ${device.desktop} {
      font-size: 3.2rem;
    }
  }

  h3 {
    font-size: 1.8rem;
    @media ${device.desktop} {
      font-size: 2.2rem;
    }
  }

  h4 {
    font-size: 1.6rem;
    @media ${device.desktop} {
      font-size: 2rem;
    }
  }

  h5 {
    font-size: 1.4rem;
    @media ${device.desktop} {
      font-size: 1.8rem;
    }
  }

  h6 {
    font-size: 1.4rem;
    @media ${device.desktop} {
      font-size: 1.4rem;
    }
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  .mobile-only {
    @media ${device.desktop} {
      display: none;
    }
  }
`;

export const globalStyles = <Global styles={reset} />;

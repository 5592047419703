import styled from "@emotion/styled";
import { neutralDay, wBody1 } from "@teamsparta/design-system";
import { zIndexLnb } from "../../../../../styles/variables";
import { DisplayResolution } from "../../../../../styles/themes/device";
import { keyframes } from "@emotion/css";

const fadeIn = keyframes`
  from {
    display: none;
    opacity: 0;
    z-index: -1;
  }
  to {
    display: flex;
    opacity: 1;
    z-index: 2;
  }
`;
const fadeout = keyframes`
  from {
    opacity: 1;
    z-index: 2;
  }
  to {
    opacity: 0;
    z-index: -1;
    display: none;
  }
`;
export const Sparthon4LnbWrapper = styled.div<{ isMobileScrolled: boolean }>`
  ${wBody1};
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${neutralDay.gray10};
  background: ${neutralDay.white};
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100vw;

  z-index: ${zIndexLnb};
  color: ${neutralDay.gray100};

  ${DisplayResolution.MobileAndMiniTablet} {
    display: ${({ isMobileScrolled }) => (isMobileScrolled ? "flex" : "none")};
    animation: ${({ isMobileScrolled }) =>
      isMobileScrolled
        ? `${fadeIn} 0.5s ease-in-out`
        : `${fadeout} 0.5s ease-in out`};

    padding: 0 16px;
    top: 48px;
  }
`;

export const LnbItemsContainer = styled.div`
  max-width: 1200px;
  padding: 0 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 0;
  }
`;

export const LnbItemWrapper = styled.div`
  display: flex;
  gap: 32px;

  ${DisplayResolution.MobileAndMiniTablet} {
    gap: 20px;
  }
`;

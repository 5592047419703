import styled from "@emotion/styled";
import { useCustomRemoteConfig } from "src/businesslogics/_common/hackle/useCustomRemoteConfig";

export const RemoteSpartaLogo = () => {
  const logoSrc = useCustomRemoteConfig(
    "scc_seasonal_spartalog",
    "string",
    "https://static.spartacodingclub.kr/TeamSparta-Inc/scc-frontend/assets/icons/logo-active.png"
  );

  return <Logo src={logoSrc} alt="스파르타코딩클럽 로고" />;
};

const Logo = styled.img`
  width: 86px;
  height: 100%;
`;

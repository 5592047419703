import { InitKakao, shareKakao } from "lib/kakao";
import { sendCPLog } from "src/businesslogics/_common/logging";
import {
  getSpecialCurriculumData,
  getSpecialPaymentData,
  MONTHLY_SPECIAL,
} from "src/models/online/special";
import { useUserId, useUserInfo } from "../../_common/auth";
import { courseTitles } from "../../../models/online/courses";
import {
  useExecuteWithLoginV2,
  useNavigateWithLoginV2,
} from "../../_common/login";
import { logApplyCourse } from "src/businesslogics/_common/loggingV2";
import { getTutorName } from "src/models/online/tutors/tutors.models";
import { extractNumbers } from "src/businesslogics/_common/utils/extractNumbers"; //2월 월간코딩 연장으로 3월에만 보여야 보여야하는 div 확인 변수

//2월 월간코딩 연장으로 3월에만 보여야 보여야하는 div 확인 변수
export const isMarch = new Date().getMonth() + 1 === 3;

export const isMonthlyTrial = (url) => {
  return (
    MONTHLY_SPECIAL === url ||
    [
      "chatgpt",
      "nbfree",
      "wf_free",
      "ai-virtual",
      "codingtest",
      "androidvsios",
    ].includes(url)
  );
};

export const isFastQna = (url) => {
  return ["chatgpt", "ai-virtual", "wf_free", "photos"].includes(url);
};

export const isIn24HoursAnswer = (url) => {
  return ["codingtest"].includes(url);
};

export const isChannelTalkAnswer = (url) => {
  return ["nbfree"].includes(url);
};

export const getMonthlyTrialTitle = () => {
  return courseTitles[MONTHLY_SPECIAL];
};

export const useAddToCartSpecial = (url, courseDetail?) => {
  const navigateWithLogin = useNavigateWithLoginV2();
  return (label) => {
    const paymentData = getSpecialPaymentData(url);
    logApplyCourse({
      course_id: courseDetail?.course.id ?? paymentData.cid,
      course_title: courseDetail?.course.title ?? paymentData.title,
      is_free: true,
      tutor: getTutorName(url),
      show_price:
        courseDetail?.price.discount_price ??
        extractNumbers(paymentData.discountPrice),
      discount_reason: "무료강의",
      display_price:
        courseDetail?.price.original_price ??
        extractNumbers(paymentData.displayPrice),
      location: label,
    });

    navigateWithLogin(
      `${process.env.ONLINE_URL}/payment/${
        courseDetail?.course.id ?? paymentData.cid
      }`,
    );
  };
};

export const copyToClipboard = async (userId, userName) => {
  try {
    let url = `${process.env.BASE_URL}/online/special/${MONTHLY_SPECIAL}?f_name=${userName}&f_uid=${userId}`;
    await navigator.clipboard.writeText(encodeURI(url));
    alert("링크가 복사되었어요! 친구에게 공유하고 혜택도 받아보세요 :)");
  } catch (err) {
    console.log(err);
  }
};

export const copyToClipboardNb = async (userId) => {
  try {
    let url = `${process.env.BASE_URL}/nb?f_uid=${userId}`;
    await navigator.clipboard.writeText(encodeURI(url));
    alert("링크가 복사되었어요! 친구에게 공유하고 혜택도 받아보세요 :)");
  } catch (err) {
    console.log(err);
  }
};

export const shareKakaoMonthlySpecial = async (userId, userName) => {
  InitKakao();
  const { duration } = getSpecialCurriculumData(MONTHLY_SPECIAL).lectures;

  shareKakao(88356, {
    monthly_special: MONTHLY_SPECIAL,
    THU: `https://static.spartacodingclub.kr/meta/og-rcmd-free.png`,
    _id: userId,
    name: userName,
  });
};

export const useOnClickCopy = () => {
  const userId = useUserId();
  const userName = useUserInfo("name");
  const withLogin = useExecuteWithLoginV2();

  return (pageName, logData, isFloating?) => {
    sendCPLog(
      `scc_${pageName}_click_${isFloating ? "floating" : "button"}`,
      logData,
    );
    withLogin(
      () => {
        copyToClipboard(userId, userName);
      },
      () => {
        alert("친구에게 추천하고 혜택을 받으려면 \n로그인이 필요해요.");
      },
    );
  };
};

export const useOnClickCopyNb = () => {
  const userId = useUserId();
  const executeWithLogin = useExecuteWithLoginV2();
  return () =>
    executeWithLogin(
      (clickButton, logData) => {
        copyToClipboardNb(userId);
        sendCPLog(clickButton, logData);
      },
      () => {
        alert("친구에게 추천하고 혜택을 받으려면 \n로그인이 필요해요.");
      },
    );
};

export const useOnClickShare = () => {
  const userId = useUserId();
  const userName = useUserInfo("name");
  const withLogin = useExecuteWithLoginV2();

  return (pageName, logData, isFloating?) => {
    sendCPLog(
      `scc_${pageName}_click_${isFloating ? "floating" : "button"}`,
      logData,
    );
    withLogin(
      () => {
        shareKakaoMonthlySpecial(userId, userName);
      },
      () => {
        alert("친구에게 추천하고 혜택을 받으려면 \n로그인이 필요해요.");
      },
    );
  };
};

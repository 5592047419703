import axios from "axios";
import { useQuery } from "react-query";
import { FundingCourses } from "../../models/_archive/catalog.model";
import { useEffect, useState } from "react";
import { useUserId } from "../../businesslogics/_common/auth";
import { CatalogCourse } from "src/models/catalog/catalogCourse";
import { MajorCategory } from "src/models/catalog/category";
import { kdcCourseData } from "src/models/kdc/courses.model";

export const useGetCurrentFundingCount = (course_id) => {
  return useQuery(
    ["getCurrentFundingCount", course_id],
    () =>
      axios.get(
        `${process.env.ONLINE_API_URL}/v2/courses/funding/${course_id}`
      ),
    {
      // enabled: course_id.length > 20,
    }
  );
};

export const useGetFundingEndDate = (courseId) => {
  const [fundingEndDate, setFundingEndDate] = useState(null);

  useEffect(() => {
    const fetchFundingCourse = async () => {
      const course = FundingCourses.find((item) => item.courseId === courseId);
      if (course) {
        setFundingEndDate(course.fundingEndDate);
      }
    };

    fetchFundingCourse();
  }, [courseId]);

  return fundingEndDate;
};

export const useGetIsNewCourseNoticeActive = (courseTitle) => {
  const userId = useUserId();
  return useQuery(["notice", userId, courseTitle], () => {
    if (userId) {
      return axios.get(
        `${
          process.env.API_URL
        }/new-course-notice?user_id=${userId}&course_title=${encodeURIComponent(
          courseTitle
        )}`
      );
    }
  });
};

export const getCoursePriceFromApi = async (courseId) => {
  try {
    const res = await axios.get(
      `${process.env.API_URL}/price/courses/${courseId}`
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const useCoursePrice = (courseId) => {
  return useQuery(["price", courseId], () => getCoursePriceFromApi(courseId));
};

export const getCatalogDetailsV2: () => Promise<CatalogCourse[]> = async () => {
  try {
    const res = await axios.get(`${process.env.API_URL}/catalog/v2`);
    return res.data?.map((course) => {
      if (Object.keys(kdcCourseData).includes(course.keyword)) {
        course.isGovCourse = true;
      }
      return course;
    });
  } catch (e) {
    console.log(e);
  }
};

export const useCatalogDetails = () => {
  return useQuery<CatalogCourse[]>("catalogV2", getCatalogDetailsV2);
};

export const getCategoriesV2 = async () => {
  try {
    const res = await axios.get(`${process.env.API_URL}/category`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const useCategoriesV2 = () => {
  return useQuery<MajorCategory[]>("categoryV2", getCategoriesV2);
};

import styled from "@emotion/styled";
import { gnbMobileHeight, zIndexGnb } from "../../../../styles/variables";
import { fontPretendard } from "../../../../styles/fonts";
import { DisplayResolution } from "src/styles/themes/device";

export const Wrapper = styled.div<{ isTabOpened; fixed }>`
  position: fixed;
  z-index: ${zIndexGnb};
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  ${({ fixed }) => fixed && "position: fixed"};
  width: 100%;
  box-shadow: inset 0px -2px 0px #eef3f6;

  ${({ isTabOpened }) => isTabOpened && `position: fixed;`}
  // 기존 페이지의 style_old.css override 방지용
  * {
    word-break: normal;
    white-space: nowrap;
    box-sizing: border-box;

    &:before,
    &:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
`;

export const TopMenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${gnbMobileHeight}px;
  padding: 0 16px;
  z-index: ${zIndexGnb};
  box-shadow: inset 0px -2px 0px #eef3f6;
  background-color: white;

  flex-shrink: 0;
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 54px;
`;

export const LeftBtnWrapper = styled(BtnWrapper)`
  justify-content: flex-start;
  width: fit-content;
`;

export const RightBtnWrapper = styled(BtnWrapper)`
  justify-content: flex-end;
`;

export const PageName = styled.div`
  font-family: ${fontPretendard};
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #141617;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MypageBtn = styled.div``;

export const GnbRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const CatalogSearchWrapper = styled.div`
  display: block;
  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const HamburgerSVGWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  margin-right: 18px;
`;

export const SearchLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

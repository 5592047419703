import { useLoadableRemoteConfig } from "@hackler/react-sdk";

/**
 * defaultValue는 hackle에서 설정한 파라미터 유형과 같은 type이여야 한다.
 */

export const useCustomRemoteConfig = (
  key: string,
  type: "string" | "number" | "boolean" | "json",
  defaultValue?: string | number | boolean
) => {
  const { isLoading, remoteConfig } = useLoadableRemoteConfig();

  switch (type) {
    case "string":
      return remoteConfig.get(key, (defaultValue as string) || "");
    case "number":
      return remoteConfig.get(key, (defaultValue as number) ?? 0);
    case "boolean":
      return remoteConfig.get(key, (defaultValue as boolean) ?? false);
    case "json":
      const parameterValue = remoteConfig.get(key, "defaultValue");

      if (isLoading || parameterValue === "defaultValue") {
        return null;
      }

      return JSON.parse(parameterValue);
    default:
      throw new Error("지원하지 않는 remote config 타입입니다");
  }
};

export const SpartaLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="24"
      viewBox="0 0 82 24"
      fill="none"
    >
      <g clipPath="url(#clip0_745_15824)">
        <path
          d="M38.5838 20.8574V21.2868H38.9955V21.7153H39.4072V22.1447H39.819V22.574H39.4072V23.0038H38.9955V23.4332H38.5838V23.8625H37.7603V23.434H38.172V23.0046H38.5838V22.5749H38.9955V22.1455H38.5838V21.7153H38.172V21.2868H37.7603V20.8574H38.5838Z"
          fill="#E8344E"
        />
        <path
          d="M37.2665 20.8953H36.5493V21.3094H36.1441V21.7313H35.7599V22.1458H35.3548V22.5674H34.971V22.9819H34.5659V23.4038H34.1821V23.8254H34.8994V23.4038H35.2831V22.9893H35.6887V22.5674H36.0721V22.1533H36.4776V21.7313H36.861V21.3168H37.2665V20.8953Z"
          fill="#E8344E"
        />
        <path
          d="M29.767 23.8633V23.434H29.3552V23.0046H28.9435V22.5749H28.5317V22.1455H28.9435V21.7153H29.3552V21.2868H29.767V20.8574H30.5905V21.2868H30.1787V21.7153H29.767V22.1447H29.3552V22.574H29.767V23.0038H30.1787V23.4332H30.5905V23.8625L29.767 23.8633Z"
          fill="#E8344E"
        />
        <path
          d="M40.1932 0L2.78262 7.30075L-0.00244141 16.3894L6.29771 23.616L31.0787 18.7822L32.4614 24L38.755 17.2848L43.7079 16.3185L46.4929 7.22658L40.1932 0Z"
          fill="#E8344E"
        />
        <path
          d="M5.03672 14.3616V13.5177H4.25439V12.6738H5.90591L5.94709 13.5177H8.12112V11.8283H5.03672V10.9861H4.29557V9.29666H5.03672V8.45483H9.0772V9.29872H9.81834V10.1426H8.16723V9.29872H5.94874V10.9881H9.07802V11.83H9.81917V13.5194H9.07802V14.3633L5.03672 14.3616Z"
          fill="white"
        />
        <path
          d="M17.6265 14.3616V10.1426H18.2062V9.29872H19.0495V8.45483H21.5792V9.29872H22.4221V10.1426H23.0022V14.3616H21.5792V12.6722H19.0495V14.3616H17.6265ZM19.0495 11.83H21.5792V10.1405H20.736V9.29872H19.8927V10.1426H19.0495V11.83Z"
          fill="white"
        />
        <path
          d="M36.293 14.3616V10.1426H36.8735V9.29872H37.7164V8.45483H40.2457V9.29872H41.089V10.1426H41.6687V14.3616H40.2457V12.6722H37.716V14.3616H36.293ZM37.716 11.83H40.2457V10.1405H39.4025V9.29872H38.5617V10.1426H37.716V11.83Z"
          fill="white"
        />
        <path
          d="M32.0205 14.3616V9.29872H30.2446V8.45483H35.3042V9.29872H33.5748V14.3616H32.0205Z"
          fill="white"
        />
        <path
          d="M15.8912 9.29872V8.45483H11.9108V9.30613H11.3101V14.3616H12.6441V11.8168H15.8912V10.9729H16.4034V9.29872H15.8912ZM15.0924 10.9729H12.6441V9.29872H15.0903L15.0924 10.9729Z"
          fill="white"
        />
        <path
          d="M29.3065 13.5177V12.6738H28.4632V11.6981H29.2567V10.9667H29.8862V9.29872H29.3065V8.45483H25.2466V9.30613H24.6401V14.3616H26.0199V11.8304H26.9912V12.7736H27.7064V13.5111H28.4698L28.4645 14.3616H30.1506V13.5177H29.3065ZM26.0199 10.9869V9.29748H28.4645V10.1418L28.4698 10.9857L26.0199 10.9869Z"
          fill="white"
        />
        <path
          d="M65.1862 3.04785H64.3071V3.93747H65.1862V3.04785Z"
          fill="#E8344E"
        />
        <path
          d="M66.1843 3.04785H65.3052V3.93747H66.1843V3.04785Z"
          fill="#E8344E"
        />
        <path
          d="M69.1872 3.04785H68.3081V3.93747H69.1872V3.04785Z"
          fill="#E8344E"
        />
        <path
          d="M68.1893 3.04785H67.3003V3.93747H68.1893V3.04785Z"
          fill="#E8344E"
        />
        <path
          d="M54.4606 5.0542H53.5815V5.94382H54.4606V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M51.4583 8.05908H50.5693V8.9487H51.4583V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 8.05908H51.5767V8.9487H52.4558V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M51.4583 7.06079H50.5693V7.94052H51.4583V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M51.4583 6.0625H50.5693V6.94223H51.4583V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 9.06714H51.5767V9.94687H52.4558V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M54.4606 9.06714H53.5815V9.94687H54.4606V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 5.0542H51.5767V5.94382H52.4558V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 7.06079H51.5767V7.94052H52.4558V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 6.0625H51.5767V6.94223H52.4558V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M53.4637 5.0542H52.5747V5.94382H53.4637V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M53.4637 9.06714H52.5747V9.94687H53.4637V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M59.2707 5.0542H58.3916V5.94382H59.2707V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M59.2707 7.06079H58.3916V7.94052H59.2707V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M59.2707 6.0625H58.3916V6.94223H59.2707V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M59.2707 8.05908H58.3916V8.9487H59.2707V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M60.2687 8.05884H59.3896V8.94846H60.2687V8.05884Z"
          fill="#E8344E"
        />
        <path
          d="M60.2687 6.0625H59.3896V6.94223H60.2687V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M60.2687 7.06079H59.3896V7.94052H60.2687V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M58.2732 9.06714H57.3843V9.94687H58.2732V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 9.06714H56.3867V9.94687H57.2658V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 6.0625H55.3794V6.94223H56.2684V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 7.06079H55.3794V7.94052H56.2684V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M59.2707 9.06714H58.3916V9.94687H59.2707V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 8.05908H55.3794V8.9487H56.2684V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 8.05908H56.3867V8.9487H57.2658V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M58.2732 5.0542H57.3843V5.94382H58.2732V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 7.06079H56.3867V7.94052H57.2658V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 6.0625H56.3867V6.94223H57.2658V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 5.0542H56.3867V5.94382H57.2658V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M66.1843 9.06714H65.3052V9.94687H66.1843V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M65.1862 7.06079H64.3071V7.94052H65.1862V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M65.1862 6.0625H64.3071V6.94223H65.1862V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M65.1862 4.05615H64.3071V4.93588H65.1862V4.05615Z"
          fill="#E8344E"
        />
        <path
          d="M65.1862 5.0542H64.3071V5.94382H65.1862V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M66.1843 4.05615H65.3052V4.93588H66.1843V4.05615Z"
          fill="#E8344E"
        />
        <path
          d="M66.1843 5.0542H65.3052V5.94382H66.1843V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M66.1843 7.06079H65.3052V7.94052H66.1843V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M66.1843 6.0625H65.3052V6.94223H66.1843V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M66.1843 8.05908H65.3052V8.9487H66.1843V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M62.1839 8.05884H61.2949V8.94846H62.1839V8.05884Z"
          fill="#E8344E"
        />
        <path
          d="M63.1818 9.06714H62.3027V9.94687H63.1818V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M63.1818 6.0625H62.3027V6.94223H63.1818V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M63.1818 7.06079H62.3027V7.94052H63.1818V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M62.1839 6.0625H61.2949V6.94223H62.1839V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M63.1818 8.05884H62.3027V8.94846H63.1818V8.05884Z"
          fill="#E8344E"
        />
        <path
          d="M65.1862 9.06714H64.3071V9.94687H65.1862V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M64.1893 9.06714H63.3003V9.94687H64.1893V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M63.1818 5.0542H62.3027V5.94382H63.1818V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M64.1893 5.0542H63.3003V5.94382H64.1893V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M62.1839 7.06079H61.2949V7.94052H62.1839V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M65.1862 8.05908H64.3071V8.9487H65.1862V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M68.1893 9.06714H67.3003V9.94687H68.1893V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M69.1872 6.0625H68.3081V6.94223H69.1872V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M69.1872 8.05884H68.3081V8.94846H69.1872V8.05884Z"
          fill="#E8344E"
        />
        <path
          d="M69.1872 5.0542H68.3081V5.94382H69.1872V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M69.1872 7.06079H68.3081V7.94052H69.1872V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M68.1893 8.05884H67.3003V8.94846H68.1893V8.05884Z"
          fill="#E8344E"
        />
        <path
          d="M68.1893 7.06079H67.3003V7.94052H68.1893V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M68.1893 6.0625H67.3003V6.94223H68.1893V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M69.1872 9.06714H68.3081V9.94687H69.1872V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M68.1893 5.0542H67.3003V5.94382H68.1893V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M74.1056 6.0625H73.2266V6.94223H74.1056V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M71.1033 9.06714H70.2144V9.94687H71.1033V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M74.1056 5.0542H73.2266V5.94382H74.1056V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M74.1056 8.05908H73.2266V8.9487H74.1056V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M74.1056 9.06714H73.2266V9.94687H74.1056V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M75.1027 9.06714H74.2236V9.94687H75.1027V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M75.1027 7.06079H74.2236V7.94052H75.1027V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M73.1077 5.0542H72.2188V5.94382H73.1077V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M75.1027 6.0625H74.2236V6.94223H75.1027V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M75.1027 8.05908H74.2236V8.9487H75.1027V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M74.1056 7.06079H73.2266V7.94052H74.1056V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M72.0998 9.06714H71.2207V9.94687H72.0998V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M72.0998 5.0542H71.2207V5.94382H72.0998V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M71.1033 6.0625H70.2144V6.94223H71.1033V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M71.1033 7.06079H70.2144V7.94052H71.1033V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M71.1033 8.05908H70.2144V8.9487H71.1033V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M71.1033 5.0542H70.2144V5.94382H71.1033V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M72.0998 6.0625H71.2207V6.94223H72.0998V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M72.0998 8.05908H71.2207V8.9487H72.0998V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M72.0998 7.06079H71.2207V7.94052H72.0998V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M80.0412 9.06714H79.1621V9.94687H80.0412V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M79.0437 11.0833H78.1548V11.9729H79.0437V11.0833Z"
          fill="#E8344E"
        />
        <path
          d="M81.0387 5.0542H80.1597V5.94382H81.0387V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M80.0412 10.0657H79.1621V10.9553H80.0412V10.0657Z"
          fill="#E8344E"
        />
        <path
          d="M79.0437 9.06714H78.1548V9.94687H79.0437V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M79.0437 5.0542H78.1548V5.94382H79.0437V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M80.0412 11.0833H79.1621V11.9729H80.0412V11.0833Z"
          fill="#E8344E"
        />
        <path
          d="M81.0387 6.0625H80.1597V6.94223H81.0387V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M81.0387 7.06079H80.1597V7.94052H81.0387V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M80.0412 5.0542H79.1621V5.94382H80.0412V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M81.0387 10.0657H80.1597V10.9553H81.0387V10.0657Z"
          fill="#E8344E"
        />
        <path
          d="M81.0387 8.05884H80.1597V8.94846H81.0387V8.05884Z"
          fill="#E8344E"
        />
        <path
          d="M78.0358 5.0542H77.1567V5.94382H78.0358V5.0542Z"
          fill="#E8344E"
        />
        <path
          d="M80.0412 6.0625H79.1621V6.94223H80.0412V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M81.0387 9.06714H80.1597V9.94687H81.0387V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M80.0412 8.05908H79.1621V8.9487H80.0412V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M80.0412 7.06079H79.1621V7.94052H80.0412V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M78.0358 8.05908H77.1567V8.9487H78.0358V8.05908Z"
          fill="#E8344E"
        />
        <path
          d="M78.0358 11.0833H77.1567V11.9729H78.0358V11.0833Z"
          fill="#E8344E"
        />
        <path
          d="M78.0358 9.06714H77.1567V9.94687H78.0358V9.06714Z"
          fill="#E8344E"
        />
        <path
          d="M77.0389 6.0625H76.1499V6.94223H77.0389V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M77.0389 8.05884H76.1499V8.94846H77.0389V8.05884Z"
          fill="#E8344E"
        />
        <path
          d="M78.0358 6.0625H77.1567V6.94223H78.0358V6.0625Z"
          fill="#E8344E"
        />
        <path
          d="M78.0358 7.06079H77.1567V7.94052H78.0358V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M77.0389 7.06079H76.1499V7.94052H77.0389V7.06079Z"
          fill="#E8344E"
        />
        <path
          d="M51.4583 15.5562H50.5693V16.4359H51.4583V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M54.4606 18.5608H53.5815V19.4405H54.4606V18.5608Z"
          fill="#E8344E"
        />
        <path
          d="M51.4583 16.5547H50.5693V17.4344H51.4583V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M51.4583 17.553H50.5693V18.4426H51.4583V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M54.4606 14.5483H53.5815V15.438H54.4606V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 15.5562H51.5767V16.4359H52.4558V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 16.5547H51.5767V17.4344H52.4558V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M53.4637 18.5608H52.5747V19.4405H53.4637V18.5608Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 18.561H51.5767V19.4408H52.4558V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 14.5483H51.5767V15.438H52.4558V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M52.4558 17.553H51.5767V18.4426H52.4558V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M53.4637 14.5483H52.5747V15.438H53.4637V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 18.561H55.3794V19.4408H56.2684V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 17.553H55.3794V18.4426H56.2684V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 15.5562H56.3867V16.4359H57.2658V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 16.5547H56.3867V17.4344H57.2658V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 14.5483H56.3867V15.438H57.2658V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 13.5498H56.3867V14.4295H57.2658V13.5498Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 17.553H56.3867V18.4426H57.2658V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 12.5415H56.3867V13.4311H57.2658V12.5415Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 13.5498H55.3794V14.4295H56.2684V13.5498Z"
          fill="#E8344E"
        />
        <path
          d="M57.2658 18.561H56.3867V19.4408H57.2658V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 15.5562H55.3794V16.4359H56.2684V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 14.5483H55.3794V15.438H56.2684V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 12.5415H55.3794V13.4311H56.2684V12.5415Z"
          fill="#E8344E"
        />
        <path
          d="M56.2684 16.5547H55.3794V17.4344H56.2684V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M63.2219 14.5483H62.3428V15.438H63.2219V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M62.2238 14.5483H61.3447V15.438H62.2238V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M62.2238 16.5547H61.3447V17.4344H62.2238V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M62.2238 18.561H61.3447V19.4408H62.2238V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M62.2238 17.553H61.3447V18.4426H62.2238V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M62.2238 15.5562H61.3447V16.4359H62.2238V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M63.2219 15.5562H62.3428V16.4359H63.2219V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M63.2219 18.5608H62.3428V19.4405H63.2219V18.5608Z"
          fill="#E8344E"
        />
        <path
          d="M63.2219 16.5547H62.3428V17.4344H63.2219V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M63.2219 17.553H62.3428V18.4426H63.2219V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M60.2189 14.5483H59.3398V15.438H60.2189V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M59.2215 15.5562H58.3325V16.4359H59.2215V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M59.2215 14.5483H58.3325V15.438H59.2215V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M59.2215 16.5547H58.3325V17.4344H59.2215V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M60.2189 18.561H59.3398V19.4408H60.2189V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M61.2259 18.561H60.3369V19.4408H61.2259V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M59.2215 17.553H58.3325V18.4426H59.2215V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M60.2189 15.5562H59.3398V16.4359H60.2189V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M60.2189 17.553H59.3398V18.4426H60.2189V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M60.2189 16.5547H59.3398V17.4344H60.2189V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M68.3776 18.5608H67.4985V19.4405H68.3776V18.5608Z"
          fill="#E8344E"
        />
        <path
          d="M68.3776 17.553H67.4985V18.4426H68.3776V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M66.3722 12.5415H65.4932V13.4311H66.3722V12.5415Z"
          fill="#E8344E"
        />
        <path
          d="M67.3797 14.5483H66.4907V15.438H67.3797V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M67.3797 18.5608H66.4907V19.4405H67.3797V18.5608Z"
          fill="#E8344E"
        />
        <path
          d="M68.3776 14.5483H67.4985V15.438H68.3776V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M69.3747 15.5562H68.4956V16.4359H69.3747V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M69.3747 16.5547H68.4956V17.4344H69.3747V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M68.3776 16.5547H67.4985V17.4344H68.3776V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M68.3776 15.5562H67.4985V16.4359H68.3776V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M69.3747 17.553H68.4956V18.4426H69.3747V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M66.3722 13.5498H65.4932V14.4295H66.3722V13.5498Z"
          fill="#E8344E"
        />
        <path
          d="M65.3743 16.5547H64.4854V17.4344H65.3743V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M66.3722 14.5483H65.4932V15.438H66.3722V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M65.3743 14.5483H64.4854V15.438H65.3743V14.5483Z"
          fill="#E8344E"
        />
        <path
          d="M65.3743 17.553H64.4854V18.4426H65.3743V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M65.3743 18.561H64.4854V19.4408H65.3743V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M65.3743 13.5498H64.4854V14.4295H65.3743V13.5498Z"
          fill="#E8344E"
        />
        <path
          d="M65.3743 15.5562H64.4854V16.4359H65.3743V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M66.3722 15.5562H65.4932V16.4359H66.3722V15.5562Z"
          fill="#E8344E"
        />
        <path
          d="M66.3722 16.5547H65.4932V17.4344H66.3722V16.5547Z"
          fill="#E8344E"
        />
        <path
          d="M66.3722 17.553H65.4932V18.4426H66.3722V17.553Z"
          fill="#E8344E"
        />
        <path
          d="M66.3722 18.561H65.4932V19.4408H66.3722V18.561Z"
          fill="#E8344E"
        />
        <path
          d="M65.3743 12.5415H64.4854V13.4311H65.3743V12.5415Z"
          fill="#E8344E"
        />
      </g>
      <defs>
        <clipPath id="clip0_745_15824">
          <rect width="81.041" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { atom } from "jotai";

export const isLoginModalShowAtom = atom(false);

export const nextPageAfterLoginAtom = atom<string | null>("");

export const toastStateAtom = atom({
  message: "",
  isVisible: false,
  isCenter: false,
});

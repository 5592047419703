import { NbCourse, kdcCourseData } from "src/models/kdc/courses.model";

export const getTitleFlattened = (title: string) =>
  title?.replaceAll(" ", "").replace("[왕초보]", "");

export const getKdcCourseById = (kdcCourseId: string): NbCourse | undefined => {
  return Object.values(kdcCourseData).find(
    (course) => course.kdcCourseId === kdcCourseId,
  );
};

export const getKdcCoursesByIds = (kdcCourseIds: string[]) => {
  return kdcCourseIds
    .map((kdcCourseId) => getKdcCourseById(kdcCourseId))
    .filter((course) => course !== undefined);
};

export const convertOnlineCourseIdToKdcCourseId = (onlineCourseId: string) => {
  return Object.values(kdcCourseData).find(
    (course) => course.onlineCourseId === onlineCourseId,
  )?.kdcCourseId;
};

export const convertKdcCourseIdToonlineCourseId = (kdcCourseId: string) => {
  return Object.values(kdcCourseData).find(
    (course) => course.kdcCourseId === kdcCourseId,
  )?.onlineCourseId;
};

export const extractKdcKeywordFromUrl = (url) => {
  return url.replace("https://spartacodingclub.kr/online/", "");
};

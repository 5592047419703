import styled from "@emotion/styled";
import {
  gnbMobileHeight,
  lineBannerMobileHeight,
  zIndexGnbMenu,
} from "../../../../styles/variables";
import {
  breakpoints,
  DisplayResolution,
} from "../../../../styles/themes/device";
import { fontPretendard } from "../../../../styles/fonts";
import {
  additional,
  mBody1,
  mCaption2,
  mTitle2,
  neutralDay,
  scc,
  wBody3,
  wCaption2,
} from "@teamsparta/design-system";

export const ToolTipBox = styled.div<{
  show;
  isShort;
  showLineBanner;
}>`
  display: block;
  padding: 32px 0 0 0;
  border: #d7e0e6 solid 1px;
  border-radius: 10px;
  position: absolute;
  top: calc(
    40px +
      ${({ showLineBanner }) =>
        showLineBanner ? `${lineBannerMobileHeight}px` : "0px"}
  );
  right: ${({ show }) => (show ? "-151px" : 0)};
  background-color: white;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  z-index: ${({ show }) => (show ? 1000 : "unset")};
  width: 282px;
  transform: translateX(-50%);
  box-shadow: 0px 8px 24px rgba(95, 102, 107, 0.12);
  font-family: "Pretendard";
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::after {
    border-color: #ffff transparent;
    border-style: solid;
    border-width: 0 6px 8px 6.5px;
    content: "";
    display: block;
    right: 5%;
    position: absolute;
    top: -7px;
    width: 0;
    z-index: 1;
  }
  &::before {
    border-color: #d7e0e6 transparent;
    border-style: solid;
    border-width: 0 6px 8px 6.5px;
    content: "";
    display: block;
    right: 5%;
    position: absolute;
    top: -8px;
    width: 0;
    z-index: 0;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    height: calc(
      100dvh - ${gnbMobileHeight}px -
        ${({ showLineBanner }) =>
          showLineBanner ? `${lineBannerMobileHeight}px` : "0px"}
    );
    position: fixed;
    top: calc(
      49px +
        ${({ showLineBanner }) =>
          showLineBanner ? `${lineBannerMobileHeight}px` : "0px"}
    );
    right: ${({ show }) => (show ? "-50%" : 0)};
    background-color: white;
    opacity: ${({ show }) => (show ? 1 : 0)};
    visibility: ${({ show }) => (show ? "visible" : "hidden")};
    z-index: ${({ show }) => (show ? zIndexGnbMenu : "unset")};
    overflow: scroll;
    border-radius: 0;
    border: none;
  }
`;
export const Wrapper = styled.div<{ show; isShort; showLineBanner }>`
  padding: 0 16px 120px;
  width: 100%;
  //height: calc(100vh - ${gnbMobileHeight}px);
  height: calc(
    100vh - ${gnbMobileHeight}px -
      ${({ showLineBanner }) =>
        showLineBanner ? `${lineBannerMobileHeight}px` : "0px"}
  );
  position: fixed;
  top: calc(
    50px +
      ${({ showLineBanner }) =>
        showLineBanner ? `${lineBannerMobileHeight}px` : "0px"}
  );
  right: ${({ show }) => (show ? 0 : "-72%")};
  background-color: white;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  z-index: ${({ show }) => (show ? zIndexGnbMenu : "unset")};
  transition: all 0.1s ease;
  overflow: scroll;
`;

export const MyPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-bottom: 12px;
  gap: 16px;
`;

//UserInfoWrapper
export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 30px;
`;

export const GreetingContainer = styled.div`
  ::-moz-selection {
    background: rgba(0, 0, 0, 0);
  }

  ::selection {
    background: rgba(0, 0, 0, 0);
  }

  display: flex;
  flex-direction: column;

  font-family: ${fontPretendard};
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #141617;

  text-align: center;
  gap: 24px;
  ${DisplayResolution.MiniTablet} {
    font-size: 16px;
    padding: 0;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  max-width: 174px;
`;

//UserName
export const UserName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 174px;
`;

export const EmailContainer = styled.div`
  ${mCaption2}
  color:${neutralDay.gray70};
  text-align: left;
`;
export const LoginCta = styled.div`
  width: 66px;
  height: 36px;
  background: #e8344e;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fontPretendard};
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
  cursor: pointer;
  margin-top: 8px;
`;
//PointCouponContainer
export const PointCouponContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  margin: 0 16px;
`;

export const PointCouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
export const PointCouponTitle = styled.div`
  color: ${neutralDay.gray90};
  ${wBody3};
`;

export const PointCouponContent = styled.div`
  ${wBody3};
  color: ${scc.red100};
`;

export const RowsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  ${DisplayResolution.TabletAndDesktop} {
    width: 250px;
  }
`;

export const Group = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  ${DisplayResolution.TabletAndDesktop} {
    padding: 16px 0 0 0;
    gap: 0;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${neutralDay.gray20};
  margin: 0 0;
`;

export const ReversDivider = styled.div`
  width: 1px;
  height: 16px;
  background: ${neutralDay.gray20};
`;

export const MyPageBanner = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: ${additional.green10};
  margin-top: 16px;

  cursor: pointer;
  ${DisplayResolution.TabletAndDesktop} {
    padding: 12px;
    margin: 16px 0;
  }
`;

export const BanenrContainer = styled.div`
  display: flex;
  width: 201.5px;
  align-items: center;
  gap: 12px;
`;

export const BannerIconWrapper = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  padding: 5.76px 1.474px 7.103px 1.875px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BannerSubText = styled.p`
  ${wCaption2};
  color: ${neutralDay.gray70};
`;

export const BannerText = styled.p`
  ${mTitle2};
  color: ${neutralDay.gray90};

  ${DisplayResolution.TabletAndDesktop} {
    ${mBody1};
    color: ${neutralDay.gray90};
  }
`;

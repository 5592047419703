import { tutorsDto } from "./tutors";

export const getTutorsData = (url: string): tutorsDto | tutorsDto[] => {
  switch (url) {
    case "game":
    case "data_v2":
      return tutors("이동현");
    case "web":
    case "sql":
    case "python":
    case "automation":
    case "lang_python":
    case "finance_automation":
    case "dataforbusiness":
    case "webplus":
    case "web_mobile":
    case "web_calendar":
    case "coding101":
    case "chatgpt":
    case "web_basic":
      return tutors("이범규");
    case "ml_basic":
      return tutors("이태희");
    case "spring":
      return tutors("최원빈");
    case "developer101":
    case "algorithm":
      return tutors("남병관");
    case "algo":
      return tutors("박현준");
    case "lang_java":
      return tutors("이윤성");
    case "lang_cpp":
      return tutors("김훈석");
    case "lang_js":
      return tutors("김신록");
    case "ml_ai":
      return tutors("임주혜");
    case "zepeto":
      return tutors("최규빈");
    case "app":
      return tutors("임상원");
    case "nocode":
      return tutors("신진호");
    case "firststep":
    case "app_intro":
      return tutors("황영상");
    case "reactforbusiness":
      return tutors("임민영");
    case "react_plusforbusiness":
      return tutors("임민영");
    case "web_pubforbusiness":
      return tutors("임흥선");
    case "spring_plusforbusiness":
      return tutors("이태훈");
    case "nodeforbusiness":
      return tutors("강승현");
    case "node_plusforbusiness":
      return tutors("강승현");
    case "djangoforbusiness":
      return tutors("이바울");
    case "app_plusforbusiness":
      return tutors("김건희");
    case "iosforbusiness":
      return tutors("주정한");
    case "androidforbusiness":
      return tutors("최재흥");
    case "dlforbusiness":
      return tutors("이태희");
    case "pytorch_dlforbusiness":
      return tutors("이태희");
    case "ml_aiforbusiness":
      return tutors("임주혜");
    case "drill_awsforbusiness":
      return tutors("곽영호");
    case "aws_plusforbusiness":
      return tutors("곽영호");
    case "dockerforbusiness":
      return tutors("신현석");
    case "drill_gitforbusiness":
      return tutors("오시영");
    case "webflow":
    case "wf_free":
    case "ga4":
      return tutors("김진홍");
    case "sqld":
      return tutors("류혜련");
    case "java":
      return tutors("최원장");
    case "javascript":
      return tutors("최원장");
    case "chatgpt_clone":
    case "chatgpt_automation":
      return tutors("한서우");
    case "chatgpt_data":
      return tutors("김병훈");
    case "nbfree":
      return tutors("이성은");
    case "csharp":
      return tutors("김영호");
    case "typescript":
      return tutors("강창민");
    case "kotlin":
      return tutors("김진영");
    case "figma":
      return tutors("이다혜");
    case "sql_2023":
      return tutors("구다희");
    case "webChatgpt":
      return tutors("최지웅");
    case "automation_genai":
    case "automation_python":
      return tutors("김우현");
    case "gpt_300_online":
      return [tutors("한서우"), tutors("김병훈")];
  }
};

export const getTutorName = (url: string): string => {
  const data = getTutorsData(url);
  if (Array.isArray(data)) {
    return data.map((tutor) => tutor.name).join(", ");
  } else {
    return data?.name ?? "";
  }
};

const tutors = (name: string): tutorsDto => {
  switch (name) {
    case "이동현":
      return {
        name: "이동현",
        bio: [
          "현) 스파르타코딩클럽 온라인개발팀 팀장",
          "현) 게임개발종합반 튜터",
          "전) 오픈갤러리 개발팀장",
          "KAIST 전산학과 졸",
          "경남과학고 졸",
        ],
        image: null,
      };

    case "김건희":
      return {
        name: "김건희",
        bio: [
          "현) 한인텔 CTO",
          "현) 로블록스 인앱게임 게임사 CEO",
          "현) 스티키픽(STKYPiC) CTO",
          "전) 야놀자 W 디자인 호텔 개발 리드",
          "전) 인바이유(INBYU) 개발 리드",
          "전) 스파르타코딩클럽 2~9기 오프라인 튜터",
        ],
        image: "css/images/online/app_plus/sec01_img01.png",
      };

    case "이범규":
      return {
        name: "이범규",
        bio: [
          "스파르타코딩클럽 대표",
          "[웹개발 종합반] 외 3개 강의 튜터",
          "전) 본엔젤스 벤처캐피털 투자심사역",
          "전) 배달의민족 Software Engineer",
          "KAIST 산업 및 시스템공학과 졸",
        ],
        image: "assets/images/tutor/lee.png",
      };

    case "김준태":
      return {
        name: "김준태",
        bio: [
          "코로나 알리미 & 마스크 알리미 개발",
          "전) 광주인공지능사관학교 메인 강사",
          "전) 인공지능 상담 플랫폼 개발",
          "고려대학교 미디어학부/컴퓨터공학 졸",
        ],
        image: null,
      };

    case "신현석":
      return {
        name: "신현석",
        bio: [
          "현) 빅펄 소프트웨어 엔지니어",
          "전) 데브시스터즈 데이터 엔지니어",
          "카이스트 전산학부 학사",
        ],
        image: "assets/images/tutor/docker_tutor1-min.png",
      };

    case "이영욱":
      return {
        name: "이영욱",
        bio: [
          "현) 제이든소프트 대표",
          "전) 삼성전자 연구소 책임 연구원",
          "전) 넷가디언 연구원",
          "전) 안랩 연구원",
          "카이스트 전산학 시스템 해킹 전공 석사",
          "칭화대 전산학 학사",
        ],
        image: "css/images/online/stock/sec01_img01.png",
      };

    case "이태희":
      return {
        name: "이태희",
        bio: [
          "빵형의개발도상국-딥러닝 유튜브 채널 운영",
          " 인공지능 스타트업 The Matrix 대표",
          " 이큐브랩 연구개발 총괄",
          " 비주얼캠프 연구개발 매니저",
          " 성균관대 전기전자컴퓨터학과 졸",
        ],
        image: "css/images/online/ml_basic/sec01_img01.png",
      };

    case "남병관":
      return {
        name: "남병관",
        bio: [
          "현) 스파르타코딩클럽 CTO",
          "현) Java Spring 튜터",
          "현) 부트캠프 항해99 기술 멘토",
          "전) 삼성 SDS Software Engineer",
          "고려대학교 생명공학부 / 융합보안 졸",
        ],
        image: "css/images/online/spring/sec01_img01.png",
      };

    case "임민영":
      return {
        name: "임민영",
        bio: [
          "스파르타코딩클럽 튜터",
          " 전) 루비큐브 스튜디오 Front-end Engineer",
          " ㄴ DevSisters 산하 스튜디오",
          " 전) Sleek 개발팀장",
          " 전) UriClass Co-founder/CPO",
        ],
        image: "css/images/online/react/sec01_img01.jpg",
      };

    case "박현준":
      return {
        name: "박현준",
        bio: [
          "스파르타코딩클럽 7~8기 튜터",
          " Riiid Software Engineer",
          " 전) 리디북스 Software Engineer",
          " 전) 마이리얼트립 Software Engineer",
          " 포스텍 창의IT융합공학과",
        ],
        image: "css/images/online/algo/sec01_img01.jpg",
      };

    case "강승현":
      return {
        name: "강승현",
        bio: [
          "현) 이큐브랩 책임 연구원",
          " DevOps Engineer",
          " 스파르타코딩클럽 7~8기 오프라인 튜터",
        ],
        image: "assets/images/tutor/node.png",
      };

    case "이태훈":
      return {
        name: "이태훈",
        bio: [
          "L 전자 HE사업부 SW 엔지니어",
          "L 전자 자료구조 강의만족도 93%",
          " S대학교 컴공과 학사 / S대학교 컴공과 석사 졸",
        ],
        image: "css/images/online/spring_plus/sec01_img01.png",
      };

    case "오시영":
      return {
        name: "오시영",
        bio: [
          "스파르타코딩클럽 2~12기 튜터",
          " 전) 닷페이스 소프트웨어 엔지니어",
          " 전) 다음소프트 백엔드 개발자",
          " 전) 네이버 Edwith 웹 백엔드 리뷰어",
        ],
        image: "css/images/online/git/sec01_img01.png",
      };

    case "최재흥":
      return {
        name: "최재흥",
        bio: [
          "스파르타코딩클럽 7~11기 튜터",
          " 현) 딜라이트룸 알라미 안드로이드 앱 개발 그룹 테크 리드",
          " 전) 카플랫 안드로이드 앱 개발자",
          " 전) 넷스루 프론트 및 안드로이드 앱 개발자",
          " 포항공과대학교 컴퓨터공학과 학사",
        ],
        image: "css/images/online/android/sec01_img01.png",
      };

    case "이바울":
      return {
        name: "이바울",
        bio: [
          "스파르타코딩클럽 9~11기 튜터",
          " 현) 쏘카 CTO산하 모빌리티시스템팀 SW Engineer",
          " 전) 태피툰 코딩 기업교육 강사",
          " 전) 유니포인트 기업부설연구소 SW 개발자",
          " 동국대학교 컴퓨터공학과 석사 졸",
        ],
        image: "css/images/online/django/sec01_img01.png",
      };

    case "주정한":
      return {
        name: "주정한",
        bio: [
          "스파르타코딩클럽 9~11기 튜터",
          " 현) 육아 테크 서비스 '맘맘' 공동 창업 및 개발 총괄",
          " 전) 모바일 키보드 앱 '짤키' CTO(스냅챗 투자 유치)",
          " 전) 대치동 코딩학원 강사",
          " 한양대학교 컴퓨터공학과 학사",
        ],
        image: "css/images/online/ios/sec01_img01.png",
      };

    case "곽영호":
      return {
        name: "곽영호",
        bio: [
          "스파르타코딩클럽 5~11기 튜터",
          " 전) 식권대장 DevOps 엔지니어",
          " 전) 인터파크 도서 백엔드 개발자",
          " <알면 더 쉬운 도커 쿠버네티스> 저자",
        ],
        image: "css/images/online/aws_drill/sec01_img01.png",
      };

    case "이윤성":
      return {
        name: "이윤성",
        bio: [
          "스파르타코딩클럽 9기 튜터",
          " 현) 글로벌 IT회사 소프트웨어 엔지니어",
          " 전) 티맥스소프트 소프트웨어 엔지니어",
          " 아주대학교 소프트웨어학과 학사",
        ],
        image: "css/images/online/lang_java/sec01_img01.png",
      };

    case "김훈석":
      return {
        name: "김훈석",
        bio: [
          "스파르타코딩클럽 9~11기 튜터",
          " 글로벌 IT기업 Nexplayer 소프트웨어 엔지니어",
          " 경희대학교 전자공학과 졸",
        ],
        image: "css/images/online/lang_cpp/sec01_img01.png",
      };

    case "김신록":
      return {
        name: "김신록",
        bio: [
          "스파르타코딩클럽 7~8기 튜터",
          " 현) 직방 백엔드 개발자",
          " 전) 플리토 백엔드 개발자",
          " 전) 잡플래닛 백엔드 개발자",
        ],
        image: "css/images/online/lang_js/sec01_img01.png",
      };

    case "임흥선":
      return {
        name: "임흥선",
        bio: [
          "딜리헙 프론트앤드 개발자",
          "전 LGU+ 프론트앤드 개발자/선임연구원",
          "전 카닥 프론트앤드 개발자",
          "전 이큐브랩 개발자",
        ],
        image: "css/images/online/web_pub/thumb_tutor_web_pub.png",
      };

    case "임주혜":
      return {
        name: "임주혜",
        bio: [
          "BCG Data Scientist",
          "전) NH투자증권 Data Scientist",
          "전) (주)SK Data Scientist",
          "전) 미국 Earth Institute Data Scientist",
          "Columbia University 환경공학 석사 ",
          "카이스트 토목공학 학사",
        ],
        image: "css/images/online/ml_ai/thumb_tutor_ml_ai.png",
      };

    case "김서진":
      return {
        name: "김서진",
        bio: [
          "현) 커널로그 CDO",
          "전) 팀스파르타 데이터분석가",
          "연세 학부 졸, 석/박사 수료",
        ],
        image: null,
      };

    case "최규빈":
      return {
        name: "최규빈",
        bio: [
          "현) 디피앤스튜디오 메타버스 게임개발자",
          "스파르타코딩클럽 앱개발 종합반 미니 튜터",
          "숭실대학교 글로벌미래교육원 정보통신공학과 졸",
        ],
        image: "css/images/online/zepeto/sec01_img01.png",
      };

    case "임상원":
      return {
        name: "임상원",
        bio: [
          "현) azit 대표",
          "전) 모두닥 백엔드 인턴",
          "전) 스타트업 M사 웹개발 강사",
          "서울대학교 기계항공공학부 졸업",
        ],
        image: "assets/images/tmt_curri_detail/tutors/임상원.png",
      };

    case "신진호":
      return {
        name: "신진호",
        bio: [
          "현) 크래프톤 모바일 사업PM 팀장",
          "전) 액션스퀘어 프로덕션팀 팀장",
          "전) 네시삼십삼분 해외PM팀 팀장",
        ],
        image: "assets/images/tmt_curri_detail/tutors/신진호.png",
      };

    case "황영상":
      return {
        name: "황영상",
        bio: [
          "스파르타코딩클럽 커리큘럼 개발자",
          "[컴퓨터를 몰라도 할 수 있는 코딩 첫걸음] 외 2개 강의 튜터",
          "메이킹 챌린지 튜터",
          "내일배움캠프 수료",
        ],
        image: "assets/images/tmt_curri_detail/tutors/황영상.png",
      };
    case "김진홍":
      return {
        name: "김진홍",
        bio: [
          "현) 오프라이트, 공동창업자",
          "현) 마켓핏랩, 그로스 컨설턴트",
          "전) 쿼타북, 사업개발 팀장",
          "전) 시니어생활연구소, 공동 창업자",
          "전) 카카오벤처스, 투자팀 인턴",
          "고려대학교 경영학과 졸",
        ],
        image: "assets/images/tmt_curri_detail/tutors/김진홍.png",
      };
    case "류혜련":
      return {
        name: "류혜련",
        bio: [
          "현) 입시전문 교육 E사 전사DBA",
          "전) 행정안전부 차세대 시스템 구축",
          "오라클 SQL 개발자",
          "전) (주)코웨이 데이터분석을 위한",
          "DW 구축 프로젝트",
          "홍익대학교 정보컴퓨터 공학부 졸",
        ],
        image: "assets/images/tmt_curri_detail/tutors/류혜련.png",
      };
    case "최원장":
      return {
        name: "최원장",
        bio: [
          "전) 미국 E-commerce 기업 개발자",
          "전) SI 대기업 개발자",
          "전) 금융권 개발자",
          "전) 인터넷 은행 개발자",
        ],
        image: "assets/images/tmt_curri_detail/tutors/최원장_desktop.png",
      };
    case "한서우":
      return {
        name: "한서우",
        bio: [
          "현) 비지트 CTO",
          "현) 국제인공지능윤리협회 자문위원",
          "전) 뷰메진 CTO",
          "전) 한국전자기술연구원 컴퓨터 비전 연구원",
          "전) Webarter Inc. CTO, Co-founder",
          "전) 네이버 부트캠프 PY4E 멘토",
          "고려대학교 컴퓨터학과 박사 수료",
          "경희대학교 전자공학과 석사 졸",
        ],
        image: "assets/images/tmt_curri_detail/tutors/한서우_desktop.png",
      };
    case "김병훈":
      return {
        name: "김병훈",
        bio: [
          "현) 예비 유니콘 스타트업 소프트웨어 엔지니어",
          "전) 정부기관산하 연구기관 연구원",
        ],
        image: "assets/images/tmt_curri_detail/tutors/김병훈_desktop.png",
      };
    case "이성은":
      return {
        name: "이성은",
        bio: [],
        image: "",
      };
    case "최원빈":
      return {
        name: "최원빈",
        bio: [
          "현) 항해99 9기~ Spring 튜터",
          "전) 항해99 5기~8기 기술 매니저",
          "*항해99는 취업률 92%의 실무 최적화 개발자 취업 부트캠프입니다.",
        ],
        image: "",
      };
    case "김영호":
      return {
        name: "김영호",
        bio: [
          "현) 한국정보기술연구원(KITRI) - 유니티 강의",
          "현) 플레이소프트 - 프로그래밍 강의",
          "현) 경기 꿈의 대학 - 고등부 유니티 강의, 기타 출강",
          "전) 조이펀 실감형 콘텐츠 프로젝트 기반 창작자 양성 - 유니티 강의",
          "전) 레벨구구구",
        ],
        image: "",
      };
    case "강창민":
      return {
        name: "강창민",
        bio: [
          "현) DevCra CIO",
          "전) 캐스팅 CTO",
          "전) 머스트게임즈 연구소장",
          "전) 로그프레소 소프트웨어 엔지니어",
          "전) 스마일게이트 게임 서버 프로그래머",
        ],
        image: "",
      };
    case "김진영":
      return {
        name: "김진영",
        bio: [
          "현) 솔티랩 개발팀장",
          "현) 성균관대학교 박사 과정",
          "전) Best of the Best 8기",
        ],
        image: "",
      };
    case "이다혜":
      return {
        name: "이다혜",
        bio: ["현) UXUI 디자인 에이전시", "전) BOMAPP UXUI 디자이너"],
        image: "",
      };
    case "구다희":
      return {
        name: "구다희",
        bio: [
          "현) E - commerce 업계 데이터분석가",
          "전) 바로고, 데이터사이언스팀",
          "전) 레진엔터테인먼트, 미국전략실행팀",
          "성균관대학교 졸",
        ],
        image: "",
      };
    case "최지웅":
      return {
        name: "최지웅",
        bio: [
          "현) 스파르타코딩클럽 콘텐츠 리드",
          "전) 삼성 청년 SW 아카데미(SSAFY) 파이썬 트랙 대표 교수",
          "전) 코드잇 Contents Producer",
          "전) 에이럭스 전임 연구원",
        ],
        image: "",
      };
    case "김우현":
      return {
        name: "김우현",
        bio: [
          "현) 비현코의 자동화연구소 대표",
          "현) 기업 교육 프리랜서 강사",
          "전) 삼성 모바일 디스플레이 엔지니어",
          "전) 현대제철 기업교육(HRD)팀",
        ],
        image: "",
      };
    default:
      return null;
  }
};

export const FooterCustomerService = [
  {
    url: ["", "", "", `https://support.spartacodingclub.kr/`],
    title: "고객센터",
    text: [
      `홈페이지 우측 하단 [문의하기]를 통해<br/>1:1 채팅상담을 이용하실 수 있습니다.<br/>(전화상담을 원하시는 경우,<br/>채팅상담을 통해 신청부탁드립니다.)`,
      `채팅 상담 운영시간 :<br/>
        월요일 14:00-17:30 <br/>
        화~금요일 10:30-17:30`,
      "(점심시간 12:30-14:00 / 주말,공휴일 휴무)",
      "고객센터 바로가기",
    ],
  },
];
export const FooterNavItemV2 = [
  {
    url: [
      "/catalog",
      "https://nbcamp.spartacodingclub.kr/",
      "/nb",
      "/community",
      "/blog",
      "/event",
      "/exhibition",
    ],
    title: "서비스",
    text: [
      "전체 강의",
      "국비지원 부트캠프",
      "국비지원 강의",
      "커뮤니티",
      "블로그",
      "이벤트",
      "수강생 작품",
    ],
  },
  {
    url: [
      "https://hanghae99.spartacodingclub.kr/",
      "https://swjungle.net/",
      "https://easygpt.ai/",
      "http://www.hoy.im/",
      "https://www.effic.biz/",
    ],
    title: "패밀리 사이트",
    text: [
      "항해99",
      "SW사관학교 정글",
      "easyGPT",
      "데일리스크럼툴 호이",
      "CRM자동화 에픽",
    ],
  },
  {
    url: [
      "https://b2b.spartacodingclub.kr/",
      "https://sparta-builders.com/",
      "https://intellipick.spartacodingclub.kr/company",
    ],
    title: "기업 서비스",
    text: ["기업 교육", "외주 개발", "신입 개발자 채용"],
  },
  {
    url: [
      "https://spartacodingclub.career.greetinghr.com/",
      "https://spartacodingclub.career.greetinghr.com/",
      "https://sparta-career-b2b.oopy.io/",
      "https://career.spartacodingclub.kr/",
      "https://inblog.ai/teamsparta",
    ],
    title: "회사",
    text: [
      "강의 튜터 지원",
      "부트캠프 튜터 지원",
      "협력사 지원",
      "인재 채용",
      "팀블로그",
    ],
    tag: "채용중",
  },
];
// TODO: 사용하지 않음
export const FooterNavItems = [
  {
    href: `${process.env.BASE_URL}/sparta_online`,
    text: "스파르타 온라인",
  },
  {
    href: process.env.HANGHAE_URL,
    text: "항해99",
  },
  {
    href: process.env.CHANG_URL,
    text: "{창}",
  },
  {
    href: "https://swcamp.spartacodingclub.kr",
    text: "SW 캠프",
  },
  {
    href: "https://spartacodingclub.kr/nb",
    text: "국비지원교육",
  },
  {
    href: "https://intellipick.spartacodingclub.kr",
    text: "인텔리픽",
  },
  {
    href: "https://ddingdong.spartacodingclub.kr/",
    text: "초등학생 코딩",
  },
  {
    href: "https://spartacodingclub.kr/b2b",
    text: "기업 서비스",
  },
];

export const nbFooterNavItems = [
  {
    text: "HOME",
    href: "/",
  },
  {
    text: "스파르타코딩클럽",
    href: "/",
  },
  // {
  //     text: '내일배움단 플러스',
  //     href: 'https://sparta-nb-new.oopy.io'
  // },
];

export const FooterSNSItems = [
  {
    href: "https://www.instagram.com/spartacodingclub",
    text: "인스타그램",
    img: "instagram",
  },
  {
    href: "https://m.blog.naver.com/PostList.nhn?blogId=spartacoding&categoryNo=6&logCode=0&categoryName=%EC%88%98%EA%B0%95%EC%83%9D%C2%A0%EC%86%94%EC%A7%81%ED%9B%84%EA%B8%B0",
    text: "블로그",
    img: "blog",
  },

  {
    href: "https://www.youtube.com/channel/UC2Fh3XoQYNYTba-cigq5cAA",
    text: "유튜브",
    img: "youtube",
  },
];

export const FooterInfoFirstLineItems = [
  {
    href: "https://forms.gle/4kPLDbR8vVcvKVpd7",
    text: "튜터",
  },
  {
    href: "https://career.spartacodingclub.kr/",
    text: "채용",
  },
  {
    href: "https://sparta-career-b2b.oopy.io/",
    text: "협력사 지원",
  },
  {
    href: "https://swjungle.net/",
    text: "SW사관학교 정글",
  },
  {
    href: "https://easygpt.ai/",
    text: "easyGPT",
  },
];

export const FooterInfoSecondLineItems = [
  {
    href: "https://teamsparta.notion.site/7b1dc644460946f08bab08b794de685f",
    text: "개인정보처리방침",
  },
  {
    href: "https://teamsparta.notion.site/247d57da1322424d8e8c551df21a048e",
    text: "서비스 이용약관",
  },
  {
    href: "https://teamsparta.notion.site/6abff2edf5b04abebeed5c97e194b6f3",
    text: "환불 규정",
  },
];

export const InfoDropDownText = "팀스파르타(주) 사업자 정보";
export const InfoDropDownIconImg =
  process.env.STATIC_PATH + "/css/images/drop_down_icon.png";

export const BusinessInfoItems = [
  {
    title: "대표자:",
    value: "이범규",
  },
  {
    title: "사업자 등록번호:",
    value: "783-86-01715",
  },
  {
    title: "통신판매업 신고번호:",
    value: "2020-서울강남-02300",
  },
  {
    href: "https://drive.google.com/file/d/1xtT3BX_uowATTC7X788a0EYYTkVx551r/view?usp=sharing",
    value: "평생교육시설 신고번호: 제 661호",
  },
  {
    title: "주소:",
    value: "서울특별시 강남구 테헤란로44길 8 12층",
  },
  {
    title: "이메일:",
    value: "contact@teamsparta.co",
  },
  {
    title: "전화: ",
    value: "1522-8016",
  },
];

export const CopyRightText =
  "Copyright ©2022 TEAMSPARTA. All rights reserved.";

export const NoFooterPages = [
  "/signup_kko",
  "/coding1010",
  "/codingpeople/share",
  "/community/mobile",
];

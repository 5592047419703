import React from "react";
import { useFallbackImageSrc } from "src/businesslogics/_common/fallbackImage/useFallbackImageSrc";

interface Props {
  children: React.ReactElement;
}

export const ImageWrapperWithFallback: React.FC = ({ children }: Props) => {
  const { src, ...rest } = children.props;
  const { imgSrc, primarySrc, onErrorImage } = useFallbackImageSrc(src);

  return process.env.IS_PROD ? (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement, {
            src: primarySrc,
            ...rest,
          });
        }
      })}
    </>
  ) : (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement, {
            onError: onErrorImage,
            src: imgSrc,
            ...rest,
          });
        }
      })}
    </>
  );
};

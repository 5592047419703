import { getCookie, setCookie } from "../window/cookie";

export const createDeviceId = () => {
  let result = [];
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 20; i++) {
    result.push(chars.charAt(Math.floor(Math.random() * chars.length)));
  }
  return result.join("");
};

export const getDeviceId = () => {
  if (!getCookie("device_id")) {
    setCookie("device_id", createDeviceId(), 60);
  }
  return getCookie("device_id");
};

import { UserMetaData } from "../../queries/gnb";
import { logout } from "../../businesslogics/_common/auth";

type Anchor = {
  key: string;
  name: string;
  href?: string;
  onClick?: Function;
  className?: string;
  needLogin?: boolean;
  isLoginNeededToNavigate?: boolean;
  brand?: string;
};

export const sccAnchor: Anchor = {
  key: "scc",
  name: "스파르타코딩클럽",
  href: "/",
  brand: "온라인",
};

const catalogAnchor: Anchor = {
  key: "catalog",
  name: "코딩 단기 완성",
  href: "/catalog",
  brand: "온라인",
};

const nbAnchor: Anchor = {
  key: "nb",
  name: "국비지원",
  href: "/nb",
  brand: "내일배움단",
};

const changAnchor: Anchor = {
  key: "chang",
  name: "직장인 IT 창업",
  href: "https://chang.spartacodingclub.kr",
  brand: "창",
};

const hanghaeAnchor: Anchor = {
  key: "hanghae",
  name: "개발자 취업 캠프",
  href: "https://hanghae99.spartacodingclub.kr",
  brand: "항해",
};

const swcampAnchor: Anchor = {
  key: "swcamp",
  name: "IT 취업 캠프",
  href: "https://swcamp.spartacodingclub.kr",
  brand: "SW캠프",
};

const ddingdongAnchor: Anchor = {
  key: "ddingdong",
  name: "초등학생 코딩",
  href: "https://ddingdong.spartacodingclub.kr/",
  brand: "띵동코딩",
};

export const b2bAnchor: Anchor = {
  key: "b2b",
  name: "기업 서비스",
  href: "/b2b",
  brand: "기업 서비스",
};

export const blogAnchor: Anchor = {
  key: "blog",
  name: "블로그",
  href: "/blog",
  brand: "블로그",
};

export const communityAnchor: Anchor = {
  key: "community",
  name: "커뮤니티",
  href: "/community",
  brand: "커뮤니티",
};

export const productAnchors = [
  catalogAnchor,
  nbAnchor,
  changAnchor,
  hanghaeAnchor,
  swcampAnchor,
  ddingdongAnchor,
];

export const desktopNonProductAnchors = [blogAnchor, communityAnchor];

export const mobileNonProductAnchors = [b2bAnchor, communityAnchor, blogAnchor];

export enum CustomGnbAction {
  BACK,
  COURSE_SEARCH,
}

export const customGnb = {
  nb: {
    pageName: "국비지원",
  },
  b2b: {
    pageName: "기업교육",
  },
  community: {
    pageName: "커뮤니티",
  },
  blog: {
    pageName: "블로그",
  },
  catalog: {
    pageName: "코딩 단기 완성",
  },
};

export const classroomAnchor: Anchor = {
  key: "classroom",
  name: "내 강의실",
  href: `${process.env.ONLINE_URL}/classroom`,
};

export const pointAnchor: Anchor = {
  key: "point",
  name: "포인트",
  href: `${process.env.ONLINE_URL}/mypage/mypoint`,
  isLoginNeededToNavigate: true,
};

export const couponAnchor: Anchor = {
  key: "coupon",
  name: "쿠폰",
  href: `${process.env.ONLINE_URL}/mypage/mycoupon`,
  isLoginNeededToNavigate: true,
};

export const voucherAnchor: Anchor = {
  key: "voucher",
  name: "수강권",
  href: `${process.env.ONLINE_URL}/mypage/myvoucher`,
  isLoginNeededToNavigate: true,
};

export const certificateAnchor: Anchor = {
  key: "certificate",
  name: "수강증/수료증",
  href: `${process.env.ONLINE_URL}/mypage/mycertificate`,
  needLogin: true,
};

export const rcmdsfreeAnchor: Anchor = {
  key: "rcmdsfree",
  name: "친구추천",
  href: `${process.env.BASE_URL}/rcmdsfree`,
  needLogin: true,
};

export const faqAnchor: Anchor = {
  key: "faq",
  name: "자주 묻는 질문",
  href: "https://support.spartacodingclub.kr/faqs",
};

export const noticeAnchor: Anchor = {
  key: "notice",
  name: "공지사항",
  href: "https://support.spartacodingclub.kr/notices",
};

export const basicInfoAnchor: Anchor = {
  key: "basicInfo",
  name: "계정",
  href: `${process.env.ONLINE_URL}/mypage/mybasicinfo`,
};

export const logoutAnchor: Anchor = {
  key: "logout",
  name: "로그아웃",
  onClick: () => {
    const next = encodeURIComponent(window.location.href);
    window.location.href = `${process.env.ONLINE_URL}/logout?next=${next}`;
  },
};

export const myEnrollGoods = [
  pointAnchor,
  couponAnchor,
  voucherAnchor,
  certificateAnchor,
  rcmdsfreeAnchor,
];

export const qnas = [faqAnchor, noticeAnchor];

export const accounts = [basicInfoAnchor, logoutAnchor];

export class userMetaDataModel {
  public classroom;
  public point;
  public coupon;
  public voucher;

  constructor(public queryResultData: UserMetaData) {
    this.classroom =
      !!this.queryResultData &&
      this.queryResultData.is_enrolled_exist &&
      "수강중";
    this.point = !!this.queryResultData
      ? `${this.queryResultData.point_amount.toLocaleString()}원`
      : "";
    this.coupon = !!this.queryResultData
      ? `${this.queryResultData?.active_coupon_count}장`
      : "";
    this.voucher = !!this.queryResultData
      ? `${this.queryResultData?.active_voucher_count}장`
      : "";
  }
}

export const getLogEventName = (keyName) => {
  const gnbAnchorKeys = [
    "scc",
    "catalog",
    "nb",
    "chang",
    "hanghae",
    "blog",
    "community",
    "swcamp",
    "intellipick",
    "itacademy",
    "bisness",
    "faq",
    "ddingdong",
  ];
  const mypageAnchorKeys = [
    "classroom",
    "point",
    "coupon",
    "voucher",
    "certificate",
    "notice",
    "basicInfo",
    "logout",
    "rcmdsfree",
    "registrationStatus",
  ];
  if (gnbAnchorKeys.indexOf(keyName) !== -1) return "scc_gnb_click";
  if (mypageAnchorKeys.indexOf(keyName) !== -1) return "scc_myPage_click";
  if (keyName === "b2b") return "scc_b2b_click";
  if (["bizRecruit", "bizOutsourcing", "bizEducation"].indexOf(keyName) !== -1)
    return "scc_b2b_click";
  if (["sparthon4"].indexOf(keyName) !== -1)
    return "scc_spartonEventPage_lnb_click";
  return "scc_gnb_click";
};

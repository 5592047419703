import {
  Bucketlist,
  ChatGptMyService,
  Kotlin,
  Momentum,
  MyProfile,
  ProjectManaging,
  WebCrawlingMarket,
} from "../catalog/courses.model";

export const MonthlyCourses = [Bucketlist, Momentum, MyProfile];

export const Categories = [
  {
    name: "전체",
    isInitial: true,
  },
  {
    name: "Best",
    isNew: true,
  },
  {
    name: "무료 강의",
  },
  {
    name: "코딩의 효능",
    isNew: true,
  },
  {
    name: "인기 급상승",
    isFire: true,
  },
  {
    name: "코딩 입문",
  },
  {
    name: "실전/응용",
    isNew: true,
  },
  {
    name: "해적단 코스",
    isNew: true,
  },
  {
    name: "국비지원",
  },
  {
    name: "청소년",
  },
  {
    name: "공개 예정",
  },
];

export const Languages = [
  {
    key: "all",
    title: "전체",
    weeks: [],
    courseId: "0",
  },
  {
    key: "html",
    title: "HTML · CSS",
    weeks: [],
    courseId: "1",
  },
  {
    key: "flutter",
    title: "Flutter",
    weeks: [],
    courseId: "2",
  },
  {
    key: "sql",
    title: "SQL",
    weeks: [],
    courseId: "3",
  },
  // {
  //   title: "React",
  //   weeks: [],
  //   courseId: "4",
  // },
  {
    key: "spring",
    title: "Spring",
    weeks: [],
    courseId: "5",
  },
  {
    key: "python",
    title: "Python",
    weeks: [],
    courseId: "6",
  },
  {
    key: "javascript",
    title: "JavaScript",
    weeks: [],
    courseId: "7",
  },
  {
    key: "java",
    title: "Java",
    weeks: [],
    courseId: "8",
  },
  {
    key: "cpp",
    title: "C++",
    weeks: [],
    courseId: "9",
  },
  {
    key: "unity",
    title: "Unity",
    weeks: [],
    courseId: "10",
  },
  {
    key: "nocode",
    title: "No-code",
    weeks: [],
    courseId: "11",
  },
  {
    key: "ai",
    title: "AI",
    weeks: [],
    courseId: "12",
  },
  {
    key: "c#",
    title: "C#",
    weeks: [],
    courseId: "13",
  },
  {
    key: "typescript",
    title: "TypeScript",
    weeks: [],
    courseId: "14",
  },
  {
    key: "kotlin",
    title: "Kotlin",
    weeks: [],
    courseId: "15",
  },
];

export const FundingCourses = [
  ProjectManaging,
  ChatGptMyService,
  WebCrawlingMarket,
];

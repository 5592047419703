import { useEffect, useState } from "react";
import { calculateDaysBetween } from "../../_common/utils/dateFormatter";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { useUserId, useUserInfo } from "../../_common/auth";
import {
  useExecuteWithLoginV2,
  useIsLoggedInV2,
  usePlainLoginV2,
} from "../../_common/login";
import { useRouter } from "next/router";
import { useToast } from "src/components/atoms/Toast/Toast";
import { useIsMobile } from "src/businesslogics/_layout/window";
import { useAnimationAtom } from "../useAnimation";
import { useAtom } from "jotai";
import {
  isShowMarketingModalAtom,
  isShowPrizeModalAtom,
  prizeAtom,
} from "src/stores/event/hyoneung";
import { useToggleModal } from "src/businesslogics/modal/useToggleModal";
import { usePrizeMutation } from "src/queries/event/hyoneung";
import { useThrottle } from "src/businesslogics/community/Q_Q/useThrottle";
import { useInterval } from "src/businesslogics/community/review";

const EVENT_START_DATE = new Date("2023/06/20 00:00:00");
const EVENT_PERIOD = 14;

const getTimeUntilNoonNextDay = () => {
  const now = new Date();
  let nextNoon;

  if (now.getHours() >= 12) {
    nextNoon = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      12,
      0,
      0,
    );
  } else {
    nextNoon = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      12,
      0,
      0,
    );
  }

  const diff = nextNoon.getTime() - now.getTime();

  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return {
    hours: hours < 10 ? "0" + hours : hours,
    minutes: minutes < 10 ? "0" + minutes : minutes,
    seconds: seconds < 10 ? "0" + seconds : seconds,
  };
};

export const isFirstEventDay = () => {
  const today = new Date();

  return today.getDate() === EVENT_START_DATE.getDate();
};

export const useCountdownToBowlOpening = () => {
  const [time, setTime] = useState(getTimeUntilNoonNextDay());

  useInterval(() => {
    setTime(getTimeUntilNoonNextDay());
  }, 1000);

  return time;
};

const initialEventDate = () => {
  const eventEndDate = new Date(EVENT_START_DATE);

  eventEndDate.setDate(eventEndDate.getDate() + EVENT_PERIOD - 1);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return eventEndDate.getTime() > today.getTime() ? today : eventEndDate;
};

export const useHyoneungEventDate = () => {
  const [eventDate, setEventDate] = useState(initialEventDate());
  const [eventDayIndex, setEventDayIndex] = useState(
    calculateDaysBetween(EVENT_START_DATE, eventDate),
  );

  useEffect(() => {
    setEventDayIndex(calculateDaysBetween(EVENT_START_DATE, eventDate));
  }, [eventDate]);

  const decrementDate = () => {
    if (eventDayIndex > 0) {
      const newDate = new Date(eventDate);
      newDate.setDate(newDate.getDate() - 1);
      setEventDate(newDate);
    }
  };

  const incrementDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (
      eventDayIndex < EVENT_PERIOD - 1 &&
      eventDate.getTime() < today.getTime()
    ) {
      const newDate = new Date(eventDate);
      newDate.setDate(newDate.getDate() + 1);
      setEventDate(newDate);
    }
  };

  return { eventDate, eventDayIndex, decrementDate, incrementDate };
};

export const useIsOpenBowl = (openCount: number | null) => {
  const [isOpenBowl, setIsOpenBowl] = useState(false);

  useEffect(() => {
    if (openCount && openCount > 0) {
      setIsOpenBowl(true);
      return;
    }
    setIsOpenBowl(false);
  }, [openCount]);

  return isOpenBowl;
};

const copyToClipboard = async (shareUrl) => {
  try {
    await navigator.clipboard.writeText(encodeURI(shareUrl));
  } catch (err) {
    console.log(err);
  }
};

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

const copyToClipboardWithDocument = (shareUrl) => {
  const el = document.createElement("textarea");
  el.value = shareUrl;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const shareUrl = async (shareUrl, onSuccess, onFail) => {
  if (navigator.share) {
    navigator
      .share({
        title: "",
        url: shareUrl,
      })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        console.log("Sharing failed", e);
      });
  } else {
    onFail();
    console.log("Share API not supported.");
  }
};

export const useOnClickShare = () => {
  const userId = useUserId();
  const userName = useUserInfo("name");
  const withLogin = useExecuteWithLoginV2();
  const router = useRouter();
  const showToast = useToast();
  const isMobile = useIsMobile();

  const url = `${process.env.BASE_URL}${router.pathname}?f_name=${userName}&f_uid=${userId}`;

  return (button_text, location) => {
    withLogin(
      () => {
        if (isMobile) {
          shareUrl(
            url,
            () => {
              showToast(
                "친구에게 링크를 공유했어요.\n친구가 이벤트에 참여하게 되면 알려드릴게요!",
              );
            },
            () => {
              if (isAndroid()) {
                copyToClipboardWithDocument(url);
              } else {
                copyToClipboard(url);
              }
              showToast("링크가 복사되었어요. 지금 친구에게 공유해 보세요.");
            },
          );
        } else {
          copyToClipboard(url);
          showToast("링크가 복사되었어요. 지금 친구에게 공유해 보세요.");
        }
        sendCPLog("scc_event_hyoneung_share_click", {
          loc: location,
          button_text: button_text,
          url: url,
        });
      },
      () => {
        showToast("친구에게 추천하고 혜택을 받으려면 \n로그인이 필요해요.");
      },
    );
  };
};

export const useOpenBowl = (openCount, f_uid) => {
  const [, triggerAnimation] = useAnimationAtom(2000);

  const [, setPrize]: any = useAtom(prizeAtom);
  const [, toggleShowPrizeModal] = useToggleModal(isShowPrizeModalAtom);
  const [, toggleShowMarketingModal] = useToggleModal(isShowMarketingModalAtom);
  const userId = useUserId();

  const prizeMutate = usePrizeMutation(userId, f_uid);
  const isLoggedIn = useIsLoggedInV2();
  const goLoginV2 = usePlainLoginV2();

  const handleNotLoggedIn = () => {
    goLoginV2();
    sendCPLog("scc_event_hyoneung_click", {
      button_text: "참여하기",
      page_url: "https://online.spartacodingclub.kr/login",
    });
  };

  const handleResult = async (result) => {
    if (result?.is_marketing_needed) {
      toggleShowMarketingModal();
    } else if (result?.not_enough_chance) {
    } else {
      setTimeout(() => {
        setPrize(result.prize);
        toggleShowPrizeModal();
      }, 2000);
    }
  };

  const onClickOpenBowl = async () => {
    if (!isLoggedIn) {
      handleNotLoggedIn();
      return;
    }
    if (openCount === 0 || openCount === null) {
      return;
    }

    sendCPLog("scc_event_hyoneung_click", {
      button_text: "참여하기",
      page_url: "https://spartacodingclub.kr/hyoneung",
    });

    triggerAnimation();

    try {
      const result = await prizeMutate.mutateAsync();
      handleResult(result);
    } catch (error) {
      console.error(error);
    }
  };

  const throttledOnClickOpenBowl = useThrottle(onClickOpenBowl, 2000);

  return throttledOnClickOpenBowl;
};

import React, { useState } from "react";
import {
  useClickAnchor,
  useIsCurrentUrl,
  useOnClickGnbItem,
} from "../../../../businesslogics/nbGuide";
import * as S from "./nbGuideGnb.style";
import {
  nbGuideGnbAnchors,
  nbGuideLogoAnchor,
} from "../../../../models/nbGuide/gnb.models";
import { SpartaLogo } from "src/components/atoms/Icons/SpartaLogo/SpartaLogo.svg";

const NbGuideGnbTablet = ({ isOpen, setIsOpen }) => {
  const onClickLogo = useClickAnchor(nbGuideLogoAnchor);
  const hoverStateArray = useState("");
  const [isSnbDown, setIsSnbDown] = [isOpen, setIsOpen];

  const setHoverState = hoverStateArray[1];
  const makeSnbDown = () => {
    setIsSnbDown(true);
  };
  const makeSnbUp = () => {
    setIsSnbDown(false);
    setHoverState("");
  };

  return (
    <>
      <Gnb
        onClickLogo={onClickLogo}
        hoverStateArray={hoverStateArray}
        makeSnbDown={makeSnbDown}
      />
      <S.Divider />
      {/* 이벤트 연장 가능성으로 주석처리 */}
      {/*<EventLineBanner />*/}
    </>
  );
};

const Gnb = ({ onClickLogo, hoverStateArray, makeSnbDown }) => {
  return (
    <S.GnbContainer>
      <S.LogoContainer onClick={onClickLogo}>
        <SpartaLogo />
        <S.LogoGuideText>국비지원 가이드북</S.LogoGuideText>
      </S.LogoContainer>
      <InnerContainer hoverStateArray={hoverStateArray} isSub={false} />
    </S.GnbContainer>
  );
};

const Snb = ({ isVisible, hoverStateArray }) => {
  return (
    <S.SnbContainer isVisible={isVisible}>
      <S.LeftPadding />
      <InnerContainer hoverStateArray={hoverStateArray} isSub={true} />
      <S.Padding />
    </S.SnbContainer>
  );
};

const InnerContainer = ({ hoverStateArray, isSub }) => {
  const anchors = nbGuideGnbAnchors;
  const [hoverState, setHoverState] = hoverStateArray;

  return (
    <S.InnerContainer>
      {anchors.map((anchor) => {
        return <GnbItem key={`gnb-desktop-${anchor.key}`} anchor={anchor} />;
      })}
    </S.InnerContainer>
  );
};

const GnbItem = ({ anchor }) => {
  const isCurrentUrl = useIsCurrentUrl(anchor.href);
  const onClickGndItem = useOnClickGnbItem(anchor.href, anchor.name);
  return (
    <S.GnbItem onClick={onClickGndItem} isCurrentUrl={isCurrentUrl}>
      {anchor.name}
    </S.GnbItem>
  );
};

const Column = ({ anchor, hoverState, setHoverState, isSub }) => {
  return (
    <S.Column
      key={`gnb-desktop-${anchor.key}`}
      onMouseEnter={() => setHoverState(anchor.key)}
    >
      {isSub ? (
        anchor.subAnchors?.map((subAnchor) => {
          return (
            <GnbAnchor
              key={`gnb-desktop-subAnchor-${subAnchor.key}`}
              anchor={subAnchor}
              isSub={isSub}
              isActive={hoverState === anchor.key}
            />
          );
        })
      ) : (
        <GnbAnchor
          anchor={anchor}
          isActive={hoverState === anchor.key}
          isSub={isSub}
        />
      )}
      {isSub && anchor.subAnchors?.length % 2 === 1 && <S.Padding />}
    </S.Column>
  );
};

const GnbAnchor = ({ anchor, isSub, isActive }) => {
  const onClickAnchor = useClickAnchor(anchor);
  const isAlive = anchor.href ? true : false;

  return (
    <S.Anchor
      onClick={onClickAnchor}
      isSub={isSub}
      isActive={isActive}
      isAlive={isAlive}
    >
      {anchor.name}
    </S.Anchor>
  );
};

export default NbGuideGnbTablet;
